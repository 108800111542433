import dayjs from 'dayjs'
import { i18n } from 'next-i18next'

export class RegHistoryModel {
  status: string
  businessRegistrationNumber: string
  createdAt: string
  joinStatusMessage: string

  constructor({ status, businessRegistrationNumber, createdAt, joinStatusMessage }) {
    this.status = status
    this.businessRegistrationNumber = businessRegistrationNumber
    this.createdAt = createdAt
    this.joinStatusMessage = joinStatusMessage
  }

  public get displayStatus(): string {
    return i18n.t(`membership:newUsers.detail.regHistory.status.${this.status}`)
  }

  public get displayCreatedAt(): string {
    return this.createdAt ? dayjs(this.createdAt).format('YYYY-MM-DD HH:mm') : ''
  }
}

export class RegHistoryModelCollection {
  items: RegHistoryModel[]

  constructor({ items }) {
    this.items = items
  }

  static init(): RegHistoryModelCollection {
    return new RegHistoryModelCollection({ items: null })
  }
}
