/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConsignmentVehicleListDtoRequestWithExcel = {
    plateNumber?: string;
    vehicleOperationType?: ConsignmentVehicleListDtoRequestWithExcel.vehicleOperationType;
};
export namespace ConsignmentVehicleListDtoRequestWithExcel {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

