/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ZigTruckProductListDtoRequest = {
    businessRegistrationNumber?: string;
    driverName?: string;
    driverUserId?: string;
    fromDate?: string;
    isReceiveAdvertisingInfoAgreements?: Array<boolean>;
    isUseMarketingAgreements?: Array<boolean>;
    pageNo?: number;
    pageSize?: number;
    productManageType: ZigTruckProductListDtoRequest.productManageType;
    toDate?: string;
    truckNumber?: string;
    zigtruckProductStatuses?: Array<'BEFORE_SALE' | 'COMPLETED' | 'DELETE' | 'ORIGIN_DATA_REGISTER' | 'PAUSE' | 'SALE'>;
};
export namespace ZigTruckProductListDtoRequest {
    export enum productManageType {
        TEMP = 'TEMP',
        ZIGTRUCK = 'ZIGTRUCK',
    }
}

