/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceConfigInfoRes = {
    fixed: boolean;
    type: PriceConfigInfoRes.type;
    value?: number;
};
export namespace PriceConfigInfoRes {
    export enum type {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
}

