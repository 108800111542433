/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalTime } from './LocalTime';
import type { ZoneOffset } from './ZoneOffset';
export type ZoneOffsetTransitionRule = {
    dayOfMonthIndicator?: number;
    dayOfWeek?: ZoneOffsetTransitionRule.dayOfWeek;
    localTime?: LocalTime;
    midnightEndOfDay?: boolean;
    month?: ZoneOffsetTransitionRule.month;
    offsetAfter?: ZoneOffset;
    offsetBefore?: ZoneOffset;
    standardOffset?: ZoneOffset;
    timeDefinition?: ZoneOffsetTransitionRule.timeDefinition;
};
export namespace ZoneOffsetTransitionRule {
    export enum dayOfWeek {
        FRIDAY = 'FRIDAY',
        MONDAY = 'MONDAY',
        SATURDAY = 'SATURDAY',
        SUNDAY = 'SUNDAY',
        THURSDAY = 'THURSDAY',
        TUESDAY = 'TUESDAY',
        WEDNESDAY = 'WEDNESDAY',
    }
    export enum month {
        APRIL = 'APRIL',
        AUGUST = 'AUGUST',
        DECEMBER = 'DECEMBER',
        FEBRUARY = 'FEBRUARY',
        JANUARY = 'JANUARY',
        JULY = 'JULY',
        JUNE = 'JUNE',
        MARCH = 'MARCH',
        MAY = 'MAY',
        NOVEMBER = 'NOVEMBER',
        OCTOBER = 'OCTOBER',
        SEPTEMBER = 'SEPTEMBER',
    }
    export enum timeDefinition {
        STANDARD = 'STANDARD',
        UTC = 'UTC',
        WALL = 'WALL',
    }
}

