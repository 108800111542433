/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderVehicleUpdateDtoRequest = {
    consignmentMdm?: string;
    fixedVehicleId?: number;
    isUsed?: boolean;
    vehicleOperationType?: OrderVehicleUpdateDtoRequest.vehicleOperationType;
};
export namespace OrderVehicleUpdateDtoRequest {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

