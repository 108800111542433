import { create } from 'zustand'
import * as Common from '@constants/common'
import { MenuItem } from '@features/general/adminUser/types'
import { devtoolsInNonProd } from '@utils'
import { searchMenuTree } from '@utils/menuTree'
import { MenuDto } from 'src/data'

type State = {
  menuCategory: Common.MenuCategory
  menu: Record<Common.MenuCategory, MenuItem[]>
  menuRootPath: Record<Common.MenuCategory, string>
  availableMenu: number
  selectedId: Record<Common.MenuCategory, MenuItem['menuConstId']>
  isLoaded: boolean
}
type Action = {
  setMenuCategory(menuCategory: Common.MenuCategory): void
  setMenu(menus: MenuDto[]): void
  setSelectedId(id: MenuDto['menuConstId']): void
  reset(): void
}
interface IMenuState extends State, Action {}

const initialState = (): State => ({
  menuCategory: '',
  menu: {
    theunban: [],
    portal: [],
  },
  menuRootPath: {
    theunban: '',
    portal: '',
  },
  // selectedId 초기 값: 로그인 후 기본 홈경로 지정시 menuConstId 값을 기재하면 됨
  selectedId: {
    theunban: '',
    portal: 'M000000069', // 운송포털 기본 홈경로: 복화현황조회
  },
  availableMenu: 0,
  isLoaded: false,
})

export const useMenuStore = create<IMenuState>()(
  devtoolsInNonProd(
    (set, get) => ({
      ...initialState(),
      setMenuCategory: (menuCategory: Common.MenuCategory) => set({ menuCategory }),
      setMenu: (menus: MenuDto[]) => {
        const theunbanMenu = menus?.find(item => item.menuConstId === 'M000000001') as MenuItem
        const portalMenu = menus?.find(item => item.menuConstId === 'M000000058') as MenuItem
        const menu: State['menu'] = { theunban: theunbanMenu?.subMenus ?? [], portal: portalMenu?.subMenus ?? [] }
        const menuRootPath = { ...get().menuRootPath }
        const selectedId: State['selectedId'] = { ...get().selectedId }

        // 더운반 첫번째 메뉴 선택
        if (menu.theunban.length > 0) {
          searchMenuTree(menu.theunban, currentNode => {
            if (
              currentNode.menuUrl &&
              ((!!selectedId.thrunban && selectedId.theunban === currentNode.menuConstId) || !selectedId.theunban)
            ) {
              menuRootPath.theunban = currentNode.menuUrl
              selectedId.theunban = currentNode.menuConstId
              return false
            }
          })
        }

        // 운송포털 첫번째 메뉴 선택
        if (menu.portal.length > 0) {
          searchMenuTree(menu.portal, currentNode => {
            if (
              currentNode.menuUrl &&
              ((!!selectedId.portal && selectedId.portal === currentNode.menuConstId) || !selectedId.portal)
            ) {
              menuRootPath.portal = currentNode.menuUrl
              selectedId.portal = currentNode.menuConstId
              return false
            }
          })
        }

        set({
          menu,
          menuRootPath,
          selectedId,
          availableMenu: menus?.length,
          isLoaded: true,
        })
      },
      setSelectedId: (nextSelectedId: MenuDto['menuConstId']) => {
        const menuCategory = get().menuCategory
        if (menuCategory) {
          const selectedId = get().selectedId
          selectedId[menuCategory] = nextSelectedId
          set({ selectedId })
        }
      },
      reset: () => set(initialState()),
    }),
    { name: 'MenuStore' },
  ),
)
