/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DriverDispatchRequestDtoRequest = {
    dispatchChannel?: DriverDispatchRequestDtoRequest.dispatchChannel;
    driverFeeAmount: number;
    finalPrice: number;
    isMonthly?: boolean;
    memberId: string;
    orderId: string;
    otherEtaxPublisherType?: DriverDispatchRequestDtoRequest.otherEtaxPublisherType;
    storageOpenFeeAmount: number;
};
export namespace DriverDispatchRequestDtoRequest {
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
}

