/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberSearchDtoPriceGroupPolicyDto } from './MemberSearchDtoPriceGroupPolicyDto';
export type MemberSearchDtoResponseSearchOwnerUser = {
    approvalStatus?: MemberSearchDtoResponseSearchOwnerUser.approvalStatus;
    approvalStatusValue?: string;
    businessGroupName?: string;
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessType?: MemberSearchDtoResponseSearchOwnerUser.businessType;
    businessTypeValue?: string;
    department?: string;
    eevidenceUrl?: string;
    isRegisteredCard?: boolean;
    joinStatus?: MemberSearchDtoResponseSearchOwnerUser.joinStatus;
    mdmCode?: string;
    mdmRegistrationStatus?: MemberSearchDtoResponseSearchOwnerUser.mdmRegistrationStatus;
    mdmRegistrationStatusValue?: string;
    memberApprovalCompletedAt?: string;
    memberCategoryName?: string;
    memberCategoryType?: MemberSearchDtoResponseSearchOwnerUser.memberCategoryType;
    memberCreatedAt?: string;
    memberId?: string;
    officePhoneNumber?: string;
    ownerUserName?: string;
    paymentMethodType?: MemberSearchDtoResponseSearchOwnerUser.paymentMethodType;
    paymentMethodTypeValue?: string;
    phoneNumber?: string;
    priceGroupPolicies?: Array<MemberSearchDtoPriceGroupPolicyDto>;
    priceGroupPolicyCount?: number;
    registrationChannelType?: MemberSearchDtoResponseSearchOwnerUser.registrationChannelType;
    registrationChannelTypeValue?: string;
    transactionMethodType?: MemberSearchDtoResponseSearchOwnerUser.transactionMethodType;
    transactionMethodTypeValue?: string;
    userId?: string;
};
export namespace MemberSearchDtoResponseSearchOwnerUser {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum businessType {
        CORPORATE = 'CORPORATE',
        INDIVIDUAL = 'INDIVIDUAL',
        NO_TAX = 'NO_TAX',
        SIMPLIFIED = 'SIMPLIFIED',
        SIMPLIFIED_NO_TAX = 'SIMPLIFIED_NO_TAX',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
    }
    export enum paymentMethodType {
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        QUICK_PAY = 'QUICK_PAY',
    }
    export enum registrationChannelType {
        CJ_ENM = 'CJ_ENM',
        UNBAN = 'UNBAN',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

