/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ZigTruckProductListDtoZigTruckProductInfo = {
    businessRegistrationNumber?: string;
    createdAt?: string;
    createdBy?: string;
    driverName?: string;
    driverUserId?: string;
    finalPrice?: number;
    highPrice?: number;
    invoiceAttachFileCreatedAt?: string;
    invoiceAttachFileSignedUrl?: string;
    lowPrice?: number;
    ownerName?: string;
    price?: number;
    productManageType?: ZigTruckProductListDtoZigTruckProductInfo.productManageType;
    productPurchaseManageStatus?: ZigTruckProductListDtoZigTruckProductInfo.productPurchaseManageStatus;
    productStatus?: ZigTruckProductListDtoZigTruckProductInfo.productStatus;
    productType?: ZigTruckProductListDtoZigTruckProductInfo.productType;
    receiveAdvertisingInfoAgreement?: boolean;
    salesPurchaseAgreementAttachFileCreatedAt?: string;
    salesPurchaseAgreementAttachFileSignedUrl?: string;
    tons?: string;
    truckName?: string;
    truckNumber?: string;
    updatedAt?: string;
    updatedBy?: string;
    useMarketingAgreement?: boolean;
    zigtruckManagedProductCreatedAt?: string;
    zigtruckProductId?: number;
};
export namespace ZigTruckProductListDtoZigTruckProductInfo {
    export enum productManageType {
        TEMP = 'TEMP',
        ZIGTRUCK = 'ZIGTRUCK',
    }
    export enum productPurchaseManageStatus {
        AFTER_CONSULTING = 'AFTER_CONSULTING',
        BEFORE_CONSULTING = 'BEFORE_CONSULTING',
        COMPLETED_CONTRACT = 'COMPLETED_CONTRACT',
        CONSULTING = 'CONSULTING',
    }
    export enum productStatus {
        BEFORE_SALE = 'BEFORE_SALE',
        COMPLETED = 'COMPLETED',
        DELETE = 'DELETE',
        ORIGIN_DATA_REGISTER = 'ORIGIN_DATA_REGISTER',
        PAUSE = 'PAUSE',
        SALE = 'SALE',
    }
    export enum productType {
        DIRECT = 'DIRECT',
        SPEED = 'SPEED',
    }
}

