/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetBusinessCreditInfoDtoUsingRequest = {
    businessName?: string;
    businessRegistrationNumber?: string;
    creditResult?: GetBusinessCreditInfoDtoUsingRequest.creditResult;
    creditSalesAvailabilityType?: GetBusinessCreditInfoDtoUsingRequest.creditSalesAvailabilityType;
    endDateTime: string;
    isUnpaidBonds?: boolean;
    mdmCode?: string;
    ownerUserId?: string;
    pageNo: number;
    pageSize: number;
    startDateTime: string;
};
export namespace GetBusinessCreditInfoDtoUsingRequest {
    export enum creditResult {
        F = 'F',
        P = 'P',
    }
    export enum creditSalesAvailabilityType {
        GOOD = 'GOOD',
        LOW = 'LOW',
    }
}

