/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SavePriceGroupPolicyDtoNonContractPolicy = {
    calType?: SavePriceGroupPolicyDtoNonContractPolicy.calType;
    driverValue?: number;
    ownerValue?: number;
    type?: SavePriceGroupPolicyDtoNonContractPolicy.type;
};
export namespace SavePriceGroupPolicyDtoNonContractPolicy {
    export enum calType {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
    export enum type {
        MAX = 'MAX',
        NONE = 'NONE',
    }
}

