/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessTabConfigDtoContractCategoryBusiness = {
    affiliateCode?: BusinessTabConfigDtoContractCategoryBusiness.affiliateCode;
    businessGroupId?: number;
    businessGroupName?: string;
    businessId?: string;
    businessName?: string;
    isContractFreightOrderOpen?: string;
};
export namespace BusinessTabConfigDtoContractCategoryBusiness {
    export enum affiliateCode {
        B_MART = 'B_MART',
        COMMON = 'COMMON',
        FURSYS = 'FURSYS',
        FURSYS_VENDOR = 'FURSYS_VENDOR',
        HCORE = 'HCORE',
        KOAS = 'KOAS',
        LIVART = 'LIVART',
        NAMYANG_WATER = 'NAMYANG_WATER',
        ONE = 'ONE',
        PND = 'PND',
        SK_NETWORKS = 'SK_NETWORKS',
        WND = 'WND',
    }
}

