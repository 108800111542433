/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderUpdateDtoEstimateRoutePrice = {
    display?: string;
    displayCoupon?: string;
    displayLcl?: string;
    orderType?: OrderUpdateDtoEstimateRoutePrice.orderType;
    ownerOrderType?: OrderUpdateDtoEstimateRoutePrice.ownerOrderType;
};
export namespace OrderUpdateDtoEstimateRoutePrice {
    export enum orderType {
        MULTI = 'MULTI',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
    export enum ownerOrderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

