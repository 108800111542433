import { GLOBAL_STORE } from '@constants/storeKeys'
import { MetropolitanListModelMapper, OrderMetaAllListModelMapper } from '@features/common/mappers'
import { MetropolitanListModel, OrderMetaAllListModel } from '@features/common/types/models'
import { devtoolsInNonProd } from '@utils'
import { create } from 'zustand'
import { IOrderMetaOption } from '@features/common/types/models/orderMetaAllListModel'

interface IGlobalState {
  isLoading: boolean
  isRouteLoading: boolean
  isFetchedCommonMetaList: boolean
  isFetchedMetropolitanList: boolean
  orderMetaAllListModel: OrderMetaAllListModel
  metropolitanListModel: MetropolitanListModel
  setIsLoading(isLoading: boolean): void
  setIsRouteLoading(isRouteLoading: boolean): void
  setCommonMetaList(result): Promise<void>
  getCommonMetropolitanList(result): Promise<void>
  getActiveOtherEtaxPublisherType(): Array<IOrderMetaOption>
}

export const useGlobalStore = create<IGlobalState>()(
  devtoolsInNonProd(
    (set, get) => ({
      isLoading: false,
      isRouteLoading: false,
      isFetchedCommonMetaList: false,
      isFetchedMetropolitanList: false,
      orderMetaAllListModel: OrderMetaAllListModel.init(),
      metropolitanListModel: MetropolitanListModel.init(),

      setIsLoading: (isLoading: boolean) => set({ isLoading }),
      setIsRouteLoading: (isRouteLoading: boolean) => set({ isRouteLoading }),
      setCommonMetaList: async result => {
        const orderMetaAllListModel = OrderMetaAllListModelMapper.mapToPresentation(result)

        if (orderMetaAllListModel) {
          set({ orderMetaAllListModel, isFetchedCommonMetaList: true })
        }
      },

      // 정산 유형에서 HOME_TAX 제거
      getActiveOtherEtaxPublisherType: () =>
        get().orderMetaAllListModel.otherEtaxPublisherType.filter(item => item.key !== 'HOME_TAX'),

      getCommonMetropolitanList: async result => {
        const metropolitanListModel = MetropolitanListModelMapper.mapToPresentation(result)

        if (metropolitanListModel) {
          set({ metropolitanListModel, isFetchedCommonMetaList: true })
        }
      },
    }),
    { name: GLOBAL_STORE },
  ),
)
