/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminPartnerOrderAccidentCancelDtoRequest = {
    accidentCancelType: AdminPartnerOrderAccidentCancelDtoRequest.accidentCancelType;
    memo?: string;
    orderId: string;
    ownerNewTotalAmount?: number;
    ownerOldTotalAmount?: number;
    partnerNewTotalAmount?: number;
    partnerOldTotalAmount?: number;
    serviceType: AdminPartnerOrderAccidentCancelDtoRequest.serviceType;
};
export namespace AdminPartnerOrderAccidentCancelDtoRequest {
    export enum accidentCancelType {
        BREAKDOWNS_AND_ACCIDENTS = 'BREAKDOWNS_AND_ACCIDENTS',
        BREAKDOWNS_AND_ACCIDENTS_BY_PARTNER = 'BREAKDOWNS_AND_ACCIDENTS_BY_PARTNER',
        CONSIGNED_FIXED_VEHICLE = 'CONSIGNED_FIXED_VEHICLE',
        DEFECTIVE_AND_RECALL = 'DEFECTIVE_AND_RECALL',
        ETC = 'ETC',
        INCORRECT_INFORMATION = 'INCORRECT_INFORMATION',
        PERSONAL_AFFAIRS = 'PERSONAL_AFFAIRS',
        PICKUP_COMPLETE_DELAY = 'PICKUP_COMPLETE_DELAY',
        PICKUP_COMPLETE_DELAY_BY_PARTNER = 'PICKUP_COMPLETE_DELAY_BY_PARTNER',
        RESCHEDULE = 'RESCHEDULE',
    }
    export enum serviceType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
}

