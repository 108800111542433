/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SaveVerificationStatusDtoRequest = {
    approvalStatus?: SaveVerificationStatusDtoRequest.approvalStatus;
    returnMessage?: string;
    stickerEventVerificationId?: string;
};
export namespace SaveVerificationStatusDtoRequest {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

