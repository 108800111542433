/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchPriceGroupPolicyDtoResult = {
    businessCount?: number;
    createdAt?: string;
    createdBy?: string;
    isDefault?: boolean;
    priceGroupPolicyId?: string;
    serviceType?: SearchPriceGroupPolicyDtoResult.serviceType;
    shortTitle?: string;
    title?: string;
    transactionMethodType?: SearchPriceGroupPolicyDtoResult.transactionMethodType;
    transactionMethodTypeValue?: string;
    updatedAt?: string;
    updatedBy?: string;
};
export namespace SearchPriceGroupPolicyDtoResult {
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

