/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NewPartnerUserDtoRequestApprovalSave = {
    approvalStatus: NewPartnerUserDtoRequestApprovalSave.approvalStatus;
    approvalStatusMessage?: string;
    businessId: string;
    memberId: string;
};
export namespace NewPartnerUserDtoRequestApprovalSave {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

