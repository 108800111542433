/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SapSettlementDtoInvoiceOwnerFile } from './SapSettlementDtoInvoiceOwnerFile';
export type SapSettlementDtoSalesResult = {
    apprNo?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessSettlementGroupId?: string;
    businessSettlementGroupName?: string;
    businessTaxType?: string;
    carTonTypeValue?: string;
    carTypeValue?: string;
    cardApproveNo?: string;
    deliveryCompleteDate?: string;
    departureAddressMemo?: string;
    departureManagerName?: string;
    departureManagerPhoneNumber?: string;
    destinationAddressMemo?: string;
    destinationManagerName?: string;
    destinationManagerPhoneNumber?: string;
    documentId?: string;
    documentIssuedAt?: string;
    documentStatusType?: SapSettlementDtoSalesResult.documentStatusType;
    documentStatusTypeValue?: string;
    dropAt?: string;
    dropRequestAt?: string;
    freightType?: SapSettlementDtoSalesResult.freightType;
    freightTypeValue?: string;
    fromAddress?: string;
    fromAddressName?: string;
    isRegistered?: boolean;
    orderCreatedAt?: string;
    orderId?: string;
    orderNo?: string;
    orderOwnerId?: string;
    orderOwnerName?: string;
    paySuccessAt?: string;
    paymentConditionCodeDesc?: string;
    paymentMethod?: string;
    paymentMethodValue?: string;
    paymentReceiptFile?: SapSettlementDtoInvoiceOwnerFile;
    pickupAt?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    supplyAmount?: number;
    tempDocumentId?: string;
    toAddress?: string;
    toAddressName?: string;
    totalAmount?: number;
    vatAmount?: number;
};
export namespace SapSettlementDtoSalesResult {
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

