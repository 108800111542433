/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverBannerDtoResponseInfo = {
    createdAt?: string;
    createdBy?: string;
    endDate?: string;
    id?: number;
    linkUrl?: string;
    name?: string;
    signUrl?: string;
    startDate?: string;
    status?: SearchDriverBannerDtoResponseInfo.status;
    statusValue?: string;
};
export namespace SearchDriverBannerDtoResponseInfo {
    export enum status {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
}

