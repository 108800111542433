/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonbProductsHistory } from './JsonbProductsHistory';
import type { JsonbProductsImage } from './JsonbProductsImage';
import type { JsonbProductsMortgageHistory } from './JsonbProductsMortgageHistory';
import type { JsonbProductsSeizureHistory } from './JsonbProductsSeizureHistory';
import type { JsonbProductsTuningHistory } from './JsonbProductsTuningHistory';
import type { JsonbZigTruckProductsCarOption } from './JsonbZigTruckProductsCarOption';
import type { JsonbZigTruckProductsMaintenance } from './JsonbZigTruckProductsMaintenance';
export type ZigTruckProductDetailDtoResult = {
    accidentContents?: string;
    axis?: ZigTruckProductDetailDtoResult.axis;
    businessRegistrationNumber?: string;
    carOption?: JsonbZigTruckProductsCarOption;
    carRegisterSearchDate?: string;
    carType?: string;
    carUse?: string;
    colorCode?: string;
    colorName?: string;
    createdAt?: string;
    createdBy?: string;
    distance?: number;
    driverName?: string;
    driverPlatformUserId?: number;
    driverPlatformUserPhoneNumber?: string;
    driverUserId?: string;
    etcInfo?: string;
    finalPrice?: number;
    firstRegistrationDate?: string;
    fuel?: string;
    highPrice?: number;
    identificationNumber?: string;
    inspectionHistory?: Array<JsonbProductsHistory>;
    inspectionInvalidEndDate?: string;
    inspectionInvalidStartDate?: string;
    invoiceAttachFileCreatedAt?: string;
    invoiceAttachFileSignedUrl?: string;
    lastOwnerInfo?: JsonbProductsHistory;
    loadedCode?: string;
    loadedInnerArea?: number;
    loadedInnerHeight?: number;
    loadedInnerLength?: number;
    loadedName?: string;
    lowPrice?: number;
    maintenance?: JsonbZigTruckProductsMaintenance;
    manufacturerCategoriesCode?: string;
    manufacturerCategoriesId?: number;
    manufacturerCategoriesName?: string;
    modelId?: number;
    modelName?: string;
    mortgageHistory?: Array<JsonbProductsMortgageHistory>;
    motorType?: string;
    ownerName?: string;
    power?: string;
    price?: number;
    productCreateStep?: string;
    productManageType?: ZigTruckProductDetailDtoResult.productManageType;
    productPurchaseManageStatus?: ZigTruckProductDetailDtoResult.productPurchaseManageStatus;
    productStatus: ZigTruckProductDetailDtoResult.productStatus;
    productType?: ZigTruckProductDetailDtoResult.productType;
    productsImage?: JsonbProductsImage;
    salesPurchaseAgreementAttachFileCreatedAt?: string;
    salesPurchaseAgreementAttachFileSignedUrl?: string;
    seizureHistory?: Array<JsonbProductsSeizureHistory>;
    tireStatus?: string;
    tons?: string;
    tradingHistory?: Array<JsonbProductsHistory>;
    transmission?: string;
    truckName?: string;
    truckNumber?: string;
    tuningHistory?: Array<JsonbProductsTuningHistory>;
    updatedAt?: string;
    updatedBy?: string;
    year?: number;
    zigtruckManagedProductCreatedAt?: string;
    zigtruckManagedProductId?: number;
    zigtruckProductId: number;
};
export namespace ZigTruckProductDetailDtoResult {
    export enum axis {
        BACK = 'BACK',
        FRONT = 'FRONT',
        NONE = 'NONE',
    }
    export enum productManageType {
        TEMP = 'TEMP',
        ZIGTRUCK = 'ZIGTRUCK',
    }
    export enum productPurchaseManageStatus {
        AFTER_CONSULTING = 'AFTER_CONSULTING',
        BEFORE_CONSULTING = 'BEFORE_CONSULTING',
        COMPLETED_CONTRACT = 'COMPLETED_CONTRACT',
        CONSULTING = 'CONSULTING',
    }
    export enum productStatus {
        BEFORE_SALE = 'BEFORE_SALE',
        COMPLETED = 'COMPLETED',
        DELETE = 'DELETE',
        ORIGIN_DATA_REGISTER = 'ORIGIN_DATA_REGISTER',
        PAUSE = 'PAUSE',
        SALE = 'SALE',
    }
    export enum productType {
        DIRECT = 'DIRECT',
        SPEED = 'SPEED',
    }
}

