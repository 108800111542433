/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type VehicleListDtoRequest = {
    dispatchMethod?: VehicleListDtoRequest.dispatchMethod;
    ownerBusinessId?: string;
    ownerMemberId?: string;
    plateNumber?: string;
};
export namespace VehicleListDtoRequest {
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
}

