/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BarosIoBoundGubunsDtoRequest = {
    centerCode?: string;
    ioBoundType?: BarosIoBoundGubunsDtoRequest.ioBoundType;
    isInbound: boolean;
};
export namespace BarosIoBoundGubunsDtoRequest {
    export enum ioBoundType {
        FORWARDING = 'FORWARDING',
        RECEIVING = 'RECEIVING',
    }
}

