/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessGroupResult } from './BusinessGroupResult';
import type { PromotionDetailResult } from './PromotionDetailResult';
export type BannerDtoResponseBannerOpen = {
    bannerApplyType?: BannerDtoResponseBannerOpen.bannerApplyType;
    bannerId?: number;
    businessGroups?: Array<BusinessGroupResult>;
    promotions?: Array<PromotionDetailResult>;
};
export namespace BannerDtoResponseBannerOpen {
    export enum bannerApplyType {
        ALL = 'ALL',
        GROUP = 'GROUP',
        PROMOTION = 'PROMOTION',
    }
}

