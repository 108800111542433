/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FaqDetailDtoResult = {
    contents?: string;
    faqCategory?: FaqDetailDtoResult.faqCategory;
    faqId?: number;
    isBest?: boolean;
    title?: string;
};
export namespace FaqDetailDtoResult {
    export enum faqCategory {
        ALARM = 'ALARM',
        DISPATCH = 'DISPATCH',
        ETC = 'ETC',
        MEMBER = 'MEMBER',
        ORDER = 'ORDER',
        SETTLEMENT = 'SETTLEMENT',
    }
}

