/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceGroupPolicyContractHistoryDtoPriceGroupPolicyContractHistoryAreaInfo = {
    adminName?: string;
    createdAt?: string;
    departureAddressCategory1?: string;
    departureAddressCategory2?: string;
    departureAddressCategory3?: string;
    departureAddressCategory4?: string;
    destinationAddressCategory1?: string;
    destinationAddressCategory2?: string;
    destinationAddressCategory3?: string;
    destinationAddressCategory4?: string;
    groupingId?: string;
    historyType?: PriceGroupPolicyContractHistoryDtoPriceGroupPolicyContractHistoryAreaInfo.historyType;
};
export namespace PriceGroupPolicyContractHistoryDtoPriceGroupPolicyContractHistoryAreaInfo {
    export enum historyType {
        CREATE = 'CREATE',
        DELETE = 'DELETE',
        NONE = 'NONE',
        UPDATE = 'UPDATE',
    }
}

