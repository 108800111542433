/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PathsDto } from './PathsDto';
export type JsonbRoute = {
    distance?: number;
    isHighWay?: boolean;
    option?: JsonbRoute.option;
    paths?: Array<PathsDto>;
    spend_time?: number;
    taxiFare?: number;
    toll_fee?: number;
};
export namespace JsonbRoute {
    export enum option {
        MOTORCYCLE = 'MOTORCYCLE',
        REAL_TRAFFIC = 'REAL_TRAFFIC',
        REAL_TRAFFIC2 = 'REAL_TRAFFIC2',
        REAL_TRAFFIC_FREE_ROAD = 'REAL_TRAFFIC_FREE_ROAD',
        RECOMMENDATION = 'RECOMMENDATION',
        SORT_DISTANCE = 'SORT_DISTANCE',
    }
}

