/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderDetailDtoHistoryResult = {
    addressType?: OrderDetailDtoHistoryResult.addressType;
    addressTypeValue?: string;
    confirmFeeAddExtraFee?: string;
    invoiceItemAmount?: string;
    invoiceItemType?: OrderDetailDtoHistoryResult.invoiceItemType;
    invoiceItemTypeValue?: string;
    isAdd?: boolean;
    isDel?: boolean;
    isFinal?: boolean;
    memberRoleTypeValue?: string;
    orderStatus?: OrderDetailDtoHistoryResult.orderStatus;
    orderStatusValue?: string;
    phoneNumber?: string;
    serviceType?: OrderDetailDtoHistoryResult.serviceType;
    serviceTypeValue?: string;
    updateId?: string;
    updateName?: string;
    updatedAt?: string;
    updatedBy?: string;
};
export namespace OrderDetailDtoHistoryResult {
    export enum addressType {
        COMMON = 'COMMON',
        DROP = 'DROP',
        PICKUP = 'PICKUP',
        PICKUP_RETURN = 'PICKUP_RETURN',
        WAYPOINT = 'WAYPOINT',
    }
    export enum invoiceItemType {
        BASE_FEE = 'BASE_FEE',
        EVENT_COUPON_FEE = 'EVENT_COUPON_FEE',
        EVENT_PROMOTION_FEE = 'EVENT_PROMOTION_FEE',
        EXTRA_ACTUAL_EXPENSES_BILLED_FEE = 'EXTRA_ACTUAL_EXPENSES_BILLED_FEE',
        EXTRA_ADDITIONAL_FORWARDING_FEE = 'EXTRA_ADDITIONAL_FORWARDING_FEE',
        EXTRA_COD_WAYPOINT_FEE = 'EXTRA_COD_WAYPOINT_FEE',
        EXTRA_DAILY_FREIGHT_FEE = 'EXTRA_DAILY_FREIGHT_FEE',
        EXTRA_DISTANCE_ALLOWANCE_FEE = 'EXTRA_DISTANCE_ALLOWANCE_FEE',
        EXTRA_DRIVER_DROP_FEE = 'EXTRA_DRIVER_DROP_FEE',
        EXTRA_DRIVER_PICKUP_FEE = 'EXTRA_DRIVER_PICKUP_FEE',
        EXTRA_EMERGENCY_FREIGHT_FEE = 'EXTRA_EMERGENCY_FREIGHT_FEE',
        EXTRA_EQUIPMENT_FEE = 'EXTRA_EQUIPMENT_FEE',
        EXTRA_ETC_FEE = 'EXTRA_ETC_FEE',
        EXTRA_HOLIDAY_FEE = 'EXTRA_HOLIDAY_FEE',
        EXTRA_LIFT_FEE = 'EXTRA_LIFT_FEE',
        EXTRA_MONTHLY_FREIGHT_FEE = 'EXTRA_MONTHLY_FREIGHT_FEE',
        EXTRA_NIGHT_SURCHARGE_FEE = 'EXTRA_NIGHT_SURCHARGE_FEE',
        EXTRA_OIL_PRICE_INDEXATION_FEE = 'EXTRA_OIL_PRICE_INDEXATION_FEE',
        EXTRA_PERSONAL_INFO_FEE = 'EXTRA_PERSONAL_INFO_FEE',
        EXTRA_RETURNING_FEE = 'EXTRA_RETURNING_FEE',
        EXTRA_ROUND_FEE = 'EXTRA_ROUND_FEE',
        EXTRA_SHIPPING_ADJUSTMENT_FEE = 'EXTRA_SHIPPING_ADJUSTMENT_FEE',
        EXTRA_STORAGE_OPEN_FEE = 'EXTRA_STORAGE_OPEN_FEE',
        EXTRA_UNDERGROUND_ROUND_FEE = 'EXTRA_UNDERGROUND_ROUND_FEE',
        EXTRA_WAITING_FEE = 'EXTRA_WAITING_FEE',
        EXTRA_WASTE_FEE = 'EXTRA_WASTE_FEE',
        EXTRA_WAYPOINT_FEE = 'EXTRA_WAYPOINT_FEE',
        STICKER_EVENT_FEE = 'STICKER_EVENT_FEE',
        VAT_FEE = 'VAT_FEE',
        WCI_FEE = 'WCI_FEE',
    }
    export enum orderStatus {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DISPATCHING = 'DISPATCHING',
        DISPATCH_BIND = 'DISPATCH_BIND',
        DISPATCH_COMPLETE = 'DISPATCH_COMPLETE',
        DISPATCH_CONFIRM = 'DISPATCH_CONFIRM',
        DISPATCH_DIRECT_CAR = 'DISPATCH_DIRECT_CAR',
        DISPATCH_DRIVER_CANCEL = 'DISPATCH_DRIVER_CANCEL',
        DISPATCH_FAIL = 'DISPATCH_FAIL',
        DISPATCH_OWNER_CANCEL = 'DISPATCH_OWNER_CANCEL',
        DRIVER_ACCIDENT_CANCEL = 'DRIVER_ACCIDENT_CANCEL',
        DROP_COMPLETE = 'DROP_COMPLETE',
        DROP_MOVING = 'DROP_MOVING',
        ORDER_CANCEL = 'ORDER_CANCEL',
        ORDER_UPDATE = 'ORDER_UPDATE',
        OWNER_ACCIDENT_CANCEL = 'OWNER_ACCIDENT_CANCEL',
        PARTNER_ACCIDENT_CANCEL = 'PARTNER_ACCIDENT_CANCEL',
        PICKUP_COMPLETE = 'PICKUP_COMPLETE',
        PICKUP_MOVING = 'PICKUP_MOVING',
        PICKUP_RETURN_MOVING = 'PICKUP_RETURN_MOVING',
        WAYPOINT10_COMPLETE = 'WAYPOINT10_COMPLETE',
        WAYPOINT10_MOVING = 'WAYPOINT10_MOVING',
        WAYPOINT11_COMPLETE = 'WAYPOINT11_COMPLETE',
        WAYPOINT11_MOVING = 'WAYPOINT11_MOVING',
        WAYPOINT12_COMPLETE = 'WAYPOINT12_COMPLETE',
        WAYPOINT12_MOVING = 'WAYPOINT12_MOVING',
        WAYPOINT13_COMPLETE = 'WAYPOINT13_COMPLETE',
        WAYPOINT13_MOVING = 'WAYPOINT13_MOVING',
        WAYPOINT14_COMPLETE = 'WAYPOINT14_COMPLETE',
        WAYPOINT14_MOVING = 'WAYPOINT14_MOVING',
        WAYPOINT15_COMPLETE = 'WAYPOINT15_COMPLETE',
        WAYPOINT15_MOVING = 'WAYPOINT15_MOVING',
        WAYPOINT16_COMPLETE = 'WAYPOINT16_COMPLETE',
        WAYPOINT16_MOVING = 'WAYPOINT16_MOVING',
        WAYPOINT17_COMPLETE = 'WAYPOINT17_COMPLETE',
        WAYPOINT17_MOVING = 'WAYPOINT17_MOVING',
        WAYPOINT18_COMPLETE = 'WAYPOINT18_COMPLETE',
        WAYPOINT18_MOVING = 'WAYPOINT18_MOVING',
        WAYPOINT19_COMPLETE = 'WAYPOINT19_COMPLETE',
        WAYPOINT19_MOVING = 'WAYPOINT19_MOVING',
        WAYPOINT1_COMPLETE = 'WAYPOINT1_COMPLETE',
        WAYPOINT1_MOVING = 'WAYPOINT1_MOVING',
        WAYPOINT20_COMPLETE = 'WAYPOINT20_COMPLETE',
        WAYPOINT20_MOVING = 'WAYPOINT20_MOVING',
        WAYPOINT2_COMPLETE = 'WAYPOINT2_COMPLETE',
        WAYPOINT2_MOVING = 'WAYPOINT2_MOVING',
        WAYPOINT3_COMPLETE = 'WAYPOINT3_COMPLETE',
        WAYPOINT3_MOVING = 'WAYPOINT3_MOVING',
        WAYPOINT4_COMPLETE = 'WAYPOINT4_COMPLETE',
        WAYPOINT4_MOVING = 'WAYPOINT4_MOVING',
        WAYPOINT5_COMPLETE = 'WAYPOINT5_COMPLETE',
        WAYPOINT5_MOVING = 'WAYPOINT5_MOVING',
        WAYPOINT6_COMPLETE = 'WAYPOINT6_COMPLETE',
        WAYPOINT6_MOVING = 'WAYPOINT6_MOVING',
        WAYPOINT7_COMPLETE = 'WAYPOINT7_COMPLETE',
        WAYPOINT7_MOVING = 'WAYPOINT7_MOVING',
        WAYPOINT8_COMPLETE = 'WAYPOINT8_COMPLETE',
        WAYPOINT8_MOVING = 'WAYPOINT8_MOVING',
        WAYPOINT9_COMPLETE = 'WAYPOINT9_COMPLETE',
        WAYPOINT9_MOVING = 'WAYPOINT9_MOVING',
    }
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
}

