/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminSystemAccessLogDtoRequest = {
    logType?: AdminSystemAccessLogDtoRequest.logType;
    pageNo?: number;
    pageSize?: number;
    serviceType?: AdminSystemAccessLogDtoRequest.serviceType;
};
export namespace AdminSystemAccessLogDtoRequest {
    export enum logType {
        ACCOUNT_LOCK = 'ACCOUNT_LOCK',
        ACCOUNT_UNLOCK = 'ACCOUNT_UNLOCK',
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        DRIVER_ON_BOARDING_COMPLETE = 'DRIVER_ON_BOARDING_COMPLETE',
        DRIVER_OPTION_UPDATE = 'DRIVER_OPTION_UPDATE',
        JOIN_REJECT = 'JOIN_REJECT',
        LOGIN = 'LOGIN',
        LOGIN_FAIL = 'LOGIN_FAIL',
        LOGOUT = 'LOGOUT',
        MEMBER_ROLE_UPDATE = 'MEMBER_ROLE_UPDATE',
        PASSWORD_MODIFY = 'PASSWORD_MODIFY',
        PRIVACY_DOWNLOAD = 'PRIVACY_DOWNLOAD',
        PRIVACY_SEARCH = 'PRIVACY_SEARCH',
        PRIVACY_UPDATE = 'PRIVACY_UPDATE',
        REFRESH_LOGIN = 'REFRESH_LOGIN',
        REGULAR = 'REGULAR',
        SIGN_UP = 'SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        USER_DELETE = 'USER_DELETE',
    }
    export enum serviceType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
}

