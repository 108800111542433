import { OrderMetaAllListModel } from '@features/common/types/models/orderMetaAllListModel'

export default class OrderMetaAllListModelMapper {
  static mapToPresentation(list) {
    const result = list

    return new OrderMetaAllListModel({
      addressType: result.addressType,
      carTonType: result.carTonType,
      carType: result.carType,
      vehicleOptionType: result.vehicleOptionType,
      cardType: result.cardType,
      dispatchIntervalType: result.dispatchIntervalType,
      dispatchType: result.dispatchType,
      dispatchMethod: result.dispatchMethod,
      orderStatus: result.orderStatus,
      ownerSettlementStatus: result.ownerSettlementStatus,
      packingMethodType: result.packingMethodType,
      pickupDropMethodType: result.pickupDropMethodType,
      ownerSelectablePaymentConditionCode: result?.ownerSelectablePaymentConditionCode,
      appLaunchDate: result.appLaunchDate,
      freightType: result.freightType,
      bankType: result.bankType,
      digitHandlingType: result.digitHandlingType,
      documentStatusType: result.documentStatusType,
      depositSettlementStatus: result.depositSettlementStatus,
      businessGroupAffiliateCode: result.businessGroupAffiliateCode,
      invoiceItemType: result.invoiceItemType,
      otherEtaxPublisherType: result.otherEtaxPublisherType,
      liftedTask: result.liftedTask,
      tileBox: result.tileBox,
      orderItemType: result.orderItemType,
      registrationChannelType: result.registrationChannelType,
      partnerSelectablePaymentConditionCode: result.partnerSelectablePaymentConditionCode,
    })
  }
}
