/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetStickerEventDetailDtoDriverUserResult = {
    businessId?: string;
    businessRegistrationNumber?: string;
    carTonType?: GetStickerEventDetailDtoDriverUserResult.carTonType;
    carType?: GetStickerEventDetailDtoDriverUserResult.carType;
    contractEndDateTime?: string;
    contractFileName?: string;
    contractFileUrl?: string;
    firstVerificationDateTime?: string;
    isReContract?: boolean;
    isStickerContractExpired?: boolean;
    memberId?: string;
    name?: string;
    phoneNumber?: string;
    plateNumber?: string;
    stickerEventWinnerMemberId?: string;
};
export namespace GetStickerEventDetailDtoDriverUserResult {
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
}

