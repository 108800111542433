/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminSmsSendDtoFile } from './AdminSmsSendDtoFile';
import type { AdminSmsSendDtoMessage } from './AdminSmsSendDtoMessage';
export type AdminSmsSendDtoRequest = {
    content?: string;
    contentType?: AdminSmsSendDtoRequest.contentType;
    countryCode?: string;
    files?: Array<AdminSmsSendDtoFile>;
    messages?: Array<AdminSmsSendDtoMessage>;
    reserveTime?: string;
    reserveTimeZone?: string;
    scheduleCode?: string;
    subject?: string;
    type?: AdminSmsSendDtoRequest.type;
};
export namespace AdminSmsSendDtoRequest {
    export enum contentType {
        AD = 'AD',
        COMM = 'COMM',
    }
    export enum type {
        LMS = 'LMS',
        MMS = 'MMS',
        SMS = 'SMS',
    }
}

