/*
 * FontProvider.tsx
 * 기본적으로 페이지 진입시 프로바이더 적용하면 되지만, react-portal을 사용하는
 * 컴포넌트 내에서 폰트가 적용되지 않는 문제로 포탈 내 컴포넌트에 다시 래핑되어야 함.
 */
import React, { createElement, useState, useEffect } from 'react'
import localFont from 'next/font/local'

type Props = React.PropsWithChildren<{
  tag?: string
  id?: string
  className?: string
  style?: React.CSSProperties
}>

/*
 * [주의] localFont 선언은 모듈 스코프에서 정의해야만 함
 *
 * Font Weight 정의 (굵기 별로 폰트를 지정해 사용가능):
 *   100 = Thin
 *   200 = ExtraLight
 *   300 = Light
 *   400 = Regular
 *   500 = Medium
 *   600 = SemiBold
 *   700 = Bold
 *   800 = ExtraBold
 *   900 = Heavy
 */
const suitVariable = localFont({
  variable: '--font-suit',
  src: [
    {
      path: './fonts/SUIT-Variable.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/SUIT-Variable.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/SUIT-Variable.woff2',
      weight: '800',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: true,
  fallback: [
    '-apple-system',
    'BlinkMacSystemFont',
    'system-ui',
    'Roboto',
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'sans-serif',
  ],
})

const spoqaVariable = localFont({
  variable: '--font-spoqa',
  src: [
    {
      path: './fonts/SpoqaHanSansNeo-Thin.woff2',
      weight: '100',
      style: 'normal',
    },
    {
      path: './fonts/SpoqaHanSansNeo-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/SpoqaHanSansNeo-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/SpoqaHanSansNeo-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/SpoqaHanSansNeo-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: true,
  fallback: [
    '-apple-system',
    'BlinkMacSystemFont',
    'system-ui',
    'Roboto',
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'sans-serif',
  ],
})

function FontsProvider(props: Props) {
  const { id, tag = 'div', style } = props
  const [className, setClassName] = useState<string>('')

  // CSR~SSR간 해시값 차이가 발생하여 CSR에서만 className 설정
  useEffect(() => {
    setClassName(
      [
        // 기본 font-family (.className 선언)
        // SUIT폰트변경시복구: //suitVariable.className,
        spoqaVariable.className,
        // 스타일 루트에 폰트 variable 선언 (--font-*)
        // SUIT폰트변경시복구: //suitVariable.variable,
        spoqaVariable.variable,
        // props className
        props.className,
      ]
        .filter(Boolean)
        .join(' '),
    )
  }, [props.className])

  // 컴포넌트 랜더링
  return createElement<Omit<Props, 'tag'>>(tag, { id, className, style }, props.children)
}

export default FontsProvider
