/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberSearchDtoResponseSearchSleepDriverUser = {
    businessId?: string;
    businessName?: string;
    businessType?: MemberSearchDtoResponseSearchSleepDriverUser.businessType;
    carTonType?: MemberSearchDtoResponseSearchSleepDriverUser.carTonType;
    carType?: MemberSearchDtoResponseSearchSleepDriverUser.carType;
    joinStatus?: MemberSearchDtoResponseSearchSleepDriverUser.joinStatus;
    mdmCode?: string;
    memberId?: string;
    memberSleepAt?: string;
    plateNumber?: string;
    registrationNumber?: string;
    representativeName?: string;
    userId?: string;
};
export namespace MemberSearchDtoResponseSearchSleepDriverUser {
    export enum businessType {
        CORPORATE = 'CORPORATE',
        INDIVIDUAL = 'INDIVIDUAL',
        NO_TAX = 'NO_TAX',
        SIMPLIFIED = 'SIMPLIFIED',
        SIMPLIFIED_NO_TAX = 'SIMPLIFIED_NO_TAX',
    }
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
}

