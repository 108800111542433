/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessPriceGroupDto = {
    priceId?: string;
    priceModuleType?: BusinessPriceGroupDto.priceModuleType;
    shortTitle?: string;
    title?: string;
};
export namespace BusinessPriceGroupDto {
    export enum priceModuleType {
        NAMYANG_GENERAL = 'NAMYANG_GENERAL',
        NAMYANG_WATER = 'NAMYANG_WATER',
    }
}

