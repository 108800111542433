import { OrderDetailDtoResult } from '@/data'

export const formatterWaypointOrderBy = (orderStatus: any) => orderStatus.match(/WAYPOINT(\d+)_/)?.[1]

let orderStatus: string[] = []
for (let i = 1; i <= 20; i++) {
  orderStatus.push(OrderDetailDtoResult.orderStatus[`WAYPOINT${i}_MOVING`])
  orderStatus.push(OrderDetailDtoResult.orderStatus[`WAYPOINT${i}_COMPLETE`])
}

export const isCheckedWaypointStep = (orderStatusProps: any, baseStatusesProps?: string[]) => {
  const baseStatuses: string[] = baseStatusesProps ? [...baseStatusesProps, ...orderStatus] : [...orderStatus]

  return baseStatuses.includes(orderStatusProps)
}

export const isCheckedWaypointMoving = (orderStatus: any) => {
  const baseStatuses: string[] = []

  for (let i = 1; i <= 20; i++) {
    baseStatuses.push(OrderDetailDtoResult.orderStatus[`WAYPOINT${i}_MOVING`])
  }

  return baseStatuses.includes(orderStatus)
}

export const isCheckedWaypointComplete = (orderStatus: any) => {
  const baseStatuses: string[] = []

  for (let i = 1; i <= 20; i++) {
    baseStatuses.push(OrderDetailDtoResult.orderStatus[`WAYPOINT${i}_COMPLETE`])
  }

  return baseStatuses.includes(orderStatus)
}
