/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoRequestMemberApprovalStatus = {
    approvalStatus?: MemberDtoRequestMemberApprovalStatus.approvalStatus;
    attachFileIds?: Array<number>;
    memberId?: string;
};
export namespace MemberDtoRequestMemberApprovalStatus {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
}

