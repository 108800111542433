/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CommonFileDtoResult = {
    approvalStatus?: CommonFileDtoResult.approvalStatus;
    fileCreateAt?: string;
    fileId?: number;
    fileName?: string;
    fileUrl?: string;
};
export namespace CommonFileDtoResult {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

