/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MapPoiDtoResult = {
    address1?: string;
    address2?: string;
    category?: MapPoiDtoResult.category;
    detailAddress?: string;
    lat?: number;
    lng?: number;
    roadAddress1?: string;
    roadAddress2?: string;
    zipCode?: string;
};
export namespace MapPoiDtoResult {
    export enum category {
        L1 = 'L1',
        L2 = 'L2',
        L3 = 'L3',
        L4 = 'L4',
        PARCEL = 'PARCEL',
        ROAD = 'ROAD',
    }
}

