/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ModifyCreditAvailabilityDtoRequest = {
    businessId: string;
    creditSalesAvailabilityType: ModifyCreditAvailabilityDtoRequest.creditSalesAvailabilityType;
};
export namespace ModifyCreditAvailabilityDtoRequest {
    export enum creditSalesAvailabilityType {
        GOOD = 'GOOD',
        LOW = 'LOW',
        UNAVAILABLE = 'UNAVAILABLE',
    }
}

