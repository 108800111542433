import { b64ToObj, objToB64 } from '@utils/index'

export const useExpiryLocalStorage = () => {
  const getWithExpiry = key => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const { value: item } = b64ToObj(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (typeof item.expiry !== 'undefined' && now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item
  }

  const setWithExpiry = (key: string, value: any) => {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    localStorage.setItem(key, objToB64({ value }))
  }

  return { getWithExpiry, setWithExpiry }
}
