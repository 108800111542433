/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfoDto } from './AddressInfoDto';
import type { Point } from './Point';
export type OrderWayPointDto = {
    address?: AddressInfoDto;
    id?: string;
    isSaveAddress?: boolean;
    orderBy?: number;
    point?: Point;
    type?: OrderWayPointDto.type;
    waypointRequestAt?: string;
};
export namespace OrderWayPointDto {
    export enum type {
        DEPARTURE = 'DEPARTURE',
        DESTINATION = 'DESTINATION',
    }
}

