/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderOtherEtaxDtoUpdateRequest = {
    orderId: string;
    otherEtaxPublisherType?: OrderOtherEtaxDtoUpdateRequest.otherEtaxPublisherType;
};
export namespace OrderOtherEtaxDtoUpdateRequest {
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
}

