import { Singleton } from '@features/common/types/singleton'
import { AdminSignUpDtoRequest } from 'src/data/models/AdminSignUpDtoRequest'

export class AdminUserModel extends Singleton {
  constructor(
    public adminId: string = '',
    public adminName: string = '',
    public slackId: string = '',
    public phone: string = '',
    public authorization: AdminSignUpDtoRequest.memberRoleType = AdminSignUpDtoRequest.memberRoleType.ADMIN_USER,
  ) {
    super()
  }
}
