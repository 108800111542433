/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OffsetTimeRes } from './OffsetTimeRes';
import type { PriceConfigInfoRes } from './PriceConfigInfoRes';
import type { PriceConfigTonInfo } from './PriceConfigTonInfo';
export type PriceConfigDtoRes = {
    createdDate?: string;
    displayOrder?: number;
    endDate?: string;
    endTime?: OffsetTimeRes;
    priceConfigType: PriceConfigDtoRes.priceConfigType;
    priceInfo: PriceConfigInfoRes;
    startDate?: string;
    startTime?: OffsetTimeRes;
    status: PriceConfigDtoRes.status;
    title: string;
    tonInfo: PriceConfigTonInfo;
};
export namespace PriceConfigDtoRes {
    export enum priceConfigType {
        EVERYDAY = 'EVERYDAY',
        NONE = 'NONE',
        WEEKDAY = 'WEEKDAY',
        WEEKEND_AND_HOLIDAY = 'WEEKEND_AND_HOLIDAY',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        DISABLED = 'DISABLED',
    }
}

