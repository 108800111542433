import { IListItem } from '@features/common/types'
import { isEqual } from 'lodash'
import { i18n } from 'next-i18next'

export class LoadingAreaFilterModel implements IListItem {
  public key: string
  public value: any
  public selected?: boolean
  public disabled?: boolean

  constructor({ key, selected = false }) {
    this.key = key
    this.value = key
    this.selected = selected
  }

  public get label(): string {
    return this.key === 'ALL' ? i18n.t('common:all') : this.key
  }
}

export class LoadingAreaFilterCollection {
  items: Array<LoadingAreaFilterModel>

  constructor() {
    this.items = []
  }

  public get selectedItems(): Array<LoadingAreaFilterModel> {
    return this.items.filter(item => item.selected)
  }

  public get selectedItemValues(): Array<string> {
    return isEqual(this.selectedItems.length, this.items.length) ? null : this.selectedItems.map(item => item.value)
  }
}
