import { Singleton } from '@features/common/types/singleton'
import dayjs from 'dayjs'
import { AdminUserDtoAdminUserInfo } from 'src/data'

export class AdminUserListItemModel {
  public id: string
  public memberId: string
  public adminId: string
  public adminName: string
  public memberRoleType: AdminUserDtoAdminUserInfo.memberRoleType
  public isLocked?: boolean
  public lastAccessAt?: string
  public createdAt?: string
  public updatedAt?: string
  public phoneNumber?: string

  constructor({
    id,
    memberId,
    adminId,
    adminName,
    memberRoleType,
    isLocked = false,
    lastAccessAt = null,
    createdAt = null,
    updatedAt = null,
    phoneNumber = '',
  }) {
    this.id = memberId
    this.memberId = memberId
    this.adminId = adminId
    this.adminName = adminName
    this.memberRoleType = memberRoleType
    this.isLocked = isLocked
    this.lastAccessAt = lastAccessAt
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.phoneNumber = phoneNumber
  }

  public get displayLastAccessAt(): string {
    return this.lastAccessAt ? dayjs(this.lastAccessAt).format('YY.MM.DD HH:mm') : '-'
  }

  public get displayCreatedAt(): string {
    return this.createdAt ? dayjs(this.createdAt).format('YY.MM.DD HH:mm') : '-'
  }

  public get displayUpdatedAt(): string {
    return this.updatedAt ? dayjs(this.updatedAt).format('YY.MM.DD HH:mm') : '-'
  }
}

export class AdminUserListModel extends Singleton {
  constructor(
    public items: Array<AdminUserListItemModel> = [],
    public total: number = 0,
  ) {
    super()
  }
}
