/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type QuickPaySettlementDtoExternalTaxInvoiceIssuedExcelResult = {
    billNo?: string;
    billNoUpdatedAt?: string;
    billNoUpdatedByAdminId?: string;
    billNoUpdatedByAdminName?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    dispatchChannel?: QuickPaySettlementDtoExternalTaxInvoiceIssuedExcelResult.dispatchChannel;
    documentId?: string;
    documentIdIssuedAt?: string;
    documentIdIssuedByAdminId?: string;
    documentIdIssuedByAdminName?: string;
    documentStatusTypeValue?: string;
    driverMemberCategoryTypeValue?: string;
    orderNo?: string;
    otherEtaxPublisherType?: QuickPaySettlementDtoExternalTaxInvoiceIssuedExcelResult.otherEtaxPublisherType;
    otherEtaxPublisherTypeValue?: string;
    ownerMemberCategoryTypeValue?: string;
    paySuccessAt?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    quickpayRequestDesignateAt?: string;
    sapErrorMessage?: string;
    supplyAmount?: number;
    totalAmount?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace QuickPaySettlementDtoExternalTaxInvoiceIssuedExcelResult {
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
}

