/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverBannerDtoRequest = {
    pageNo?: number;
    pageSize?: number;
    progressStatus: SearchDriverBannerDtoRequest.progressStatus;
    type: SearchDriverBannerDtoRequest.type;
};
export namespace SearchDriverBannerDtoRequest {
    export enum progressStatus {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
    export enum type {
        BANNER = 'BANNER',
        BOTTOM_POPUP = 'BOTTOM_POPUP',
        FULL_POPUP = 'FULL_POPUP',
    }
}

