/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberAccountCheckDtoRequest = {
    bankAccountNo: string;
    bankType: MemberAccountCheckDtoRequest.bankType;
};
export namespace MemberAccountCheckDtoRequest {
    export enum bankType {
        _002 = '_002',
        _003 = '_003',
        _004 = '_004',
        _005 = '_005',
        _007 = '_007',
        _011 = '_011',
        _012 = '_012',
        _020 = '_020',
        _023 = '_023',
        _027 = '_027',
        _031 = '_031',
        _032 = '_032',
        _034 = '_034',
        _035 = '_035',
        _037 = '_037',
        _039 = '_039',
        _045 = '_045',
        _048 = '_048',
        _050 = '_050',
        _054 = '_054',
        _055 = '_055',
        _064 = '_064',
        _071 = '_071',
        _081 = '_081',
        _088 = '_088',
        _089 = '_089',
        _090 = '_090',
        _092 = '_092',
        _209 = '_209',
        _218 = '_218',
        _222 = '_222',
        _224 = '_224',
        _225 = '_225',
        _227 = '_227',
        _238 = '_238',
        _240 = '_240',
        _243 = '_243',
        _247 = '_247',
        _261 = '_261',
        _262 = '_262',
        _263 = '_263',
        _264 = '_264',
        _265 = '_265',
        _266 = '_266',
        _267 = '_267',
        _269 = '_269',
        _270 = '_270',
        _271 = '_271',
        _278 = '_278',
        _279 = '_279',
        _280 = '_280',
        _287 = '_287',
        _288 = '_288',
        _290 = '_290',
        _291 = '_291',
        _292 = '_292',
        _294 = '_294',
        _295 = '_295',
    }
}

