/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractPriceDtoRequest = {
    businessId: string;
    endDate: string;
    startDate: string;
    type: ContractPriceDtoRequest.type;
    value: number;
};
export namespace ContractPriceDtoRequest {
    export enum type {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
}

