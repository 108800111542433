/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonbAddressDto } from './JsonbAddressDto';
import type { OrderLocationOption } from './OrderLocationOption';
import type { Point } from './Point';
export type FavoritesOrderDtoFavoriteWaypointDto = {
    address?: JsonbAddressDto;
    addressString?: string;
    id?: string;
    locationOption?: OrderLocationOption;
    orderBy?: number;
    point?: Point;
    type?: FavoritesOrderDtoFavoriteWaypointDto.type;
};
export namespace FavoritesOrderDtoFavoriteWaypointDto {
    export enum type {
        DEPARTURE = 'DEPARTURE',
        DESTINATION = 'DESTINATION',
    }
}

