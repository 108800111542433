/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BannerDtoResponseBannerTarget = {
    bannerApplyType?: BannerDtoResponseBannerTarget.bannerApplyType;
    mappingGroups?: Array<any>;
    unMappingGroups?: Array<any>;
};
export namespace BannerDtoResponseBannerTarget {
    export enum bannerApplyType {
        ALL = 'ALL',
        GROUP = 'GROUP',
        PROMOTION = 'PROMOTION',
    }
}

