/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminOnePgSettlementDtoRequest = {
    businessSearchText?: string;
    cardApproveNo?: string;
    fromDate?: string;
    onePgSettlementStatus?: SearchAdminOnePgSettlementDtoRequest.onePgSettlementStatus;
    orderNo?: string;
    pageNo?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: string;
    toDate?: string;
};
export namespace SearchAdminOnePgSettlementDtoRequest {
    export enum onePgSettlementStatus {
        CANCELLATION_FAIL = 'CANCELLATION_FAIL',
        CANCELLATION_SUCCESS = 'CANCELLATION_SUCCESS',
        PAYING_FAIL = 'PAYING_FAIL',
        PAYING_SUCCESS = 'PAYING_SUCCESS',
    }
}

