/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonFileDtoReceiptInfo } from './CommonFileDtoReceiptInfo';
export type CommonFileDtoOrderReceiptResult = {
    fileCount?: number;
    fileCreateAt?: string;
    isMandatory?: boolean;
    receiptInfos?: Array<CommonFileDtoReceiptInfo>;
    uploadStatus?: CommonFileDtoOrderReceiptResult.uploadStatus;
};
export namespace CommonFileDtoOrderReceiptResult {
    export enum uploadStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

