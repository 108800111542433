/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceConfigInfo = {
    fixed: boolean;
    type: PriceConfigInfo.type;
    value?: number;
};
export namespace PriceConfigInfo {
    export enum type {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
}

