/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonLocationOption } from './JsonLocationOption';
export type OrderLocationOption = {
    createdAt?: string;
    createdBy?: string;
    id?: number;
    isDeleted?: boolean;
    locationOptionOrderType?: OrderLocationOption.locationOptionOrderType;
    locationType?: OrderLocationOption.locationType;
    option?: JsonLocationOption;
    orderId?: string;
    referenceId?: number;
    updatedAt?: string;
    updatedBy?: string;
    version?: number;
};
export namespace OrderLocationOption {
    export enum locationOptionOrderType {
        FAVORITE = 'FAVORITE',
        ORDER = 'ORDER',
        RECENT = 'RECENT',
    }
    export enum locationType {
        DROP = 'DROP',
        PICKUP = 'PICKUP',
        RETURN = 'RETURN',
        WAYPOINT = 'WAYPOINT',
    }
}

