/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractPriceDtoContractPrice = {
    adminName?: string;
    createdDate?: string;
    endDate?: string;
    id?: number;
    isDisableAvailable?: boolean;
    startDate?: string;
    type?: ContractPriceDtoContractPrice.type;
    value?: number;
};
export namespace ContractPriceDtoContractPrice {
    export enum type {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
}

