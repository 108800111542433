/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonFileDtoResult } from './CommonFileDtoResult';
export type VehicleDtoResult = {
    businessRegistrationFileId?: number;
    fileInfo?: CommonFileDtoResult;
    garageAddress?: string;
    height?: number;
    length?: number;
    maximumPayload?: VehicleDtoResult.maximumPayload;
    phase?: VehicleDtoResult.phase;
    plateNumber?: string;
    purpose?: string;
    totalWeight?: number;
    vehicleId?: number;
    vehicleType?: VehicleDtoResult.vehicleType;
    width?: number;
};
export namespace VehicleDtoResult {
    export enum maximumPayload {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum phase {
        PARTNER_PHASE1 = 'PARTNER_PHASE1',
        PARTNER_PHASE2 = 'PARTNER_PHASE2',
        PHASE1 = 'PHASE1',
        PHASE2 = 'PHASE2',
    }
    export enum vehicleType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
}

