import axios, { AxiosRequestConfig } from 'axios'
import type { AxiosInstance, AxiosError, AxiosResponse as BaseAxiosResponse, InternalAxiosRequestConfig } from 'axios'
import dynamic from 'next/dynamic'
import { i18n } from 'next-i18next'
import React, { createContext, useContext, useRef, useEffect } from 'react'
import { ApiResponseCode } from '@/constants/enums/apiResponseCode'
import { useRouteChangeLoader, useAuth } from '@/features/common/hooks'
import { useGlobalStore } from '@/features/common/stores/useGlobalStore'
import { captureExceptionWithTitle } from '@utils/captureExceptionWithTitle'
import * as Events from '@/utils/events'

interface IProps
  extends React.PropsWithChildren<{
    onRequest?(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>
    onRequestError?(error: AxiosError): void
    onResponse?(response: BaseAxiosResponse): BaseAxiosResponse | Promise<BaseAxiosResponse>
    onResponseError?(error: AxiosError): void
  }> {}

type FetcherContextValue = {
  axiosInstance: AxiosInstance
}

const LoadingBar = dynamic(() => import('@/features/common/components/loadingBar'), { ssr: false })

const isLogout = (responseCode: ApiResponseCode) => {
  // 204, 208, 212 일 때 로그아웃
  switch (responseCode) {
    case ApiResponseCode.AUTH_NO_TOKEN_ERROR:
    case ApiResponseCode.AUTH_MULTI_LOGIN_ERROR:
    case ApiResponseCode.AUTH_INVALID_REFRESH_TOKEN:
      return true
    default:
      return false
  }
}

const FetcherContext = createContext<FetcherContextValue>({
  axiosInstance: axios,
})

// ReactQuery의 mutation 등에서 response 오류를 인식하도록 reject 처리되도록 한다.
// 오류발생시 서버에서 전달하는 오류 메시지를 표시하지 않기 위해서는 responseCode 값을 아래 case에 추가해준다.
const onRejected = (responseCode: number, message?: string): boolean => {
  switch (responseCode) {
    case ApiResponseCode.OTHER_BUSINESS_ORDER_IS_INCLUDED:
    case ApiResponseCode.ORDER_MODIFY_DRIVER_FIXED_FEE_OVER:
    case ApiResponseCode.ORDER_MODIFY_OWNER_FIXED_FEE_OVER:
    case ApiResponseCode.ORDER_MODIFY_DRIVER_FIXED_FEE_OVER_BY_2HOUR_OUT:
    case ApiResponseCode.ORDER_MODIFY_OWNER_FIXED_FEE_OVER_2HOUR_OUT:
    case ApiResponseCode.DOCUMENT_ALREADY_SAVE_ERROR:
    case ApiResponseCode.TPS_MATCHBACK_ENGINE_ACTIVE_ERROR: // 복화설정 > 활성된 설정값 없는 경우
    case ApiResponseCode.TPS_MATCHBACK_ENGINE_MAX_TRIP_COUNT_ERROR: // 복화설정 > 유효성검증 실패한 경우
    case ApiResponseCode.INVOICE_OWNER_AMOUNT_UPDATE_ERROR: // 계약물류 추가운임비 추가 > 추가운임비 실패한 경우
    case ApiResponseCode.MULTI_DOCUMENT_ISSUE_ERROR: // 외부정보망매입전표발생 실패한 경우
    case ApiResponseCode.COMMON_DUPLICATED_ELEMENT: // 이미 등록된 차량번호 일 경우
    case ApiResponseCode.ORDER_EXTRA_INVOICE_SAVE_FINAL_CONCURRENCY: // 이미 추가운임비가 최종등록된 경우
    case ApiResponseCode.ORDER_NOT_ALLOWED_OPEN_CONTRACT_OWNER: // 이미 추가운임비가 최종등록된 경우
    case ApiResponseCode.ORDER_ALREADY_OPENED_ERROR: // 이미 주문이 공개된 경우
    case ApiResponseCode.ORDER_OPEN_FAIL_ERROR: // 선택한 주문 공개가 실패한 경우
    case ApiResponseCode.ORDER_CLOSE_FAIL_ERROR: // 선택한 주문 비공개가 실패한 경우
    case ApiResponseCode.DIFFERENT_BUSINESS_DRIVER_OPEN_OPTION: // 계약물류 주문 노출” 설정이 ON -> OFF 로 바꼈을 경우
    case ApiResponseCode.EXIST_CONSIGNMENT_MDM_VEHICLE: // pnd차량관리 > 등록모달 > 이미 등록된 차량일 경우
    case ApiResponseCode.NOT_FOUND_MEMBER_BY_PLATE_NUMBER: // pnd차량관리 > 등록모달 > 차량 정보가 조회되지 않을 경우
    case ApiResponseCode.EXIST_CONSIGNMENT_MDM_CODE: // pnd차량관리 > 등록모달 > mdm코드가 이미등록되었을때
    case ApiResponseCode.STICKER_EVENT_CONTRACT_STATUS_ERROR: // 대형스티커 인증 > List > 계약 연장 불가한 대상이 포함되어 있는 경우
    case ApiResponseCode.COMMON_INVALID_STATUS: // 주문상세 > 정산그룹 변경 시 : 전표발행한 주문은 정산그룹을 변경할 수 없습니다.
    case ApiResponseCode.PARTNER_MAPPING_ALREADY_EXISTS_BY_OWNER: // 파트너 > 회원상세 > 이미 매칭된 화주가 있을 경우
    case ApiResponseCode.PARTNER_ORDER_DRIVING_ALREADY_EXISTS: // 파트너 > 회원상세 > 매칭된 화주 해제시 불가한 경우
    case ApiResponseCode.ORDER_CREATE_BUSINESS_TRANSACTION_LEVEL_INVALID: // 재배차 시 거래불가
    case ApiResponseCode.ORDER_RE_DISPATCH_BUSINESS_TRANSACTION_LEVEL_INVALID: // 거래불가 주문생성 시
      return true // reject 필요
    default:
      // 로딩 인디케이터 제거를 위해
      setTimeout(() => alert(message ?? `데이터 오류가 발생했습니다 (${responseCode})`), 100)
      return true // reject 필요
  }
}

export function FetcherProvider(props: IProps) {
  const { getToken, getRefreshToken, saveToken, logout, isExistRefreshToken } = useAuth()
  const { isLoading, setIsLoading } = useGlobalStore()
  const { isRouteLoading } = useRouteChangeLoader()
  let isRefreshing = false
  const failedQueue = []
  const availableRequests = useRef<number>(0)

  axios.defaults.headers.common = { 'Content-Type': 'application/json-patch+json' }
  axios.defaults.method = 'get'
  axios.defaults.withCredentials = false // true 일 경우 CORS 에러발생
  axios.defaults.timeout = 300000
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_PROXY_API_URL
  axios.interceptors.request.use()
  axios.interceptors.response.use()

  const axiosInstance = axios.create()

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    config => {
      // 헤더 정보에 "disable-loading" 포함되어 있을 경우 로딩 인디케이터를 띄우지 않음
      if (!config.headers.get('disable-loading')) {
        Events.emit('fetcher/LOADING', true)
      }

      // 운송포털 API 일 경우 baseURL 변경
      if (config.url.startsWith('/tps')) {
        config.baseURL = process.env.NEXT_PUBLIC_PROXY_TPS_API_URL
      }

      const token = getToken()

      if (token) {
        // 토큰재발급 API 요청시 헤더에 Authorization 넘기지 않도록 예외처리 필요하여 진행됨
        if (!['/admin_user/v1/reissue-token'].includes(config.url)) {
          config.headers.set({ Authorization: `${token.grantType} ${token.accessToken}` })
        }
        config.headers.set({ 'app-version': `${process.env.APP_VERSION} (${process.env.NEXT_PUBLIC_APP_ENV})` })
      }

      return props.onRequest?.(config) ?? config
    },
    error => {
      Events.emit('fetcher/LOADING', false)
      props.onRequestError?.(error)
      return Promise.reject(error)
    },
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      Events.emit('fetcher/LOADING', false)
      if (typeof response.data !== 'string' && response.data.responseCode !== ApiResponseCode.COMMON_OK) {
        if (onRejected(response.data.responseCode, response.data.message)) {
          return Promise.reject(response.data)
        }
      }
      return props.onResponse?.(response) ?? response
    },
    async error => {
      Events.emit('fetcher/LOADING', false)
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            if (isLogout(error.response.data?.responseCode) || !isExistRefreshToken()) {
              return logout()
            } else {
              const originalConfig = error.config
              if (originalConfig._retry) return

              // 첫실패 이후에 들어오는 실패건들은 queue에 넣어놓기
              if (isRefreshing) return saveFailedQueue(originalConfig)

              isRefreshing = true
              originalConfig._retry = true

              try {
                const token = await getRefreshToken()
                saveToken(token)

                // refresh token 진행 후 정상으로 진행하기 위해 queue 에 담아둔 실패 건들 실행
                processFailedQueue(null)
                isRefreshing = false

                return Promise.resolve(axiosInstance(originalConfig))
              } catch (e) {
                processFailedQueue(error)
                if (!isExistRefreshToken()) alert(i18n.t(`common:noAccessPermission`))
                isRefreshing = true
                return logout()
              }
            }
          }
          case 511:
            // 511 에러 발생시 로그아웃
            logout().catch()
            break
          default:
            captureExceptionWithTitle('☢️ Api Error', error)
            if (error?.responseCode) {
              onRejected(error.responseCode, error.message)
            }
            break
        }
      }

      props.onResponseError?.(error)
      return Promise.reject(error)
    },
  )

  /**
   * queue 에 저장
   *
   * @param originalConfig {AxiosRequestConfig}
   */
  const saveFailedQueue = (originalConfig: AxiosRequestConfig) =>
    new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
      .then(() => Promise.resolve(axiosInstance(originalConfig)))
      .catch(isRefreshingErr => Promise.reject(isRefreshingErr))

  /**
   * 실패한 request를 꺼내옴
   *
   * @param error {AxiosError}
   */
  const processFailedQueue = (error: AxiosError) => {
    failedQueue.forEach(promise => {
      if (error) {
        promise.reject(error)
      } else {
        promise.resolve()
      }
    })

    failedQueue.fill(undefined)
    failedQueue.length = 0
  }

  useEffect(() => {
    const loadingHandler = Events.create<boolean>(flag => {
      availableRequests.current = availableRequests.current + (flag ? 1 : -1)
      if (availableRequests.current < 0) {
        availableRequests.current = 0
      }

      setIsLoading(availableRequests.current > 0)
    })

    Events.on('fetcher/LOADING', loadingHandler)

    return () => {
      Events.off('fetcher/LOADING', loadingHandler)
    }
  }, [])

  return (
    <FetcherContext.Provider value={{ axiosInstance }}>
      {props.children}
      <LoadingBar isShow={isLoading || isRouteLoading} />
    </FetcherContext.Provider>
  )
}

export function useFetcher() {
  const { axiosInstance } = useContext(FetcherContext)
  return axiosInstance
}

export default FetcherContext
