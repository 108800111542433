/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceConfigOwnerFreightDtoFreightSaveRequest = {
    memberId: string;
    ownerStandardWaypointPrice?: boolean;
    purchaseFee?: number;
    salesFee?: number;
    transactionMethodType: PriceConfigOwnerFreightDtoFreightSaveRequest.transactionMethodType;
};
export namespace PriceConfigOwnerFreightDtoFreightSaveRequest {
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

