/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoUpdateMemberStatusToWaitingResult = {
    approvalStatus?: MemberDtoUpdateMemberStatusToWaitingResult.approvalStatus;
    approvalStatusMessage?: string;
    businessId?: string;
    joinStatus?: MemberDtoUpdateMemberStatusToWaitingResult.joinStatus;
    joinStatusMessage?: string;
    memberId?: string;
};
export namespace MemberDtoUpdateMemberStatusToWaitingResult {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
}

