import { i18n } from 'next-i18next'

import { IListItem } from '@features/common/types'
import { TonTypeEnum } from '@features/membership/common/types'
import { VehicleType } from '@features/common/types/enums'

export class TonTypeModel implements IListItem {
  public key: string
  public value: any
  public selected?: boolean
  public disabled?: boolean

  constructor({ key, selected = false }) {
    this.key = key
    this.value = key
    this.selected = selected
  }

  public get label(): string {
    return i18n.t(`common:vehicleInfo.weights.${this.key}`)
  }
}

export class TonTypeCollection {
  items: Array<TonTypeModel>

  constructor({ items = null }) {
    this.items = items
  }

  public disAvailableItems(carType?: string): Array<any> {
    if (carType !== VehicleType.DARMAS && carType !== VehicleType.LABOR) {
      return this.items.filter(item => item.key === TonTypeEnum.TON0_3 || item.key === TonTypeEnum.TON0_5)
    }

    return []
  }

  static init(): TonTypeCollection {
    const items = [
      TonTypeEnum.TON0_3,
      TonTypeEnum.TON0_5,
      TonTypeEnum.TON1_0,
      TonTypeEnum.TON1_4,
      TonTypeEnum.TON2_5,
      TonTypeEnum.TON3_5,
      TonTypeEnum.TON5_0,
      TonTypeEnum.TON5_0_PLUS,
      TonTypeEnum.TON11_0,
      TonTypeEnum.TON18_0,
      TonTypeEnum.TON25_0,
    ].map((item): TonTypeModel => new TonTypeModel({ key: item }))
    return new TonTypeCollection({ items })
  }
}
