/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchRecentItemsDtoItem = {
    itemId?: string;
    itemName?: string;
    method?: SearchRecentItemsDtoItem.method;
};
export namespace SearchRecentItemsDtoItem {
    export enum method {
        BOX = 'BOX',
        CARTON = 'CARTON',
        ETC = 'ETC',
        PALLET = 'PALLET',
        ROLL_CONTAINER = 'ROLL_CONTAINER',
        TON_BAG = 'TON_BAG',
    }
}

