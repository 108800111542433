/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonFileDtoResult } from './CommonFileDtoResult';
export type OrderDriverInfoDtoResult = {
    businessName?: string;
    businessRegistrationNumber?: string;
    carTonTypeValue?: string;
    carTypeValue?: string;
    driverCarNumber?: string;
    driverImageInfo?: CommonFileDtoResult;
    driverMemberId?: string;
    driverName?: string;
    driverPhoneNumber?: string;
    driverType?: OrderDriverInfoDtoResult.driverType;
    driverTypeValue?: string;
    drivingVehicleId?: number;
    isSettlementApproval?: boolean;
    isTempUser?: boolean;
    mdmCode?: string;
    ton?: OrderDriverInfoDtoResult.ton;
    type?: OrderDriverInfoDtoResult.type;
};
export namespace OrderDriverInfoDtoResult {
    export enum driverType {
        DIRECT = 'DIRECT',
        FIXED = 'FIXED',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        TEMP_DRIVER = 'TEMP_DRIVER',
        UNBAN = 'UNBAN',
    }
    export enum ton {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum type {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
}

