/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverPopupDtoDetailResult = {
    carTonTypes?: Array<'TON00_5' | 'TON0_3' | 'TON0_5' | 'TON11_0' | 'TON18_0' | 'TON1_0' | 'TON1_4' | 'TON25_0' | 'TON2_5' | 'TON3_5' | 'TON5_0' | 'TON5_0_PLUS'>;
    displayType?: SearchDriverPopupDtoDetailResult.displayType;
    endDate?: string;
    fileName?: string;
    id?: number;
    linkUrl?: string;
    name?: string;
    signUrl?: string;
    startDate?: string;
};
export namespace SearchDriverPopupDtoDetailResult {
    export enum displayType {
        NEW_TAB = 'NEW_TAB',
        PAGE_CHANGE = 'PAGE_CHANGE',
    }
}

