/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConsignmentVehicleListDtoRequest = {
    pageNo?: number;
    pageSize?: number;
    plateNumber?: string;
    vehicleOperationType?: ConsignmentVehicleListDtoRequest.vehicleOperationType;
};
export namespace ConsignmentVehicleListDtoRequest {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

