/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BannerDtoRequestBannerTarget = {
    bannerApplyType: BannerDtoRequestBannerTarget.bannerApplyType;
    bannerId: number;
};
export namespace BannerDtoRequestBannerTarget {
    export enum bannerApplyType {
        ALL = 'ALL',
        GROUP = 'GROUP',
        PROMOTION = 'PROMOTION',
    }
}

