/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchPriceGroupPolicyDtoRequest = {
    pageNo?: number;
    pageSize?: number;
    serviceType: SearchPriceGroupPolicyDtoRequest.serviceType;
    title?: string;
    transactionMethodType?: SearchPriceGroupPolicyDtoRequest.transactionMethodType;
};
export namespace SearchPriceGroupPolicyDtoRequest {
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

