/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo = {
    bankAccountNo?: string;
    bankAccountOwnerName?: string;
    bankType?: ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo.bankType;
    bankTypeValue?: string;
    paymentConditionCode?: ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo.paymentConditionCode;
    paymentConditionCodeValue?: string;
    paymentMethod?: ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo.paymentMethod;
    paymentMethodValue?: string;
    transactionMethodType?: ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo.transactionMethodType;
    transactionMethodTypeValue?: string;
};
export namespace ApprovedPartnerUserDtoApprovedPartnerUserSettlementInfo {
    export enum bankType {
        _002 = '_002',
        _003 = '_003',
        _004 = '_004',
        _005 = '_005',
        _007 = '_007',
        _011 = '_011',
        _012 = '_012',
        _020 = '_020',
        _023 = '_023',
        _027 = '_027',
        _031 = '_031',
        _032 = '_032',
        _034 = '_034',
        _035 = '_035',
        _037 = '_037',
        _039 = '_039',
        _045 = '_045',
        _048 = '_048',
        _050 = '_050',
        _054 = '_054',
        _055 = '_055',
        _064 = '_064',
        _071 = '_071',
        _081 = '_081',
        _088 = '_088',
        _089 = '_089',
        _090 = '_090',
        _092 = '_092',
        _209 = '_209',
        _218 = '_218',
        _222 = '_222',
        _224 = '_224',
        _225 = '_225',
        _227 = '_227',
        _238 = '_238',
        _240 = '_240',
        _243 = '_243',
        _247 = '_247',
        _261 = '_261',
        _262 = '_262',
        _263 = '_263',
        _264 = '_264',
        _265 = '_265',
        _266 = '_266',
        _267 = '_267',
        _269 = '_269',
        _270 = '_270',
        _271 = '_271',
        _278 = '_278',
        _279 = '_279',
        _280 = '_280',
        _287 = '_287',
        _288 = '_288',
        _290 = '_290',
        _291 = '_291',
        _292 = '_292',
        _294 = '_294',
        _295 = '_295',
    }
    export enum paymentConditionCode {
        D_3003 = 'D_3003',
        D_3015 = 'D_3015',
        D_3020 = 'D_3020',
        D_3023 = 'D_3023',
        D_3025 = 'D_3025',
        D_3028 = 'D_3028',
        D_3030 = 'D_3030',
        D_3035 = 'D_3035',
        D_3040 = 'D_3040',
        D_3042 = 'D_3042',
        D_3045 = 'D_3045',
        D_3050 = 'D_3050',
        D_3052 = 'D_3052',
        D_3055 = 'D_3055',
        D_3060 = 'D_3060',
        D_3065 = 'D_3065',
        D_3067 = 'D_3067',
        D_3070 = 'D_3070',
        D_3075 = 'D_3075',
        D_3080 = 'D_3080',
        D_3090 = 'D_3090',
        D_3120 = 'D_3120',
        D_3910 = 'D_3910',
        K_0001 = 'K_0001',
        K_0002 = 'K_0002',
        K_BA15 = 'K_BA15',
        K_BA27 = 'K_BA27',
        K_SB01 = 'K_SB01',
        K_SB02 = 'K_SB02',
        K_SB03 = 'K_SB03',
        K_SB04 = 'K_SB04',
        K_SB05 = 'K_SB05',
        K_T002 = 'K_T002',
        K_T005 = 'K_T005',
        K_T007 = 'K_T007',
        K_T010 = 'K_T010',
        K_T015 = 'K_T015',
        K_T020 = 'K_T020',
        K_T030 = 'K_T030',
        K_T040 = 'K_T040',
        K_T045 = 'K_T045',
        K_T050 = 'K_T050',
        K_T060 = 'K_T060',
        K_T070 = 'K_T070',
        K_T090 = 'K_T090',
        K_T120 = 'K_T120',
        K_T150 = 'K_T150',
        K_TA10 = 'K_TA10',
        K_TA15 = 'K_TA15',
        K_TA19 = 'K_TA19',
        K_TA20 = 'K_TA20',
        K_TA21 = 'K_TA21',
        K_TA25 = 'K_TA25',
        K_TA26 = 'K_TA26',
        K_TA27 = 'K_TA27',
        K_TA31 = 'K_TA31',
        K_TB01 = 'K_TB01',
        K_TB02 = 'K_TB02',
        K_TB03 = 'K_TB03',
        K_TB04 = 'K_TB04',
        K_TB05 = 'K_TB05',
        K_TB06 = 'K_TB06',
        K_TB07 = 'K_TB07',
        K_TB09 = 'K_TB09',
        K_TB10 = 'K_TB10',
        K_TB12 = 'K_TB12',
        K_TB13 = 'K_TB13',
        K_TB14 = 'K_TB14',
        K_TB15 = 'K_TB15',
        K_TB16 = 'K_TB16',
        K_TB17 = 'K_TB17',
        K_TB20 = 'K_TB20',
        K_TB21 = 'K_TB21',
        K_TB23 = 'K_TB23',
        K_TB25 = 'K_TB25',
        K_TB31 = 'K_TB31',
        K_TD05 = 'K_TD05',
        K_TD06 = 'K_TD06',
        K_TD10 = 'K_TD10',
        K_TD15 = 'K_TD15',
        K_TD31 = 'K_TD31',
        K_TW27 = 'K_TW27',
        K_TZ07 = 'K_TZ07',
        K_TZ10 = 'K_TZ10',
        K_TZ13 = 'K_TZ13',
        K_TZ15 = 'K_TZ15',
        K_TZ20 = 'K_TZ20',
        K_TZ24 = 'K_TZ24',
        K_TZ25 = 'K_TZ25',
        K_TZ31 = 'K_TZ31',
        K_Y040 = 'K_Y040',
        K_YB09 = 'K_YB09',
        K_YB23 = 'K_YB23',
        K_YZ07 = 'K_YZ07',
        K_YZ10 = 'K_YZ10',
        K_YZ13 = 'K_YZ13',
        K_YZ15 = 'K_YZ15',
        K_YZ20 = 'K_YZ20',
        K_YZ25 = 'K_YZ25',
        K_YZ31 = 'K_YZ31',
    }
    export enum paymentMethod {
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        QUICK_PAY = 'QUICK_PAY',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

