/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessAdministrationMemoDtoMemoInfo = {
    categoryType?: BusinessAdministrationMemoDtoMemoInfo.categoryType;
    categoryTypeValue?: string;
    createdAt?: string;
    createdBy?: string;
    memo?: string;
    memoId?: number;
};
export namespace BusinessAdministrationMemoDtoMemoInfo {
    export enum categoryType {
        AREA = 'AREA',
        DISPATCH = 'DISPATCH',
        ETC = 'ETC',
        NORMAL = 'NORMAL',
        PRICE = 'PRICE',
        VEHICLE = 'VEHICLE',
    }
}

