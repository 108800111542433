export interface IMetropolitanListOption {
  key: string
  value: string
  label: string
}

export interface IMetropolitanListModel {
  loadingArea: Array<IMetropolitanListOption>
  unloadingArea: Array<IMetropolitanListOption>
}

/**
 * 상하차지역 리스트 view model
 */
export class MetropolitanListModel implements IMetropolitanListModel {
  loadingArea: Array<IMetropolitanListOption>
  unloadingArea: Array<IMetropolitanListOption>

  constructor({ loadingArea, unloadingArea }: IMetropolitanListModel) {
    this.loadingArea = loadingArea
    this.unloadingArea = unloadingArea
  }

  static init(): MetropolitanListModel {
    return new MetropolitanListModel({
      loadingArea: [],
      unloadingArea: [],
    })
  }
}
