/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisplayTonTypes } from './DisplayTonTypes';
export type PromotionSearch = {
    adminId: string;
    closeDateTime?: string;
    closedAdminId?: string;
    condition: string;
    countType: PromotionSearch.countType;
    countValue: number;
    createdAt: string;
    endDateTime?: string;
    id: number;
    priceType: PromotionSearch.priceType;
    priceValue: number;
    serviceType: PromotionSearch.serviceType;
    startDateTime?: string;
    title: string;
    tonTypes: DisplayTonTypes;
};
export namespace PromotionSearch {
    export enum countType {
        ONCE = 'ONCE',
        REPEAT = 'REPEAT',
        UNLIMITED = 'UNLIMITED',
    }
    export enum priceType {
        FIXED = 'FIXED',
        FREE = 'FREE',
        PERCENT = 'PERCENT',
    }
    export enum serviceType {
        DRIVER_SERVICE = 'DRIVER_SERVICE',
        OWNER_SERVICE = 'OWNER_SERVICE',
    }
}

