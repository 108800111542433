/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoUpdateLoadLiabilityInsuranceApprovalStatusToWaitingResult = {
    businessId?: string;
    loadLiabilityInsuranceApprovalStatus?: MemberDtoUpdateLoadLiabilityInsuranceApprovalStatusToWaitingResult.loadLiabilityInsuranceApprovalStatus;
    loadLiabilityInsuranceApprovalStatusMessage?: string;
    memberId?: string;
};
export namespace MemberDtoUpdateLoadLiabilityInsuranceApprovalStatusToWaitingResult {
    export enum loadLiabilityInsuranceApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
}

