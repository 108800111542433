/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type JsonbCar = {
    dispatchInterval?: JsonbCar.dispatchInterval;
    isFrozenTemp?: boolean;
    isLifted?: boolean;
    isNonVibrating?: boolean;
    isRefrigeratedTemp?: boolean;
    isRisingWing?: boolean;
    isRoomTemp?: boolean;
    isStowageBackOpen?: boolean;
    isStowageLeftOpen?: boolean;
    isStowageRightOpen?: boolean;
    number?: number;
    ton?: JsonbCar.ton;
    type?: JsonbCar.type;
};
export namespace JsonbCar {
    export enum dispatchInterval {
        INTERVAL0 = 'INTERVAL0',
        INTERVAL120 = 'INTERVAL120',
        INTERVAL150 = 'INTERVAL150',
        INTERVAL180 = 'INTERVAL180',
        INTERVAL30 = 'INTERVAL30',
        INTERVAL60 = 'INTERVAL60',
        INTERVAL90 = 'INTERVAL90',
    }
    export enum ton {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum type {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
}

