/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest = {
    billNo?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    dispatchChannel?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.dispatchChannel;
    documentStatusType?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.documentStatusType;
    driverMemberCategoryType?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.driverMemberCategoryType;
    fromDate?: string;
    isBillNoExists?: boolean;
    isMdmComplete?: boolean;
    orderNo?: string;
    otherEtaxPublisherType?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.otherEtaxPublisherType;
    ownerMemberCategoryType?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.ownerMemberCategoryType;
    pageNo?: number;
    pageSize?: number;
    plateNumber?: string;
    settlementSearchDateType?: SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest.settlementSearchDateType;
    sortColumn?: string;
    sortDirection?: string;
    toDate?: string;
};
export namespace SearchAdminQuickPaySettlementDtoExternalTaxInvoiceIssuedRequest {
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum driverMemberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
    export enum ownerMemberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum settlementSearchDateType {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DOCUMENT_ISSUED = 'DOCUMENT_ISSUED',
        DROP = 'DROP',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        PAY_SUCCESS = 'PAY_SUCCESS',
        PICKUP = 'PICKUP',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
        QUICKPAY_REQUEST_DESIGNATED = 'QUICKPAY_REQUEST_DESIGNATED',
    }
}

