/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderDirectOperatedCarListDtoDirectCarOrderInfo = {
    businessName?: string;
    carTonType?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.carTonType;
    carTonTypeValue?: string;
    carType?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.carType;
    carTypeValue?: string;
    createdAt?: string;
    dispatchMethod?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.dispatchMethod;
    dispatchMethodValue?: string;
    dropRequestAt?: string;
    fromAddress?: string;
    fromName?: string;
    ioBoundCenterCode?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.ioBoundCenterCode;
    ioBoundCenterCodeValue?: string;
    ioBoundDetailCode?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.ioBoundDetailCode;
    ioBoundDetailCodeValue?: string;
    ioBoundMemo?: string;
    ioBoundType?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.ioBoundType;
    ioBoundTypeValue?: string;
    orderId?: string;
    orderItem?: string;
    orderNo?: string;
    orderStatus?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.orderStatus;
    orderStatusValue?: string;
    orderType?: OrderDirectOperatedCarListDtoDirectCarOrderInfo.orderType;
    orderTypeValue?: string;
    ownerName?: string;
    ownerUserId?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    toAddress?: string;
    toName?: string;
    vehicleOptionTypeValues?: Array<string>;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace OrderDirectOperatedCarListDtoDirectCarOrderInfo {
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
    export enum ioBoundCenterCode {
        R43001 = 'R43001',
        R43004 = 'R43004',
        R43005 = 'R43005',
        R43006 = 'R43006',
        R43007 = 'R43007',
    }
    export enum ioBoundDetailCode {
        R46001 = 'R46001',
        R46002 = 'R46002',
        R46003 = 'R46003',
        R46004 = 'R46004',
        R46005 = 'R46005',
        R46006 = 'R46006',
        R46007 = 'R46007',
        R46008 = 'R46008',
        R46009 = 'R46009',
        R46010 = 'R46010',
        R46011 = 'R46011',
        R46012 = 'R46012',
        R46013 = 'R46013',
        R46014 = 'R46014',
        R46015 = 'R46015',
        R46016 = 'R46016',
    }
    export enum ioBoundType {
        FORWARDING = 'FORWARDING',
        RECEIVING = 'RECEIVING',
    }
    export enum orderStatus {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DISPATCHING = 'DISPATCHING',
        DISPATCH_BIND = 'DISPATCH_BIND',
        DISPATCH_COMPLETE = 'DISPATCH_COMPLETE',
        DISPATCH_CONFIRM = 'DISPATCH_CONFIRM',
        DISPATCH_DIRECT_CAR = 'DISPATCH_DIRECT_CAR',
        DISPATCH_DRIVER_CANCEL = 'DISPATCH_DRIVER_CANCEL',
        DISPATCH_FAIL = 'DISPATCH_FAIL',
        DISPATCH_OWNER_CANCEL = 'DISPATCH_OWNER_CANCEL',
        DRIVER_ACCIDENT_CANCEL = 'DRIVER_ACCIDENT_CANCEL',
        DROP_COMPLETE = 'DROP_COMPLETE',
        DROP_MOVING = 'DROP_MOVING',
        ORDER_CANCEL = 'ORDER_CANCEL',
        ORDER_UPDATE = 'ORDER_UPDATE',
        OWNER_ACCIDENT_CANCEL = 'OWNER_ACCIDENT_CANCEL',
        PARTNER_ACCIDENT_CANCEL = 'PARTNER_ACCIDENT_CANCEL',
        PICKUP_COMPLETE = 'PICKUP_COMPLETE',
        PICKUP_MOVING = 'PICKUP_MOVING',
        PICKUP_RETURN_MOVING = 'PICKUP_RETURN_MOVING',
        WAYPOINT10_COMPLETE = 'WAYPOINT10_COMPLETE',
        WAYPOINT10_MOVING = 'WAYPOINT10_MOVING',
        WAYPOINT11_COMPLETE = 'WAYPOINT11_COMPLETE',
        WAYPOINT11_MOVING = 'WAYPOINT11_MOVING',
        WAYPOINT12_COMPLETE = 'WAYPOINT12_COMPLETE',
        WAYPOINT12_MOVING = 'WAYPOINT12_MOVING',
        WAYPOINT13_COMPLETE = 'WAYPOINT13_COMPLETE',
        WAYPOINT13_MOVING = 'WAYPOINT13_MOVING',
        WAYPOINT14_COMPLETE = 'WAYPOINT14_COMPLETE',
        WAYPOINT14_MOVING = 'WAYPOINT14_MOVING',
        WAYPOINT15_COMPLETE = 'WAYPOINT15_COMPLETE',
        WAYPOINT15_MOVING = 'WAYPOINT15_MOVING',
        WAYPOINT16_COMPLETE = 'WAYPOINT16_COMPLETE',
        WAYPOINT16_MOVING = 'WAYPOINT16_MOVING',
        WAYPOINT17_COMPLETE = 'WAYPOINT17_COMPLETE',
        WAYPOINT17_MOVING = 'WAYPOINT17_MOVING',
        WAYPOINT18_COMPLETE = 'WAYPOINT18_COMPLETE',
        WAYPOINT18_MOVING = 'WAYPOINT18_MOVING',
        WAYPOINT19_COMPLETE = 'WAYPOINT19_COMPLETE',
        WAYPOINT19_MOVING = 'WAYPOINT19_MOVING',
        WAYPOINT1_COMPLETE = 'WAYPOINT1_COMPLETE',
        WAYPOINT1_MOVING = 'WAYPOINT1_MOVING',
        WAYPOINT20_COMPLETE = 'WAYPOINT20_COMPLETE',
        WAYPOINT20_MOVING = 'WAYPOINT20_MOVING',
        WAYPOINT2_COMPLETE = 'WAYPOINT2_COMPLETE',
        WAYPOINT2_MOVING = 'WAYPOINT2_MOVING',
        WAYPOINT3_COMPLETE = 'WAYPOINT3_COMPLETE',
        WAYPOINT3_MOVING = 'WAYPOINT3_MOVING',
        WAYPOINT4_COMPLETE = 'WAYPOINT4_COMPLETE',
        WAYPOINT4_MOVING = 'WAYPOINT4_MOVING',
        WAYPOINT5_COMPLETE = 'WAYPOINT5_COMPLETE',
        WAYPOINT5_MOVING = 'WAYPOINT5_MOVING',
        WAYPOINT6_COMPLETE = 'WAYPOINT6_COMPLETE',
        WAYPOINT6_MOVING = 'WAYPOINT6_MOVING',
        WAYPOINT7_COMPLETE = 'WAYPOINT7_COMPLETE',
        WAYPOINT7_MOVING = 'WAYPOINT7_MOVING',
        WAYPOINT8_COMPLETE = 'WAYPOINT8_COMPLETE',
        WAYPOINT8_MOVING = 'WAYPOINT8_MOVING',
        WAYPOINT9_COMPLETE = 'WAYPOINT9_COMPLETE',
        WAYPOINT9_MOVING = 'WAYPOINT9_MOVING',
    }
    export enum orderType {
        MULTI = 'MULTI',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

