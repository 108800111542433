interface IAdminUserListFilterModel {
  name: string
  adminId: string
  memberRoleType: string
  pageIndex: number
  pageCount: number
}
export class AdminUserListFilterModel implements IAdminUserListFilterModel {
  name: string
  adminId: string
  memberRoleType: string
  pageIndex: number
  pageCount: number

  constructor({ name, adminId, pageIndex, pageCount, memberRoleType }: IAdminUserListFilterModel) {
    this.name = name
    this.adminId = adminId
    this.pageIndex = pageIndex
    this.pageCount = pageCount
    this.memberRoleType = memberRoleType
  }

  static init() {
    return new AdminUserListFilterModel({
      name: '',
      adminId: '',
      memberRoleType: null,
      pageIndex: 0,
      pageCount: 20,
    })
  }
}
