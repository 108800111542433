import {
  BusinessDtoRequestCopyOfBankbook,
  BusinessRegistrationFileDtoRequestFileApprovalStatus,
  BusinessRegistrationFileDtoRequestFileImageUpload,
  DriverUserDtoResponseDriverUser,
  MemberDtoResponseMemberSettlementApprovalStatus,
  MemberSearchDtoResponseSearchDriverUser,
  PaymentModifyDtoRequest,
  VehicleDtoRequestVehicleRegistration,
} from 'src/data'
import { MemberSearchDtoResponseSearchOwnerUser } from 'src/data/models/MemberSearchDtoResponseSearchOwnerUser'

export const PaymentMethodApprovalStatus = { ...PaymentModifyDtoRequest.status, ALL: 'ALL' }
export const MemberApprovalStatus = { ...MemberSearchDtoResponseSearchOwnerUser.approvalStatus, ALL: 'ALL' }
export const MemberSettlementApprovalStatus = {
  ...MemberDtoResponseMemberSettlementApprovalStatus.settlementApprovalStatus,
  ALL: 'ALL',
}
export const MemberInsuranceApprovalStatus = {
  ...DriverUserDtoResponseDriverUser.loadLiabilityInsuranceApprovalStatus,
  ALL: 'ALL',
}
export const MdmRegistrantionStatus = { ...MemberSearchDtoResponseSearchOwnerUser.mdmRegistrationStatus, ALL: 'ALL' }
export const MemberJoinStatus = { ...MemberSearchDtoResponseSearchOwnerUser.joinStatus }
export const BusinessType = { ...MemberSearchDtoResponseSearchOwnerUser.businessType }
export const RegFileApprovalStatus = {
  ...BusinessRegistrationFileDtoRequestFileApprovalStatus.approvalStatus,
  ALL: 'ALL',
  NOSELECT: 'NOSELECT',
}
export const RegFileRefType = { ...BusinessRegistrationFileDtoRequestFileImageUpload.refType }
export const RegFilePhase = { ...DriverUserDtoResponseDriverUser.phase }
export const VehicleMaximumPayloadType = { ...VehicleDtoRequestVehicleRegistration.maximumPayload }
export const TonType = { ...VehicleDtoRequestVehicleRegistration.maximumPayload, ALL: 'ALL' }
export const BankType = { ...BusinessDtoRequestCopyOfBankbook.bankType }
export const RegPathType = { ...MemberSearchDtoResponseSearchDriverUser.registrationPathType, ALL: 'ALL' }
export const FareType = { ...MemberSearchDtoResponseSearchOwnerUser.transactionMethodType, ALL: 'ALL' }
export const MemberCategoryType = { ...MemberSearchDtoResponseSearchOwnerUser.memberCategoryType, ALL: 'ALL' }
export const DriverMemberCategoryType = { ...MemberSearchDtoResponseSearchOwnerUser.memberCategoryType, ALL: 'ALL' }
export const InsuranceTargetType = {
  ALL: 'ALL',
  TARGET: 'TARGET',
  NONTARGET: 'NONTARGET',
}
