/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FixedDriverSearchDtoRegularDriverUser = {
    businessRegistrationNumber?: string;
    driverName?: string;
    maximumPayload?: FixedDriverSearchDtoRegularDriverUser.maximumPayload;
    mdmCode?: string;
    memberId?: string;
    plateNumber?: string;
    userId?: string;
    vehicleType?: FixedDriverSearchDtoRegularDriverUser.vehicleType;
};
export namespace FixedDriverSearchDtoRegularDriverUser {
    export enum maximumPayload {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum vehicleType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
}

