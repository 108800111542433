/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SapSettlementDtoInvoiceDriverResult = {
    billNo?: string;
    businessId?: string;
    documentId?: string;
    invoiceDriverId?: string;
    invoiceStatus?: SapSettlementDtoInvoiceDriverResult.invoiceStatus;
    invoiceType?: SapSettlementDtoInvoiceDriverResult.invoiceType;
    memberId?: string;
    orderId?: string;
    tempDocumentId?: string;
    totalAmount?: number;
    totalAmountWithoutVat?: number;
    upperInvoiceId?: string;
};
export namespace SapSettlementDtoInvoiceDriverResult {
    export enum invoiceStatus {
        ADMIN_CONFIRMED = 'ADMIN_CONFIRMED',
        CANCELLED = 'CANCELLED',
        CANCEL_FAILED = 'CANCEL_FAILED',
        COMPLETE = 'COMPLETE',
        CONFIRMED = 'CONFIRMED',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NEGOTIATE = 'NEGOTIATE',
        QUICK_PAY_REAPPLIED = 'QUICK_PAY_REAPPLIED',
        QUICK_PAY_REAPPLY_CANCELLED = 'QUICK_PAY_REAPPLY_CANCELLED',
        QUICK_PAY_REQUESTED = 'QUICK_PAY_REQUESTED',
        TAX_INVOICE_ISSUED = 'TAX_INVOICE_ISSUED',
    }
    export enum invoiceType {
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        MONTHLY_PAYMENT = 'MONTHLY_PAYMENT',
        POST_PAYMENT = 'POST_PAYMENT',
        PRE_PAYMENT = 'PRE_PAYMENT',
        REFUND = 'REFUND',
    }
}

