/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BannerDtoResponseList = {
    adminId?: string;
    bannerApplyMenuType?: BannerDtoResponseList.bannerApplyMenuType;
    bannerApplyType?: BannerDtoResponseList.bannerApplyType;
    bannerCloseType?: BannerDtoResponseList.bannerCloseType;
    bannerId?: number;
    bannerProgressStatus?: BannerDtoResponseList.bannerProgressStatus;
    bannerTargetType?: BannerDtoResponseList.bannerTargetType;
    bannerTitle?: string;
    bannerType?: BannerDtoResponseList.bannerType;
    createdAt?: string;
    endDate?: string;
    isUsed?: boolean;
    startDate?: string;
};
export namespace BannerDtoResponseList {
    export enum bannerApplyMenuType {
        OWNER_HOME = 'OWNER_HOME',
        OWNER_ORDER_CREATE = 'OWNER_ORDER_CREATE',
        OWNER_ORDER_DETAIL = 'OWNER_ORDER_DETAIL',
    }
    export enum bannerApplyType {
        ALL = 'ALL',
        GROUP = 'GROUP',
        PROMOTION = 'PROMOTION',
    }
    export enum bannerCloseType {
        NO_SEETING = 'NO_SEETING',
        ONE_DAY = 'ONE_DAY',
        ONE_WEEK = 'ONE_WEEK',
    }
    export enum bannerProgressStatus {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
    export enum bannerTargetType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
    export enum bannerType {
        BANNER = 'BANNER',
        POPUP = 'POPUP',
    }
}

