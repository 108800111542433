/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NoticeListDtoResult = {
    adminName?: string;
    contents?: string;
    createdAt?: string;
    isPinned?: boolean;
    noticeEndAt?: string;
    noticeId?: number;
    noticeProgress?: NoticeListDtoResult.noticeProgress;
    noticeStartAt?: string;
    noticeType?: NoticeListDtoResult.noticeType;
    title?: string;
    updatedAt?: string;
};
export namespace NoticeListDtoResult {
    export enum noticeProgress {
        BEFORE_POST = 'BEFORE_POST',
        POSTING = 'POSTING',
        POSTING_ENDED = 'POSTING_ENDED',
    }
    export enum noticeType {
        EVENT = 'EVENT',
        NOTICE = 'NOTICE',
        UPDATE = 'UPDATE',
    }
}

