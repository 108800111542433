/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StickerEventVerificationDto = {
    approvalStatus?: StickerEventVerificationDto.approvalStatus;
    attachFileId?: number;
    stickerEventVerificationId?: string;
};
export namespace StickerEventVerificationDto {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTER = 'REGISTER',
        REREGISTER = 'REREGISTER',
        RETURN = 'RETURN',
    }
}

