/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfoDto } from './AddressInfoDto';
import type { JsonbCar } from './JsonbCar';
import type { JsonbPickupDropMethod } from './JsonbPickupDropMethod';
import type { OrderItemDtoResult } from './OrderItemDtoResult';
import type { OrderJsonbCarDtoResult } from './OrderJsonbCarDtoResult';
import type { Point } from './Point';
import type { WayPointDto } from './WayPointDto';
export type OrderRecentDtoResult = {
    businessId?: string;
    businessName?: string;
    createdAt?: string;
    dispatchMethod?: OrderRecentDtoResult.dispatchMethod;
    dispatchMethodValue?: string;
    dropAt?: string;
    dropMethod?: JsonbPickupDropMethod;
    dropRequestAt?: string;
    estimateMaxFee?: number;
    estimateMinFee?: number;
    fromAddress?: AddressInfoDto;
    fromPoint?: Point;
    memberId?: string;
    memberName?: string;
    orderId?: string;
    orderItems?: Array<OrderItemDtoResult>;
    orderNo?: string;
    orderOptionCashOnDeliveryTypes?: Array<'DROP_CASH_ON_DELIVERY' | 'RETURN_CASH_ON_DELIVERY' | 'WAYPOINT10_CASH_ON_DELIVERY' | 'WAYPOINT11_CASH_ON_DELIVERY' | 'WAYPOINT12_CASH_ON_DELIVERY' | 'WAYPOINT13_CASH_ON_DELIVERY' | 'WAYPOINT14_CASH_ON_DELIVERY' | 'WAYPOINT15_CASH_ON_DELIVERY' | 'WAYPOINT16_CASH_ON_DELIVERY' | 'WAYPOINT17_CASH_ON_DELIVERY' | 'WAYPOINT18_CASH_ON_DELIVERY' | 'WAYPOINT19_CASH_ON_DELIVERY' | 'WAYPOINT1_CASH_ON_DELIVERY' | 'WAYPOINT20_CASH_ON_DELIVERY' | 'WAYPOINT2_CASH_ON_DELIVERY' | 'WAYPOINT3_CASH_ON_DELIVERY' | 'WAYPOINT4_CASH_ON_DELIVERY' | 'WAYPOINT5_CASH_ON_DELIVERY' | 'WAYPOINT6_CASH_ON_DELIVERY' | 'WAYPOINT7_CASH_ON_DELIVERY' | 'WAYPOINT8_CASH_ON_DELIVERY' | 'WAYPOINT9_CASH_ON_DELIVERY'>;
    orderOptionItemTypes?: Array<'DROP_ITEM_SET' | 'RETURN_ITEM_SET' | 'WAYPOINT10_ITEM_SET' | 'WAYPOINT11_ITEM_SET' | 'WAYPOINT12_ITEM_SET' | 'WAYPOINT13_ITEM_SET' | 'WAYPOINT14_ITEM_SET' | 'WAYPOINT15_ITEM_SET' | 'WAYPOINT16_ITEM_SET' | 'WAYPOINT17_ITEM_SET' | 'WAYPOINT18_ITEM_SET' | 'WAYPOINT19_ITEM_SET' | 'WAYPOINT1_ITEM_SET' | 'WAYPOINT20_ITEM_SET' | 'WAYPOINT2_ITEM_SET' | 'WAYPOINT3_ITEM_SET' | 'WAYPOINT4_ITEM_SET' | 'WAYPOINT5_ITEM_SET' | 'WAYPOINT6_ITEM_SET' | 'WAYPOINT7_ITEM_SET' | 'WAYPOINT8_ITEM_SET' | 'WAYPOINT9_ITEM_SET'>;
    ownerOrderType?: OrderRecentDtoResult.ownerOrderType;
    pickupAt?: string;
    pickupMethod?: JsonbPickupDropMethod;
    pickupRequestAt?: string;
    requestCar?: JsonbCar;
    requestCarInfo?: OrderJsonbCarDtoResult;
    toAddress?: AddressInfoDto;
    toPoint?: Point;
    updatedAt?: string;
    wayPointDtos?: Array<WayPointDto>;
};
export namespace OrderRecentDtoResult {
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
    export enum ownerOrderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

