/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractFreightDetailDtoTonAndBaseFeeResult } from './ContractFreightDetailDtoTonAndBaseFeeResult';
export type ContractFreightDetailDtoContractFreightDriverDetail = {
    contractFreightAreaId?: string;
    contractFreightDrivers?: Array<ContractFreightDetailDtoTonAndBaseFeeResult>;
    departureAddressCategory1?: string;
    departureAddressCategory2?: string;
    departureAddressCategory3?: string;
    departureAddressCategory4?: string;
    destinationAddressCategory1?: string;
    destinationAddressCategory2?: string;
    destinationAddressCategory3?: string;
    destinationAddressCategory4?: string;
    freightType?: ContractFreightDetailDtoContractFreightDriverDetail.freightType;
};
export namespace ContractFreightDetailDtoContractFreightDriverDetail {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

