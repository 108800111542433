import * as Sentry from '@sentry/nextjs'
import { ApiResponseCode } from '@/constants/enums/apiResponseCode'
import { ApiError } from '@features/common/types'

export const captureExceptionWithTitle = (title: string, error: any) => {
  Sentry.captureException(new ApiError(error, error?.message, title), scope => {
    scope.setContext('Api Response', error)

    switch (error?.responseCode) {
      case ApiResponseCode.COMMON_UNKNOWN:
      case ApiResponseCode.COMMON_BAD_REQUEST:
      case ApiResponseCode.COMMON_JSON_PARSING_ERROR:
        scope.setLevel('error')
        break

      case ApiResponseCode.AUTH_NO_TOKEN_ERROR:
      case ApiResponseCode.AUTH_MULTI_LOGIN_ERROR:
      case ApiResponseCode.AUTH_INVALID_REFRESH_TOKEN:
        scope.setLevel('debug')
        break

      case ApiResponseCode.COMMON_TEMPORARY_UNAVAILABLE:
      case ApiResponseCode.AUTH_INVALID_SERVICE_TYPE:
      case ApiResponseCode.AUTH_INVALID_SERVICE_TYPE_ERROR:
        scope.setLevel('warning')
        break

      default:
        scope.setLevel('info')
        break
    }
    return scope
  })
}
