/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PromotionSearchRequest = {
    fromDate?: string;
    pageNo?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: string;
    status: PromotionSearchRequest.status;
    toDate?: string;
};
export namespace PromotionSearchRequest {
    export enum status {
        CLOSE = 'CLOSE',
        OPEN = 'OPEN',
        WAIT = 'WAIT',
    }
}

