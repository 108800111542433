import { AdminUserDtoResult } from 'src/data'
import { create } from 'zustand'
import { devtoolsInNonProd } from '@utils'
import { AdminUserListModelMapper } from '../mappers'
import { AdminUserListFilterModel, AdminUserListModel, IAdminUserListState } from '../types'

const initialAdminUserListState = {
  filter: AdminUserListFilterModel.init(),
  adminUsers: AdminUserListModel.getInstance(),
}

export const useAdminUserListStore = create<IAdminUserListState>()(
  devtoolsInNonProd(
    (set, get) => ({
      ...initialAdminUserListState,
      updateFilter: newFilter => {
        const filter = get().filter
        set({ filter: new AdminUserListFilterModel({ ...filter, ...newFilter }) })
      },
      setAdminUsers: (data: AdminUserDtoResult) => {
        const adminUsers = AdminUserListModelMapper.mapToPresentation(data)
        set({ adminUsers })
      },
      reset: () => {
        set(initialAdminUserListState)
      },
    }),
    { name: 'AdminUserListStore' },
  ),
)
