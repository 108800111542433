/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StatisticsDtoRequest = {
    businessGroupId?: number;
    businessId?: string;
    businessSettlementGroupName?: string;
    fromDate: string;
    memberCategoryType?: StatisticsDtoRequest.memberCategoryType;
    searchMonth?: string;
};
export namespace StatisticsDtoRequest {
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
}

