/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SapSettlementDtoCreditSalesDepositResult = {
    actualExpensesBilledAmount?: number;
    billNo?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessSettlementGroupId?: string;
    businessSettlementGroupName?: string;
    depositSettlementStatus?: SapSettlementDtoCreditSalesDepositResult.depositSettlementStatus;
    depositSettlementStatusValue?: string;
    documentId?: string;
    documentIssuedAt?: string;
    documentStatusType?: SapSettlementDtoCreditSalesDepositResult.documentStatusType;
    documentStatusTypeValue?: string;
    oilPriceIndexationAmount?: number;
    oilPriceIndexationPercent?: number;
    originalSupplyAmount?: number;
    paySuccessAt?: string;
    remark?: string;
    shippingAdjustmentAmount?: number;
    tempDocumentId?: string;
    totalAmount?: number;
    totalSupplyAmount?: number;
    vatAmount?: number;
};
export namespace SapSettlementDtoCreditSalesDepositResult {
    export enum depositSettlementStatus {
        DEPOSIT_COMPLETE = 'DEPOSIT_COMPLETE',
        DEPOSIT_INCOMPLETE = 'DEPOSIT_INCOMPLETE',
    }
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
}

