import { Buffer } from 'buffer'
import { devtools } from 'zustand/middleware'
export { OrderDateTimeUtil } from './orderDateTimeUtil'

export const int2char = n => {
  const BI_RM = '0123456789abcdefghijklmnopqrstuvwxyz'
  return BI_RM.charAt(n)
}

/**
 * base64 문자열을 hex로 변환한다.
 * @param s
 * @returns hex 문자열
 */
export const b64tohex = s => {
  const b64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  const b64pad = '='
  let ret = ''
  let i
  let k = 0 // b64 state, 0-3
  let slop = 0
  for (i = 0; i < s.length; ++i) {
    if (s.charAt(i) === b64pad) {
      break
    }
    const v = b64map.indexOf(s.charAt(i))
    if (v < 0) {
      continue
    }
    if (k === 0) {
      ret += int2char(v >> 2)
      slop = v & 3
      k = 1
    } else if (k === 1) {
      ret += int2char((slop << 2) | (v >> 4))
      slop = v & 0xf
      k = 2
    } else if (k === 2) {
      ret += int2char(slop)
      ret += int2char(v >> 2)
      slop = v & 3
      k = 3
    } else {
      ret += int2char((slop << 2) | (v >> 4))
      ret += int2char(v & 0xf)
      k = 0
    }
  }
  if (k === 1) {
    ret += int2char(slop << 2)
  }
  return ret
}

/**
 * base64 문자열을 object로 치환한다.
 * @param str
 * @returns object
 */
export const b64ToObj = str => JSON.parse(Buffer.from(str, 'base64').toString())

/**
 * object를 base64로 치환한다.
 * @param obj
 * @returns base64 문자열
 */
export const objToB64 = (obj): string => Buffer.from(JSON.stringify(obj)).toString('base64')

export const devtoolsInNonProd = (process.env.NODE_ENV === 'production'
  ? (fn: any) => fn
  : devtools) as unknown as typeof devtools

/**
 * getUUID
 * 유니크한 ID값 얻기 (기본 4자리)
 * 파라미터 문자열 내에 'x'나 'X' 표시를 랜덤값으로 치환.
 *
 * @param format {string}
 * @returns {string}
 */
export function getUUID(format: string = 'xxxx'): string {
  return format.replace(/[x|X]/g, c => {
    const r = Math.ceil(Math.random() * Math.pow(10, c.length)).toString(16)
    return c === 'X' ? r.toUpperCase() : r
  })
}

/**
 * uuid
 * 기존 react-uuid 라이브러리의 UUID 생성 방식대로 리턴.
 * example: 1b83fd69-abe7-468c-bea1-306a8aa1c81d
 */
export function uuid(): string {
  return getUUID('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx')
}
