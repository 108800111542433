/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonbAddress } from './JsonbAddress';
import type { Point } from './Point';
export type OwnerAdminAddressBookDtoResult = {
    address?: JsonbAddress;
    addressType?: OwnerAdminAddressBookDtoResult.addressType;
    businessId?: string;
    businessName?: string;
    createdAt?: string;
    isDropBasicAddress?: boolean;
    isPickupBasicAddress?: boolean;
    isWaypointBasicAddress?: boolean;
    memberId?: string;
    memberName?: string;
    orderBy?: number;
    ownerAddressBookId?: string;
    point?: Point;
    updatedAt?: string;
};
export namespace OwnerAdminAddressBookDtoResult {
    export enum addressType {
        COMMON = 'COMMON',
        DROP = 'DROP',
        PICKUP = 'PICKUP',
        PICKUP_RETURN = 'PICKUP_RETURN',
        WAYPOINT = 'WAYPOINT',
    }
}

