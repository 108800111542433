import { MenuItem } from '@features/general/adminUser/types'

/**
 * searchMenuTree
 * 트리 탐색: 각 노드에 대한 엔트리를 콜백으로 전달하거나 전체 검색 순서를 리턴한다.
 *
 * @template N TreeNode
 * @param rootNode {N | N[]}
 * @param callback {(currentNode) => false | undefined | void}
 * @param searchMode {'BFS' | 'DFS' | undefined}
 * @returns {N}
 */
export function searchMenuTree<N extends MenuItem>(
  rootNode: N | N[],
  callback?: (currentNode: N) => false | undefined | void,
  searchMode: 'BFS' | 'DFS' | undefined = 'BFS',
): N[] {
  const visitedNodes: N[] = []
  const unmetNodes: N[] = Array.isArray(rootNode) ? [...rootNode] : [rootNode]
  let isBroken: boolean = false

  while (unmetNodes.length && !isBroken) {
    const currentNode = unmetNodes.shift()

    if (currentNode) {
      const isVisited = visitedNodes.some(n => n.menuConstId === currentNode.menuConstId)
      if (!isVisited) {
        visitedNodes.push(currentNode)
        unmetNodes[searchMode === 'BFS' ? 'push' : 'unshift'](...(currentNode.subMenus as N[]))
        if (callback?.(currentNode) === false) {
          // 콜백에서 false를 리턴하면 즉시 탐색 종료
          isBroken = true
        }
      }
    }
  }

  return visitedNodes
}
