/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CouponDtoBusinessGroupDto } from './CouponDtoBusinessGroupDto';
export type CouponDtoCouponPolicySaveRequest = {
    businessGroups?: Array<CouponDtoBusinessGroupDto>;
    countType?: CouponDtoCouponPolicySaveRequest.countType;
    countValue?: Array<number>;
    couponPolicyId?: number;
    couponTitle?: string;
    endDateTime?: string;
    maximumDiscount?: number;
    priceType?: CouponDtoCouponPolicySaveRequest.priceType;
    priceValue?: number;
    serviceType?: CouponDtoCouponPolicySaveRequest.serviceType;
    startDateTime?: string;
    useEndDateTime?: string;
    useStartDateTime?: string;
};
export namespace CouponDtoCouponPolicySaveRequest {
    export enum countType {
        INTERVAL = 'INTERVAL',
    }
    export enum priceType {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
    }
    export enum serviceType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
}

