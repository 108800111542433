import { LicenseInfo } from '@mui/x-license-pro'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { UNAUTH_ROUTES } from '@/constants/routes'
import { RouteGuard } from '@/features/common/components/RouteGuard'
import { FetcherProvider } from '@/providers/FetcherContext'
import InaviMapClientProvider from '@/providers/InaviMapClientProvider'
import ReactQueryProvider from '@/providers/ReactQueryProvider'
import FontsProvider from '@/providers/FontsProvider'
import ThemeProvider from '@/providers/ThemeProvider'
import '@cjconnect/react-common/dist/styles/editor.css'
import 'dayjs/locale/ko'
import '@assets/styles/globals.scss'
import '@assets/styles/nextjs-jsoneditor.css'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_PRO_KEY)
dayjs.locale('ko')
dayjs.extend(utc)
dayjs.extend(customParseFormat)

const AuthenticatedLayout = dynamic(() => import('@features/common/components/layouts/AuthenticatedLayout'), {
  ssr: false,
})
const UnauthenticatedLayout = dynamic(() => import('@features/common/components/layouts/UnauthenticatedLayout'), {
  ssr: false,
})

function MyApp({ Component, pageProps }: AppProps): JSXElement {
  const router = useRouter()
  const AlteredLayout = useMemo(
    () => (UNAUTH_ROUTES.includes(router.pathname) ? UnauthenticatedLayout : AuthenticatedLayout),
    [router],
  )

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no' />
        <meta name='mobile-web-app-capable' content='no' />
        {/* Site verifications */}
        <meta name='google-site-verification' content='xOFAmSg9ZDMmP4f6UvYFNcuaHzzromYkRfUewAugK1Y' />
        <meta name='naver-site-verification' content='7a90b9f8238420a2ff4b386366f47a3e8b57d917' />
        {/* Favicon */}
        <link rel='icon' href='/favicon.ico' type='image/x-icon' sizes='310x310' />
        <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' sizes='310x310' />
        {/* Pre-connects and DNS-prefetches */}
        <link rel='dns-prefetch' href='https://t1.daumcdn.net' />
        <title>{process.env.NEXT_PUBLIC_APP_TITLE}</title>
      </Head>

      <FetcherProvider>
        <ReactQueryProvider state={pageProps.dehydratedState}>
          <FontsProvider>
            <ThemeProvider>
              <div id='portal'></div>
              <RouteGuard>
                <AlteredLayout>
                  <InaviMapClientProvider>
                    <Component {...pageProps} />
                  </InaviMapClientProvider>
                </AlteredLayout>
              </RouteGuard>
            </ThemeProvider>
          </FontsProvider>
        </ReactQueryProvider>
      </FetcherProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
