/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminSapSettlementDtoSapCreditSalesDepositRequest = {
    businessId?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessSettlementGroupIds?: Array<string>;
    depositSettlementStatus?: SearchAdminSapSettlementDtoSapCreditSalesDepositRequest.depositSettlementStatus;
    documentId?: string;
    documentStatusType?: SearchAdminSapSettlementDtoSapCreditSalesDepositRequest.documentStatusType;
    fromDate?: string;
    pageNo?: number;
    pageSize?: number;
    settlementSearchDateType: SearchAdminSapSettlementDtoSapCreditSalesDepositRequest.settlementSearchDateType;
    sortColumn?: string;
    sortDirection?: string;
    tempDocumentId?: string;
    toDate?: string;
};
export namespace SearchAdminSapSettlementDtoSapCreditSalesDepositRequest {
    export enum depositSettlementStatus {
        DEPOSIT_COMPLETE = 'DEPOSIT_COMPLETE',
        DEPOSIT_INCOMPLETE = 'DEPOSIT_INCOMPLETE',
    }
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum settlementSearchDateType {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DOCUMENT_ISSUED = 'DOCUMENT_ISSUED',
        DROP = 'DROP',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        PAY_SUCCESS = 'PAY_SUCCESS',
        PICKUP = 'PICKUP',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
        QUICKPAY_REQUEST_DESIGNATED = 'QUICKPAY_REQUEST_DESIGNATED',
    }
}

