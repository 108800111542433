/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonbAddress } from './JsonbAddress';
import type { Point } from './Point';
export type BusinessDtoRequestBusinessLicense = {
    businessAddress?: JsonbAddress;
    businessCategory?: string;
    businessCondition?: string;
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessType?: BusinessDtoRequestBusinessLicense.businessType;
    corporateRegistrationNumber?: string;
    headOfficeAddress?: JsonbAddress;
    mdmAddress?: string;
    memberId?: string;
    point?: Point;
    representativeName?: string;
    representativePhoneNumber?: string;
    startDate?: string;
};
export namespace BusinessDtoRequestBusinessLicense {
    export enum businessType {
        CORPORATE = 'CORPORATE',
        INDIVIDUAL = 'INDIVIDUAL',
        NO_TAX = 'NO_TAX',
        SIMPLIFIED = 'SIMPLIFIED',
        SIMPLIFIED_NO_TAX = 'SIMPLIFIED_NO_TAX',
    }
}

