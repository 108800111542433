/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoSignUpHistory = {
    businessRegistrationNumber?: string;
    createdAt?: string;
    joinStatusMessage?: string;
    status?: MemberDtoSignUpHistory.status;
};
export namespace MemberDtoSignUpHistory {
    export enum status {
        DEACTIVATED = 'DEACTIVATED',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SIGN_UP = 'SIGN_UP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
}

