import { useExpectRouteDetailQuery } from '@features/orderDetail/queries/useMatchingStatusDetailQuery'
import { DriverLocationModel, WaypointListModel } from '@features/orderDetail/types'
import { captureExceptionWithTitle } from '@utils/captureExceptionWithTitle'
import DriverLocationDtoResult from '@utils/firebase/models/driverLocationDtoResult'
import { useEffect, useState } from 'react'
import { OrderDetailDtoResult } from 'src/data'
import { MapPoint } from '../types/models/mapPoint'
import PrevDriverLocationModel from '../types/models/prevDriverLocationModel'
import { formatterWaypointOrderBy, isCheckedWaypointMoving, isCheckedWaypointStep } from '@utils/commonUtils'

interface IUseGetInaviRoute {
  point: { from: MapPoint; to: MapPoint }
  waypointListModel?: WaypointListModel
  setDriverLocationModelData: (driverLocationDataModel: DriverLocationDtoResult) => void
  getDriverLocationModelData: () => DriverLocationModel
  getPrevDriverLocation: () => PrevDriverLocationModel
  setPrevDriverLocation: (prevDriverLocationModel: PrevDriverLocationModel) => void
  prevPosition: PrevDriverLocationModel
}

export const useGetInaviRoute = ({
  point,
  waypointListModel,
  setDriverLocationModelData,
  getDriverLocationModelData,
  getPrevDriverLocation,
  setPrevDriverLocation,
  prevPosition,
}: IUseGetInaviRoute) => {
  const [driverLocation, setDriverLocation] = useState<DriverLocationDtoResult>(DriverLocationDtoResult.init())

  const getDestPoint = (driverLocation: DriverLocationDtoResult) => {
    if (isCheckedWaypointMoving(driverLocation.status)) {
      return waypointListModel.waypointList[Number(formatterWaypointOrderBy(driverLocation.status) - 1)].point
    }

    switch (driverLocation.status) {
      case OrderDetailDtoResult.orderStatus.PICKUP_MOVING:
        return point.from

      case OrderDetailDtoResult.orderStatus.DROP_MOVING:
      default:
        return point.to
    }
  }

  const endPoint = getDestPoint(driverLocation)
  const params = {
    startX: driverLocation.lng,
    startY: driverLocation.lat,
    endX: endPoint.lng,
    endY: endPoint.lat,
  }

  const { refetch: mutateGetRouteDetail } = useExpectRouteDetailQuery(params, {
    onSuccess(data: { spend_time: number }) {
      setPrevDriverLocation(new PrevDriverLocationModel({ ...prevPosition, refreshTime: new Date() }))
      setDriverLocationModelData(new DriverLocationDtoResult({ ...driverLocation, remainTimeSecond: data.spend_time }))
    },
    onError(err) {
      captureExceptionWithTitle('☢️ Api Error', err)
      setDriverLocationModelData(driverLocation)
    },
  })

  const isRequestExpectRoute = (result: DriverLocationDtoResult) => {
    const baseStatuses: string[] = [
      OrderDetailDtoResult.orderStatus.PICKUP_MOVING,
      OrderDetailDtoResult.orderStatus.DROP_MOVING,
    ]
    return (
      result.remainTimeSecond <= 0 &&
      isCheckedWaypointStep(OrderDetailDtoResult.orderStatus[result.status], baseStatuses)
    )
  }

  const getExpectRouteDetailData = async (driverLocation: DriverLocationDtoResult) => {
    //이전 위치와 비교해 달라졌을 경우에만 api호출
    const prevDriverLocation = getPrevDriverLocation()
    if (prevDriverLocation.lat === driverLocation.lat && prevDriverLocation.lng === driverLocation.lng) {
      setDriverLocationModelData(
        new DriverLocationDtoResult({
          ...driverLocation,
          remainTimeSecond: getDriverLocationModelData().remainTimeSecond,
        }),
      )
      return
    }

    setPrevDriverLocation(
      new PrevDriverLocationModel({
        lat: driverLocation.lat,
        lng: driverLocation.lng,
        refreshTime: prevPosition.refreshTime,
      }),
    )

    // 이전 호출시간에서 1분이 지났을 경우에 api호출
    const currentTime = new Date()
    if (prevDriverLocation.isNotRefresh(currentTime)) {
      setDriverLocationModelData(
        new DriverLocationDtoResult({
          ...driverLocation,
          remainTimeSecond: getDriverLocationModelData().remainTimeSecond,
        }),
      )
      return
    }
    setDriverLocation(new DriverLocationDtoResult(driverLocation))
  }

  useEffect(() => {
    if (driverLocation.lng && driverLocation.lat && driverLocation.phoneNumber && driverLocation.plateNumber) {
      mutateGetRouteDetail()
    }
  }, [driverLocation])

  useEffect(
    () => () => {
      setPrevDriverLocation(PrevDriverLocationModel.init())
    },
    [],
  )

  return {
    isRequestExpectRoute,
    getExpectRouteDetailData,
  }
}
