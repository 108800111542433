import { CommonFileDtoResult } from '@/data'

export type { IAddressInfo } from './addressInfo'
export { ApiError } from './apiError'
export { DriverType as DriverTypeEnum } from './enums/driverType'
export type { IPointInfo } from './pointInfo'
export type { ISelectBoxInfo } from './selectBoxInfo'
export type { IAccessToken } from './token'
export { User } from './user'
export type { IUser } from './user'

export { CarTonFilterCollection, CarTonFilterModel } from './models/carTonFilterModel'
export { CarTypeFilterCollection, CarTypeFilterModel } from './models/carTypeFilterModel'
export { LoadingAreaFilterCollection, LoadingAreaFilterModel } from './models/loadingAreaFilterModel'

export type { IGetRecentOrderVehiclesResponse } from './getRecentOrderVehiclesResponse'
export type { IGetRecentOrderItemsResponse } from './getRecentOrderItemsResponse'

export interface IListItem<K = string, V = any> {
  key: K
  value: V
  label?: string
  selected?: boolean
  disabled?: boolean
  isDefault?: boolean
}

export interface IFileInfo {
  fileCreateAt?: string
  fileId?: number
  fileName?: string
  fileUrl?: string
  fileData?: unknown | string
  approvalStatus?: CommonFileDtoResult.approvalStatus
}

export interface IErrorBag {
  fieldName: string
  message: string
}

type DataGridFilterMulti = { type: 'MULTI'; data: IListItem[] }
type DataGridFilterSearch = { type: 'SEARCH'; data: string }
export type DataGridFilterItem = { id: string } & (DataGridFilterMulti | DataGridFilterSearch)

export { Vehicle as VehicleEnum } from './enums/vehicle'
export type { VehicleEnumType } from './enums/vehicle'
export { VehicleWeight as VehicleWeightEnum } from './enums/vehicleWeight'
export type { VehicleWeightEnumType } from './enums/vehicleWeight'
export type { default as IMeta } from './interfaces/meta'
export type { default as IOrderMetaModel } from './orderMetaModel'
export type { IMetaState } from './states/metaState'
export { PaymentMethodType as OrderPaymentMethodTypeEnum } from './enums/paymentMethodType'
export { LiftedTask as LiftedTaskEnum } from './enums/liftedTask'
export type { LiftedTaskEnumType } from './enums/liftedTask'
export { ItemType as ItemTypeEnum } from './enums/itemType'
export type { ItemTypeEnumType } from './enums/itemType'
