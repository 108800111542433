import { GetMonitoringDtoRequest } from '@features/common/types/enums/GetMonitoringDtoRequest'

interface IDriverLocationDtoResult {
  driverId: string
  isGpsOff: boolean
  lat: number
  lng: number
  orderId: string
  orderNo: string
  status: string
  timeStamp: number
  remainDistanceMeter?: number
  remainTimeSecond?: number
  tonType?: GetMonitoringDtoRequest.carTonType
  vehicleType?: GetMonitoringDtoRequest.carType
  driverStatus?: GetMonitoringDtoRequest.driverStatus
  vehicleOptionTypes?: GetMonitoringDtoRequest.vehicleOption[]
  plateNumber?: string
  phoneNumber: string
  name: string
  speed: number
  isAlert?: boolean
  callCardRecommendedOption?: boolean
  distance?: number // 상차지부터 기사의 위치까지의 거리 km
  partnerBusinessId?: string
  // partnerOrderInfo?: {
  //   name?: string
  //   orderId?: string
  //   orderNo?: string
  //   partnerBusinessId?: string
  //   phoneNumber?: string
  //   plateNumber?: string
  //   tonType?: string
  //   vehicleType?: string
  // }
}

class DriverLocationDtoResult implements IDriverLocationDtoResult {
  driverId: string
  isGpsOff: boolean
  lat: number
  lng: number
  orderId: string
  orderNo: string
  status: string
  timeStamp: number
  remainDistanceMeter?: number
  remainTimeSecond?: number
  tonType?: GetMonitoringDtoRequest.carTonType
  vehicleType?: GetMonitoringDtoRequest.carType
  driverStatus?: GetMonitoringDtoRequest.driverStatus
  vehicleOptionTypes?: GetMonitoringDtoRequest.vehicleOption[]
  plateNumber?: string
  phoneNumber: string
  name: string
  speed: number
  isAlert?: boolean
  callCardRecommendedOption?: boolean
  distance?: number // 상차지부터 기사의 위치까지의 거리 km
  partnerBusinessId?: string
  // partnerOrderInfo?: {
  //   name?: string
  //   orderId?: string
  //   orderNo?: string
  //   partnerBusinessId?: string
  //   phoneNumber?: string
  //   plateNumber?: string
  //   tonType?: string
  //   vehicleType?: string
  // }

  constructor({
    driverId,
    isGpsOff,
    lat,
    lng,
    orderId,
    orderNo,
    status,
    timeStamp,
    remainDistanceMeter,
    remainTimeSecond,
    tonType,
    vehicleType,
    driverStatus,
    vehicleOptionTypes,
    plateNumber,
    phoneNumber,
    name,
    speed,
    callCardRecommendedOption,
    distance,
  }: IDriverLocationDtoResult) {
    this.driverId = driverId
    this.isGpsOff = isGpsOff
    this.lat = lat
    this.lng = lng
    this.orderId = orderId
    this.orderNo = orderNo
    this.status = status
    this.timeStamp = timeStamp
    this.remainDistanceMeter = remainDistanceMeter
    this.remainTimeSecond = remainTimeSecond
    this.tonType = tonType
    this.vehicleType = vehicleType
    this.driverStatus = driverStatus
    this.vehicleOptionTypes = vehicleOptionTypes
    this.plateNumber = plateNumber
    this.phoneNumber = phoneNumber
    this.name = name
    this.speed = speed
    this.callCardRecommendedOption = callCardRecommendedOption
    this.distance = distance
  }

  static init() {
    return new DriverLocationDtoResult({
      driverId: '',
      isGpsOff: false,
      lat: 0,
      lng: 0,
      orderId: '',
      orderNo: '',
      status: '',
      timeStamp: 0,
      phoneNumber: '',
      name: '',
      speed: 0,
      vehicleOptionTypes: [],
      callCardRecommendedOption: false,
      distance: 0,
    })
  }

  public get isEmptyLocation(): boolean {
    return !this.lng || !this.lat
  }
}

export default DriverLocationDtoResult
