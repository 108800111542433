import { AdminUserDtoResult } from 'src/data'
import { AdminUserListItemModel, AdminUserListModel } from '../types'
import { uuid } from '@utils'

export class AdminUserListModelMapper {
  static mapToPresentation(data: AdminUserDtoResult): AdminUserListModel {
    const model = new AdminUserListModel()
    model.total = data.total
    model.items = data.adminUserInfo.map(
      userInfo =>
        new AdminUserListItemModel({
          id: uuid(),
          memberId: userInfo.memberId,
          adminId: userInfo.adminId,
          adminName: userInfo.adminName,
          memberRoleType: userInfo.memberRoleType,
          isLocked: userInfo.locked,
          lastAccessAt: userInfo.lastAccessAt,
          createdAt: userInfo.createdAt,
          updatedAt: userInfo.updatedAt,
          phoneNumber: userInfo.phoneNumber,
        }),
    )

    return model
  }
}
