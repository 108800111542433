// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"80e87041d7422254748279d2c21dee1dd4748cb9"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import {
  init,
  browserTracingIntegration,
  captureConsoleIntegration,
  replayIntegration,
  rewriteFramesIntegration,
} from '@sentry/nextjs'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN

if (dsn) {
  init({
    dsn,

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    release: `admin-front@${process.env.APP_VERSION}`,
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    attachStacktrace: true,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      browserTracingIntegration(),
      replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, /^https:\/\/api-admin(?:-int|-stage|)\.unban\.ai/],
        networkRequestHeaders: ['Cache-Control'],
        networkResponseHeaders: ['Referrer-Policy'],
      }),
      rewriteFramesIntegration({
        iteratee: frame => {
          if (frame.filename) {
            frame.filename = decodeURI(frame.filename.replace('.next', 'app:///_next'))
          }
          return frame
        },
      }),
    ],

    /* prettier-ignore */
    ignoreErrors: [
      'Malformed UTF-8 data',
      'Non-Error promise rejection captured with keys: message, responseCode, result',
      'Cannot destructure property \'data\' of \'(intermediate value)\' as it is undefined.',
    ],

    beforeSend: (event, hint) => {
      const { response } = hint.originalException as any
      return [401, 511].includes(Number(response?.status ?? 0)) ? null : event
    },
  })
}
