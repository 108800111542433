import { MetropolitanListModel } from '@features/common/types/models'
import { i18n } from 'next-i18next'

export default class MetropolitanListModelMapper {
  static mapToPresentation(list) {
    const commonOption = {
      key: 'ALL',
      value: i18n.t('common:all'),
      label: i18n.t('common:all'),
    }

    const result = list
    const loadingArea = [commonOption]
    const unloadingArea = [commonOption]

    result.forEach(res => {
      loadingArea.push({
        key: res,
        value: res,
        label: res,
      })
      unloadingArea.push({
        key: res,
        value: res,
        label: res,
      })
    })

    return new MetropolitanListModel({
      loadingArea: loadingArea,
      unloadingArea: unloadingArea,
    })
  }
}
