/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SapSettlementDtoSapSalesGroupRequest = {
    businessId?: string;
    fromDate?: string;
    orderIds?: Array<string>;
    settlementSearchDateType?: SapSettlementDtoSapSalesGroupRequest.settlementSearchDateType;
    toDate?: string;
};
export namespace SapSettlementDtoSapSalesGroupRequest {
    export enum settlementSearchDateType {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DOCUMENT_ISSUED = 'DOCUMENT_ISSUED',
        DROP = 'DROP',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        PAY_SUCCESS = 'PAY_SUCCESS',
        PICKUP = 'PICKUP',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
        QUICKPAY_REQUEST_DESIGNATED = 'QUICKPAY_REQUEST_DESIGNATED',
    }
}

