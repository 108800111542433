/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminSmsRequestDtoMessage = {
    contentType?: AdminSmsRequestDtoMessage.contentType;
    countryCode?: string;
    from?: string;
    messageId?: string;
    requestTime?: string;
    to?: string;
};
export namespace AdminSmsRequestDtoMessage {
    export enum contentType {
        AD = 'AD',
        COMM = 'COMM',
    }
}

