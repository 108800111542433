/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractFreightOwnerDtoContractFreightInfo } from './ContractFreightOwnerDtoContractFreightInfo';
export type ContractFreightOwnerDtoRequest = {
    businessId: Array<string>;
    contractFreightInfos?: Array<ContractFreightOwnerDtoContractFreightInfo>;
    freightType: ContractFreightOwnerDtoRequest.freightType;
};
export namespace ContractFreightOwnerDtoRequest {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

