/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderMatchingForExcelDtoWayPointForExcel } from './OrderMatchingForExcelDtoWayPointForExcel';
export type OrderMatchingForExcelDtoMatchingOrder = {
    businessName?: string;
    businessSettlementGroupMemo?: string;
    dropRequestAt?: string;
    fromAddr?: string;
    fromAddrManagerName?: string;
    fromAddrPhoneNumber?: string;
    fromAddrRoadAddress1?: string;
    fromAddrRoadAddress2?: string;
    ioBoundMemo?: string;
    isDriverOpened?: boolean;
    itemName?: string;
    orderId?: string;
    orderNo?: string;
    orderType?: string;
    pickupRequestAt?: string;
    priceGroupPolicyTitle?: string;
    rangePrice?: string;
    requestCarType?: string;
    stowageOpen?: string;
    toAddr?: string;
    toAddrManagerName?: string;
    toAddrPhoneNumber?: string;
    toAddrRoadAddress1?: string;
    toAddrRoadAddress2?: string;
    ton?: string;
    totalDistance?: number;
    transactionMethodType?: OrderMatchingForExcelDtoMatchingOrder.transactionMethodType;
    waypoints?: Array<OrderMatchingForExcelDtoWayPointForExcel>;
};
export namespace OrderMatchingForExcelDtoMatchingOrder {
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

