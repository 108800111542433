/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BannerImageDtoRequestFileImageUpload } from './BannerImageDtoRequestFileImageUpload';
export type BannerDtoRequestCreate = {
    bannerApplyMenuType?: BannerDtoRequestCreate.bannerApplyMenuType;
    bannerCloseType: BannerDtoRequestCreate.bannerCloseType;
    bannerDisplayType: BannerDtoRequestCreate.bannerDisplayType;
    bannerTargetType: BannerDtoRequestCreate.bannerTargetType;
    bannerTitle: string;
    bannerType: BannerDtoRequestCreate.bannerType;
    endDate?: string;
    images?: Array<BannerImageDtoRequestFileImageUpload>;
    isUsed?: boolean;
    startDate: string;
};
export namespace BannerDtoRequestCreate {
    export enum bannerApplyMenuType {
        OWNER_HOME = 'OWNER_HOME',
        OWNER_ORDER_CREATE = 'OWNER_ORDER_CREATE',
        OWNER_ORDER_DETAIL = 'OWNER_ORDER_DETAIL',
    }
    export enum bannerCloseType {
        NO_SEETING = 'NO_SEETING',
        ONE_DAY = 'ONE_DAY',
        ONE_WEEK = 'ONE_WEEK',
    }
    export enum bannerDisplayType {
        NEW_TAB = 'NEW_TAB',
        PAGE_CHANGE = 'PAGE_CHANGE',
    }
    export enum bannerTargetType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
    export enum bannerType {
        BANNER = 'BANNER',
        POPUP = 'POPUP',
    }
}

