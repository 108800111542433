/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractFreightOwnerDtoTonAndBaseFee = {
    baseFee?: number;
    contractFreightDriverId?: string;
    contractFreightOwnerId?: string;
    quickTon?: ContractFreightOwnerDtoTonAndBaseFee.quickTon;
    ton?: ContractFreightOwnerDtoTonAndBaseFee.ton;
};
export namespace ContractFreightOwnerDtoTonAndBaseFee {
    export enum quickTon {
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
    }
    export enum ton {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
}

