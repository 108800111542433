/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderReceiptDtoOrderReceiptFileInfo } from './OrderReceiptDtoOrderReceiptFileInfo';
export type OrderReceiptDtoReceiptInfo = {
    orderReceiptInfos?: Array<OrderReceiptDtoOrderReceiptFileInfo>;
    orderReceiptType?: OrderReceiptDtoReceiptInfo.orderReceiptType;
    orderReceiptTypeValue?: string;
};
export namespace OrderReceiptDtoReceiptInfo {
    export enum orderReceiptType {
        DROP = 'DROP',
        RETURN = 'RETURN',
        WAYPOINT1 = 'WAYPOINT1',
        WAYPOINT10 = 'WAYPOINT10',
        WAYPOINT11 = 'WAYPOINT11',
        WAYPOINT12 = 'WAYPOINT12',
        WAYPOINT13 = 'WAYPOINT13',
        WAYPOINT14 = 'WAYPOINT14',
        WAYPOINT15 = 'WAYPOINT15',
        WAYPOINT16 = 'WAYPOINT16',
        WAYPOINT17 = 'WAYPOINT17',
        WAYPOINT18 = 'WAYPOINT18',
        WAYPOINT19 = 'WAYPOINT19',
        WAYPOINT2 = 'WAYPOINT2',
        WAYPOINT20 = 'WAYPOINT20',
        WAYPOINT3 = 'WAYPOINT3',
        WAYPOINT4 = 'WAYPOINT4',
        WAYPOINT5 = 'WAYPOINT5',
        WAYPOINT6 = 'WAYPOINT6',
        WAYPOINT7 = 'WAYPOINT7',
        WAYPOINT8 = 'WAYPOINT8',
        WAYPOINT9 = 'WAYPOINT9',
    }
}

