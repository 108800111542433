/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PushDtoSendMessageHistoryRequest = {
    pageNo?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: PushDtoSendMessageHistoryRequest.sortDirection;
};
export namespace PushDtoSendMessageHistoryRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

