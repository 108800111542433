/**
 * API 관련 코드
 */
export enum ApiResponseCode {
  COMMON_OK = 0,
  PRICE_CONFIG_DUPLICATED_ORDER = 1001,
  PRICE_CONFIG_INVALID_CAL_VALUE = 1002,
  PRICE_CONFIG_INVALID_DATE = 1003,
  PRICE_CONFIG_INVALID_TIME = 1004,
  PRICE_CONFIG_INVALID_DATA = 1005,
  PRICE_CONFIG_HISTORY_NOT_FOUND = 1006,
  COMMON_UNKNOWN = 101,
  COMMON_BAD_REQUEST = 102,
  COMMON_APP_NEED_UPGRADE = 103,
  COMMON_TEMPORARY_UNAVAILABLE = 104,
  COMMON_NO_SUCH_ELEMENT = 105,
  COMMON_JSON_PARSING_ERROR = 106,
  COMMON_REQUEST_ALREADY_RUNNING = 107,
  COMMON_INVALID_STATUS = 108,
  COMMON_DUPLICATE_DATA_EXIST = 109,
  COMMON_GOVERNMENT_SERVER_ERROR = 110,
  NOT_FOUND_PROMOTION = 1101,
  INVALID_UPDATE_REQUEST_START_DATE = 1102,
  COMMON_SMS_VERIFY_REQUEST_ERROR = 111,
  COMMON_BUSINESS_STATUS_UNAVAILABLE = 112,
  COMMON_BUSINESS_TAX_TYPE_UNAVAILABLE = 113,
  COMMON_BAD_REQUEST_BUSINESS_REGISTRATION = 114,
  COMMON_AWS_KMS_ERROR = 115,
  COMMON_PORTAL_BAD_REQUEST = 116,
  COMMON_DUPLICATED_ELEMENT = 117,
  EVENT_ALREADY_REGISTRATION_ERROR = 1201,
  STICKER_EVENT_NO_TARGET_MEMBER_ERROR = 1202,
  STICKER_EVENT_NO_DATA_ERROR = 1203,
  STICKER_EVENT_ALREADY_VERIFICATION_ERROR = 1204,
  STICKER_EVENT_INVALID_UPLOAD_STATUS_ERROR = 1205,
  STICKER_EVENT_INVALID_UPLOAD_FILE_TYPE_ERROR = 1206,
  STICKER_EVENT_INVALID_UPLOAD_DATE_ERROR = 1207,
  STICKER_EVENT_NO_VERIFICATION_DATA_ERROR = 1208,
  STICKER_EVENT_EXPIRED_VERIFICATION_DATE_ERROR = 1209,
  STICKER_EVENT_ALREADY_UPLOAD_IMAGE_ERROR = 1210,
  STICKER_EVENT_UPLOAD_CONTRACT_ERROR = 1211,
  STICKER_EVENT_INVOICE_NO_DATA_ERROR = 1212,
  STICKER_EVENT_INVALID_INVOICE_AMOUNT_ERROR = 1213,
  STICKER_EVENT_INVALID_INVOICE_STATUS_ERROR = 1214,
  STICKER_EVENT_IN_PROGRESS_INVOICE_ISSUE_ERROR = 1215,
  STICKER_EVENT_INVOICE_ISSUE_INVALID_DATE_ERROR = 1216,
  STICKER_EVENT_NOT_EXISTS_INVOICE_DATA_ERROR = 1217,
  STICKER_EVENT_ALREADY_CONTRACT_ERROR = 1218,
  STICKER_EVENT_CONTRACT_STATUS_ERROR = 1219,
  BAROS_CLIENT_ERROR = 1301,
  BAROS_CLIENT_NOTNULL_PARAM = 1302,
  BAROS_CLIENT_ILLEGAL_ARGUMENT = 1303,
  BAROS_CLIENT_ILLEGAL_CAR_REQUEST_STATUS = 1304,
  BAROS_CLIENT_CENTER_CAPACITY_EXCEED = 1305,
  BAROS_CLIENT_CANNOT_CHANGE_ARRIVAL = 1306,
  BAROS_CLIENT_INVALID_IP_TOKEN = 1307,
  BAROS_CLIENT_CAR_REQUEST_ARRIVAL_TIME_EXCEED_NOW = 1308,
  CONTRACT_FREIGHT_DUPLICATE_DATA_EXIST = 1401,
  NOT_FOUND_CONTRACT_FREIGHT = 1402,
  NOT_FOUND_CONTRACT_FREIGHT_AREA = 1403,
  NOT_FOUND_CONTRACT_FREIGHT_TON = 1404,
  NOT_CONTRACT_FREIGHT_EXCEL_VERIFICATION = 1405,
  NOT_CONTRACT_FREIGHT = 1406,
  DUPLICATE_FIXED_DRIVER = 1407,
  CONTRACT_FREIGHT_TON_BASE_FEE = 1408,
  CONTRACT_FREIGHT_PRICE_VALUE_ERROR = 1409,
  CONTRACT_FREIGHT_PRICE_TIME_ERROR = 1410,
  TPS_MATCHBACK_ENGINE_ACTIVE_ERROR = 1501,
  TPS_MATCHBACK_ENGINE_MAX_TRIP_COUNT_ERROR = 1502,
  TPS_MATCHBACK_ENGINE_TOTAL_MIN_DISTANCE_ERROR = 1503,
  TPS_MATCHBACK_ENGINE_TOTAL_MAX_OPERATION_HOUR_ERROR = 1504,
  TPS_MATCHBACK_ENGINE_TOTAL_EMPTY_DISTANCE_ERROR = 1505,
  TPS_MATCHBACK_ENGINE_MAX_IDLE_TIME_ERROR = 1506,
  TPS_REGION_DUPLICATED_ERROR = 1551,
  TPS_REGION_GEO_ADMINISTRATIVE_DUPLICATED_ERROR = 1552,
  AUTH_INVALID_TOKEN = 201,
  AUTH_NO_MEMBER_ERROR = 202,
  AUTH_INVALID_PASSWORD_ERROR = 203,
  AUTH_NO_TOKEN_ERROR = 204,
  AUTH_DRIVER_USER_ALREADY_EXIST_ERROR = 205,
  AUTH_INVALID_SERVICE_TYPE = 207,
  AUTH_MULTI_LOGIN_ERROR = 208,
  AUTH_PERMISSION_ERROR = 209,
  AUTH_INVALID_SERVICE_TYPE_ERROR = 210,
  AUTH_VEHICLE_ALREADY_EXIST_ERROR = 211,
  AUTH_INVALID_REFRESH_TOKEN = 212,
  AUTH_OWNER_PHONE_NUMBER_ALREADY_EXIST_ERROR = 213,
  AUTH_NO_APPROVED_ERROR = 214,
  AUTH_OWNER_USER_ALREADY_EXIST_ERROR = 215,
  NOT_FOUND_OWNER_USER = 216,
  NOT_FOUND_DRIVER_USER = 217,
  INVALID_MEMBER_SERVICE_TYPE = 218,
  DRIVER_CHANGED_ERROR = 219,
  AUTH_IDENTICAL_PASSWORD_ERROR = 220,
  AUTH_DEACTIVATED_WITHIN_48HOURS_ERROR = 221,
  UN_REGISTRATION_FAIL_ERROR = 222,
  AUTH_SLEEP_MEMBER_ERROR = 223,
  NO_MAIN_OWNER_ERROR = 224,
  INVALID_PASSWORD_CHANGE_DATE = 225,
  INVALID_PASSWORD_SAME = 226,
  AUTH_LOGIN_DEACTIVATED_WITHIN_48HOURS_ERROR = 227,
  INVALID_PASSWORD_THREE_COMBINATION = 228,
  INVALID_PASSWORD_CONTAIN_ID = 229,
  INVALID_PASSWORD_REPEAT = 230,
  ACCOUNT_LOCK_LOGIN_ERROR = 231,
  NOT_CHANGED_PASSWORD = 232,
  EXPIRED_PASSWORD = 233,
  BASIC_ADDRESS_BOOK_ERROR = 234,
  NOT_EXIST_BASIC_ADDRESS_BOOK_ERROR = 235,
  ADMIN_REG_DRIVER_MDM_NOT_COMPLETE_ERROR = 236,
  ADMIN_REG_DRIVER_SIGN_UP_NOT_AVAILABLE_ERROR = 237,
  ALREADY_EXIST_ADDRESS_BOOK_ERROR = 238,
  DELETE_BASIC_ADDRESS_BOOK_ERROR = 239,
  MEMBER_SETTLEMENT_GROUP_IS_REQUIRED = 240,
  NOT_INPUT_LOWER_THAN_NOW_MONTHLY_SHIPPING_FEE = 241,
  MONTHLY_SHIPPING_FEE_INPUT_MORE_ZERO = 242,
  MONTHLY_SHIPPING_FEE_HAS_BEEN_EXCEEDED = 243,
  DO_NOT_USE_MONTHLY_SHIPPING_FEE_LIMIT = 244,
  DRIVER_INVALID_REF_CODE = 245,
  REDIS_PHONE_NUMBER_EXPIRED = 246,
  NOT_FOUND_MAIN_OWNER_USER = 247,
  EXPIRED_DRIVER_REF_CODE = 248,
  NOT_FOUND_MEMBER_BY_PLATE_NUMBER = 249,
  EXIST_CONSIGNMENT_MDM_VEHICLE = 250,
  EXIST_CONSIGNMENT_MDM_CODE = 251,
  ORDER_NO_ELEMENT_ERROR = 301,
  ORDER_NO_DISPATCH_BIND_ERROR = 302,
  ORDER_NO_CANCELLABLE_ERROR = 303,
  ORDER_NO_DISPATCH_REQUEST_ERROR = 304,
  ORDER_ALREADY_ORDER_CANCELED_ERROR = 305,
  ORDER_ORDER_CANCEL_ERROR = 306,
  ORDER_ORDER_CANCEL_ERP_ERROR = 307,
  ORDER_ESTIMATE_SKIP_ERROR = 308,
  ORDER_REDISPATCHING_INVALID_ORDER_STATUS_ERROR = 309,
  ORDER_REDISPATCHING_ESTIMATE_PRICE_FAIL_ERROR = 310,
  ORDER_NO_OWNER_ADDRESS_BOOK_ERROR = 401,
  ORDER_NO_OWNER_FAVORITES_ORDER_ERROR = 402,
  ORDER_NO_OWNER_FAVORITES_ORDER_ITEM_ERROR = 403,
  ORDER_NO_MEMBER_PAYMENT_INFO_ERROR = 404,
  ORDER_PAYMENT_REMAIN_ERROR = 405,
  ORDER_DISPATCH_OWNER_CANCEL_FAIL_ERROR = 406,
  ORDER_DISPATCH_CANCEL_FAIL_STATUS_ERROR = 407,
  ORDER_EXTRA_INVOICE_FAIL_STATUS_ERROR = 408,
  ORDER_EXTRA_INVOICE_FINAL_STATUS_ERROR = 409,
  ORDER_NO_CONTRACT_FREIGHT_FEE_ERROR = 410,
  ORDER_CONTRACT_SECTION_FROM_ERROR = 411,
  ORDER_CONTRACT_SECTION_TO_ERROR = 412,
  ORDER_CONTRACT_SECTION_TON_ERROR = 413,
  ORDER_NO_CONTRACT_ERROR = 414,
  ORDER_PICKUP_REQUEST_PASSED_CURRENT = 415,
  ORDER_CONTRACT_FREIGHT_SAVE_ERROR = 416,
  ORDER_CONTRACT_FREIGHT_VERIFICATION_ERROR = 417,
  ORDER_EXCEL_UPLOAD_ERROR = 418,
  ORDER_EXCEL_UPLOAD_NO_DATA = 419,
  ORDER_EXCEL_INVALID_FILE_TYPE = 420,
  ORDER_EXCEL_MAX_ROWS_OVER = 421,
  ORDER_EXCEL_MAX_SIZE_OVER = 422,
  ORDER_EXCEL_INVALID_STATUS = 423,
  ORDER_EXCEL_NOT_FOUND_PROGRESS = 424,
  ORDER_EXCEL_CANCEL_STATUS_ERROR = 425,
  ORDER_EXCEL_FOUND_PROGRESS_ERROR = 426,
  ORDER_INBOUND_MANY_ORDER = 427,
  ORDER_EXCEL_MAX_ROWS_ZERO = 428,
  ORDER_FREIGHT_TYPE_CHANGE_IS_POSSIBLE_DISPATCHING = 429,
  ORDER_CREATE_INVALID_TON = 430,
  ORDER_MULTI_MODIFY_ERROR = 431,
  ORDER_NO_ESTIMATE_PRICE_HISTORY_ERROR = 432,
  ORDER_EXCEL_FILE_IO_EXCEPTION_ERROR = 433,
  ORDER_EXCEL_CREATE_NO_DATA_ERROR = 434,
  ORDER_ADDRESS_BOOK_NO_MODIFY_ERROR = 435,
  ORDER_CREATE_INVALID_CAR_TYPE = 436,
  ORDER_CREATE_INVALID_ITEM_TON = 437,
  ORDER_RETURN_REQUEST_PASSED_DROP_REQUEST = 438,
  ORDER_WAYPOINT_VALIDATION_ERROR = 439,
  ORDER_UPDATE_IS_POSSIBLE_DISPATCHING = 440,
  ORDER_IN_BOUND_CENTER_CODE_AND_IO_BOUND_DETAIL_CODE_IS_REQUIRED = 441,
  ORDER_OLD_TICKET_DUE_TO_MODIFY = 442,
  ORDER_MONTHLY_INVOICE_NOT_MODIFY = 443,
  ORDER_MODIFY_DRIVER_FIXED_FEE_OVER = 444,
  ORDER_MODIFY_OWNER_FIXED_FEE_OVER = 445,
  ORDER_TRUNCATE_100_WON = 446,
  ORDER_MODIFY_DRIVER_FIXED_FEE_OVER_BY_2HOUR_OUT = 447,
  ORDER_MODIFY_OWNER_FIXED_FEE_OVER_2HOUR_OUT = 448,
  ORDER_ID_IS_REQUIRED = 449,
  ORDER_CREATE_HANDWORK_INVALID = 450,
  ORDER_CREATE_STOWAGE_INVALID = 451,
  ORDER_CREATE_ORDER_ITEM_INVALID = 452,
  ORDER_CREATE_ADDRESS_INVALID = 453,
  ORDER_CREATE_ESTIMATE_PRICE_INVALID_INVALID = 454,
  ORDER_DRIVER_OWNER_MINUS_TOTAL_AMOUNT_ERROR = 455,
  ORDER_ALREADY_OPENED_ERROR = 456,
  ORDER_OPEN_FAIL_ERROR = 457,
  ORDER_CLOSE_FAIL_ERROR = 475,
  ORDER_CREATE_BUSINESS_TRANSACTION_LEVEL_INVALID = 476,
  ORDER_RE_DISPATCH_BUSINESS_TRANSACTION_LEVEL_INVALID = 477,
  ORDER_EXCEL_INVALID_FILE_NAME = 458,
  ORDER_CONSIGNED_KEY_IN_OWNER_FEE = 459,
  ORDER_CONSIGNED_KEY_IN_DRIVER_FEE = 460,
  ORDER_CREATE_INVALID_REQUEST_CAR = 461,
  ORDER_EXTRA_INVOICE_ITEM_DUPLICATED = 462,
  ORDER_EXTRA_INVOICE_ITEM_CANNOT_BE_DELETED = 463,
  ORDER_EXTRA_INVOICE_SAVE_FINAL_CONCURRENCY = 464,
  ORDER_NOT_ALLOWED_OPEN_CONTRACT_OWNER = 465,
  DIFFERENT_BUSINESS_DRIVER_OPEN_OPTION = 466,
  VEHICLE_NO_ELEMENT_ERROR = 501,
  VEHICLE_MAXIMUM_PREFERENCE_AREA_ERROR = 502,
  VEHICLE_NO_ETA_ERROR = 503,
  VEHICLE_NO_DISPATCHABLE_ERROR = 504,
  VEHICLE_CANCEL_ERROR = 505,
  VEHICLE_COMPLETE_ERROR = 506,
  VEHICLE_DUPLICATE_PREFERENCE_ERROR = 507,
  VEHICLE_ALREADY_ON_DRIVING_ERROR = 508,
  VEHICLE_BEFORE_ETA_ERROR = 509,
  VEHICLE_AFTER_ETA_ERROR = 510,
  VEHICLE_SAME_PICKUP_REQUEST_AT_ERROR = 511,
  VEHICLE_PICK_UP_COMPLETE_AREA_ERROR = 512,
  VEHICLE_DROP_MOVING_AREA_ERROR = 513,
  VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR1 = 514,
  VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR2 = 515,
  VEHICLE_INFO_NOT_ENOUGH = 516,
  VEHICLE_DEPARTURE_AND_DESTINATION_NULL_ERROR = 517,
  VEHICLE_DISPATCH_UNAVAILABLE_TON_TYPE_ERROR = 518,
  VEHICLE_ALREADY_ON_DRIVE_MOVING_ERROR = 519,
  VEHICLE_DIRECT_OPERATED_CAR_ALREADY_EXIST_ERROR = 520,
  VEHICLE_NOT_EXIST_DIRECT_OPERATED_CAR_ERROR = 521,
  VEHICLE_DELETE_DIRECT_OPERATED_CAR_ERROR = 522,
  VEHICLE_CAR_TYPE_TON_TYPE_NOT_MATCH_ERROR = 523,
  VEHICLE_DISPATCH_UNAVAILABLE_CAR_TYPE_ERROR = 524,
  VEHICLE_REQUEST_DISPATCH_BASE_PRICE_ERROR = 599,
  VEHICLE_REQUEST_TRAILER_ERROR = 600,
  PAYMENT_NOT_ALLOWED_STATUS_ERROR = 601,
  PAYMENT_NO_INVOICE_ERROR = 602,
  PAYMENT_FAILED_FROM_ONE_PG = 603,
  NO_VENDOR_SAP_CODE_FOUND = 604,
  OTHER_BUSINESS_ORDER_IS_INCLUDED = 605,
  SAP_DOCUMENT_ISSUE_IN_PROGRESS = 606,
  EQUAL_PAYMENT_METHOD = 607,
  ALREADY_PAYMENT_METHOD = 608,
  MDM_STATUS_NOT_COMPLETE = 609,
  ESTIMATE_ROUTING_BAD_REQUEST = 610,
  PAYMENT_INVALID_STATUS_CREDIT_SALES = 611,
  SAP_DOCUMENT_ISSUE_NOT_IN_PROGRESS = 612,
  OTHER_BUSINESS_SETTLEMENT_GROUP_ORDER_IS_INCLUDED = 613,
  OTHER_FREIGHT_TYPE_ORDER_IS_INCLUDED = 614,
  DOCUMENT_ALREADY_SAVE_ERROR = 615,
  DOCUMENT_OIL_PRICE_DIFFERENCE_ERROR = 616,
  DOCUMENT_AMOUNT_SAVE_INVALID_STATUS_ERROR = 617,
  ALREADY_DOCUMENT_ISSUED_ERROR = 618,
  DOCUMENT_AMOUNT_IS_ZERO_ERROR = 619,
  DOCUMENT_AMOUNT_CONVERT_TO_MODEL_ERROR = 620,
  DOCUMENT_DUPLICATED_BILL_NO_ERROR = 621,
  NOT_EQUAL_REQUEST_SUPPLY_AMOUNT_ERROR = 622,
  INVOICE_OWNER_AMOUNT_UPDATE_ERROR = 623,
  MULTI_DOCUMENT_ISSUE_ERROR = 624,
  EXTERNAL_OCR_NON_REQUESTED_RESULT_RETURNED = 801,
  EXTERNAL_INAVI_GEO_OUT_OF_RANGE_ERROR = 802,
  EXTERNAL_INAVI_HAS_NO_ADDRESS_ERROR = 803,
  EXTERNAL_NO_RESPONSE = 804,
  EXTERNAL_INAVI_COMMON_ERROR = 805,
  EXTERNAL_SEARCH_ADDRESS_ERROR = 806,
  EXTERNAL_PAGE_ACCESS_EXPIRED = 807,
  TOO_LARGE_REQUEST = 901,
  BAD_JSON_REQUEST = 902,
  REQUEST_DATA_MALFORMED = 903,
  INTERNAL_ERROR = 904,
  AUTH_BUSINESS_ALREADY_EXIST_ERROR = 905,
  BUSINESS_NOT_REQUEST_APPROVAL = 906,
  BUSINESS_REGISTRATION_FILE_NO_DATA = 907,
  BUSINESS_REGISTRATION_FILE_NOT_LATEST = 908,
  BUSINESS_REGISTRATION_FILE_REVIEW_PHASE1 = 909,
  BUSINESS_REGISTRATION_FILE_REVIEW_PHASE2 = 910,
  BUSINESS_REGISTRATION_FILE_DO_NOT_DELETE = 911,
  BUSINESS_REGISTRATION_FILE_DO_NOT_UPDATE_COMPLETE_FILE = 912,
  NOT_FOUND_BUSINESS = 913,
  BUSINESS_REGISTRATION_FILES_IN_WAITING_STATUS = 914,
  BUSINESS_REGISTRATION_FILES_NOT_AVAILABLE_TO_UPDATE_TO_WAITING = 915,
  BUSINESS_SETTLEMENT_GROUP_NO_DATA = 916,
  BUSINESS_SETTLEMENT_GROUP_NAME_ALREADY_EXIST_ERROR = 917,
  BUSINESS_UNAVAILABLE_CREDIT_ORDER_CREATE_ERROR = 918,
  BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME = 919,
  BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME_FOR_MDM_REQUEST = 920,
  BUSINESS_RECEIPT_MANDATORY_ERROR = 921,
  PARTNER_MAPPING_ALREADY_EXISTS_BY_OWNER = 2101,
  PARTNER_ORDER_DRIVING_ALREADY_EXISTS = 2102,
}
