/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AlimTalkDtoSendMessageHistoryRequest = {
    pageNo?: number;
    pageSize?: number;
    requestId?: string;
    sortColumn?: string;
    sortDirection?: AlimTalkDtoSendMessageHistoryRequest.sortDirection;
};
export namespace AlimTalkDtoSendMessageHistoryRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

