/**
 * 공통
 */

// 글로벌
export const GLOBAL_STORE = 'GlobalStore'
export const DATAGRID_STORE = 'DataGridStore'
export const FILTER_ORDER_STATUS_STORE = 'Filter/OrderStatus'
export const FILTER_ORDER_STATUS_FOR_DRIVING_STATUS_STORE = 'Filter/OrderStatusForDrivingStatus'
export const FILTER_DISPATCH_TYPE_STORE = 'FILTER_DISPATCH_TYPE_STORE'

/**
 * 대시보드
 */

// 대시보드
export const DASHBOARD_STORE = 'DashboardStore'

/**
 * 회원관리
 */

// 신용 거래 신청 현황
export const MEMBERSHIP_CREDIT_TRANS_STATUS_STORE = 'Membership/CreditTransactionStatusStore'

// 신용 거래 승인 상태 필터
export const MEMBERSHIP_CREDIT_APPROVAL_STATUS_FILTER_STORE = 'Membership/CreditTransaction/ApprovalStatusFilterStore'

// 신용 거래 검색 필터
export const MEMBERSHIP_CREDIT_SEARCH_KEYWORD_STORE = 'Membership/CreditTransaction/SearchKeywordStore'

// 신규회원 날짜 필터
export const MEMBERSHIP_NEW_USER_DATE_FILTER_STORE = 'Membership/NewUser/DateFilterStore'

// 신규회원 승인 상태 필터
export const MEMBERSHIP_NEW_USER_APPROVAL_STATUS_FILTER_STORE = 'Membership/NewUser/ApprovalStatusFilterStore'

// 신규회원 MDM 승인 상태 필터
export const MEMBERSHIP_NEW_USER_MDM_REG_STATUS_FILTER_STORE = 'Membership/NewUser/MdmRegStatusFilterStore'

// 신규회원 사업자 그룹 필터
export const MEMBERSHIP_NEW_USER_BUSINESS_GROUP_FILTER_STORE = 'Membership/NewUser/BusinessGroupFilterStore'

// 신규회원 회원가입 경로 필터
export const MEMBERSHIP_NEW_USER_REG_PATH_TYPE_FILTER_STORE = 'Membership/NewUser/RegPathTypeFilterStore'

// 신규회원 사업자등록증 필터
export const MEMBERSHIP_NEW_USER_BUSINESSLICENSE_FILTER_STORE = 'Membership/NewUser/BusinessLicenseFilterStore'

// 신규회원 통장사본 필터
export const MEMBERSHIP_NEW_USER_COPYOFBANKBOOK_FILTER_STORE = 'Membership/NewUser/CopyOfBankbookFilterStore'

// 신규회원 사업자 그룹 필터
export const MEMBERSHIP_NEW_USER_FARE_OPTION_STORE = 'Membership/NewUser/FareOptionStore'

// 신규회원 화주유형 그룹 필터
export const MEMBERSHIP_NEW_USER_MEMBER_CATEGORY_OPTION_STORE = 'Membership/NewUser/MemberCategoryStore'

// 신규회원 차주유형 그룹 필터
export const MEMBERSHIP_NEW_USER_DRIVER_MEMBER_CATEGORY_OPTION_STORE = 'Membership/NewUser/DriverMemberCategoryStore'

// 신규회원 검색 필터
export const MEMBERSHIP_NEW_USER_SEARCH_KEYWORD_STORE = 'Membership/NewUser/SearchKeywordStore'

// 신규회원 필터
export const MEMBERSHIP_NEW_USER_FILTER_STORE = 'Membership/NewUser/FilterStore'

// 신규회원 사업자 등록증
export const MEMBERSHIP_NEW_USER_BUSINESS_LICENSE_STORE = 'Membership/NewUser/BusinessLicenseStore'

// 신규회원 자동차 면허
export const MEMBERSHIP_NEW_USER_DRIVER_LICENSE_STORE = 'Membership/NewUser/DriverLicenseStore'

// 신규회원 은행 사본
export const MEMBERSHIP_NEW_USER_BANKBOOK_COPY_STORE = 'Membership/NewUser/BankbookCopyStore'

// 기존 회원 차종 필터
export const MEMBERSHIP_USER_CARTYPE_FILTER_STORE = 'Membership/User/CarTypeFilterStore'

// 기존 회원 톤급 필터
export const MEMBERSHIP_USER_CARTON_FILTER_STORE = 'Membership/User/CarTonFilterStore'

// 기존 회원 운전면허증 필터
export const MEMBERSHIP_USER_DRIVERLICENSE_FILTER_STORE = 'Membership/User/DriverLicenseFilterStore'

// 기존 회원 자동차 등록증 필터
export const MEMBERSHIP_USER_VEHICLEREGISTRATION_FILTER_STORE = 'Membership/User/VehicleRegistrationFilterStore'

// 기존 회원 화물운송자격증 필터
export const MEMBERSHIP_USER_CARGO_TRANS_CERTIFICATE_FILTER_STORE = 'Membership/User/CargoTransCertificateFilterStore'

// 기존 회원 적재물 배상 책임 보험 필터
export const MEMBERSHIP_USER_LOADLIABILITYINSURANCE_FILTER_STORE = 'Membership/User/LoadLiabilityInsuranceFilterStore'

// 기존 회원 화주유형 그룹 필터
export const MEMBERSHIP_USER_MEMBER_CATEGORY_OPTION_STORE = 'Membership/User/MemberCategoryStore'

// 기존 회원 차주유형 그룹 필터
export const MEMBERSHIP_USER_DRIVER_MEMBER_CATEGORY_OPTION_STORE = 'Membership/User/DriverMemberCategoryStore'

// 기존 회원 결제방식 그룹 필터
export const MEMBERSHIP_PAYMENT_OPTION_STORE = 'Membership/User/PaymentOptionStore'

// 신규 차주 목록
export const NEW_DRIVER_LIST_STORE = 'Membership/NewDriver/ListStore'

// 신규 차주 서류 정보
export const NEW_DRIVER_REGFILE_STORE = 'Membership/NewDriver/RegFileStore'

// 신규 차주 서류 모달 정보
export const NEW_DRIVER_REGFILE_MODAL_STORE = 'Membership/NewDriver/RegFileModalStore'

// 신규 임시차 차주 목록
export const NEW_PROVISIONARY_DRIVER_LIST_STORE = 'Membership/NewProvDriver/ListStore'

// 신규 차주 회원 정보
export const NEW_DRIVER_INFO_STORE = 'Membership/NewDriver/InfoStore'

// 신규 차주 서류 변경 이력
export const NEW_DRIVER_REGFILE_CHANGELOG_STORE = 'Membership/NewDriver/RegFileChangeLogStore'

// 신규 화주 목록
export const NEW_OWNER_LIST_STORE = 'Membership/NewOwner/ListStore'

// 신규 파트너 목록
export const NEW_PARTNER_LIST_STORE = 'Membership/NewPartner/ListStore'

// 신규 화주 서류 정보
export const NEW_OWNER_REGFILE_STORE = 'Membership/NewOwner/RegFileStore'

// 신규 화주 회원 정보
export const NEW_OWNER_INFO_STORE = 'Membership/NewOwner/InfoStore'

// 신규 화주 서류 변경 이력
export const NEW_OWNER_REGFILE_CHANGELOG_STORE = 'Membership/NewOwner/RegFileChangeLogStore'

// 차주 상세
export const DRIVER_DETAIL_STORE = 'Membership/Driver/DetailStore'

// 화주 상세
export const OWNER_DETAIL_STORE = 'Membership/Owner/DetailStore'

// 기존 회원 목록
export const USER_STORE = 'Membership/UserStore'

// 회원 목록에서 사용자 유형
export const USER_TYPE_STORE = 'Membership/UserTypeStore'

// 신규 파트너 회원 정보
export const NEW_PARTNER_DETAIL_STORE = 'Membership/NewPartner/DetailStore'

// 휴면 화주 계정
export const DORMANT_OWNER_USER_MANAGEMENT = 'Membership/Dormant/Owners'

// 휴면 차주 계정
export const DORMANT_DRIVER_USER_MANAGEMENT = 'Membership/Dormant/Drivers'

// 휴면 계정 날짜 필터
export const DORMANT_DATE_FILTER_STORE = 'Membership/Dormant/DateFilterStore'

// 휴면 계정 키워드 검색 필터
export const DORMANT_SEARCH_KEYWORD_STORE = 'Membership/Dormant/SearchKeywordStore'

// 휴면 계정 차종 필터
export const DORMANT_CARTYPE_FILTER_STORE = 'Membership/Dormant/CarTypeFilterStore'

// 휴면 계정 톤급 필터
export const DORMANT_CARTON_FILTER_STORE = 'Membership/Dormant/CarTonFilterStore'

// 탈퇴 회원 목록
export const WITHDRAWAL_LIST_STORE = 'Membership/Withdrawal/ListStore'

// 탈퇴 회원 날짜 필터
export const WITHDRAWAL_DATE_FILTER_STORE = 'Membership/Withdrawal/DateFilterStore'

// 탈퇴 회원 키워드 검색 필터
export const WITHDRAWAL_SEARCH_KEYWORD_STORE = 'Membership/Withdrawal/SearchKeywordStore'

// 탈퇴 회원 유형 필터
export const WITHDRAWAL_TYPE_FILTER_STORE = 'Membership/Withdrawal/TypeFilterStore'

/**
 * 계약물류
 */
// 계약물류 운임관리 목록
export const CONTRACT_LOGISTICS_FARE_STORE = 'ContractLogistics/Fare'

// 계약물류 운임관리 > 상세페이지 목록
export const CONTRACT_LOGISTICS_FARE_DETAIL_STORE = 'ContractLogistics/FareDetail'

// 계약물류 > 매출 계약운임 관리 > 필터
export const CONTRACT_LOGISTICS_SALES_FARE_FILTER_STORE = 'ContractLogistics/SalesFare/FilterStore'

// 계약물류 > 매출 계약운임 관리 > 날짜 필터
export const CONTRACT_LOGISTICS_SALES_FARE_DATE_FILTER_STORE = 'ContractLogistics/SalesFare/DateFilterStore'

// 계약물류 > 매출 계약운임 관리 > 검색 키워드 필터
export const CONTRACT_LOGISTICS_SALES_FARE_SEARCH_KEYWORD_STORE = 'ContractLogistics/SalesFare/SearchKeywordStore'

// 계약물류 > 매입 계약운임 관리 > 필터
export const CONTRACT_LOGISTICS_PURCHASE_FARE_FILTER_STORE = 'ContractLogistics/PurchaseFare/FilterStore'

// 계약물류 > 매입 계약운임 관리 > 날짜 필터
export const CONTRACT_LOGISTICS_PURCHASE_FARE_DATE_FILTER_STORE = 'ContractLogistics/PurchaseFare/DateFilterStore'

// 계약물류 > 매입 계약운임 관리 > 검색 키워드 필터
export const CONTRACT_LOGISTICS_PURCHASE_FARE_SEARCH_KEYWORD_STORE = 'ContractLogistics/PurchaseFare/SearchKeywordStore'

/**
 * 화물관리
 */

// 화주 조회
export const CARGO_OWNER_SEARCH_STORE = 'Cargo/OwnerSearchStore'

// 운송 현황 목록
export const CARGO_DRIVING_LIST_STORE = 'Cargo/DrivingListStore'

// 거래 매칭 목록
export const CARGO_MATCHING_LIST_STORE = 'Cargo/MatchingListStore'

// 거래 매칭 목록 > 날짜 필터
export const CARGO_MATCHING_LIST_DATE_FILTER_STORE = 'Cargo/MatchingListStore/DateFilterStore'

// 거래 매칭 목록 > 차종 필터
export const CARGO_MATCHING_LIST_CARTYPE_FILTER_STORE = 'Cargo/MatchingListStore/CarTypeFilterStore'

// 거래 매칭 목록 > 톤급 필터
export const CARGO_MATCHING_LIST_CARTON_FILTER_STORE = 'Cargo/MatchingListStore/CarTonFilterStore'

// 거래 매칭 목록 > 상/하차지 필터
export const CARGO_MATCHING_LIST_LOADINGAREA_FILTER_STORE = 'Cargo/MatchingListStore/LoadingAreaFilterStore'

// 거래 매칭 목록 > 키워드 검색 필터
export const CARGO_MATCHING_LIST_SEARCH_KEYWORD_STORE = 'Cargo/MatchingListStore/SearchKeywordStore'

// 선호 운송 권역
export const CARGO_PRFER_DRIVING_STORE = 'Cargo/PreferDrivingStore'

// 화주 직영차 운송현황
export const OWNER_DIRECT_CAR_STORE = 'Cargo/OwnerDirectCarStore'
/**
 * 정산관리
 */

// PG 결제 정보 확인
export const SETTLEMENT_PG_STORE = 'Settlement/PgStore'

// 퀵페이
export const SETTLEMENT_QUICKPAY_STORE = 'Settlement/QuickpayStore'

// 결제 실패
export const SETTLEMENT_PAYMENT_FAIL_STORE = 'Settlement/PaymentFailStore'

// PG 결제
export const SETTLEMENT_PG_PAYMENT_STORE = 'Settlement/PgPaymentStore'

// 차주 대기
export const SETTLEMENT_DRIVER_WAITING_STORE = 'Settlement/DriverWaitingStore'

// 퀵페이 실패
export const SETTLEMENT_QUICKPAY_FAIL_STORE = 'Settlement/QuickPayFailStore'

// 퀵페이 요청
export const SETTLEMENT_QUICKPAY_REQUEST_STORE = 'Settlement/QuickpayRequestStore'

// 외부정보망 발행 세금계산서 입력 및 정산
export const SETTLEMENT_NOT_ISSUE_TAX_INVOICE_STORE = 'Settlement/NotIssueTaxInvoiceStore'

// 세금 정산
export const SETTLEMENT_TAX_BILL_STORE = 'Settlement/TaxBillStore'

// 퀵페이 히스토리
export const SETTLEMENT_QUICKPAY_HISTORY_STORE = 'Settlement/QuickpayHistoryStore'

// 매출/매입 현황
export const SETTLEMENT_SALES_PURCHASE_STATUS_STORE = 'Settlement/SalesPurchaseStatusStore'

// 매입 현황
export const SETTLEMENT_PURCHASE_STATUS_STORE = 'Settlement/PurchaseStatusStore'

// 매출 현황
export const SETTLEMENT_SALES_STATUS_STORE = 'Settlement/SalesStatusSettlementStore'

// 계약물류 추가운임비 추가
export const CONTRACT_LOGISTICS_ADD_COST_STORE = 'Settlement/ContractLogisticsAddCostStore'

// 계약물류 추가운임비 추가 변경 이력
export const CONTRACT_LOGISTICS_ADD_COST_HISTORY_STORE = 'Settlement/ContractLogisticsAddCostHistoryStore'

// 정산관리 > 정산그룹 필터
export const SETTLEMENT_GROUP_MULTI_FILTER_STORE = 'Settlement/SettlementGroupMultiFilterStore'

// 정산관리 > 정산그룹 리스트
export const SETTLEMENT_GROUP_LIST_STORE = 'Settlement/SettlementGroupListStore'

/**
 * 주문 상세
 */

// 주문 상세 - 거래 매칭
export const ORDER_DETAIL_MATCHING_STATUS_STORE = 'OrderDetail/MatchingStatusStore'

// 주문 상세 - 인수증 관리
export const ORDER_DETAIL_RECEIPT_INFO_STORE = 'OrderDetail/ReceiptInfoStore'

// 주문 수정 > 주문 유형 store
export const ORDER_EDIT_ORDER_TYPE_STORE = 'OrderEdit/OrderTypeStore'

// 주문 수정 > 상차지 정보 store
export const ORDER_EDIT_LOADINGZONE_INFO_STORE = 'OrderEdit/LoadingZoneInfoStore'

// 주문 수정 > 하차지 정보 store
export const ORDER_EDIT_UNLOADINGZONE_INFO_STORE = 'OrderEdit/UnloadingZoneInfoStore'

// 주문 수정 > 경유지 목록 store
export const ORDER_EDIT_WAYPOINT_LIST_STORE = 'OrderEdit/WaypointListStore'

// 주문 수정 > 입출고 정보 store
export const ORDER_EDIT_WAREHOUSING_INFO_STORE = 'OrderEdit/WarehousingInfoStore'

// 주문 수정 > 차량 정보 store
export const ORDER_EDIT_VEHICLE_INFO_STORE = 'OrderEdit/VehicleInfoStore'

// 주문 수정 > 화물 정보 store
export const ORDER_EDIT_CARGO_INFO_STORE = 'OrderEdit/CargoInfoStore'

// 주문 수정 > 전자 인수증
export const ORDER_DETAIL_ELECTRONIC_RECEIPT_STORE = 'OrderEdit/ElectronicReceipt'

/**
 * 차량관제
 */

// 차량관제
export const DRIVING_STORE = 'Driving/DrivingStore'

// 차량관제 상세
export const DRIVING_DETAIL_STORE = 'Driving/DetailStore'

/**
 * 프로모션
 */
// 프로모션 설정 : 진행 프로모션 + 예정 프로모션 + 종료 프로모션
export const PROMOTION_SETTINGS_STORE = 'Promotion/ProgressSettingsStore'
// 프로모션 설정 : 복제된 프로모션 데이터
export const PROMOTION_DUPLICATE_STORE = 'Promotion/ProgressDuplicateStore'

export const PROMOTION_OWNER_CONFIG_STORE = 'Promotion/OwnerPromotionConfigStore'
export const PROMOTION_DRIVER_CONFIG_STORE = 'Promotion/DriverPromotionConfigStore'

export const PROMOTION_OWNER_DETAIL_STORE = 'Promotion/OwnerPromotionDetailStore'

export const BIG_STICKER_VERIFICATION_STORE = 'Promotion/BigStickerVerificationStore'
export const DRIVER_BIG_STICKER_SETTLEMENT_STORE = 'Promotion/DriverBigStickerSettlementStore'
export const DRIVER_BIG_STICKER_SETTLEMENT_DETAIL_STORE = 'Promotion/DriverBigStickerSettlementDetailStore'
export const DRIVER_BIG_STICKER_SETTLEMENT_DATE_FILTER_STORE = 'Promotion/DriverBigStickerSettlementDateFilterStore'

/**
 * 설정
 */

export const SETTINGS_MESSAGE_STORE = 'Settings/MessageStore'

/**
 * 결제
 */

export const PAYMENT_STORE = 'PaymentStore'

/**
 * 회계팀
 */

// 회계팀 사용자
export const ACCOUNTING_TEAM_STORE = 'AccountingTeamStore'

/**
 * 공통관리
 */
// 공통관리 > 위치정보 접근 취급 대장
export const COMMON_MANAGEMENT_LOCATION_INFO_ACCESSLOGS_STORE = 'CommonManagement/LocationInfoAccessLogs'

/**
 * 운임설정
 */
export const FEIGHT_STORE = 'FeightStore'

export const FEIGHT_INPUT_STORE = 'FeightInputStore'
export const FEIGHT_RESULT_STORE = 'FeightResultStore'
export const FEIGHT_FULL_FARE_HISTORY_STORE = 'FeightFullFareHistoryStore'

/**
 * 포탈 > 전체주문현황
 */
export const PORTAL_ORDER_LIST_STORE = 'Portal/Order/ListStore'
export const PORTAL_ORDER_LIST_FILTER_STORE = 'Portal/Order/List/FilterStore'
export const PORTAL_ORDER_DETAIL_STORE = 'Portal/Order/DetailStore'
export const PORTAL_DRIVER_MANAGEMENT_STORE = 'Portal/DriverManagement'
export const PORTAL_OVERALL_PERFORMANCE_FILTER_STORE = 'Portal/ActualOrder/filter'

export const BOARD_STORE = 'Board/Store'
