/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessRegistrationFileDtoRequestFileApprovalStatus = {
    approvalStatus?: BusinessRegistrationFileDtoRequestFileApprovalStatus.approvalStatus;
    approvalStatusMessage?: string;
    businessRegistrationFileId: number;
};
export namespace BusinessRegistrationFileDtoRequestFileApprovalStatus {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

