/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberSearchDtoResponseSearchDeactivatedOwnerUser = {
    businessRegistrationNumber?: string;
    deactivationType?: MemberSearchDtoResponseSearchDeactivatedOwnerUser.deactivationType;
    joinStatus?: MemberSearchDtoResponseSearchDeactivatedOwnerUser.joinStatus;
    mdmCode?: string;
    memberCreatedAt?: string;
    memberDeactivatedAt?: string;
    memberId?: string;
    reasonMessage?: string;
    userId?: string;
};
export namespace MemberSearchDtoResponseSearchDeactivatedOwnerUser {
    export enum deactivationType {
        DEACTIVATED = 'DEACTIVATED',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
}

