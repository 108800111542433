import { AdminInfoWithLogDto } from 'src/data'
import AdminUserAccessLogModel from './adminUserAccessLogModel'

interface IAdminUserDetailModel {
  memberId: string
  adminId: string
  adminName: string
  slackId: string
  isLocked: boolean
  loginFailCount: number
  phoneNumber: string
  memberRoleType: AdminInfoWithLogDto.memberRoleType
  accessLogs: AdminUserAccessLogModel[]
}

class AdminUserDetailModel implements IAdminUserDetailModel {
  memberId: string
  adminId: string
  adminName: string
  slackId: string
  isLocked: boolean
  loginFailCount: number
  phoneNumber: string
  memberRoleType: AdminInfoWithLogDto.memberRoleType
  accessLogs: AdminUserAccessLogModel[]

  constructor({
    memberId,
    adminId,
    adminName,
    slackId,
    isLocked,
    loginFailCount,
    phoneNumber,
    memberRoleType,
    accessLogs,
  }: IAdminUserDetailModel) {
    this.memberId = memberId
    this.adminId = adminId
    this.adminName = adminName
    this.slackId = slackId
    this.isLocked = isLocked
    this.loginFailCount = loginFailCount
    this.phoneNumber = phoneNumber
    this.memberRoleType = memberRoleType
    this.accessLogs = accessLogs
  }

  static init() {
    return new AdminUserDetailModel({
      memberId: '',
      adminId: '',
      adminName: '',
      slackId: '',
      isLocked: false,
      loginFailCount: 0,
      phoneNumber: '',
      memberRoleType: AdminInfoWithLogDto.memberRoleType.ADMIN_USER,
      accessLogs: [],
    })
  }
}

export default AdminUserDetailModel
