/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminOrderV2DtoRequest = {
    businessGroupAffiliateCodes?: Array<'B_MART' | 'COMMON' | 'FURSYS' | 'FURSYS_VENDOR' | 'HCORE' | 'KOAS' | 'LIVART' | 'NAMYANG_WATER' | 'ONE' | 'PND' | 'SK_NETWORKS' | 'WND'>;
    businessIds?: Array<string>;
    businessSearch?: string;
    carTons?: Array<string>;
    carTypes?: Array<string>;
    createdByIdSearch?: string;
    createdByNameSearch?: string;
    customerCode?: string;
    customerName?: string;
    freightTypes?: Array<'DISTRICT_FREIGHT' | 'HALF_DAILY_FREIGHT' | 'MIXED_FREIGHT' | 'MONTHLY_FREIGHT' | 'NORMAL_FREIGHT' | 'QUICK_FREIGHT' | 'RANGE_FREIGHT'>;
    fromAddress?: Array<string>;
    fromAddressSearch?: string;
    fromDate: string;
    isCjEnm?: boolean;
    itemNameSearch?: string;
    memberCategoryType?: SearchAdminOrderV2DtoRequest.memberCategoryType;
    orderChannelType?: SearchAdminOrderV2DtoRequest.orderChannelType;
    orderNoSearch?: string;
    orderPaymentMethodTypes?: Array<'CASH_ON_DELIVERY' | 'CREDIT_CARD' | 'CREDIT_SALES' | 'INTERNAL_CREDIT_SALES'>;
    ownerOrderTypes?: Array<'MULTI' | 'ROUND_TRIP' | 'SINGLE' | 'WAYPOINTS'>;
    pageNo?: number;
    pageSize?: number;
    priceGroupPolicyIds?: Array<string>;
    sortColumn?: string;
    sortDirection?: string;
    toAddress?: Array<string>;
    toAddressSearch?: string;
    toDate: string;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace SearchAdminOrderV2DtoRequest {
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum orderChannelType {
        ADMIN_ORDER = 'ADMIN_ORDER',
        CJ_ENM = 'CJ_ENM',
        HCORE_ERP = 'HCORE_ERP',
        OWNER_APP = 'OWNER_APP',
        OWNER_APP_LLM = 'OWNER_APP_LLM',
        OWNER_WEB = 'OWNER_WEB',
        OWNER_WEB_BLOCK = 'OWNER_WEB_BLOCK',
        SYSTEM_ERP = 'SYSTEM_ERP',
        TPS_HELLO = 'TPS_HELLO',
    }
}

