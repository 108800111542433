/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ApprovedPartnerUserDtoPartnerDriverResult = {
    businessRegistrationNumber?: string;
    carTonType?: ApprovedPartnerUserDtoPartnerDriverResult.carTonType;
    carTonTypeValue?: string;
    carType?: ApprovedPartnerUserDtoPartnerDriverResult.carType;
    carTypeValue?: string;
    categoryType?: ApprovedPartnerUserDtoPartnerDriverResult.categoryType;
    createdAt?: string;
    driverName?: string;
    driverUserId?: string;
    memberId?: string;
    partnerDriverStatus?: ApprovedPartnerUserDtoPartnerDriverResult.partnerDriverStatus;
    partnerDriverStatusValue?: string;
    partnerDriverType?: ApprovedPartnerUserDtoPartnerDriverResult.partnerDriverType;
    partnerDriverTypeValue?: string;
    plateNumber?: string;
    theUnbanDriverInfo?: string;
};
export namespace ApprovedPartnerUserDtoPartnerDriverResult {
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum categoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum partnerDriverStatus {
        INVITATION_COMPLETE = 'INVITATION_COMPLETE',
        INVITATION_WAIT = 'INVITATION_WAIT',
        NONE = 'NONE',
    }
    export enum partnerDriverType {
        BELONGING_VEHICLE = 'BELONGING_VEHICLE',
        CONSIGNMENT_VEHICLE = 'CONSIGNMENT_VEHICLE',
        TEMPORARY_VEHICLE = 'TEMPORARY_VEHICLE',
    }
}

