/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessAdministrationMemoDtoSaveRequest = {
    businessId: string;
    categoryType: BusinessAdministrationMemoDtoSaveRequest.categoryType;
    memo: string;
};
export namespace BusinessAdministrationMemoDtoSaveRequest {
    export enum categoryType {
        AREA = 'AREA',
        DISPATCH = 'DISPATCH',
        ETC = 'ETC',
        NORMAL = 'NORMAL',
        PRICE = 'PRICE',
        VEHICLE = 'VEHICLE',
    }
}

