/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BannerDtoRequestSearchList = {
    bannerProgressStatus: BannerDtoRequestSearchList.bannerProgressStatus;
    bannerTargetType: BannerDtoRequestSearchList.bannerTargetType;
    bannerType: BannerDtoRequestSearchList.bannerType;
    pageNo?: number;
    pageSize?: number;
};
export namespace BannerDtoRequestSearchList {
    export enum bannerProgressStatus {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
    export enum bannerTargetType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
    export enum bannerType {
        BANNER = 'BANNER',
        POPUP = 'POPUP',
    }
}

