/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PromotionHistoryResult = {
    adminId: string;
    changeDateTime: string;
    changeText: string;
    changeType: PromotionHistoryResult.changeType;
};
export namespace PromotionHistoryResult {
    export enum changeType {
        CLOSE = 'CLOSE',
        DELETE = 'DELETE',
        SAVE = 'SAVE',
        UPDATE_BUSINESS_GROUP = 'UPDATE_BUSINESS_GROUP',
        UPDATE_PROMOTION = 'UPDATE_PROMOTION',
        UPDATE_TITLE = 'UPDATE_TITLE',
    }
}

