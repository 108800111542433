/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ServiceConfigDtoGetRequest = {
    serviceConfigurationCode: ServiceConfigDtoGetRequest.serviceConfigurationCode;
};
export namespace ServiceConfigDtoGetRequest {
    export enum serviceConfigurationCode {
        CLIENT = 'CLIENT',
        DRIVER = 'DRIVER',
        DRIVER_APP_MAINTENANCE = 'DRIVER_APP_MAINTENANCE',
        ORDER_EXCEL_TEMPLATE_CONFIG = 'ORDER_EXCEL_TEMPLATE_CONFIG',
        OWNER_APP_MAINTENANCE = 'OWNER_APP_MAINTENANCE',
        PARTNER_DRIVER_APP_MAINTENANCE = 'PARTNER_DRIVER_APP_MAINTENANCE',
        SERVER = 'SERVER',
        SERVER_CONFIG = 'SERVER_CONFIG',
    }
}

