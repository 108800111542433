/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SavePriceGroupPolicyDtoExtraConfig } from './SavePriceGroupPolicyDtoExtraConfig';
import type { SavePriceGroupPolicyDtoNonContractPolicy } from './SavePriceGroupPolicyDtoNonContractPolicy';
export type SavePriceGroupPolicyDtoSaveConfigRequest = {
    driverOrderRoundExtraConfig?: SavePriceGroupPolicyDtoExtraConfig;
    driverUseUnbanExtraPriceList?: Array<'BASE_FEE' | 'EVENT_COUPON_FEE' | 'EVENT_PROMOTION_FEE' | 'EXTRA_ACTUAL_EXPENSES_BILLED_FEE' | 'EXTRA_ADDITIONAL_FORWARDING_FEE' | 'EXTRA_COD_WAYPOINT_FEE' | 'EXTRA_DAILY_FREIGHT_FEE' | 'EXTRA_DISTANCE_ALLOWANCE_FEE' | 'EXTRA_DRIVER_DROP_FEE' | 'EXTRA_DRIVER_PICKUP_FEE' | 'EXTRA_EMERGENCY_FREIGHT_FEE' | 'EXTRA_EQUIPMENT_FEE' | 'EXTRA_ETC_FEE' | 'EXTRA_HOLIDAY_FEE' | 'EXTRA_LIFT_FEE' | 'EXTRA_MONTHLY_FREIGHT_FEE' | 'EXTRA_NIGHT_SURCHARGE_FEE' | 'EXTRA_OIL_PRICE_INDEXATION_FEE' | 'EXTRA_PERSONAL_INFO_FEE' | 'EXTRA_RETURNING_FEE' | 'EXTRA_ROUND_FEE' | 'EXTRA_SHIPPING_ADJUSTMENT_FEE' | 'EXTRA_STORAGE_OPEN_FEE' | 'EXTRA_UNDERGROUND_ROUND_FEE' | 'EXTRA_WAITING_FEE' | 'EXTRA_WASTE_FEE' | 'EXTRA_WAYPOINT_FEE' | 'STICKER_EVENT_FEE' | 'VAT_FEE' | 'WCI_FEE'>;
    nonContractPolicy?: SavePriceGroupPolicyDtoNonContractPolicy;
    ownerOrderRoundExtraConfig?: SavePriceGroupPolicyDtoExtraConfig;
    ownerUseUnbanExtraPriceList?: Array<'BASE_FEE' | 'EVENT_COUPON_FEE' | 'EVENT_PROMOTION_FEE' | 'EXTRA_ACTUAL_EXPENSES_BILLED_FEE' | 'EXTRA_ADDITIONAL_FORWARDING_FEE' | 'EXTRA_COD_WAYPOINT_FEE' | 'EXTRA_DAILY_FREIGHT_FEE' | 'EXTRA_DISTANCE_ALLOWANCE_FEE' | 'EXTRA_DRIVER_DROP_FEE' | 'EXTRA_DRIVER_PICKUP_FEE' | 'EXTRA_EMERGENCY_FREIGHT_FEE' | 'EXTRA_EQUIPMENT_FEE' | 'EXTRA_ETC_FEE' | 'EXTRA_HOLIDAY_FEE' | 'EXTRA_LIFT_FEE' | 'EXTRA_MONTHLY_FREIGHT_FEE' | 'EXTRA_NIGHT_SURCHARGE_FEE' | 'EXTRA_OIL_PRICE_INDEXATION_FEE' | 'EXTRA_PERSONAL_INFO_FEE' | 'EXTRA_RETURNING_FEE' | 'EXTRA_ROUND_FEE' | 'EXTRA_SHIPPING_ADJUSTMENT_FEE' | 'EXTRA_STORAGE_OPEN_FEE' | 'EXTRA_UNDERGROUND_ROUND_FEE' | 'EXTRA_WAITING_FEE' | 'EXTRA_WASTE_FEE' | 'EXTRA_WAYPOINT_FEE' | 'STICKER_EVENT_FEE' | 'VAT_FEE' | 'WCI_FEE'>;
    priceGroupPolicyId: string;
    transactionMethodType: SavePriceGroupPolicyDtoSaveConfigRequest.transactionMethodType;
};
export namespace SavePriceGroupPolicyDtoSaveConfigRequest {
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

