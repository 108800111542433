/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SalesPurchaseStatusSettlementDtoResult = {
    apprNo?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessTaxType?: string;
    carTonTypeValue?: string;
    carTypeValue?: string;
    deliveryCompleteDate?: string;
    documentId?: string;
    documentIssuedAt?: string;
    dropRequestAt?: string;
    freightType?: SalesPurchaseStatusSettlementDtoResult.freightType;
    freightTypeValue?: string;
    fromAddress?: string;
    fromAddressName?: string;
    orderCreatedAt?: string;
    orderId?: string;
    orderNo?: string;
    orderOwnerId?: string;
    orderOwnerName?: string;
    paySuccessAt?: string;
    paymentMethod?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    sapSettlementStatus?: string;
    sapSettlementStatusValue?: string;
    serviceType?: string;
    toAddress?: string;
    toAddressName?: string;
    totalAmount?: number;
    totalAmountWithoutVat?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace SalesPurchaseStatusSettlementDtoResult {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

