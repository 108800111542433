/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistrationFileApprovalStatus } from './RegistrationFileApprovalStatus';
export type SearchMemberDtoRequestSearchList = {
    approvalStatus?: Array<'COMPLETE' | 'NONE' | 'REJECT' | 'RETURN' | 'REVIEW' | 'WAITING'>;
    businessGroupNo?: number;
    businessInfo?: string;
    carTonType?: Array<'TON00_5' | 'TON0_3' | 'TON0_5' | 'TON11_0' | 'TON18_0' | 'TON1_0' | 'TON1_4' | 'TON25_0' | 'TON2_5' | 'TON3_5' | 'TON5_0' | 'TON5_0_PLUS'>;
    carType?: Array<'CARGO' | 'DARMAS' | 'LABOR' | 'MOTORCYCLE' | 'TOP' | 'TRAILER' | 'WING_BODY' | 'WING_BODY_CARGO'>;
    contactNo?: string;
    downloadReason?: Array<string>;
    fromDate: string;
    idRegistrationFileStatus?: SearchMemberDtoRequestSearchList.idRegistrationFileStatus;
    isLoadLiabilityInsuranceTarget?: boolean;
    isReSignUp?: boolean;
    isRegisteredCard?: boolean;
    mdmCode?: string;
    mdmRegistrationStatus?: Array<'COMPLETE' | 'LEAVE' | 'REAPPLY' | 'REQUEST' | 'RETURN' | 'WAITING'>;
    memberCategoryTypes?: Array<'DRIVER_MEMBER' | 'DRIVER_TEMP' | 'DRIVER_TEST' | 'OWNER_CONTRACT' | 'OWNER_INNER' | 'OWNER_NORMAL' | 'OWNER_TEST' | 'PARTNER_DRIVER' | 'PARTNER_MEMBER' | 'PARTNER_OWNER'>;
    name?: string;
    new?: boolean;
    pageNo?: number;
    pageSize?: number;
    paymentMethodTypes?: Array<'CREDIT_CARD' | 'CREDIT_SALES' | 'INTERNAL_CREDIT_SALES' | 'QUICK_PAY'>;
    plateNumber?: string;
    priceGroupPolicyId?: Array<string>;
    registrationChannelType?: Array<'CJ_ENM' | 'UNBAN'>;
    registrationFileApprovalStatus?: RegistrationFileApprovalStatus;
    registrationPathType?: Array<'ADMIN' | 'ADMIN_TO_GOOGLE_PLAY' | 'APK' | 'GOOGLE_PLAY'>;
    settlementApprovalStatus?: boolean;
    sortColumn?: string;
    sortDirection?: string;
    toDate: string;
    transactionMethodType?: Array<'CONTRACT_FREIGHT' | 'CONTRACT_RANGE_FREIGHT' | 'RANGE_FREIGHT'>;
    userId?: string;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace SearchMemberDtoRequestSearchList {
    export enum idRegistrationFileStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

