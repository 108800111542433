/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OperationDtoRequest = {
    amount?: number;
    approvalNo?: string;
    invoiceItemType?: OperationDtoRequest.invoiceItemType;
    invoiceStatusType: OperationDtoRequest.invoiceStatusType;
    isCashOnDelivery?: boolean;
    orderNo: string;
    paySuccessDate?: string;
};
export namespace OperationDtoRequest {
    export enum invoiceItemType {
        BASE_FEE = 'BASE_FEE',
        EVENT_COUPON_FEE = 'EVENT_COUPON_FEE',
        EVENT_PROMOTION_FEE = 'EVENT_PROMOTION_FEE',
        EXTRA_ACTUAL_EXPENSES_BILLED_FEE = 'EXTRA_ACTUAL_EXPENSES_BILLED_FEE',
        EXTRA_ADDITIONAL_FORWARDING_FEE = 'EXTRA_ADDITIONAL_FORWARDING_FEE',
        EXTRA_COD_WAYPOINT_FEE = 'EXTRA_COD_WAYPOINT_FEE',
        EXTRA_DAILY_FREIGHT_FEE = 'EXTRA_DAILY_FREIGHT_FEE',
        EXTRA_DISTANCE_ALLOWANCE_FEE = 'EXTRA_DISTANCE_ALLOWANCE_FEE',
        EXTRA_DRIVER_DROP_FEE = 'EXTRA_DRIVER_DROP_FEE',
        EXTRA_DRIVER_PICKUP_FEE = 'EXTRA_DRIVER_PICKUP_FEE',
        EXTRA_EMERGENCY_FREIGHT_FEE = 'EXTRA_EMERGENCY_FREIGHT_FEE',
        EXTRA_EQUIPMENT_FEE = 'EXTRA_EQUIPMENT_FEE',
        EXTRA_ETC_FEE = 'EXTRA_ETC_FEE',
        EXTRA_HOLIDAY_FEE = 'EXTRA_HOLIDAY_FEE',
        EXTRA_LIFT_FEE = 'EXTRA_LIFT_FEE',
        EXTRA_MONTHLY_FREIGHT_FEE = 'EXTRA_MONTHLY_FREIGHT_FEE',
        EXTRA_NIGHT_SURCHARGE_FEE = 'EXTRA_NIGHT_SURCHARGE_FEE',
        EXTRA_OIL_PRICE_INDEXATION_FEE = 'EXTRA_OIL_PRICE_INDEXATION_FEE',
        EXTRA_PERSONAL_INFO_FEE = 'EXTRA_PERSONAL_INFO_FEE',
        EXTRA_RETURNING_FEE = 'EXTRA_RETURNING_FEE',
        EXTRA_ROUND_FEE = 'EXTRA_ROUND_FEE',
        EXTRA_SHIPPING_ADJUSTMENT_FEE = 'EXTRA_SHIPPING_ADJUSTMENT_FEE',
        EXTRA_STORAGE_OPEN_FEE = 'EXTRA_STORAGE_OPEN_FEE',
        EXTRA_UNDERGROUND_ROUND_FEE = 'EXTRA_UNDERGROUND_ROUND_FEE',
        EXTRA_WAITING_FEE = 'EXTRA_WAITING_FEE',
        EXTRA_WASTE_FEE = 'EXTRA_WASTE_FEE',
        EXTRA_WAYPOINT_FEE = 'EXTRA_WAYPOINT_FEE',
        STICKER_EVENT_FEE = 'STICKER_EVENT_FEE',
        VAT_FEE = 'VAT_FEE',
        WCI_FEE = 'WCI_FEE',
    }
    export enum invoiceStatusType {
        ADMIN_CONFIRMED = 'ADMIN_CONFIRMED',
        CANCELLED = 'CANCELLED',
        CANCEL_FAILED = 'CANCEL_FAILED',
        COMPLETE = 'COMPLETE',
        CONFIRMED = 'CONFIRMED',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NEGOTIATE = 'NEGOTIATE',
        QUICK_PAY_REAPPLIED = 'QUICK_PAY_REAPPLIED',
        QUICK_PAY_REAPPLY_CANCELLED = 'QUICK_PAY_REAPPLY_CANCELLED',
        QUICK_PAY_REQUESTED = 'QUICK_PAY_REQUESTED',
        TAX_INVOICE_ISSUED = 'TAX_INVOICE_ISSUED',
    }
}

