/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderExtraInvoiceItemDtoRequest = {
    addressType: OrderExtraInvoiceItemDtoRequest.addressType;
    extraInvoiceItemId?: number;
    invoiceItemAmount?: number;
    invoiceItemType: OrderExtraInvoiceItemDtoRequest.invoiceItemType;
    isDel: boolean;
    memo: string;
    serviceType: OrderExtraInvoiceItemDtoRequest.serviceType;
};
export namespace OrderExtraInvoiceItemDtoRequest {
    export enum addressType {
        COMMON = 'COMMON',
        DROP = 'DROP',
        PICKUP = 'PICKUP',
        PICKUP_RETURN = 'PICKUP_RETURN',
        WAYPOINT = 'WAYPOINT',
    }
    export enum invoiceItemType {
        BASE_FEE = 'BASE_FEE',
        EVENT_COUPON_FEE = 'EVENT_COUPON_FEE',
        EVENT_PROMOTION_FEE = 'EVENT_PROMOTION_FEE',
        EXTRA_ACTUAL_EXPENSES_BILLED_FEE = 'EXTRA_ACTUAL_EXPENSES_BILLED_FEE',
        EXTRA_ADDITIONAL_FORWARDING_FEE = 'EXTRA_ADDITIONAL_FORWARDING_FEE',
        EXTRA_COD_WAYPOINT_FEE = 'EXTRA_COD_WAYPOINT_FEE',
        EXTRA_DAILY_FREIGHT_FEE = 'EXTRA_DAILY_FREIGHT_FEE',
        EXTRA_DISTANCE_ALLOWANCE_FEE = 'EXTRA_DISTANCE_ALLOWANCE_FEE',
        EXTRA_DRIVER_DROP_FEE = 'EXTRA_DRIVER_DROP_FEE',
        EXTRA_DRIVER_PICKUP_FEE = 'EXTRA_DRIVER_PICKUP_FEE',
        EXTRA_EMERGENCY_FREIGHT_FEE = 'EXTRA_EMERGENCY_FREIGHT_FEE',
        EXTRA_EQUIPMENT_FEE = 'EXTRA_EQUIPMENT_FEE',
        EXTRA_ETC_FEE = 'EXTRA_ETC_FEE',
        EXTRA_HOLIDAY_FEE = 'EXTRA_HOLIDAY_FEE',
        EXTRA_LIFT_FEE = 'EXTRA_LIFT_FEE',
        EXTRA_MONTHLY_FREIGHT_FEE = 'EXTRA_MONTHLY_FREIGHT_FEE',
        EXTRA_NIGHT_SURCHARGE_FEE = 'EXTRA_NIGHT_SURCHARGE_FEE',
        EXTRA_OIL_PRICE_INDEXATION_FEE = 'EXTRA_OIL_PRICE_INDEXATION_FEE',
        EXTRA_PERSONAL_INFO_FEE = 'EXTRA_PERSONAL_INFO_FEE',
        EXTRA_RETURNING_FEE = 'EXTRA_RETURNING_FEE',
        EXTRA_ROUND_FEE = 'EXTRA_ROUND_FEE',
        EXTRA_SHIPPING_ADJUSTMENT_FEE = 'EXTRA_SHIPPING_ADJUSTMENT_FEE',
        EXTRA_STORAGE_OPEN_FEE = 'EXTRA_STORAGE_OPEN_FEE',
        EXTRA_UNDERGROUND_ROUND_FEE = 'EXTRA_UNDERGROUND_ROUND_FEE',
        EXTRA_WAITING_FEE = 'EXTRA_WAITING_FEE',
        EXTRA_WASTE_FEE = 'EXTRA_WASTE_FEE',
        EXTRA_WAYPOINT_FEE = 'EXTRA_WAYPOINT_FEE',
        STICKER_EVENT_FEE = 'STICKER_EVENT_FEE',
        VAT_FEE = 'VAT_FEE',
        WCI_FEE = 'WCI_FEE',
    }
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
}

