import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect } from 'react'
import * as Common from '@constants/common'
import { useAuth } from '@features/common/hooks'
import { useMenuStore } from '@features/general/adminUser/stores'

export default function Root() {
  const router = useRouter()
  const { logout } = useAuth()
  const { availableMenu, isLoaded, menu, menuRootPath, menuCategory, setMenuCategory } = useMenuStore()

  useEffect(() => {
    if (isLoaded) {
      setMenuCategory(menu.theunban.length > 0 ? Common.menuCategory.THEUNBAN : Common.menuCategory.PORTAL)
    }
  }, [isLoaded, menu])

  useEffect(() => {
    if (isLoaded && !availableMenu) {
      setTimeout(() => logout(), 100)
    }
  }, [isLoaded, availableMenu])

  useEffect(() => {
    // 루트 페이지로 진입하는 경우 이동 가능한 메뉴의 첫번째 페이지로 이동
    if (menuCategory) {
      router.replace(menuRootPath[menuCategory]).catch()
    }
  }, [menuRootPath, menuCategory])

  return null
}

export async function getStaticProps({ locale, defaultLocale }) {
  return {
    props: { ...(await serverSideTranslations(locale ?? defaultLocale)) },
  }
}
