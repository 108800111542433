/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceGroupPolicyContractHistoryDtoFeeRequest = {
    groupingId: string;
    serviceType: PriceGroupPolicyContractHistoryDtoFeeRequest.serviceType;
};
export namespace PriceGroupPolicyContractHistoryDtoFeeRequest {
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
}

