export interface IAlertModel {
  type?: string
  title?: string
  description?: string
  isOpened?: boolean
}

export class AlertModel implements IAlertModel {
  type?: string
  title?: string
  description?: string
  isOpened?: boolean

  constructor({ type, title, description, isOpened }: IAlertModel) {
    this.type = type
    this.title = title
    this.description = description
    this.isOpened = isOpened
  }

  static init(): AlertModel {
    return new AlertModel({
      type: '',
      title: '',
      description: '',
      isOpened: false,
    })
  }
}
