import dayjs from 'dayjs'
import { IPoint } from '../../../orderDetail/types/location'

interface IPrevDriverLocationModel extends IPoint {
  refreshTime?: Date | null
}

class PrevDriverLocationModel implements IPrevDriverLocationModel {
  lat: number
  lng: number
  refreshTime?: Date

  constructor({ lat, lng, refreshTime }: IPrevDriverLocationModel) {
    this.lat = lat
    this.lng = lng
    this.refreshTime = refreshTime
  }

  static init(): PrevDriverLocationModel {
    return new PrevDriverLocationModel({
      lat: 0,
      lng: 0,
      refreshTime: null,
    })
  }

  public isNotRefresh(currentTime: Date): boolean {
    return this.refreshTime && dayjs(currentTime).diff(dayjs(this.refreshTime), 'minute') < 1
  }
}

export default PrevDriverLocationModel
