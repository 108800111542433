/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type QuickPaySettlementDtoDriverInvoiceInHoldingResult = {
    businessRegistrationNumber?: string;
    deliveryCompleteDate?: string;
    driverMemberId?: string;
    isExtraFeeExists?: string;
    isTempUserValue?: string;
    mdmRegistrationStatus?: QuickPaySettlementDtoDriverInvoiceInHoldingResult.mdmRegistrationStatus;
    mdmRegistrationStatusValue?: string;
    orderId?: string;
    orderNo?: string;
    plateNumber?: string;
    supplyAmount?: number;
    totalAmount?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace QuickPaySettlementDtoDriverInvoiceInHoldingResult {
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
}

