/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TempDriverSearchDtoResponseExcelDownloadDriverUser = {
    approvalStatusValue?: string;
    businessGroupName?: string;
    businessLicenseApprovalStatusValue?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessTypeValue?: string;
    carTonTypeValue?: string;
    carTypeValue?: string;
    cargoTransportWorkerCertificateApprovalStatusValue?: string;
    copyOfBankbookApprovalStatusValue?: string;
    driverLicenseApprovalStatusValue?: string;
    driverUserName?: string;
    mdmCode?: string;
    mdmRegistrationStatusValue?: string;
    memberApprovalCompletedAt?: string;
    memberCategoryName?: string;
    memberCategoryType?: TempDriverSearchDtoResponseExcelDownloadDriverUser.memberCategoryType;
    memberCreatedAt?: string;
    plateNumber?: string;
    registrationPathTypeValue?: string;
    settlementApprovalStatusValue?: string;
    userId?: string;
    vehicleOptionTypeValues?: Array<string>;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace TempDriverSearchDtoResponseExcelDownloadDriverUser {
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
}

