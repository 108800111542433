/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBusinessCreditInfoDtoCreditAvailabilityInfo } from './GetBusinessCreditInfoDtoCreditAvailabilityInfo';
import type { GetBusinessCreditInfoDtoDetailCreditInfo } from './GetBusinessCreditInfoDtoDetailCreditInfo';
import type { GetBusinessCreditInfoDtoDetailFinanceInfo } from './GetBusinessCreditInfoDtoDetailFinanceInfo';
import type { GetBusinessCreditInfoDtoMonthCreditInfo } from './GetBusinessCreditInfoDtoMonthCreditInfo';
export type GetBusinessCreditInfoDtoDetailResult = {
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    creditAvailabilityInfos?: Array<GetBusinessCreditInfoDtoCreditAvailabilityInfo>;
    creditSalesApprovedAt?: string;
    creditSalesAvailabilityType?: GetBusinessCreditInfoDtoDetailResult.creditSalesAvailabilityType;
    detailCreditInfo?: GetBusinessCreditInfoDtoDetailCreditInfo;
    detailFinanceInfo?: GetBusinessCreditInfoDtoDetailFinanceInfo;
    isUnpaidBonds?: boolean;
    mdmCode?: string;
    memberId?: string;
    monthCreditInfos?: Array<GetBusinessCreditInfoDtoMonthCreditInfo>;
    ownerSelectablePaymentConditionCode?: GetBusinessCreditInfoDtoDetailResult.ownerSelectablePaymentConditionCode;
    ownerUserId?: string;
    unpaidBondsMemo?: string;
};
export namespace GetBusinessCreditInfoDtoDetailResult {
    export enum creditSalesAvailabilityType {
        GOOD = 'GOOD',
        LOW = 'LOW',
        UNAVAILABLE = 'UNAVAILABLE',
    }
    export enum ownerSelectablePaymentConditionCode {
        D_3003 = 'D_3003',
        D_3015 = 'D_3015',
        D_3020 = 'D_3020',
        D_3023 = 'D_3023',
        D_3025 = 'D_3025',
        D_3028 = 'D_3028',
        D_3030 = 'D_3030',
        D_3035 = 'D_3035',
        D_3040 = 'D_3040',
        D_3042 = 'D_3042',
        D_3045 = 'D_3045',
        D_3050 = 'D_3050',
        D_3052 = 'D_3052',
        D_3055 = 'D_3055',
        D_3060 = 'D_3060',
        D_3065 = 'D_3065',
        D_3067 = 'D_3067',
        D_3070 = 'D_3070',
        D_3075 = 'D_3075',
        D_3080 = 'D_3080',
        D_3090 = 'D_3090',
        D_3120 = 'D_3120',
        D_3910 = 'D_3910',
    }
}

