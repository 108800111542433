/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderExcelUploadDtoRequestCancel = {
    orderExcelStatus: OrderExcelUploadDtoRequestCancel.orderExcelStatus;
    ownerMemberId?: string;
};
export namespace OrderExcelUploadDtoRequestCancel {
    export enum orderExcelStatus {
        OPTIMIZE_ROUTING = 'OPTIMIZE_ROUTING',
        OPTIMIZE_ROUTING_CANCEL = 'OPTIMIZE_ROUTING_CANCEL',
        OPTIMIZE_ROUTING_COMPLETE = 'OPTIMIZE_ROUTING_COMPLETE',
        OPTIMIZE_ROUTING_ERROR = 'OPTIMIZE_ROUTING_ERROR',
        ORDER_CREATE_CANCEL = 'ORDER_CREATE_CANCEL',
        ORDER_CREATE_COMPLETE = 'ORDER_CREATE_COMPLETE',
        ORDER_CREATING = 'ORDER_CREATING',
        ORDER_CREAT_ERROR = 'ORDER_CREAT_ERROR',
        REGISTERING = 'REGISTERING',
        REGISTRATION_CANCEL = 'REGISTRATION_CANCEL',
        REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
        REGISTRATION_ERROR = 'REGISTRATION_ERROR',
        RESET = 'RESET',
        UPLOADING = 'UPLOADING',
        UPLOAD_CANCEL = 'UPLOAD_CANCEL',
        UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
        UPLOAD_ERROR = 'UPLOAD_ERROR',
        VALIDATE_CANCEL = 'VALIDATE_CANCEL',
        VALIDATE_COMPLETE = 'VALIDATE_COMPLETE',
        VALIDATE_ERROR = 'VALIDATE_ERROR',
        VALIDATING = 'VALIDATING',
    }
}

