/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type JsonbPickupDropMethod = {
    isWorkSupportRequest?: boolean;
    method?: JsonbPickupDropMethod.method;
};
export namespace JsonbPickupDropMethod {
    export enum method {
        CRANE = 'CRANE',
        FOLK_LIFT = 'FOLK_LIFT',
        HANDWORK = 'HANDWORK',
        HOIST = 'HOIST',
    }
}

