/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SavePriceGroupPolicyDtoAddRequest = {
    serviceType: SavePriceGroupPolicyDtoAddRequest.serviceType;
    shortTitle: string;
    title: string;
};
export namespace SavePriceGroupPolicyDtoAddRequest {
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
}

