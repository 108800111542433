/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SaveDriverBannerDtoRequestFileImageUpload } from './SaveDriverBannerDtoRequestFileImageUpload';
export type SaveDriverBannerDtoRequest = {
    displayType?: SaveDriverBannerDtoRequest.displayType;
    endDate?: string;
    id?: number;
    image?: SaveDriverBannerDtoRequestFileImageUpload;
    linkUrl?: string;
    name: string;
    startDate: string;
    type: SaveDriverBannerDtoRequest.type;
};
export namespace SaveDriverBannerDtoRequest {
    export enum displayType {
        NEW_TAB = 'NEW_TAB',
        PAGE_CHANGE = 'PAGE_CHANGE',
    }
    export enum type {
        BANNER = 'BANNER',
        BOTTOM_POPUP = 'BOTTOM_POPUP',
        FULL_POPUP = 'FULL_POPUP',
    }
}

