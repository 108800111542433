/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractFreightDriverDesignationDtoRequest = {
    businessId: string;
    contractFreightDriverDesignationId?: number;
    driverType: ContractFreightDriverDesignationDtoRequest.driverType;
};
export namespace ContractFreightDriverDesignationDtoRequest {
    export enum driverType {
        DIRECT = 'DIRECT',
        FIXED = 'FIXED',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        TEMP_DRIVER = 'TEMP_DRIVER',
        UNBAN = 'UNBAN',
    }
}

