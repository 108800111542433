/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WayPointDto } from './WayPointDto';
export type OrderMatchingListV2DtoOrderInfo = {
    businessName?: string;
    customerCode?: string;
    customerName?: string;
    driverCarTonType?: OrderMatchingListV2DtoOrderInfo.driverCarTonType;
    driverCarType?: OrderMatchingListV2DtoOrderInfo.driverCarType;
    driverFeeAmount?: number;
    driverOpenAt?: string;
    dropRequestAt?: string;
    estimateContractDriverFee?: number;
    estimateFixedDriverFee?: number;
    estimateMaxFeeAddExtraFee?: number;
    estimateMinFeeAddExtraFee?: number;
    freightType?: OrderMatchingListV2DtoOrderInfo.freightType;
    fromAddress?: string;
    fromName?: string;
    isDestinationCashOnDelivery?: boolean;
    isDriverOpened?: boolean;
    isMemo?: boolean;
    isSettlementApproval?: boolean;
    itemName?: string;
    liftFeeAmount?: number;
    orderChannelType?: OrderMatchingListV2DtoOrderInfo.orderChannelType;
    orderCreatedAt?: string;
    orderId?: string;
    orderNo?: string;
    orderPaymentMethodType?: OrderMatchingListV2DtoOrderInfo.orderPaymentMethodType;
    orderPaymentMethodTypeValue?: string;
    orderType?: OrderMatchingListV2DtoOrderInfo.orderType;
    orderUpdatedAt?: string;
    ownerId?: string;
    ownerName?: string;
    pickupRequestAt?: string;
    priceGroupPolicyTitle?: string;
    remainDispatchFail?: number;
    storageOpenFeeAmount?: number;
    toAddress?: string;
    toName?: string;
    totalDistance?: number;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
    wayPointDtos?: Array<WayPointDto>;
    waypointSize?: number;
};
export namespace OrderMatchingListV2DtoOrderInfo {
    export enum driverCarTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum driverCarType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum orderChannelType {
        ADMIN_ORDER = 'ADMIN_ORDER',
        CJ_ENM = 'CJ_ENM',
        HCORE_ERP = 'HCORE_ERP',
        OWNER_APP = 'OWNER_APP',
        OWNER_APP_LLM = 'OWNER_APP_LLM',
        OWNER_WEB = 'OWNER_WEB',
        OWNER_WEB_BLOCK = 'OWNER_WEB_BLOCK',
        SYSTEM_ERP = 'SYSTEM_ERP',
        TPS_HELLO = 'TPS_HELLO',
    }
    export enum orderPaymentMethodType {
        CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
    }
    export enum orderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

