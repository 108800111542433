/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AccidentCancelDtoRequest = {
    accidentCancelType: AccidentCancelDtoRequest.accidentCancelType;
    dispatchChannel?: AccidentCancelDtoRequest.dispatchChannel;
    dispatchingChange?: boolean;
    driverNewTotalAmount?: number;
    driverOldTotalAmount?: number;
    memo?: string;
    newDriverUserMemberId?: string;
    oldDriverUserMemberId?: string;
    orderId: string;
    otherEtaxPublisherType?: AccidentCancelDtoRequest.otherEtaxPublisherType;
    ownerNewTotalAmount?: number;
    ownerOldTotalAmount?: number;
    serviceType: AccidentCancelDtoRequest.serviceType;
};
export namespace AccidentCancelDtoRequest {
    export enum accidentCancelType {
        BREAKDOWNS_AND_ACCIDENTS = 'BREAKDOWNS_AND_ACCIDENTS',
        BREAKDOWNS_AND_ACCIDENTS_BY_PARTNER = 'BREAKDOWNS_AND_ACCIDENTS_BY_PARTNER',
        CONSIGNED_FIXED_VEHICLE = 'CONSIGNED_FIXED_VEHICLE',
        DEFECTIVE_AND_RECALL = 'DEFECTIVE_AND_RECALL',
        ETC = 'ETC',
        INCORRECT_INFORMATION = 'INCORRECT_INFORMATION',
        PERSONAL_AFFAIRS = 'PERSONAL_AFFAIRS',
        PICKUP_COMPLETE_DELAY = 'PICKUP_COMPLETE_DELAY',
        PICKUP_COMPLETE_DELAY_BY_PARTNER = 'PICKUP_COMPLETE_DELAY_BY_PARTNER',
        RESCHEDULE = 'RESCHEDULE',
    }
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
    export enum serviceType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
}

