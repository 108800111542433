/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentCancelDtoResult } from './AccidentCancelDtoResult';
import type { AddressInfoDto } from './AddressInfoDto';
import type { BusinessPriceGroupDto } from './BusinessPriceGroupDto';
import type { CommonFileDtoOrderReceiptResult } from './CommonFileDtoOrderReceiptResult';
import type { CouponDtoUsedCouponResult } from './CouponDtoUsedCouponResult';
import type { JsonbCar } from './JsonbCar';
import type { JsonbPickupDropMethod } from './JsonbPickupDropMethod';
import type { JsonbRoute } from './JsonbRoute';
import type { OrderDetailDtoExtraInvoiceResponseV2 } from './OrderDetailDtoExtraInvoiceResponseV2';
import type { OrderDetailDtoExtraInvoiceResult } from './OrderDetailDtoExtraInvoiceResult';
import type { OrderDetailDtoHistoryResult } from './OrderDetailDtoHistoryResult';
import type { OrderDetailDtoPriceHistoryResult } from './OrderDetailDtoPriceHistoryResult';
import type { OrderDriverInfoDtoResult } from './OrderDriverInfoDtoResult';
import type { OrderDtoResponseBusinessPayment } from './OrderDtoResponseBusinessPayment';
import type { OrderItemDtoResult } from './OrderItemDtoResult';
import type { OrderJsonbCarDtoResult } from './OrderJsonbCarDtoResult';
import type { OrderJsonbPickupDropMethodDtoResult } from './OrderJsonbPickupDropMethodDtoResult';
import type { OrderStatusHistoryDtoResult } from './OrderStatusHistoryDtoResult';
import type { Point } from './Point';
import type { PriceInfoDto } from './PriceInfoDto';
import type { WayPointDto } from './WayPointDto';
export type OrderDetailDtoResult = {
    accidentCancel?: AccidentCancelDtoResult;
    affiliateCode?: OrderDetailDtoResult.affiliateCode;
    businessFunctionOptionTypes?: Array<'CASH_ON_DELIVERY' | 'CHATBOT' | 'CONTRACT_FREIGHT_ORDER_OPEN' | 'DISPATCH_FAIL_TIME' | 'DRIVER_APP_ORDER_OPEN' | 'ELECTRONIC_RECEIPT_MANDATORY' | 'MAX_WAYPOINT_SIZE' | 'MONTHLY_SHIPPING_FEE_LIMIT' | 'ORDER_EXCEL_UPLOAD' | 'PARTNER_MAX_WAYPOINT_SIZE' | 'PICKUP_DATE_TIME_NO_CHECK' | 'RECEIPT_MANDATORY' | 'SETTLEMENT_GROUP'>;
    businessId?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessPaymentId?: string;
    businessPriceGroupDto?: BusinessPriceGroupDto;
    businessRegistrationNumber?: string;
    businessSettlementGroupId?: string;
    businessSettlementGroupMemo?: string;
    businessSettlementGroupName?: string;
    confirmFeeAddExtraFee?: number;
    couponInfo?: CouponDtoUsedCouponResult;
    createdAt?: string;
    customerCode?: string;
    customerName?: string;
    dispatchChannel?: OrderDetailDtoResult.dispatchChannel;
    dispatchCompleteDueDateTime?: string;
    dispatchMethod?: OrderDetailDtoResult.dispatchMethod;
    dispatchMethodValue?: string;
    dockArrivalAt?: string;
    dockMemo?: string;
    dockName?: string;
    dockStatusType?: OrderDetailDtoResult.dockStatusType;
    dockStatusTypeValue?: string;
    driverInfo?: OrderDriverInfoDtoResult;
    driverPriceInfoDto?: PriceInfoDto;
    dropAt?: string;
    dropMethod?: JsonbPickupDropMethod;
    dropMethodInfo?: OrderJsonbPickupDropMethodDtoResult;
    dropRequestAt?: string;
    enableOrderElectronicReceipt?: boolean;
    extraInvoice?: OrderDetailDtoExtraInvoiceResult;
    extraInvoiceV2?: OrderDetailDtoExtraInvoiceResponseV2;
    freightType?: OrderDetailDtoResult.freightType;
    freightTypeValue?: string;
    fromAddress?: AddressInfoDto;
    fromPoint?: Point;
    invoiceStatusType?: OrderDetailDtoResult.invoiceStatusType;
    invoiceType?: OrderDetailDtoResult.invoiceType;
    ioBoundCenterCode?: OrderDetailDtoResult.ioBoundCenterCode;
    ioBoundCenterCodeValue?: string;
    ioBoundDetailCode?: OrderDetailDtoResult.ioBoundDetailCode;
    ioBoundDetailCodeValue?: string;
    ioBoundDtName?: string;
    ioBoundMemo?: string;
    ioBoundType?: OrderDetailDtoResult.ioBoundType;
    ioBoundTypeValue?: string;
    isCjEnm?: boolean;
    isPartnerOrder?: boolean;
    isPastOrder?: boolean;
    isRecommendedRouteUsed?: boolean;
    isRound?: boolean;
    maxWayPointSize?: number;
    memberId?: string;
    memberName?: string;
    multiOrderCarTonTypeOption?: OrderDetailDtoResult.multiOrderCarTonTypeOption;
    orderDetailHistories?: Array<OrderDetailDtoHistoryResult>;
    orderDetailPriceHistories?: Array<OrderDetailDtoPriceHistoryResult>;
    orderId?: string;
    orderIntegrationType?: OrderDetailDtoResult.orderIntegrationType;
    orderItems?: Array<OrderItemDtoResult>;
    orderNo?: string;
    orderOptionCashOnDeliveryTypes?: Array<'DROP_CASH_ON_DELIVERY' | 'RETURN_CASH_ON_DELIVERY' | 'WAYPOINT10_CASH_ON_DELIVERY' | 'WAYPOINT11_CASH_ON_DELIVERY' | 'WAYPOINT12_CASH_ON_DELIVERY' | 'WAYPOINT13_CASH_ON_DELIVERY' | 'WAYPOINT14_CASH_ON_DELIVERY' | 'WAYPOINT15_CASH_ON_DELIVERY' | 'WAYPOINT16_CASH_ON_DELIVERY' | 'WAYPOINT17_CASH_ON_DELIVERY' | 'WAYPOINT18_CASH_ON_DELIVERY' | 'WAYPOINT19_CASH_ON_DELIVERY' | 'WAYPOINT1_CASH_ON_DELIVERY' | 'WAYPOINT20_CASH_ON_DELIVERY' | 'WAYPOINT2_CASH_ON_DELIVERY' | 'WAYPOINT3_CASH_ON_DELIVERY' | 'WAYPOINT4_CASH_ON_DELIVERY' | 'WAYPOINT5_CASH_ON_DELIVERY' | 'WAYPOINT6_CASH_ON_DELIVERY' | 'WAYPOINT7_CASH_ON_DELIVERY' | 'WAYPOINT8_CASH_ON_DELIVERY' | 'WAYPOINT9_CASH_ON_DELIVERY'>;
    orderOptionItemTypes?: Array<'DROP_ITEM_SET' | 'RETURN_ITEM_SET' | 'WAYPOINT10_ITEM_SET' | 'WAYPOINT11_ITEM_SET' | 'WAYPOINT12_ITEM_SET' | 'WAYPOINT13_ITEM_SET' | 'WAYPOINT14_ITEM_SET' | 'WAYPOINT15_ITEM_SET' | 'WAYPOINT16_ITEM_SET' | 'WAYPOINT17_ITEM_SET' | 'WAYPOINT18_ITEM_SET' | 'WAYPOINT19_ITEM_SET' | 'WAYPOINT1_ITEM_SET' | 'WAYPOINT20_ITEM_SET' | 'WAYPOINT2_ITEM_SET' | 'WAYPOINT3_ITEM_SET' | 'WAYPOINT4_ITEM_SET' | 'WAYPOINT5_ITEM_SET' | 'WAYPOINT6_ITEM_SET' | 'WAYPOINT7_ITEM_SET' | 'WAYPOINT8_ITEM_SET' | 'WAYPOINT9_ITEM_SET'>;
    orderPaymentMethodType?: OrderDetailDtoResult.orderPaymentMethodType;
    orderStatus?: OrderDetailDtoResult.orderStatus;
    orderStatusHistories?: Array<OrderStatusHistoryDtoResult>;
    orderType?: OrderDetailDtoResult.orderType;
    orderUpdatedAt?: string;
    otherEtaxPublisherType?: OrderDetailDtoResult.otherEtaxPublisherType;
    ownerBusinessPaymentInfo?: OrderDtoResponseBusinessPayment;
    ownerOrderType?: OrderDetailDtoResult.ownerOrderType;
    ownerPriceInfoDto?: PriceInfoDto;
    partnerBusinessName?: string;
    paymentOwnerId?: string;
    pickupAt?: string;
    pickupMethod?: JsonbPickupDropMethod;
    pickupMethodInfo?: OrderJsonbPickupDropMethodDtoResult;
    pickupRequestAt?: string;
    quickTonType?: OrderDetailDtoResult.quickTonType;
    quickTonTypeValue?: string;
    receiptFileInfo?: CommonFileDtoOrderReceiptResult;
    requestCar?: JsonbCar;
    requestCarInfo?: OrderJsonbCarDtoResult;
    returnRequestAt?: string;
    roundRoute?: JsonbRoute;
    route?: JsonbRoute;
    showOrderElectronicReceipt?: boolean;
    toAddress?: AddressInfoDto;
    toPoint?: Point;
    tpsOrderNo?: string;
    transactionMethodType?: OrderDetailDtoResult.transactionMethodType;
    updatedAt?: string;
    wayPointDtos?: Array<WayPointDto>;
};
export namespace OrderDetailDtoResult {
    export enum affiliateCode {
        B_MART = 'B_MART',
        COMMON = 'COMMON',
        FURSYS = 'FURSYS',
        FURSYS_VENDOR = 'FURSYS_VENDOR',
        HCORE = 'HCORE',
        KOAS = 'KOAS',
        LIVART = 'LIVART',
        NAMYANG = 'NAMYANG',
        NAMYANG_WATER = 'NAMYANG_WATER',
        ONE = 'ONE',
        PND = 'PND',
        SK_NETWORKS = 'SK_NETWORKS',
        WND = 'WND',
    }
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
    export enum dockStatusType {
        ARRIVE = 'ARRIVE',
        ASSIGNMENT = 'ASSIGNMENT',
        IDLE = 'IDLE',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum invoiceStatusType {
        ADMIN_CONFIRMED = 'ADMIN_CONFIRMED',
        CANCELLED = 'CANCELLED',
        CANCEL_FAILED = 'CANCEL_FAILED',
        COMPLETE = 'COMPLETE',
        CONFIRMED = 'CONFIRMED',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NEGOTIATE = 'NEGOTIATE',
        QUICK_PAY_REAPPLIED = 'QUICK_PAY_REAPPLIED',
        QUICK_PAY_REAPPLY_CANCELLED = 'QUICK_PAY_REAPPLY_CANCELLED',
        QUICK_PAY_REQUESTED = 'QUICK_PAY_REQUESTED',
        TAX_INVOICE_ISSUED = 'TAX_INVOICE_ISSUED',
    }
    export enum invoiceType {
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        MONTHLY_PAYMENT = 'MONTHLY_PAYMENT',
        POST_PAYMENT = 'POST_PAYMENT',
        PRE_PAYMENT = 'PRE_PAYMENT',
        REFUND = 'REFUND',
    }
    export enum ioBoundCenterCode {
        R43001 = 'R43001',
        R43004 = 'R43004',
        R43005 = 'R43005',
        R43006 = 'R43006',
        R43007 = 'R43007',
    }
    export enum ioBoundDetailCode {
        R46001 = 'R46001',
        R46002 = 'R46002',
        R46003 = 'R46003',
        R46004 = 'R46004',
        R46005 = 'R46005',
        R46006 = 'R46006',
        R46007 = 'R46007',
        R46008 = 'R46008',
        R46009 = 'R46009',
        R46010 = 'R46010',
        R46011 = 'R46011',
        R46012 = 'R46012',
        R46013 = 'R46013',
        R46014 = 'R46014',
        R46015 = 'R46015',
        R46016 = 'R46016',
    }
    export enum ioBoundType {
        FORWARDING = 'FORWARDING',
        RECEIVING = 'RECEIVING',
    }
    export enum multiOrderCarTonTypeOption {
        TON11_0 = 'TON11_0',
        TON25_0 = 'TON25_0',
        TON5_0 = 'TON5_0',
    }
    export enum orderIntegrationType {
        BASIC = 'BASIC',
        FURSYS = 'FURSYS',
        FURSYS_VENDOR = 'FURSYS_VENDOR',
        HANSSEM = 'HANSSEM',
        KOAS = 'KOAS',
        NAMYANG = 'NAMYANG',
        NAMYANG_WATER = 'NAMYANG_WATER',
    }
    export enum orderPaymentMethodType {
        CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
    }
    export enum orderStatus {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DISPATCHING = 'DISPATCHING',
        DISPATCH_BIND = 'DISPATCH_BIND',
        DISPATCH_COMPLETE = 'DISPATCH_COMPLETE',
        DISPATCH_CONFIRM = 'DISPATCH_CONFIRM',
        DISPATCH_DIRECT_CAR = 'DISPATCH_DIRECT_CAR',
        DISPATCH_DRIVER_CANCEL = 'DISPATCH_DRIVER_CANCEL',
        DISPATCH_FAIL = 'DISPATCH_FAIL',
        DISPATCH_OWNER_CANCEL = 'DISPATCH_OWNER_CANCEL',
        DRIVER_ACCIDENT_CANCEL = 'DRIVER_ACCIDENT_CANCEL',
        DROP_COMPLETE = 'DROP_COMPLETE',
        DROP_MOVING = 'DROP_MOVING',
        ORDER_CANCEL = 'ORDER_CANCEL',
        ORDER_UPDATE = 'ORDER_UPDATE',
        OWNER_ACCIDENT_CANCEL = 'OWNER_ACCIDENT_CANCEL',
        PARTNER_ACCIDENT_CANCEL = 'PARTNER_ACCIDENT_CANCEL',
        PICKUP_COMPLETE = 'PICKUP_COMPLETE',
        PICKUP_MOVING = 'PICKUP_MOVING',
        PICKUP_RETURN_MOVING = 'PICKUP_RETURN_MOVING',
        WAYPOINT10_COMPLETE = 'WAYPOINT10_COMPLETE',
        WAYPOINT10_MOVING = 'WAYPOINT10_MOVING',
        WAYPOINT11_COMPLETE = 'WAYPOINT11_COMPLETE',
        WAYPOINT11_MOVING = 'WAYPOINT11_MOVING',
        WAYPOINT12_COMPLETE = 'WAYPOINT12_COMPLETE',
        WAYPOINT12_MOVING = 'WAYPOINT12_MOVING',
        WAYPOINT13_COMPLETE = 'WAYPOINT13_COMPLETE',
        WAYPOINT13_MOVING = 'WAYPOINT13_MOVING',
        WAYPOINT14_COMPLETE = 'WAYPOINT14_COMPLETE',
        WAYPOINT14_MOVING = 'WAYPOINT14_MOVING',
        WAYPOINT15_COMPLETE = 'WAYPOINT15_COMPLETE',
        WAYPOINT15_MOVING = 'WAYPOINT15_MOVING',
        WAYPOINT16_COMPLETE = 'WAYPOINT16_COMPLETE',
        WAYPOINT16_MOVING = 'WAYPOINT16_MOVING',
        WAYPOINT17_COMPLETE = 'WAYPOINT17_COMPLETE',
        WAYPOINT17_MOVING = 'WAYPOINT17_MOVING',
        WAYPOINT18_COMPLETE = 'WAYPOINT18_COMPLETE',
        WAYPOINT18_MOVING = 'WAYPOINT18_MOVING',
        WAYPOINT19_COMPLETE = 'WAYPOINT19_COMPLETE',
        WAYPOINT19_MOVING = 'WAYPOINT19_MOVING',
        WAYPOINT1_COMPLETE = 'WAYPOINT1_COMPLETE',
        WAYPOINT1_MOVING = 'WAYPOINT1_MOVING',
        WAYPOINT20_COMPLETE = 'WAYPOINT20_COMPLETE',
        WAYPOINT20_MOVING = 'WAYPOINT20_MOVING',
        WAYPOINT2_COMPLETE = 'WAYPOINT2_COMPLETE',
        WAYPOINT2_MOVING = 'WAYPOINT2_MOVING',
        WAYPOINT3_COMPLETE = 'WAYPOINT3_COMPLETE',
        WAYPOINT3_MOVING = 'WAYPOINT3_MOVING',
        WAYPOINT4_COMPLETE = 'WAYPOINT4_COMPLETE',
        WAYPOINT4_MOVING = 'WAYPOINT4_MOVING',
        WAYPOINT5_COMPLETE = 'WAYPOINT5_COMPLETE',
        WAYPOINT5_MOVING = 'WAYPOINT5_MOVING',
        WAYPOINT6_COMPLETE = 'WAYPOINT6_COMPLETE',
        WAYPOINT6_MOVING = 'WAYPOINT6_MOVING',
        WAYPOINT7_COMPLETE = 'WAYPOINT7_COMPLETE',
        WAYPOINT7_MOVING = 'WAYPOINT7_MOVING',
        WAYPOINT8_COMPLETE = 'WAYPOINT8_COMPLETE',
        WAYPOINT8_MOVING = 'WAYPOINT8_MOVING',
        WAYPOINT9_COMPLETE = 'WAYPOINT9_COMPLETE',
        WAYPOINT9_MOVING = 'WAYPOINT9_MOVING',
    }
    export enum orderType {
        MULTI = 'MULTI',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
    export enum ownerOrderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
    export enum quickTonType {
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

