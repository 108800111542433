/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SapSettlementDtoInvoiceItem } from './SapSettlementDtoInvoiceItem';
export type SapSettlementDtoSalesContractModifyRequest = {
    businessName?: string;
    businessSettlementGroupId?: string;
    businessSettlementGroupName?: string;
    carTonTypeValue?: string;
    dropRequestAt?: string;
    freightTypeValue?: string;
    fromAddress?: string;
    invoiceExtraModifyType?: SapSettlementDtoSalesContractModifyRequest.invoiceExtraModifyType;
    invoiceExtraModifyTypeValue?: string;
    invoiceItems?: Array<SapSettlementDtoInvoiceItem>;
    orderId: string;
    orderNo?: string;
    orderOwnerId?: string;
    orderOwnerName?: string;
    orderStatusValue?: string;
    orderTypeValue?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    toAddress?: string;
    totalAmountWithoutVat?: number;
    waypointCount?: number;
};
export namespace SapSettlementDtoSalesContractModifyRequest {
    export enum invoiceExtraModifyType {
        MODIFIED = 'MODIFIED',
        NOT_SETTLED = 'NOT_SETTLED',
        NO_EXTRA = 'NO_EXTRA',
    }
}

