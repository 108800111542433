/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FaqCreateDtoRequest = {
    contents?: string;
    faqCategory?: FaqCreateDtoRequest.faqCategory;
    isBest: boolean;
    title?: string;
};
export namespace FaqCreateDtoRequest {
    export enum faqCategory {
        ALARM = 'ALARM',
        DISPATCH = 'DISPATCH',
        ETC = 'ETC',
        MEMBER = 'MEMBER',
        ORDER = 'ORDER',
        SETTLEMENT = 'SETTLEMENT',
    }
}

