/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessGroupRequest = {
    affiliateCode?: BusinessGroupRequest.affiliateCode;
    name: string;
    serviceType: BusinessGroupRequest.serviceType;
};
export namespace BusinessGroupRequest {
    export enum affiliateCode {
        B_MART = 'B_MART',
        COMMON = 'COMMON',
        FURSYS = 'FURSYS',
        FURSYS_VENDOR = 'FURSYS_VENDOR',
        HCORE = 'HCORE',
        KOAS = 'KOAS',
        LIVART = 'LIVART',
        NAMYANG_WATER = 'NAMYANG_WATER',
        ONE = 'ONE',
        PND = 'PND',
        SK_NETWORKS = 'SK_NETWORKS',
        WND = 'WND',
    }
    export enum serviceType {
        DRIVER_SERVICE = 'DRIVER_SERVICE',
        OWNER_SERVICE = 'OWNER_SERVICE',
        PARTNER_DRIVER_SERVICE = 'PARTNER_DRIVER_SERVICE',
        PARTNER_SERVICE = 'PARTNER_SERVICE',
    }
}

