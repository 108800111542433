/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConsignmentVehicleRegisterDtoRequest = {
    consignmentMdm?: string;
    isMember: boolean;
    plateNumber: string;
    vehicleOperationType: ConsignmentVehicleRegisterDtoRequest.vehicleOperationType;
};
export namespace ConsignmentVehicleRegisterDtoRequest {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

