/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OwnerUserDtoResponseOwnerUser = {
    approvalStatus?: OwnerUserDtoResponseOwnerUser.approvalStatus;
    businessGroupName?: string;
    businessId?: string;
    businessRegistrationNumber?: string;
    createdAt?: string;
    department?: string;
    eevidenceUrl?: string;
    email?: string;
    isRegisteredCard?: boolean;
    joinStatus?: OwnerUserDtoResponseOwnerUser.joinStatus;
    mdmRegistrationStatus?: OwnerUserDtoResponseOwnerUser.mdmRegistrationStatus;
    memberCategoryName?: string;
    memberCategoryType?: OwnerUserDtoResponseOwnerUser.memberCategoryType;
    memberId?: string;
    name?: string;
    officePhoneNumber?: string;
    paymentMethodType?: OwnerUserDtoResponseOwnerUser.paymentMethodType;
    paymentMethodTypeValue?: string;
    phoneNumber?: string;
    transactionMethodType?: OwnerUserDtoResponseOwnerUser.transactionMethodType;
    userId?: string;
    businessTransactionLevel?:string
};
export namespace OwnerUserDtoResponseOwnerUser {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
    }
    export enum paymentMethodType {
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        QUICK_PAY = 'QUICK_PAY',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

