/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetBusinessCreditInfoDtoDetailCreditInfo = {
    cashFlow?: string;
    creditGrade?: string;
    creditInformationId?: string;
    creditResult?: GetBusinessCreditInfoDtoDetailCreditInfo.creditResult;
    currentRatio?: number;
    memo?: string;
};
export namespace GetBusinessCreditInfoDtoDetailCreditInfo {
    export enum creditResult {
        F = 'F',
        P = 'P',
    }
}

