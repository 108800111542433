/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetEventListDtoRequest = {
    businessNameOrNumber?: string;
    carTonTypes?: Array<'TON00_5' | 'TON0_3' | 'TON0_5' | 'TON11_0' | 'TON18_0' | 'TON1_0' | 'TON1_4' | 'TON25_0' | 'TON2_5' | 'TON3_5' | 'TON5_0' | 'TON5_0_PLUS'>;
    carTypes?: Array<'CARGO' | 'DARMAS' | 'LABOR' | 'MOTORCYCLE' | 'TOP' | 'TRAILER' | 'WING_BODY' | 'WING_BODY_CARGO'>;
    endDateTime: string;
    fromContractDateTime: string;
    isInContract?: boolean;
    isReContract?: boolean;
    isSend?: boolean;
    mdmCode?: string;
    pageNo: number;
    pageSize: number;
    plateNumber?: string;
    sapSettlementStatuses?: Array<'ISSUED_COMPLETE' | 'ISSUE_IN_PROGRESS' | 'NOT_ISSUED'>;
    searchDateType?: GetEventListDtoRequest.searchDateType;
    startDateTime: string;
    stickerEventFileApprovalStatuses?: Array<'COMPLETE' | 'NONE' | 'REGISTER' | 'REREGISTER' | 'RETURN'>;
    toContractDateTime: string;
    userId?: string;
    verifyTypes?: Array<'COMPLETE' | 'REJECT' | 'SUSPEND'>;
};
export namespace GetEventListDtoRequest {
    export enum searchDateType {
        FIRST_CONTRACT = 'FIRST_CONTRACT',
        LAST_CONTRACT = 'LAST_CONTRACT',
    }
}

