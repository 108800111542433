/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverPopupDtoRequest = {
    carTonTypes?: Array<'TON00_5' | 'TON0_3' | 'TON0_5' | 'TON11_0' | 'TON18_0' | 'TON1_0' | 'TON1_4' | 'TON25_0' | 'TON2_5' | 'TON3_5' | 'TON5_0' | 'TON5_0_PLUS'>;
    pageNo?: number;
    pageSize?: number;
    progressStatus: SearchDriverPopupDtoRequest.progressStatus;
    type: SearchDriverPopupDtoRequest.type;
};
export namespace SearchDriverPopupDtoRequest {
    export enum progressStatus {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
    export enum type {
        BANNER = 'BANNER',
        BOTTOM_POPUP = 'BOTTOM_POPUP',
        FULL_POPUP = 'FULL_POPUP',
    }
}

