/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoRequestMemberSettlementApprovalStatus = {
    attachFileIds: Array<number>;
    memberId: string;
    settlementApprovalStatus: MemberDtoRequestMemberSettlementApprovalStatus.settlementApprovalStatus;
};
export namespace MemberDtoRequestMemberSettlementApprovalStatus {
    export enum settlementApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
}

