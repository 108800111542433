/*
 * ThemeProvider.tsx
 * MUI Customized Theme provider
 * @see https://mui.com/material-ui/customization/theming/
 */
import React from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import type { ThemeOptions } from '@mui/material'
import { koKR } from '@mui/material/locale'

type Props = React.PropsWithChildren<{
  mode?: 'light' | 'dark'
}>

const defaultThemeOptions: ThemeOptions = {
  typography: {
    // $--default-font-family 와 동일
    fontFamily: [
      'var(--font-spoqa)', // SUIT폰트변경시제거
      //'var(--font-suit)', // SUIT폰트변경시복구
      '-apple-system',
      '"Malgun Gothic"',
      'BlinkMacSystemFont',
      'system-ui',
      'Roboto',
      '"Helvetica Neue"',
      '"Segoe UI"',
      '"Apple SD Gothic Neo"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
}

export const lightThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    mode: 'light',
    ...defaultThemeOptions.palette,
  },
}

export const darkThemeOptions: ThemeOptions = {
  //...defaultThemeOptions,
  //palette: {
  //  mode: 'dark',
  //  ...defaultThemeOptions.palette,
  //},
}

const ThemeProvider: React.FC<Props> = props => {
  const theme = createTheme(props.mode === 'dark' ? darkThemeOptions : lightThemeOptions, koKR)
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
}

export default ThemeProvider
