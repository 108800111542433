/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NoticeDetailDtoResult = {
    contents?: string;
    endAt?: string;
    isPinned?: boolean;
    noticeId?: number;
    noticeType?: NoticeDetailDtoResult.noticeType;
    startAt?: string;
    title?: string;
};
export namespace NoticeDetailDtoResult {
    export enum noticeType {
        EVENT = 'EVENT',
        NOTICE = 'NOTICE',
        UPDATE = 'UPDATE',
    }
}

