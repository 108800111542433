/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverBannerDtoDetailResult = {
    displayType?: SearchDriverBannerDtoDetailResult.displayType;
    endDate?: string;
    fileName?: string;
    id?: number;
    linkUrl?: string;
    name?: string;
    signUrl?: string;
    startDate?: string;
};
export namespace SearchDriverBannerDtoDetailResult {
    export enum displayType {
        NEW_TAB = 'NEW_TAB',
        PAGE_CHANGE = 'PAGE_CHANGE',
    }
}

