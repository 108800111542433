/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessTabConfigDtoGetRequestDto = {
    businessSearchWord: string;
    pageNo?: number;
    pageSize?: number;
    serviceType?: BusinessTabConfigDtoGetRequestDto.serviceType;
};
export namespace BusinessTabConfigDtoGetRequestDto {
    export enum serviceType {
        SVTP000000 = 'SVTP000000',
        SVTP000001 = 'SVTP000001',
        SVTP000002 = 'SVTP000002',
        SVTP000003 = 'SVTP000003',
        SVTP000004 = 'SVTP000004',
        SVTP000005 = 'SVTP000005',
        SVTP000006 = 'SVTP000006',
    }
}

