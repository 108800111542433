/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchPriceGroupPolicyContractDtoRequest = {
    fromCreatedAt: string;
    pageNo?: number;
    pageSize?: number;
    serviceType: SearchPriceGroupPolicyContractDtoRequest.serviceType;
    title?: string;
    toCreatedAt: string;
};
export namespace SearchPriceGroupPolicyContractDtoRequest {
    export enum serviceType {
        COMMON = 'COMMON',
        DRIVER = 'DRIVER',
        OWNER = 'OWNER',
    }
}

