/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BannerDtoRequestBannerOpen = {
    bannerApplyType: BannerDtoRequestBannerOpen.bannerApplyType;
    bannerId: number;
    mappingIds?: Array<number>;
};
export namespace BannerDtoRequestBannerOpen {
    export enum bannerApplyType {
        ALL = 'ALL',
        GROUP = 'GROUP',
        PROMOTION = 'PROMOTION',
    }
}

