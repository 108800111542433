/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonFileDtoResult } from './CommonFileDtoResult';
export type DriverUserDtoResponseDriverUser = {
    approvalStatus?: DriverUserDtoResponseDriverUser.approvalStatus;
    birth?: string;
    businessGroupName?: string;
    businessId?: string;
    businessRegistrationFileId?: number;
    businessRegistrationNumber?: string;
    carTonType?: DriverUserDtoResponseDriverUser.carTonType;
    carType?: DriverUserDtoResponseDriverUser.carType;
    cargoTransportWorkerCertificateNo?: string;
    consignmentMdm?: string;
    createdAt?: string;
    driverImageInfo?: CommonFileDtoResult;
    driverMdm?: string;
    driverName?: string;
    eevidenceUrl?: string;
    fileInfo?: CommonFileDtoResult;
    idRegistrationFileStatus?: DriverUserDtoResponseDriverUser.idRegistrationFileStatus;
    identityNumber?: string;
    isFixedVehicle?: boolean;
    isLoadLiabilityInsuranceTarget?: boolean;
    isStickerEventMember?: boolean;
    joinStatus?: DriverUserDtoResponseDriverUser.joinStatus;
    loadLiabilityInsuranceApprovalStatus?: DriverUserDtoResponseDriverUser.loadLiabilityInsuranceApprovalStatus;
    loadLiabilityInsuranceApprovalStatusMessage?: string;
    loadLiabilityInsuranceLimit?: number;
    mdmRegistrationStatus?: DriverUserDtoResponseDriverUser.mdmRegistrationStatus;
    memberCategoryName?: string;
    memberCategoryType?: DriverUserDtoResponseDriverUser.memberCategoryType;
    memberId?: string;
    phase?: DriverUserDtoResponseDriverUser.phase;
    plateNumber?: string;
    registrationPathType?: DriverUserDtoResponseDriverUser.registrationPathType;
    settlementApprovalStatus?: DriverUserDtoResponseDriverUser.settlementApprovalStatus;
    userId?: string;
    vehicleOperationType?: DriverUserDtoResponseDriverUser.vehicleOperationType;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace DriverUserDtoResponseDriverUser {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum idRegistrationFileStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
    export enum loadLiabilityInsuranceApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum phase {
        PARTNER_PHASE1 = 'PARTNER_PHASE1',
        PARTNER_PHASE2 = 'PARTNER_PHASE2',
        PHASE1 = 'PHASE1',
        PHASE2 = 'PHASE2',
    }
    export enum registrationPathType {
        ADMIN = 'ADMIN',
        ADMIN_TO_GOOGLE_PLAY = 'ADMIN_TO_GOOGLE_PLAY',
        APK = 'APK',
        GOOGLE_PLAY = 'GOOGLE_PLAY',
    }
    export enum settlementApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

