/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CouponDtoCouponPolicyDetailModificationHistoryResult = {
    couponPolicyId?: number;
    couponPolicyModificationLogId?: number;
    createdAt?: string;
    createdBy?: string;
    fieldName?: string;
    memo?: string;
    modificationType?: CouponDtoCouponPolicyDetailModificationHistoryResult.modificationType;
    modificationTypeValue?: string;
    newStatus?: string;
    newValue?: string;
    oldStatus?: string;
    oldValue?: string;
};
export namespace CouponDtoCouponPolicyDetailModificationHistoryResult {
    export enum modificationType {
        BUSINESS_GROUP = 'BUSINESS_GROUP',
        ISSUE_DATE = 'ISSUE_DATE',
        TITLE = 'TITLE',
        USE_DATE = 'USE_DATE',
    }
}

