/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type JsonbItemPacking = {
    cbm?: string;
    height?: string;
    length?: string;
    method?: JsonbItemPacking.method;
    quantity?: string;
    totalWeight?: string;
    width?: string;
};
export namespace JsonbItemPacking {
    export enum method {
        BOX = 'BOX',
        CARTON = 'CARTON',
        ETC = 'ETC',
        PALLET = 'PALLET',
        ROLL_CONTAINER = 'ROLL_CONTAINER',
        TON_BAG = 'TON_BAG',
    }
}

