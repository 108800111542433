/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SapSettlementDtoInvoiceDriverFile } from './SapSettlementDtoInvoiceDriverFile';
export type SapSettlementDtoPurchaseResult = {
    billNo?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessTaxType?: string;
    documentId?: string;
    documentStatusType?: SapSettlementDtoPurchaseResult.documentStatusType;
    documentStatusTypeValue?: string;
    driverBankInfo?: string;
    freightType?: SapSettlementDtoPurchaseResult.freightType;
    freightTypeValue?: string;
    orderId?: string;
    orderNo?: string;
    paySuccessAt?: string;
    supplyAmount?: number;
    taxInvoiceFile?: SapSettlementDtoInvoiceDriverFile;
    totalAmount?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace SapSettlementDtoPurchaseResult {
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

