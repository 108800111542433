import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useBeforeHistoryChangeObserver = onRouteChange => {
  const router = useRouter()
  useEffect(() => {
    router.events.on('beforeHistoryChange', onRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', onRouteChange)
    }
  }, [router.basePath, router.events])
}
