/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminSmsMessageDtoFile } from './AdminSmsMessageDtoFile';
export type AdminSmsMessageDtoMessage = {
    completeTime?: string;
    content?: string;
    contentType?: AdminSmsMessageDtoMessage.contentType;
    countryCode?: string;
    files?: Array<AdminSmsMessageDtoFile>;
    from?: string;
    requestTime?: string;
    status?: string;
    statusCode?: string;
    statusMessage?: string;
    statusName?: string;
    telcoCode?: string;
    to?: string;
};
export namespace AdminSmsMessageDtoMessage {
    export enum contentType {
        AD = 'AD',
        COMM = 'COMM',
    }
}

