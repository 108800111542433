/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceConfigOwnerFreightDtoFreightSearchResult = {
    ownerStandardWaypointPrice?: boolean;
    purchaseFee?: number;
    salesFee?: number;
    transactionMethodType?: PriceConfigOwnerFreightDtoFreightSearchResult.transactionMethodType;
};
export namespace PriceConfigOwnerFreightDtoFreightSearchResult {
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

