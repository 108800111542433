/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OwnerAdminAddressBookDtoRequest = {
    addressType?: OwnerAdminAddressBookDtoRequest.addressType;
    businessId: string;
    keyword?: string;
    memberId?: string;
    pageNo: number;
    pageSize: number;
};
export namespace OwnerAdminAddressBookDtoRequest {
    export enum addressType {
        COMMON = 'COMMON',
        DROP = 'DROP',
        PICKUP = 'PICKUP',
        PICKUP_RETURN = 'PICKUP_RETURN',
        WAYPOINT = 'WAYPOINT',
    }
}

