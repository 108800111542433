/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberSearchDtoResponseExcelDownloadOwnerUser = {
    approvalStatusValue?: string;
    businessGroupName?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessTypeValue?: string;
    department?: string;
    email?: string;
    isRegisteredCard?: boolean;
    mdmCode?: string;
    mdmRegistrationStatusValue?: string;
    memberApprovalCompletedAt?: string;
    memberCategoryName?: string;
    memberCategoryType?: MemberSearchDtoResponseExcelDownloadOwnerUser.memberCategoryType;
    memberCreatedAt?: string;
    officePhoneNumber?: string;
    ownerUserName?: string;
    paymentMethodType?: MemberSearchDtoResponseExcelDownloadOwnerUser.paymentMethodType;
    paymentMethodTypeValue?: string;
    phoneNumber?: string;
    priceGroupPolicyTitles?: string;
    recentEstimatePriceAt?: string;
    recentLoginAt?: string;
    recentOrderCreatedAt?: string;
    registrationChannelType?: MemberSearchDtoResponseExcelDownloadOwnerUser.registrationChannelType;
    registrationChannelTypeValue?: string;
    totalDeliverCompleteOrderCount?: number;
    totalDispatchedOrderCount?: number;
    totalOrderCreateCount?: number;
    transactionMethodTypeValue?: string;
    userId?: string;
};
export namespace MemberSearchDtoResponseExcelDownloadOwnerUser {
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum paymentMethodType {
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        QUICK_PAY = 'QUICK_PAY',
    }
    export enum registrationChannelType {
        CJ_ENM = 'CJ_ENM',
        UNBAN = 'UNBAN',
    }
}

