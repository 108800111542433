/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type JsonLocationOption = {
    cashOnDelivery?: boolean;
    liftedTask?: JsonLocationOption.liftedTask;
    orderItemType?: JsonLocationOption.orderItemType;
    palletIds?: string;
    partnerOrderId?: string;
    tileBox?: JsonLocationOption.tileBox;
    waypointSequenceNo?: number;
};
export namespace JsonLocationOption {
    export enum liftedTask {
        NONE = 'NONE',
        NORMAL = 'NORMAL',
        STAIR = 'STAIR',
        TILE = 'TILE',
    }
    export enum orderItemType {
        SET = 'SET',
        SINGLE = 'SINGLE',
    }
    export enum tileBox {
        NO_01_10 = 'NO_01_10',
        NO_11_20 = 'NO_11_20',
        NO_21_30 = 'NO_21_30',
    }
}

