export const RoleTypeGroup = {
  ADMIN_USER: '더운반 그룹',
  ADMIN_SUPER_USER: '관리자 그룹',
  ADMIN_O_NE_USER: 'O-NE 본부 직접 배차 그룹',
  ADMIN_TPS_USER: '운송포털 그룹',
  ADMIN_MEMBER_USER: '회원 관리 그룹',
  ADMIN_SETTLEMENT_USER: '정산 관리 그룹',
  ADMIN_TRANSPORT_USER: '운송 관리 그룹',
} as const

export type RoleTypeGroupEnumType = (typeof RoleTypeGroup)[keyof typeof RoleTypeGroup]
