/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StickerApprovalStatusDto = {
    activeNumber?: number;
    approvalStatus?: StickerApprovalStatusDto.approvalStatus;
    approvalStatusValue?: string;
    verifyType?: StickerApprovalStatusDto.verifyType;
    verifyTypeValue?: string;
};
export namespace StickerApprovalStatusDto {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
    export enum verifyType {
        COMPLETE = 'COMPLETE',
        REJECT = 'REJECT',
        SUSPEND = 'SUSPEND',
    }
}

