/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContractFreightDriverDesignationDtoDriverHistory = {
    adminName?: string;
    contractFreightDriverDesignationHistoryId?: number;
    createdAt?: string;
    driverType?: ContractFreightDriverDesignationDtoDriverHistory.driverType;
};
export namespace ContractFreightDriverDesignationDtoDriverHistory {
    export enum driverType {
        DIRECT = 'DIRECT',
        FIXED = 'FIXED',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        TEMP_DRIVER = 'TEMP_DRIVER',
        UNBAN = 'UNBAN',
    }
}

