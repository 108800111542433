/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisplayTonTypes } from './DisplayTonTypes';
export type PromotionDetailResult = {
    closed: boolean;
    countType: PromotionDetailResult.countType;
    countValue: number;
    endDateTime?: string;
    priceType: PromotionDetailResult.priceType;
    priceValue: number;
    serviceType: PromotionDetailResult.serviceType;
    startDateTime?: string;
    title: string;
    tonTypes: DisplayTonTypes;
};
export namespace PromotionDetailResult {
    export enum countType {
        ONCE = 'ONCE',
        REPEAT = 'REPEAT',
        UNLIMITED = 'UNLIMITED',
    }
    export enum priceType {
        FIXED = 'FIXED',
        FREE = 'FREE',
        PERCENT = 'PERCENT',
    }
    export enum serviceType {
        DRIVER_SERVICE = 'DRIVER_SERVICE',
        OWNER_SERVICE = 'OWNER_SERVICE',
    }
}

