import { AdminInfoDto } from 'src/data'

/**
 * 사용자 interface
 */
export interface IUser {
  memberId: string
  userId: string
  userName: string
  slackId: string
  isLocked: boolean
  loginFailCount: number
  phoneNumber: string
  authorized?: boolean
  memberRoleType: AdminInfoDto.memberRoleType
  lastAccessAt?: Date
}

/**
 * 사용자
 */
export class User implements IUser {
  public memberId: string
  public userId: string
  public userName: string
  public slackId: string
  public isLocked: boolean
  public loginFailCount: number
  public phoneNumber: string
  public authorized?: boolean
  public memberRoleType: AdminInfoDto.memberRoleType
  public lastAccessAt?: Date

  constructor({
    memberId,
    userId,
    userName = '',
    slackId = '',
    isLocked = false,
    loginFailCount = 0,
    phoneNumber,
    authorized = false,
    memberRoleType = AdminInfoDto.memberRoleType.ADMIN_USER,
    lastAccessAt,
  }: Partial<IUser>) {
    this.memberId = memberId
    this.userId = userId
    this.userName = userName
    this.slackId = slackId
    this.isLocked = isLocked
    this.loginFailCount = loginFailCount
    this.phoneNumber = phoneNumber
    this.authorized = authorized
    this.memberRoleType = memberRoleType
    this.lastAccessAt = lastAccessAt
  }
}
