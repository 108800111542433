/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderJsonbPickupDropMethodDtoResult = {
    isWorkSupportRequest?: boolean;
    method?: OrderJsonbPickupDropMethodDtoResult.method;
    methodValue?: string;
};
export namespace OrderJsonbPickupDropMethodDtoResult {
    export enum method {
        CRANE = 'CRANE',
        FOLK_LIFT = 'FOLK_LIFT',
        HANDWORK = 'HANDWORK',
        HOIST = 'HOIST',
    }
}

