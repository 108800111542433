/**
 * 공통
 */
export const SEARCH_ADDRESS_QUERY = 'SearchAddressQuery'
export const ORDER_META_ALL_LIST_QUERY = 'OrderMetaAllListQuery'
export const METROPOLITAN_LIST_QUERY = 'MetropolitanListQuery'
export const FURSYS_SETTLEMENT_GROUP_QUERY = 'FursysSettlementGroupQuery'
export const SEARCH_BUSINESS_NAME_QUERY = 'SearchBusinessNameQuery'

export const RECENT_ORDER_VEHICLES_QUERY = 'Common/RecentOrderVehiclesQuery'

export const RECENT_ORDER_ITEMS_QUERY = 'Common/RecentOrderItemsQuery'

/**
 * 마이페이지
 */
export const MYPAGE_CHANGE_PASSWROD_MUTATION = 'Mypage/ChangePasswordMutation'
export const MYPAGE_UPDATE_MY_INFO_MUTATION = 'Mypage/updateMyInfo'
export const MYPAGE_ACCESS_LOG_LIST_QUERY = 'Mypage/accessLogList'

/**
 * 대시보드
 */
export const DASHBOARD_QUERY = 'DashboardQuery'
export const DASHBOARD_CURRENT_DRIVING_QUERY = 'Dashboard/CurrentDrivingQuery'
export const DASHBOARD_MONTHLY_SALES_PURCHASE_QUERY = 'Dashboard/MonthlySalesPurchaseQuery'
export const DASHBOARD_DRIVING_INDICATORS_CATEGORY_QUERY = 'Dashboard/DrivingIndicatorsCategoryQuery'
export const DASHBOARD_DAILY_COMPOSITE_INDICATORS_QUERY = 'Dashboard/DailyCompositeIndicatorsQuery'
export const DASHBOARD_WEEKLY_COMPOSITE_INDICATORS_QUERY = 'Dashboard/WeeklyCompositeIndicatorsQuery'
export const DASHBOARD_DAILY_USER_QUERY = 'Dashboard/DailyUserQuery'
export const DASHBOARD_WEEKLY_USER_QUERY = 'Dashboard/WeeklyUserQuery'
export const DASHBOARD_DASHBOARD_OWNER_CATEGORY_QUERY = 'Dashboard/DashboardOwnerCategoryQuery'
export const DASHBOARD_DRIVING_INDICATOR_DAILY_QUERY = 'Dashboard/DrivingIndicatorDailyQuery'
export const DASHBOARD_DRIVING_INDICATOR_WEEKLY_QUERY = 'Dashboard/DrivingIndicatorWeeklyQuery'
export const DASHBOARD_DRIVING_INDICATOR_DISPATCHING_QUERY = 'Dashboard/DrivingIndicatorDispatchingQuery'

/**
 * 인증
 */
export const SIGNUP_MUTATION = 'Auth/Signup'
export const LOGIN_QUERY = 'Auth/Login'
export const SMS_VERIFY_QUERY = 'Auth/Sms/Verify'
export const SMS_CONFIRM_QUERY = 'Auth/Sms/Confirm'
export const CURRENT_IP = 'Auth/currentIp'

/**
 * 화물관리
 */
export const CARGO_DRIVING_LIST_EXCEL_MUTATION = 'Cargo/DrivingStatus/ExcelMutation'
export const CARRIER_DRIVING_LIST_EXCEL_MUTATION = 'Carrier/DrivingStatus/ExcelMutation'
export const CARGO_EXTRA_INVOICE_MUTATION = 'Cargo/DrivingStatus/ExtraInvoiceMutation'
export const CARGO_EXTRA_INVOICE_SAVE_MUTATION = 'Cargo/DrivingStatus/ExtraInvoiceSaveMutation'
export const CARGO_ACCIDENT_CANCEL_MUTATION = 'Cargo/DrivingStatus/AccidentCancelMutation'
export const CARRIER_ACCIDENT_CANCEL_MUTATION = 'Carrier/DrivingStatus/AccidentCancelMutation'
export const CARGO_EXT_DATA_NETWORK_OPTION_MUTATION = 'Cargo/DrivingStatus/ExtDataNetworkOptionMutation'
export const CARGO_DRIVING_LIST_QUERY = 'Cargo/DrivingStatus/DrivingListQuery'
export const CARRIER_DRIVING_LIST_QUERY = 'Carrier/DrivingStatus/DrivingListQuery'
export const CARGO_ACCIDENT_CANCEL_QUERY = 'Cargo/DrivingStatus/AccidentCancelQuery'
export const CARRIER_ACCIDENT_CANCEL_QUERY = 'Carrier/DrivingStatus/AccidentCancelQuery'
export const CARGO_ACCIDENT_INVOICE_QUERY = 'Cargo/DrivingStatus/AccidentInvoiceQuery'
export const CHANGE_ORDER_STATUS = 'Cargo/DrivingStatus/ChangeOrderStatus'
export const PARTNER_CHANGE_ORDER_STATUS = 'Carrier/DrivingStatus/ChangeOrderStatus'
export const CHANGE_ORDER_COMPLETE_STATUS = 'Cargo/DrivingStatus/ChangeOrderCompleteStatus'
export const PARTNER_CHANGE_ORDER_COMPLETE_STATUS = 'Carrier/DrivingStatus/ChangeOrderCompleteStatus'
export const ROLLBACK_ORDER_STATUS = 'Cargo/DrivingStatus/RollbackOrderStatus'
export const CHANGE_WARE_HOUSE_STATUS = 'Cargo/DrivingStatus/ChangeWareHouseStatus'

export const CARGO_DISPATCH_MUTATION = 'Cargo/MatchingStatus/DispatchMutation'
export const CARGO_DISPATCH_BIND_MUTATION = 'Cargo/MatchingStatus/DispatchBindMutation'
export const CARGO_DISPATCH_CANCEL_MUTATION = 'Cargo/MatchingStatus/DispatchCancelMutation'
export const CARGO_MATCHING_LIST_QUERY = 'Cargo/MatchingStatus/MatchingListQuery'
export const CARGO_MATCHING_LIST_CHECK_DUPLICATE_USERID_MUTATION =
  'Cargo/MatchingStatus/DriverCheckDuplicateUserIdMutation'
export const CARGO_MATCHING_LIST_CONFIRM_BUSINESS_LICENSE_MUTATION =
  'Cargo/MatchingStatus/DriverConfirmBusinessLicenseMutation'
export const CARGO_MATCHING_LIST_REGIST_MUTATION = 'Cargo/MatchingStatus/DriverRegistMutation'
export const EXTEND_DISPATCH_FAIL_TIME_MUTATION = 'Cargo/MatchingStatus/ExtendDispatchFailTimeMutation'
export const CARGO_PREFER_DRIVING_AREA_QUERY = 'Cargo/PreferDrivingArea/PreferDrivingAreaQuery'
export const CARGO_PREFER_DRIVING_AREA_EXCEL_MUTATION = 'Cargo/PreferDrivingArea/PreferDrivingAreaExcelMutation'
export const OWNER_DIRECT_CAR_QUERY = 'Cargo/OwnerDirectCar/OwnerDirectCarQuery'
export const OWNER_DIRECT_CAR_EXCEL_MUTATION = 'Cargo/OwnerDirectCar/OwnerDirectCarExcelMutation'
export const CARGO_OWNER_SEARCH_QUERY = 'Cargo/OwnerSearchQuery'
export const PARTNER_CAR_OWNER_SEARCH_QUERY_QUERY = 'Cargo/PartnerCarOwnerSearchQuery'
export const PARTNER_CAR_OWNER_SINGUP_MUTATION = 'Cargo/PartnerCarOwnerSearchQuery'
export const PRE_EXTRA_INFOS_QUERY = 'Cargo/PreExtraInfosQuery'
export const EXTRA_TARGET_OPTIONS_QUERY = 'Cargo/ExtraTargetOptionsQuery'
export const SAVE_DRAFT_EXTRA_MUTATION = 'Cargo/SaveDraftExtraMutation'
export const EXTRA_HISTORY_QUERY = 'Cargo/ExtraHistoryQuery'
export const EXTRA_HISTORY_CREATED_AT_QUERY = 'Cargo/ExtraHistoryCreatedAtQuery'
export const CARGO_OWNER_ADD_LIST_QUERY = 'Cargo/MatchingStatus/OwnerAddListQuery'
export const CARGO_MATCHING_LIST_EXCEL_DOWN_MUTATION = 'Cargo/MatchingStatus/ExcelDownMutation'

/**
 * 주문 상세
 */

export const ORDER_DETAIL_DISPATCH_OWNER_CANCEL_MUTATION = 'OrderDetail/DispatchOwnerCancelMutation'
export const ORDER_DETAIL_DISPATCH_COMPLETED_MUTATION = 'OrderDetail/DispatchCompletedMutation'
export const ORDER_DETAIL_ORDER_CANCEL_MUTATION = 'OrderDetail/OrderCancelMutation'
export const PARTNER_ORDER_DETAIL_ORDER_CANCEL_MUTATION = 'PartnerOrderDetail/OrderCancelMutation'
export const ORDER_DETAIL_EXTRA_INVOICE_HISTORY_MUTATION = 'OrderDetail/ExtraInvoiceHistoryMutation'
export const ORDER_DETAIL_QUERY = 'OrderDetail/OrderDetailQuery'
export const PARTNER_ORDER_DETAIL_QUERY = 'PartnerOrderDetail/OrderDetailQuery'
export const ORDER_DETAIL_FOR_SELECTED_DRIVER_QUERY = 'OrderDetail/OrderDetailForSelectedDriverQuery'
export const ORDER_DETAIL_DRIVER_LOCATION_QUERY = 'OrderDetail/DriverLocationQuery'
export const ORDER_DETAIL_EXTRA_INVOICE_CREATED_AT_QUERY = 'OrderDetail/ExtraInvoiceCreatedAtQuery'
export const ORDER_DETAIL_ROUTE_DETAIL_QUERY = 'OrderDetail/useExpectRouteDetailQuery'
export const ORDER_DETAIL_SETTLEMENT_GROUP_QUERY = 'OrderDetail/useSettlementGroupQuery'
export const ORDER_DETAIL_UPDATE_BUSINESS_SETTLEMENT_GROUP_MUTATION = 'OrderDetail/useBusinessSettlementGroupMutation'
export const ORDER_DETAIL_UPDATE_VEHICLE_INFO_MUTATION = 'OrderDetail/updateVehicleInfoMutation'
export const ORDER_DETAIL_RANGE_INVOICE_INFO_QUERY = 'OrderDetail/rangeInvoiceQuery'
export const ORDER_DETAIL_UPDATE_MODIFY_INVOICE_INFO_MUTATION = 'OrderDetail/updateModifyInvoiceMutation'
export const ORDER_DETAIL_EDITED_ORDER_HISTORY_DATES_QUERY = 'OrderDetail/getEditedOrderHistoryDatesQuery'
export const ORDER_DETAIL_EDITED_ORDER_HISTORY_QUERY = 'OrderDetail/getEditedOrderHistoryQuery'
export const ORDER_REQUEST_DISPATCH_ESTIMATE_MUTATION = 'OrderDetail/requestDispatchEstimateMutation'
export const ORDER_REQUEST_DISPATCH_QUERY = 'OrderDetail/requestDispatchQuery'
export const ORDER_DETAIL_RECEIPT_INFO_QUERY = 'OrderDetail/getReceiptInfoQuery'
export const ORDER_DETAIL_UPDATE_RECEIPT_INFO_MUTATION = 'OrderDetail/updateReceiptInfoMutation'
export const ORDER_DETAIL_SEND_ORDER_RECEIPT_SMS_MUTATION = 'OrderDetail/sendOrderReceiptSms'
export const ORDER_DETAIL_MEMO_QUERY = 'OrderDetail/getMemoInfoQuery'
export const ORDER_DETAIL_MEMO_SAVE_MUTATION = 'OrderDetail/saveMemoInfoMutation'

/**
 * 주문 정보 수정
 */
export const ORDER_EDIT_DETAIL_QUERY = 'OrderEdit/OrderDetailQuery'
export const ORDER_EDIT_ESTIMATE_ROUTING_PRICE_MUTATION = 'OrderEdit/OrderEstimateRoutingPriceMutation'
export const ORDER_EDIT_SAVE_AFTER_DISPATCH_COMPLETE_MUTATION = 'OrderEdit/SaveAfterDispatchCompleteMutation'

/**
 * 회원관리
 */
export const MEMBERSHIP_IMAGE_SAVE_MUTATION = 'MemberShip/ImageSaveMutation'
export const MEMBERSHIP_REGFILE_ROTATION_MUTATION = 'MemberShip/RegFileRotationMutation'
export const MEMBERSHIP_REGFILE_UPDATE_MUTATION = 'MemberShip/RegFileUpdateMutation'
export const MEMBERSHIP_REGFILE_UPLOAD_MUTATION = 'MemberShip/RegFileUploadMutation'
export const MEMBERSHIP_IMAGE_QUERY = 'MemberShip/ImageQuery'

export const MEMBERSHIP_BUSINESS_GROUP_OPTIONS_QUERY = 'MemberShip/BusinessGroupOptionsQuery'

export const MEMBERSHIP_SAVE_BUSINESS_LICENSE_MUTATION = 'MemberShip/NewUser/SaveBusinessLicenseMutation'
export const MEMBERSHIP_SAVE_CARGO_TRANS_CERTIFICATE_MUTATION = 'MemberShip/NewUser/SaveCargoTransCertificateMutation'
export const MEMBERSHIP_SAVE_DRIVER_LICENSE_MUTATION = 'MemberShip/NewUser/SaveDriverLicenseMutation'
export const MEMBERSHIP_SAVE_BANKBOOK_COPY_MUTATION = 'MemberShip/NewUser/SaveCopyOfBankBookMutation'
export const MEMBERSHIP_SAVE_INSURANCE_MUTATION = 'MemberShip/NewUser/SaveInsuranceMutation'
export const MEMBERSHIP_SAVE_APPROVAL_MUTATION = 'MemberShip/NewUser/SaveApprovalMutation'
export const MEMBERSHIP_NEW_PROVISIONARY_DRIVER_LIST_EXCEL_MUTATION = 'MemberShip/NewUser/ProvDriverListExcelMutation'
export const MEMBERSHIP_NEW_DRIVER_LIST_EXCEL_MUTATION = 'MemberShip/NewUser/DriverListExcelMutation'
export const MEMBERSHIP_NEW_OWNER_LIST_EXCEL_MUTATION = 'MemberShip/NewUser/OwnerListExcelMutation'
export const MEMBERSHIP_NEW_PARTNER_LIST_EXCEL_MUTATION = 'MemberShip/NewUser/PartnerListExcelMutation'
export const MEMBERSHIP_NEW_DRIVER_QUERY = 'MemberShip/NewUser/DriverQuery'
export const MEMBERSHIP_NEW_DRIVER_LIST_QUERY = 'MemberShip/NewUser/DriverListQuery'
export const MEMBERSHIP_DRIVER_SEND_TEMP_PASSWORD = 'MemberShip/Driver/SendTempPassword'
export const MEMBERSHIP_NEW_PROVISIONARY_DRIVER_LIST_QUERY = 'MemberShip/NewUser/ProvDriverListQuery'
export const MEMBERSHIP_NEW_OWNER_QUERY = 'MemberShip/NewUser/OwnerQuery'
export const MEMBERSHIP_NEW_OWNER_LIST_QUERY = 'MemberShip/NewUser/OwnerListQuery'

export const MEMBERSHIP_NEW_PARTNER_LIST_QUERY = 'MemberShip/NewUser/PartnerListQuery'
export const MEMBERSHIP_APPROVAL_STATUS_MESSAGE_QUERY = 'MemberShip/NewUser/ApprovalStatusMessageQuery'
export const MEMBERSHIP_NEW_DRIVER_CHECK_DUPLICATE_USERID_MUTATION =
  'MemberShip/NewUser/DriverCheckDuplicateUserIdMutation'
export const MEMBERSHIP_NEW_DRIVER_CONFIRM_BUSINESS_LICENSE_MUTATION =
  'MemberShip/NewUser/DriverConfirmBusinessLicenseMutation'
export const MEMBERSHIP_NEW_DRIVER_REGIST_MUTATION = 'MemberShip/NewUser/DriverRegistMutation'
export const MEMBERSHIP_NEW_PROVISIONARY_DRIVER_SEND_SMS_MUTATION = 'MemberShip/NewUser/ProvDriverSendSmsMutation'

export const MEMBERSHIP_DRIVER_LIST_EXCEL_MUTATION = 'MemberShip/User/DriverListExcelMutation'
export const MEMBERSHIP_TEMP_DRIVER_LIST_EXCEL_MUTATION = 'MemberShip/User/TempDriverListExcelMutation'
export const MEMBERSHIP_OWNER_LIST_EXCEL_MUTATION = 'MemberShip/User/OwnerListExcelMutation'

export const MEMBERSHIP_PARTNER_LIST_EXCEL_MUTATION = 'MemberShip/User/PartnerListExcelMutation'
export const MEMBERSHIP_DETAIL_DRIVER_QUERY = 'MemberShip/User/DetailDriverUserQuery'
export const MEMBERSHIP_DETAIL_OWNER_QUERY = 'MemberShip/User/DetailOwnerUserQuery'
export const MEMBERSHIP_FIXED_DRIVER_INFO = 'MemberShip/User/FixedDriverInfoQuery'
export const REGULAR_DRIVER_USER_QUERY = 'MemberShip/User/RegularDriverUserQuery'
export const MEMBERSHIP_DRIVER_QUERY = 'MemberShip/User/DriverUserQuery'
export const MEMBERSHIP_TEMP_DRIVER_QUERY = 'MemberShip/User/TempDriverUserQuery'
export const MEMBERSHIP_OWNER_QUERY = 'MemberShip/User/OwnerUserQuery'

export const MEMBERSHIP_PARTNER_QUERY = 'MemberShip/User/PartnerUserQuery'
export const MEMBERSHIP_PARTNER_DETAIL_QUERY = 'MemberShip/User/PartnerDetailUserQuery'
export const MEMBERSHIP_PARTNER_SAVE_APPROVAL_MUTATION = 'MemberShip/User/PartnerApprovalSaveMutation'
export const SAVE_FIXED_DRIVER_USER_MUTATION = 'MemberShip/User/SaveFixedDriverUserMutation'
export const DELETE_FIXED_DRIVER_USER_MUTATION = 'MemberShip/User/DeleteFixedDriverUserMutation'
export const MEMBERSHIP_EDIT_MODAL_GET_OWNER_QUERY = 'MemberShip/User/getOwnerUserQuery'
export const MEMBERSHIP_EDIT_MODAL_GET_DRIVER_QUERY = 'MemberShip/User/getDriverUserQuery'
export const MEMBERSHIP_EDIT_MODAL_SAVE_OWNER_QUERY = 'MemberShip/User/saveOwnerUserQuery'
export const MEMBERSHIP_EDIT_MODAL_SAVE_DRIVER_QUERY = 'MemberShip/User/saveDriverUserQuery'
export const MEMBERSHIP_EDIT_MODAL_SAVE_FREIGHT_QUERY = 'MemberShip/User/saveFreightUserQuery'
export const OWNER_OPTION_SETTING_QUERY = 'MemberShip/User/OwnerOptionSettingQuery'
export const SAVE_OWNER_OPTION_SETTING_MUTATION = 'MemberShip/User/SaveOwnerOptionSettingMutation'
export const FREIGHT_GROUP_DRIVER_LIST_QUERY = 'MemberShip/User/FreightGroupSettingDriverListQuery'
export const FREIGHT_GROUP_SETTING_QUERY = 'MemberShip/User/FreightGroupSettingQuery'
export const SAVE_FREIGHT_GROUP_SETTING_MUTATION = 'MemberShip/User/SaveFreightGroupSettingMutation'
export const GET_OWNER_MEMO_QUERY = 'MemberShip/User/GetOwnerMemoQuery'
export const SAVE_OWNER_MEMO_MUTATION = 'MemberShip/User/SaveOwnerMemoMutation'
export const DELETE_OWNER_MEMO_MUTATION = 'MemberShip/User/DeleteOwnerMemoMutation'

export const MEMBERSHIP_CREDIT_TRANS_UPDATE_STATUS_MUTATION = 'Membership/UpdateStatusMutation'
export const MEMBERSHIP_CREDIT_TRANS_STATUS_QUERY = 'Membership/CreditTransStatusQuery'

export const MEMBERSHIP_REQUEST_MDM_CODE_MUTATION = 'Membership/RequestMdmCodeMutation'
export const MEMBERSHIP_E_EVIDENCE_AUTO_UPLOAD_MUTATION = 'MemberShip/NewUser/EEvidenceAutoUploadMutation'
export const MEMBERSHIP_BATCH_UPDATE_WAIT_MUTATION = 'MemberShip/NewUser/BatchUpdateWaitMutation'
export const MEMBERSHIP_BATCH_UPDATE_WAIT_INSURANCE_MUTATION = 'MemberShip/NewUser/BatchUpdateWaitInsuranceMutation'

export const MEMBERSHIP_DORMANT_OWNER_QUERY = 'MemberShip/DormantUser/OwnerUserQuery'
export const MEMBERSHIP_DORMANT_DRIVER_QUERY = 'MemberShip/DormantUser/DriverUserQuery'
export const MEMBERSHIP_DORMANT_OWNER_EXCEL_QUERY = 'MemberShip/DormantUser/OwnerUserExcelMutation'
export const MEMBERSHIP_DORMANT_DRIVER_EXCEL_QUERY = 'MemberShip/DormantUser/DriverUserExcelMutation'

export const MEMBERSHIP_WITHDRAWAL_USER_QUERY = 'Membership/WithDrawal/UserQuery'
export const MEMBERSHIP_WITHDRAWAL_USER_EXCEL_QUERY = 'Membership/WithDrawal/UserQuery/Excel'
export const MEMBERSHIP_WITHDRAWAL_MUTATION = 'Membership/WithDrawalMutation'
export const MEMBERSHIP_WITHDRAWAL_OWNER_USER_QUERY = 'Membership/WithDrawal/owner/UserQuery'
export const MEMBERSHIP_WITHDRAWAL_OWNER_USER_EXCEL_QUERY = 'Membership/WithDrawal/owner/UserQuery/Excel'
export const MEMBERSHIP_WITHDRAWAL_DRIVER_USER_QUERY = 'Membership/WithDrawal/driver/UserQuery'
export const MEMBERSHIP_WITHDRAWAL_DRIVER_USER_EXCEL_QUERY = 'Membership/WithDrawal/driver/UserQuery/Excel'
export const MEMBERSHIP_ACCOUNT_CHECK_QUERY = 'Membership/account/check'

export const MEMBERSHIP_REG_HISTORY_QUERY = 'MemberShip/User/RegHistoryQuery'
export const SAVE_BUSINESS_LICENSE_FOR_OWNER_MUTATION = 'MemberShip/User/SaveBusinessLicenseForOwnerMutation'
export const RETRY_MDM_CODE_MUTATION = 'MemberShip/User/RetryMdmCodeMutation'

export const MEMBERSHIP_CREDIT_DETAIL_AVAILABLE_LIST = 'Membership/CreditDetailAvailableList'
export const MEMBERSHIP_CREDIT_DETAIL_AVAILABLE_EXCEL = 'Membership/CreditDetailAvailableExcel'
export const MEMBERSHIP_CREDIT_DETAIL_UNAVAILABLE_LIST = 'Membership/CreditDetailUnavailableList'
export const MEMBERSHIP_CREDIT_DETAIL_INFO = 'Membership/CreditDetailInfo'
export const MEMBERSHIP_CREDIT_DETAIL_ACCOUNT_RECEIVABLE_MUTATION = 'Membership/CreditDetailAccountReceivableMutation'
export const MEMBERSHIP_CREDIT_DETAIL_FINANCIAL_STATEMENT_MUTATION = 'Membership/CreditDetailFinancialStatementMutation'
export const MEMBERSHIP_CREDIT_DETAIL_CURRENT_MONTH_INFO_MUTATION = 'Membership/CreditDetailCurrentMonthInfoMutation'
export const OPEN_NOW_MUTATION = 'Membership/OpenNowMutation'
export const CONSIGNMENT_VEHICLE_LIST_QUERY = 'Membership/ConsignmentVehicle/ListQuery'
export const CONSIGNMENT_VEHICLE_LIST_EXCEL_MUTATION = 'Membership/ConsignmentVehicle/ListExcelMutation'
export const CONSIGNMENT_VEHICLE_SEARCH_QUERY = 'Membership/ConsignmentVehicle/SearchQuery'
export const CONSIGNMENT_VEHICLE_DELETE_MUTATION = 'Membership/ConsignmentVehicle/DeleteMutation'
export const CONSIGNMENT_VEHICLE_REGISTER_MUTATION = 'Membership/ConsignmentVehicle/RegisterMutation'
export const CONSIGNMENT_VEHICLE_UPDATE_MUTATION = 'Membership/ConsignmentVehicle/UpdateMutation'

export const MEMBERSHIP_PARTNER_MATCHED_OWNER_QUERY = 'Membership/partner/MatchedOwner/Query'
export const MEMBERSHIP_PARTNER_MATCHING_OWNER_SEARCH = 'Membership/partner/MatchingOwner/SearchQuery'
export const MEMBERSHIP_PARTNER_MATCHING_OWNER_MUTATION = 'Membership/partner/MatchingOwner/SetMutation'
export const MEMBERSHIP_PARTNER_MATCHING_CANCEL_OWNER_MUTATION = 'Membership/partner/MatchingOwner/CancelMutation'

/**
 * 계약물류
 */
export const CONTRACT_LOGISTICS_FARE_QUERY = 'ContractLogistics/FareQuery'
export const CONTRACT_LOGISTICS_FARE_DETAIL_QUERY = 'ContractLogistics/FareDetailQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_QUERY = 'ContractLogistics/SalesFareQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_INFO_QUERY = 'ContractLogistics/SalesFareInfoQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_DETAIL_QUERY = 'ContractLogistics/SalesFareDetailQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_HISTORY_QUERY = 'ContractLogistics/SalesFareHistoryQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_HISTORY_DETAIL_QUERY = 'ContractLogistics/SalesFareHistoryDetailQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_EDIT_QUERY = 'ContractLogistics/SalesFareEditQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_QUERY = 'ContractLogistics/PurchaseFareQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_INFO_QUERY = 'ContractLogistics/PurchaseFareInfoQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_DETAIL_QUERY = 'ContractLogistics/PurchaseFareDetailQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_HISTORY_QUERY = 'ContractLogistics/PurchaseFareHistoryQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_HISTORY_DETAIL_QUERY = 'ContractLogistics/PurchaseFareHistoryDetailQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_EDIT_QUERY = 'ContractLogistics/PurchaseFareEditQuery'
export const CONTRACT_LOGISTICS_FOUR_LEVEL_ADDRESS_QUERY = 'ContractLogistics/FourLevelAddressQuery'
export const CONTRACT_LOGISTICS_EXCEL_VERIFY_MUTATION = 'ContractLogistics/ExcelVerifyMutation'
export const CONTRACT_LOGISTICS_EXCEL_VERIFY_CANCEL_MUTATION = 'ContractLogistics/ExcelVerifyCancelMutation'
export const CONTRACT_LOGISTICS_EXCEL_VERIFY_STATUS_QUERY = 'ContractLogistics/ExcelVerifyStatusQuery'
export const CONTRACT_LOGISTICS_EXCEL_UPLOAD_MUTATION = 'ContractLogistics/ExcelUploadMutation'
export const PURCHASE_FARE_VERIFICATION_MUTATION = 'ContractLogistics/PurchaseFareVerification'
export const SALES_FARE_VERIFICATION_MUTATION = 'ContractLogistics/SalesFareVerification'
export const PURCHASE_FARE_CREATE_MUTATION = 'ContractLogistics/PurchaseFareCreate'
export const SALES_FARE_CREATE_MUTATION = 'ContractLogistics/SalesFareCreate'
export const PURCHASE_FARE_UPDATE_MUTATION = 'ContractLogistics/PurchaseFareUpdate'
export const SALES_FARE_UPDATE_MUTATION = 'ContractLogistics/SalesFareUpdate'
export const APPLY_FREIGHT_DATE_MUTATION = 'ContractLogistics/ApplyFreightDateMutation'
export const DRIVER_APPLY_FREIGHT_DATE_MUTATION = 'ContractLogistics/DriverApplyFreightDateMutation'
export const OWNER_HISTORY_USER_QUERY = 'ContractLogistics/OwnerHistoryUserQuery'
export const DRIVER_HISTORY_USER_QUERY = 'ContractLogistics/DriverHistoryUserQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_TARGET_QUERY = 'ContractLogistics/PurchaseFareTargetQuery'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_TARGET_MUTATION = 'ContractLogistics/PurchaseFareTargetHistoryMutation'
export const CONTRACT_LOGISTICS_PURCHASE_FARE_TARGET_HISTORY_QUERY = 'ContractLogistics/PurchaseFareTargetHistoryQuery'
export const CONTRACT_LOGISTICS_SALES_FARE_EDIT_MUTATION = 'ContractLogistics/SalesFareEditMutation'
export const CONTRACT_LOGISTICS_SALES_FARE_EDIT_DISABLE_MUTATION = 'ContractLogistics/SalesFareEditDisableMutation'
export const CONTRACT_LOGISTICS_SALES_FARE_EDIT_LIST_QUERY = 'ContractLogistics/SalesFareEditListQuery'

/**
 * 정산관리
 */
export const SETTLEMENT_PG_EXCEL_MUTATION = 'Settlement/PgExcelMutation'
export const SETTLEMENT_QUICKPAY_EXCEL_MUTATION = 'Settlement/QuickpayExcelMutation'
export const SETTLEMENT_PG_PAYMENT_EXCEL_MUTATION = 'Settlement/PaymentExcelMutation'
export const SETTLEMENT_QUICKPAY_FAIL_REAPPLY_MUTATION = 'Settlement/QuickpayFailReapplyMutation'
export const SETTLEMENT_BANKBOOK_COPY_MUTATION = 'Settlement/CopyOfBankBookMutation'
export const SETTLEMENT_QUICKPAY_REQUEST_AGAIN_MUTATION = 'Settlement/QuickpayRequestAgainMutation'
export const SETTLEMENT_TAX_BILL_REQUEST_AGAIN_MUTATION = 'Settlement/TaxBillRequestAgainMutation'
export const SETTLEMENT_MONTHLY_PAYMENT_ISSUE_MUTATION = 'Settlement/MonthlyPaymentIssueMutation'
export const SETTLEMENT_QUICKPAY_HISTORY_EXCEL_MUTATION = 'Settlement/QuickpayHistoryExcelMutation'
export const SETTLEMENT_SALES_PURCHASE_STATUS_EXCEL_MUTATION = 'Settlement/SalesPurchaseStatusExcelMutation'
export const SETTLEMENT_PURCHASE_STATUS_EXCEL_MUTATION = 'Settlement/PurchaseStatusExcelMutation'
export const SETTLEMENT_PURCHASE_SLIP_SEND_MUTATION = 'Settlement/PurchaseSlipSendMutation'
export const SETTLEMENT_SALES_STATUS_EXCEL_MUTATION = 'Settlement/SalesStatusExcelMutation'
export const SETTLEMENT_SALES_SAVE_MUTATION = 'Settlement/SalesSaveMutation'
export const SETTLEMENT_PAYMENT_QUERY = 'Settlement/PaymentQuery'
export const SETTLEMENT_SALES_TOTAL_AMOUNT_QUERY = 'Settlement/SalesTotalAmountQuery'
export const SETTLEMENT_QUICKPAY_QUERY = 'Settlement/QuickpayQuery'
export const SETTLEMENT_PURCHASE_TOTAL_AMOUNT_QUERY = 'Settlement/PurchaseTotalAmountQuery'
export const SETTLEMENT_PG_PAYMENT_QUERY = 'Settlement/PgPaymentQuery'
export const SETTLEMENT_PG_PAYMENT_FAIL_QUERY = 'Settlement/PgPaymentFailQuery'
export const SETTLEMENT_DRIVER_WAITING_QUERY = 'Settlement/DriverWaitingQuery'
export const SETTLEMENT_QUICKPAY_PAYMENT_FAIL_QUERY = 'Settlement/QuickpayPaymentFailQuery'
export const SETTLEMENT_QUICKPAY_EDIT_HISTORY_QUERY = 'Settlement/EditHistoryQuery'
export const SETTLEMENT_QUICKPAY_REQUEST_QUERY = 'Settlement/QuickpayRequestQuery'
export const SETTLEMENT_TAX_BILL_QUERY = 'Settlement/TaxBillQuery'
export const SETTLEMENT_QUICKPAY_HISTORY_QUERY = 'Settlement/QuickpayHistoryQuery'
export const SETTLEMENT_SALES_PURCHASE_STATUS_QUERY = 'Settlement/SalesPurchaseStatusQuery'
export const SETTLEMENT_PURCHASE_STATUS_QUERY = 'Settlement/PurchaseStatusQuery'
export const SETTLEMENT_SALES_STATUS_QUERY = 'Settlement/SalesStatusQuery'
export const SETTLEMENT_SALES_SAP_GROUP_QUERY = 'Settlement/SapSalesSettlementGroups'
export const SETTLEMENT_CREDIT_TRANSACTION_STATUS_QUERY = 'Settlement/CreditTransactionQuery'
export const SETTLEMENT_CREDIT_TRANSACTION_STATUS_EXCEL_MUTATION = 'Settlement/CreditTransactionExcelMutation'
export const SETTLEMENT_CREDIT_TRANSACTION_BILL_NO_MUTATION = 'Settlement/CreditTransactionBillNoMutation'
export const SETTLEMENT_CREDIT_TRANSACTION_COMPLETE_MUTATION = 'Settlement/CreditTransactionBillNoMutation'
export const SETTLEMENT_CALC_OIL_PRICE_MUTATION = 'Settlement/SapSettlementCalcOilAmountMutation'
export const SETTLEMENT_UPDATE_OIL_PRICE_MUTATION = 'Settlement/SapSettlementUpdateOilAmountMutation'
export const SETTLEMENT_CANCEL_GROUPS_MUTATION = 'Settlement/SapSettlementCancelGroupsMutation'
export const SETTLEMENT_SEND_CREDIT_PAYMENT_ISSUE_MUTATION = 'Settlement/SendCreditPaymentIssueDocumentsMutation'
export const SETTLEMENT_CREDIT_TRANSACTION_DETAIL_QUERY = 'Settlement/CreditTransactionDetailQuery'
export const SETTLEMENT_CREDIT_TRANSACTION_DETAIL_SHIPPING_LIST_QUERY =
  'Settlement/CreditTransactionDetail/ShippingListQuery'
export const SETTLEMENT_CREDIT_TRANSACTION_DETAIL_SHIPPING_LIST_EXCEL_DOWNLOAD_MUTATION =
  'Settlement/CreditTransactionDetail/ShippingListExcelDownloadMutation'
export const SETTLEMENT_CONTRACT_LOGISTICS_ADD_COST_LIST_QUERY = 'Settlement/ContractLogisticsAddCostListQuery'
export const SETTLEMENT_CONTRACT_LOGISTICS_SET_CONFIRM_QUERY = 'Settlement/ContractLogisticsSetConfirmQuery'

export const SETTLEMENT_CONTRACT_LOGISTICS_SALES_CONTRACT_INVOICE_ITEM_LIST_QUERY =
  'Settlement/ContractLogisticsSalesContractInvoiceItemListQuery'
export const SETTLEMENT_CONTRACT_LOGISTICS_ADD_COST_LIST_FOR_EXCEL_MUTATION =
  'Settlement/ContractLogisticsAddCostListForExcelMutation'
export const SETTLEMENT_CONTRACT_EXTRA_FEE_MODIFY_MUTATION = 'Settlement/ContractExtraFeeModifyMutation'
export const SETTLEMENT_UPLOAD_EXTRA_FEE_MODIFY_QUERY = 'Settlement/UploadExtraFeeModifyQuery'
export const SETTLEMENT_NOT_ISSUE_TAX_INVOICE_SEARCH_QUERY = 'Settlement/NotIssueTaxInvoiceSearchQuery'
export const SETTLEMENT_SAVE_BILL_NO_MUTATION = 'Settlement/SaveBillNoyMutation'
export const SETTLEMENT_CHECK_BILL_NO_MUTATION = 'Settlement/CheckBillNoyMutation'
export const SETTLEMENT_SAVE_DEFINE_DATE_MUTATION = 'Settlement/SaveDefineDateMutation'
export const SETTLEMENT_SEND_SAP_PURCHASE_ISSUE_NO_MUTATION = 'Settlement/SendSapPurchaseIssueNoeMutation'
export const SETTLEMENT_BATCH_SAP_PURCHASE_ISSUE_NO_MUTATION = 'Settlement/BatchSapPurchaseIssueNoeMutation'
export const SETTLEMENT_DOWNLOAD_EXCEL_PURCHASE_ISSUE_NO_MUTATION = 'Settlement/DownloadExcelPurchaseIssueNoeMutation'
export const SETTLEMENT_GROUP_LIST_QUERY = 'Settlement/SettlementGroup'
export const SETTLEMENT_GROUP_LIST_DETAIL_QUERY = 'Settlement/SettlementGroupDetail'

/**
 * 차량관제
 */
export const DRIVING_CURRENT_DRIVER_POS_LIST_QUERY = 'Driving/CurrentDriverPosListQuery'
export const DRIVING_CURRENT_DRIVER_INFO_LIST_QUERY = 'Driving/CurrentDriverInfoListQuery'
export const DRIVING_DETAIL_QUERY = 'Driving/DetailQuery'
export const DRIVING_GET_DRIVER_LOCATION_QUERY = 'Driving/GetDriverLocationQuery'
export const DRIVING_SET_LOCATION_LOGS_SAVE_QUERY = 'Driving/SetLocationLogsSave'

/**
 * 최적운임
 */
export const ESTIMATED_FARE_OPTIONS_LIST_QUERY = 'EstimatedFareOption/ListQuery'
export const ESTIMATED_FARE_OPTIONS_HISTORY_LIST_QUERY = 'EstimatedFareOption/HistoryListQuery'

/**
 * 프로모션
 */
export const PROMOTION_SETTING_LIST_QUERY = 'Promotion/SettingsListQuery'
export const PROMOTION_SETTING_DELETE_MUTATION = 'Promotion/SettingsDeleteMutation'
export const PROMOTION_SETTING_DETAIL_QUERY = 'Promotion/SettingsDetailQuery'
export const PROMOTION_SETTING_DETAIL_HISTORY_QUERY = 'Promotion/SettingsDetailHistoryQuery'
export const PROMOTION_SETTING_UPDATE_MUTATION = 'Promotion/SettingsUpdateMutation'
export const PROMOTION_SETTING_CLOSE_MUTATION = 'Promotion/SettingsCloseMutation'
export const PROMOTION_SETTING_SAVE_MUTATION = 'Promotion/SettingsSaveMutation'
export const PROMOTION_SETTING_MEMBER_GROUP_SAVE_MUTATION = 'Promotion/SettingsMemberGroupSaveMutation'
export const PROMOTION_SETTING_MEMBER_GROUP_LIST_QUERY = 'Promotion/SettingsMemberGroupListQuery'
export const PROMOTION_SETTING_MEMBER_GROUP_NAME_QUERY = 'Promotion/SettingsMemberGroupNameQuery'
export const PROMOTION_OWNER_PROMOTION_POPUP_QUERY = 'Promotion/OwnerPromotionPopupQuery'
export const PROMOTION_OWNER_PROMOTION_GROUP_QUERY = 'Promotion/OwnerPromotionGroupQuery'
export const PROMOTION_OWNER_PROMOTION_PROMOTION_QUERY = 'Promotion/OwnerPromotionPromotionQuery'
export const PROMOTION_DRIVER_PROMOTION_POPUP_QUERY = 'Promotion/DriverPromotionPopupQuery'
export const PROMOTION_DRIVER_PROMOTION_GROUP_QUERY = 'Promotion/DriverPromotionGroupQuery'
export const PROMOTION_DRIVER_POPUP_QUERY = 'Promotion/DriverPopupQuery'
export const PROMOTION_POPUP_DETAIL_QUERY = 'Promotion/PromotionPopupDetailQuery'
export const PROMOTION_POPUP_CREATE_POPUP = 'Promotion/PromotionCreatePopupMutation'
export const PROMOTION_DRIVER_POPUP_SAVE_POPUP = 'Promotion/PromotionSavePopupMutation'
export const PROMOTION_DRIVER_POPUP_DETAIL_POPUP = 'Promotion/PromotionDetailPopupMutation'
export const PROMOTION_OWNER_PROMOTION_POPUP_DELETE_MUTATION = 'Promotion/OwnerPromotionPopupDeleteMutation'
export const PROMOTION_DRIVER_PROMOTION_POPUP_UPDATE_MUTATION = 'Promotion/DriverPromotionPopupUpdateMutation'
export const PROMOTION_OWNER_SAVE_POPUP_MUTATION = 'Promotion/OwnerSavePopupMutation'
export const PROMOTION_OWNER_SAVE_PROMOTION_MUTATION = 'Promotion/OwnerSavePromotionMutation'
export const PROMOTION_DRIVER_SAVE_POPUP_MUTATION = 'Promotion/DriverSavePopupMutation'
export const BIG_STICKER_DRIVER_INFO_QUERY = 'Promotion/BigStickerDriverInfoQuery'
export const BIG_STICKER_UPLOAD_IMAGE_MUTATION = 'Promotion/BigStickerUploadImageMutation'
export const BIG_STICKER_DOWNLOAD_IMAGE_MUTATION = 'Promotion/BigStickerDownloadImageMutation'
export const BIG_STICKER_GET_VERIFICATION_HISTORY_LIST_QUERY = 'Promotion/BigStickerVerificationHistoryListQuery'
export const BIG_STICKER_GET_IMAGES_DATA_QUERY = 'Promotion/BigStickerImagesDataQuery'
export const BIG_STICKER_GET_MONTHLY_HISTORY_LIST_QUERY = 'Promotion/BigStickerMonthlyHistoryListQuery'
export const BIG_STICKER_GET_STATUS_HISTORY_LIST_QUERY = 'Promotion/BigStickerStatusHistoryListQuery'
export const BIG_STICKER_GET_LIST = 'Promotion/BigStickerListQuery'
export const BIG_STICKER_GET_EXCEL_LIST = 'Promotion/BigStickerExcelListQuery'
export const BIG_STICKER_UPLOAD_CONTRACT_MUTATION = 'Promotion/BigStickerUploadContractMutation'
export const BIG_STICKER_CALC_QUERY = 'Promotion/BigStickerCalcQuery'
export const BIG_STICKER_EDIT_MUTATION = 'Promotion/BigStickerCalcEditMutation'
export const BIG_STICKER_INVOICE_MONTHLY_MUTATION = 'Promotion/BigStickerInvoiceMonthlyMutation'
export const BIG_STICKER_INVOICE_MONTHLY_EXCEL_MUTATION = 'Promotion/BigStickerInvoiceMonthlyExcelMutation'
export const BIG_STICKER_INVOICE_QUERY = 'Promotion/BigStickerInvoiceQuery'
export const BIG_STICKER_INVOICE_EXCEL_MUTATION = 'Promotion/BigStickerInvoiceExcelMutation'
export const BIG_STICKER_INVOICE_HISTORY_QUERY = 'Promotion/BigStickerInvoiceHistoryQuery'
export const BIG_STICKER_EVENT_RE_CONTRACT_VALIDATION_QUERY = 'Promotion/BigStickerEventReContractValidationQuery'
export const BIG_STICKER_EVENT_RE_CONTRACT_MUTATION = 'Promotion/BigStickerEventReContractMutation'
export const GET_COUPON_LIST_QUERY = 'Coupon/GetCouponListQuery'
export const GET_COUPON_HISTORY_QUERY = 'Coupon/GetCouponHistoryQuery'
export const GET_COUPON_HISTORY_MUTATION = 'Coupon/GetCouponHistoryMutation'
export const GET_COUPON_CONFIG_QUERY = 'Coupon/GetCouponConfigQuery'
export const GET_COUPON_DETAIL_HISTORY_QUERY = 'Coupon/GetCouponDetailHistoryQuery'
export const GET_BUSINESS_GROUP_QUERY = 'Coupon/GetBusinessGroupQuery'
export const SAVE_COUPON_MUTATION = 'Coupon/SaveCouponMutation'

/**
 * 공통관리
 */
export const ADMIN_USER_REGIST_MUTATION = 'General/AdminUserRegisterMutation'
export const ADMIN_USER_UPDATE_MUTATION = 'General/AdminUserUpdateMutation'
export const ADMIN_USER_LIST_QUERY = 'General/AdminUserListQuery'
export const SYSTEM_ACCESS_PERMISSION_LOG_LIST_QUERY = 'General/SystemAccessPermissionLogListQuery'
export const SYSTEM_USING_LOG_LIST_QUERY = 'General/SystemUsingLogListQuery'
export const PRIVACY_ACCESS_LOG_LIST_QUERY = 'General/PrivacyAccessLogListQuery'
export const ADMIN_USER_DETAIL_QUERY = 'General/AdminUserDetailQuery'
export const ADMIN_USER_UNLOCK_MUTATION = 'General/AdminUserUnlockMutation'
export const ADMIN_USER_SEND_TEMP_PASSWORD_MUTATION = 'General/AdminUserSendTempPasswordMutation'
export const ADMIN_LOCATION_ACCESS_LOGS_QUERY = 'General/LocationAccessLogsQuery'
export const MENU_QUERY = 'General/MenuQuery'

/**
 * 설정
 */
export const SETTINGS_ADDRESS_UPDATE_MUTATION = 'Settings/AddressUpdateMutation'
export const SETTINGS_MESSAGE_QUERY = 'Settings/MessageQuery'
export const SETTINGS_DETAIL_MESSAGE_QUERY = 'Settings/DetailMessageQuery'
export const SETTINGS_SELECTED_MESSAGE_QUERY = 'Settings/SelectedMessageQuery'
export const SETTINGS_GROUP_LIST_QUERY = 'Settings/GroupListQuery'
export const SETTINGS_GROUP_ADD_QUERY = 'Settings/GroupAddQuery'

/**
 * 회계팀
 */
export const ACCOUNTING_TEAM_QUERY = 'AccountingTeamQuery'

/**
 * 최적운임
 */
export const ESTIMATED_FARE_OPTIONS_QUERY = 'EstimatedFareSettings/fareOptionsQuery'
export const ESTIMATED_FARE_OPTIONS_MUTATION = 'EstimatedFareSettings/fareOptionsMutation'
export const ESTIMATED_FARE_MODIFY_DELIVERY_PRICE = 'EstimatedFareSettings/modifyDeliveryPriceMutation'

export const ESTIMATED_FARE_MODIFY_FREIGHT_MUTATION = 'EstimatedFareSettings/ModifyFreightMutation'

export const ESTIMATED_FULL_FARE_QUERY = 'EstimatedFareSettings/FullFareQuery'
export const ESTIMATED_FULL_FARE_HISTORY_QUERY = 'EstimatedFareSettings/FullFareHistoryQuery'
export const OWNER_FREIGHT_QUERY = 'Owner/FreightQuery'
export const OWNER_FREIGHT_HISTORY_QUERY = 'Owner/FreightHistoryQuery'
export const OWNER_FREIGHT_MODIFY_FREIGHT_MUTATION = 'Owner/FreightMutation'

/**
 * 모니터링
 */
export const MONITORING_REAL_DRIVER_PATH_QUERY = 'EstimatedFareSettings/fareOptionsMutation'

/**
 * 내부물자
 */
export const INTERNAL_LOGISTICS_GET_CARGO_TYPE_LIST_QUERY = 'InternalLogistics/GetCargoTypeListQuery'
export const INTERNAL_LOGISTICS_GET_RECENT_ORDERS_QUERY = 'InternalLogistics/GetRecentOrdersQuery'
export const INTERNAL_LOGISTICS_GET_FREIGHT_QUERY = 'InternalLogistics/GetFreightQuery'
export const INTERNAL_LOGISTICS_ESTIMATE_ROUTING_MUTATION = 'InternalLogistics/EstimateRoutingMutation'
export const INTERNAL_LOGISTICS_GET_ORDER_CREATE_USER_INFO_QUERY = 'InternalLogistics/GetOrderCreateUserInfoQuery'
export const INTERNAL_LOGISTICS_CREATE_ORDER_QUERY = 'InternalLogistics/GetOrderCreateUserInfoQuery'
export const INTERNAL_LOGISTICS_GET_FAVORITE_ORDERS_QUERY = 'InternalLogistics/GetFavoriteOrderQuery'
export const INTERNAL_LOGISTICS_GET_ORDERS_UPLOAD_MUTATION = 'InternalLogistics/GetOrdersUploadStatusMutation'
export const INTERNAL_LOGISTICS_UPLOAD_ORDERS_UPLOAD_QUERY = 'InternalLogistics/UploadOrdersUploadQuery'
export const INTERNAL_LOGISTICS_RESET_ORDERS_UPLOAD_MUTATION = 'InternalLogistics/ResetOrdersUploadMutation'
export const INTERNAL_LOGISTICS_GET_ORDERS_UPLOAD_URL_QUERY = 'InternalLogistics/GetOrdersUploadTemplateUrlQuery'
export const INTERNAL_LOGISTICS_GET_ORDERS_UPLOAD_VALIDATE_QUERY = 'InternalLogistics/GetOrdersUploadValidateQuery'
export const INTERNAL_LOGISTICS_CREATE_ORDERS_UPLOAD_TEMP_QUERY = 'InternalLogistics/CreateOrdersUploadTempQuery'
export const INTERNAL_LOGISTICS_CREATE_ORDERS_UPLOAD_MUTATION = 'InternalLogistics/CreateOrdersUploadMutation'
export const INTERNAL_LOGISTICS_CANCEL_ORDERS_UPLOAD_MUTATION = 'InternalLogistics/CancelOrdersUploadMutation'
export const INTERNAL_LOGISTICS_VEHICLE_NUMBER_CHECK_MUTATION = 'InternalLogistics/VehicleNumberCheckMutation'
export const INTERNAL_LOGISTICS_DUPLICATE_ORDER_QUERY = 'InternalLogistics/DuplicateOrderQuery'

/**
 * 복화관리
 */
export const MATCH_BACK_DETAIL_QUERY = 'MatchBack/DetailQuery'
export const MATCH_BACK_LIST_QUERY = 'MatchBack/ListQuery'
export const MATCH_BACK_LIST_FOR_EXCEL_QUERY = 'MatchBack/ListForExcelMutation'
export const MATCH_BACK_SETTING_LIST_QUERY = 'MatchBack/SettingListQuery'
export const MATCH_BACK_SETTING_ITEM_BY_ID_MUTATION = 'MatchBack/SettingItemByIdMutation'
export const SAVE_MATH_BACK_SETTING_MUTATION = 'MatchBack/SaveSettingMutation'
export const ADD_MATH_BACK_SETTING_MUTATION = 'MatchBack/AddSettingMutation'

export const PORTAL_CARTON_LIST_QUERY = 'CarTon/listQuery'

export const PORTAL_ITEM_LIST_QUERY = 'Item/listQuery'

export const PORTAL_ORDER_LIST_QUERY = 'Portal/Order/ListQuery'
export const PORTAL_ORDER_LIST_EXCEL_DOWNLOAD_QUERY = 'Portal/Order/ListExcelDownloadQuery'
export const PORTAL_ORDER_DETAIL_QUERY = 'Portal/Order/DetailQuery'
export const PORTAL_ORDER_UPDATE_HISTORY_DATE_QUERY = 'Portal/Order/UpdateHistoryDateQuery'
export const PORTAL_ORDER_UPDATE_HISTORY_QUERY = 'Portal/Order/UpdateHistoryQuery'

export const PORTAL_DRIVER_LIST_QUERY = 'Portal/DriverManagement/ListQuery'
export const PORTAL_DRIVER_LIST_EXCEL_DOWNLOAD_MUTATION = 'Portal/DriverManagement/ListExcelDownloadMutation'

export const PORTAL_ACTUAL_ORDER_LIST_QUERY = 'Portal/ActualOrder/ListQuery'
export const PORTAL_ACTUAL_ORDER_EXCEL_DOWNLOAD_MUTATION = 'Portal/ActualOrder/ListExcelDownloadMutation'

/**
 * 게시판
 */
export const BOARD_FAQ_LIST_QUERY = 'Board/Faq/List'
export const BOARD_FAQ_DETAIL_QUERY = 'Board/Faq/Detail'
export const BOARD_FAQ_CREATE_MUTATION = 'Board/Faq/Create'
export const BOARD_FAQ_UPDATE_MUTATION = 'Board/Faq/Update'
export const BOARD_FAQ_DELETE_MUTATION = 'Board/Faq/Delete'
export const BOARD_NOTICE_LIST_QUERY = 'Board/Notice/List'
export const BOARD_NOTICE_DETAIL_QUERY = 'Board/Notice/Detail'
export const BOARD_NOTICE_CREATE_MUTATION = 'Board/Notice/Create'
export const BOARD_NOTICE_UPDATE_MUTATION = 'Board/Notice/Update'
export const BOARD_NOTICE_DELETE_MUTATION = 'Board/Notice/Delete'

/**
 * 운임그룹설정
 */
export const GET_PRICE_GROUP_OWNER_QUERY = 'PriceGroup/getOwnerQuery'
export const GET_PRICE_GROUP_DRIVER_QUERY = 'PriceGroup/getDriverQuery'
export const GET_PRICE_GROUP_DETAIL_QUERY = 'PriceGroup/getDriverDetailQuery'
export const GET_PRICE_GROUP_OWNERS_QUERY = 'PriceGroup/getOwnersQuery'
export const GET_PRICE_GROUP_QUERY = 'PriceGroup/getQuery'
export const GET_FARE_QUERY = 'PriceGroup/getFareQuery'
export const ADD_PRICE_GROUP_QUERY = 'PriceGroup/AddQuery'

export const UPDATE_PRICE_GROUP_MUTATION = 'PriceGroup/Update'
export const SAVE_OWNERS_QUERY = 'PriceGroup/saveOwnersQuery'
export const SAVE_FARE_QUERY = 'PriceGroup/saveFareQuery'
