export class Singleton {
  private static instance = null
  public static getInstance<T extends typeof Singleton>(this: T): InstanceType<T> {
    if (!this.instance) {
      this.instance = new this() as InstanceType<T>
    }

    return this.instance
  }
}
