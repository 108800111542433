import { InaviMapClient } from '@cjconnect/react-common/dist/components/InaviMap/InaviMapProvider'
import { InaviMapProvider } from '@cjconnect/react-common'
import React, { useMemo } from 'react'

interface IProps extends React.PropsWithChildren {}

function InaviMapClientProvider(props: IProps) {
  /**
   * 아이나비 지도 실행시 appKey와 styleId가 필요
   * 기본 지도를 넣으려면 styleId 삭제해도 됨
   */
  const inaviMapClient = useMemo<InaviMapClient>(
    () => ({
      appKey: process.env.NEXT_PUBLIC_INAVI_APP_KEY ?? '',
      styleId: process.env.NEXT_PUBLIC_INAVI_STYLE_ID,
    }),
    [],
  )

  return <InaviMapProvider client={inaviMapClient}>{props.children}</InaviMapProvider>
}

export default InaviMapClientProvider
