export const menuCategory = {
  THEUNBAN: 'theunban',
  PORTAL: 'portal',
}
export type MenuCategory = (typeof menuCategory)[keyof typeof menuCategory]

export const allowable = {
  UNKNOWN: 0,
  ALLOWED: 1,
  DISALLOWED: 2,
}
export type Allowable = (typeof allowable)[keyof typeof allowable]
