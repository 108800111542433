/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoResponseMemberApprovalStatus = {
    approvalStatus: MemberDtoResponseMemberApprovalStatus.approvalStatus;
    attachFileIds: Array<number>;
    memberId: string;
};
export namespace MemberDtoResponseMemberApprovalStatus {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
}

