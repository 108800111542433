/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SapSettlementDtoUpdateDocumentAmountRequest = {
    actualExpensesBilledAmount: number;
    digitHandlingType: SapSettlementDtoUpdateDocumentAmountRequest.digitHandlingType;
    oilPriceIndexationAmount: number;
    oilPriceIndexationPercent: number;
    originalSupplyAmount: number;
    remark?: string;
    shippingAdjustmentAmount: number;
    tempDocumentId: string;
    totalSupplyAmount: number;
};
export namespace SapSettlementDtoUpdateDocumentAmountRequest {
    export enum digitHandlingType {
        HUNDRED_ROUND = 'HUNDRED_ROUND',
        HUNDRED_TRUNC = 'HUNDRED_TRUNC',
        ONE_ROUND = 'ONE_ROUND',
        ONE_TRUNC = 'ONE_TRUNC',
        TEN_ROUND = 'TEN_ROUND',
        TEN_TRUNC = 'TEN_TRUNC',
    }
}

