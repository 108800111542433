/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ResponseCodeInfoDto = {
    message?: string;
    responseCode?: ResponseCodeInfoDto.responseCode;
};
export namespace ResponseCodeInfoDto {
    export enum responseCode {
        ACCOUNT_LOCK_LOGIN_ERROR__231 = 'ACCOUNT_LOCK_LOGIN_ERROR__231',
        ADMIN_REG_DRIVER_MDM_NOT_COMPLETE_ERROR__236 = 'ADMIN_REG_DRIVER_MDM_NOT_COMPLETE_ERROR__236',
        ADMIN_REG_DRIVER_SIGN_UP_NOT_AVAILABLE_ERROR__237 = 'ADMIN_REG_DRIVER_SIGN_UP_NOT_AVAILABLE_ERROR__237',
        ALREADY_DOCUMENT_ISSUED_ERROR__618 = 'ALREADY_DOCUMENT_ISSUED_ERROR__618',
        ALREADY_EXIST_ADDRESS_BOOK_ERROR__238 = 'ALREADY_EXIST_ADDRESS_BOOK_ERROR__238',
        ALREADY_ORDER_RECEIPT_UPLOADING__467 = 'ALREADY_ORDER_RECEIPT_UPLOADING__467',
        ALREADY_PAYMENT_METHOD__608 = 'ALREADY_PAYMENT_METHOD__608',
        AUTH_BUSINESS_ALREADY_EXIST_ERROR__905 = 'AUTH_BUSINESS_ALREADY_EXIST_ERROR__905',
        AUTH_DEACTIVATED_WITHIN_48HOURS_ERROR__221 = 'AUTH_DEACTIVATED_WITHIN_48HOURS_ERROR__221',
        AUTH_DRIVER_USER_ALREADY_EXIST_ERROR__205 = 'AUTH_DRIVER_USER_ALREADY_EXIST_ERROR__205',
        AUTH_IDENTICAL_PASSWORD_ERROR__220 = 'AUTH_IDENTICAL_PASSWORD_ERROR__220',
        AUTH_INVALID_PASSWORD_ERROR__203 = 'AUTH_INVALID_PASSWORD_ERROR__203',
        AUTH_INVALID_REFRESH_TOKEN__212 = 'AUTH_INVALID_REFRESH_TOKEN__212',
        AUTH_INVALID_SERVICE_TYPE_ERROR__210 = 'AUTH_INVALID_SERVICE_TYPE_ERROR__210',
        AUTH_INVALID_SERVICE_TYPE__207 = 'AUTH_INVALID_SERVICE_TYPE__207',
        AUTH_INVALID_TOKEN__201 = 'AUTH_INVALID_TOKEN__201',
        AUTH_LOGIN_DEACTIVATED_WITHIN_48HOURS_ERROR__227 = 'AUTH_LOGIN_DEACTIVATED_WITHIN_48HOURS_ERROR__227',
        AUTH_MULTI_LOGIN_ERROR__208 = 'AUTH_MULTI_LOGIN_ERROR__208',
        AUTH_NO_APPROVED_ERROR__214 = 'AUTH_NO_APPROVED_ERROR__214',
        AUTH_NO_MEMBER_ERROR__202 = 'AUTH_NO_MEMBER_ERROR__202',
        AUTH_NO_TOKEN_ERROR__204 = 'AUTH_NO_TOKEN_ERROR__204',
        AUTH_OWNER_PHONE_NUMBER_ALREADY_EXIST_ERROR__213 = 'AUTH_OWNER_PHONE_NUMBER_ALREADY_EXIST_ERROR__213',
        AUTH_OWNER_USER_ALREADY_EXIST_ERROR__215 = 'AUTH_OWNER_USER_ALREADY_EXIST_ERROR__215',
        AUTH_PERMISSION_ERROR__209 = 'AUTH_PERMISSION_ERROR__209',
        AUTH_SLEEP_MEMBER_ERROR__223 = 'AUTH_SLEEP_MEMBER_ERROR__223',
        AUTH_VEHICLE_ALREADY_EXIST_ERROR__211 = 'AUTH_VEHICLE_ALREADY_EXIST_ERROR__211',
        BAD_JSON_REQUEST__902 = 'BAD_JSON_REQUEST__902',
        BAROS_CLIENT_CANNOT_CHANGE_ARRIVAL__1306 = 'BAROS_CLIENT_CANNOT_CHANGE_ARRIVAL__1306',
        BAROS_CLIENT_CAR_REQUEST_ARRIVAL_TIME_EXCEED_NOW__1308 = 'BAROS_CLIENT_CAR_REQUEST_ARRIVAL_TIME_EXCEED_NOW__1308',
        BAROS_CLIENT_CENTER_CAPACITY_EXCEED__1305 = 'BAROS_CLIENT_CENTER_CAPACITY_EXCEED__1305',
        BAROS_CLIENT_ERROR__1301 = 'BAROS_CLIENT_ERROR__1301',
        BAROS_CLIENT_ILLEGAL_ARGUMENT__1303 = 'BAROS_CLIENT_ILLEGAL_ARGUMENT__1303',
        BAROS_CLIENT_ILLEGAL_CAR_REQUEST_STATUS__1304 = 'BAROS_CLIENT_ILLEGAL_CAR_REQUEST_STATUS__1304',
        BAROS_CLIENT_INVALID_IP_TOKEN__1307 = 'BAROS_CLIENT_INVALID_IP_TOKEN__1307',
        BAROS_CLIENT_NOTNULL_PARAM__1302 = 'BAROS_CLIENT_NOTNULL_PARAM__1302',
        BASIC_ADDRESS_BOOK_ERROR__234 = 'BASIC_ADDRESS_BOOK_ERROR__234',
        BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME_FOR_MDM_REQUEST__920 = 'BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME_FOR_MDM_REQUEST__920',
        BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME__919 = 'BUSINESS_ACCOUNT_OWNER_NAME_NOT_MATCHING_REPRESENTATIVE_NAME__919',
        BUSINESS_CONTRACT_NO_LOGIN_BY_CJ_ENM__924 = 'BUSINESS_CONTRACT_NO_LOGIN_BY_CJ_ENM__924',
        BUSINESS_ELECTRONIC_RECEIPT_NOT_AVAILABLE__925 = 'BUSINESS_ELECTRONIC_RECEIPT_NOT_AVAILABLE__925',
        BUSINESS_NOT_EXIST_IN_AFFILIATE__922 = 'BUSINESS_NOT_EXIST_IN_AFFILIATE__922',
        BUSINESS_NOT_REQUEST_APPROVAL__906 = 'BUSINESS_NOT_REQUEST_APPROVAL__906',
        BUSINESS_RECEIPT_MANDATORY_ERROR__921 = 'BUSINESS_RECEIPT_MANDATORY_ERROR__921',
        BUSINESS_REGISTRATION_FILES_IN_WAITING_STATUS__914 = 'BUSINESS_REGISTRATION_FILES_IN_WAITING_STATUS__914',
        BUSINESS_REGISTRATION_FILES_NOT_AVAILABLE_TO_UPDATE_TO_WAITING__915 = 'BUSINESS_REGISTRATION_FILES_NOT_AVAILABLE_TO_UPDATE_TO_WAITING__915',
        BUSINESS_REGISTRATION_FILE_DO_NOT_DELETE__911 = 'BUSINESS_REGISTRATION_FILE_DO_NOT_DELETE__911',
        BUSINESS_REGISTRATION_FILE_DO_NOT_UPDATE_COMPLETE_FILE__912 = 'BUSINESS_REGISTRATION_FILE_DO_NOT_UPDATE_COMPLETE_FILE__912',
        BUSINESS_REGISTRATION_FILE_NOT_LATEST__908 = 'BUSINESS_REGISTRATION_FILE_NOT_LATEST__908',
        BUSINESS_REGISTRATION_FILE_NO_DATA__907 = 'BUSINESS_REGISTRATION_FILE_NO_DATA__907',
        BUSINESS_REGISTRATION_FILE_REVIEW_PHASE1__909 = 'BUSINESS_REGISTRATION_FILE_REVIEW_PHASE1__909',
        BUSINESS_REGISTRATION_FILE_REVIEW_PHASE2__910 = 'BUSINESS_REGISTRATION_FILE_REVIEW_PHASE2__910',
        BUSINESS_SETTLEMENT_GROUP_IS_NOT_ACTIVE__923 = 'BUSINESS_SETTLEMENT_GROUP_IS_NOT_ACTIVE__923',
        BUSINESS_SETTLEMENT_GROUP_NAME_ALREADY_EXIST_ERROR__917 = 'BUSINESS_SETTLEMENT_GROUP_NAME_ALREADY_EXIST_ERROR__917',
        BUSINESS_SETTLEMENT_GROUP_NO_DATA__916 = 'BUSINESS_SETTLEMENT_GROUP_NO_DATA__916',
        BUSINESS_UNAVAILABLE_CREDIT_ORDER_CREATE_ERROR__918 = 'BUSINESS_UNAVAILABLE_CREDIT_ORDER_CREATE_ERROR__918',
        COMMON_APP_NEED_UPGRADE__103 = 'COMMON_APP_NEED_UPGRADE__103',
        COMMON_AWS_KMS_ERROR__115 = 'COMMON_AWS_KMS_ERROR__115',
        COMMON_BAD_REQUEST_BUSINESS_REGISTRATION__114 = 'COMMON_BAD_REQUEST_BUSINESS_REGISTRATION__114',
        COMMON_BAD_REQUEST__102 = 'COMMON_BAD_REQUEST__102',
        COMMON_BUSINESS_STATUS_NOT_EXIST__119 = 'COMMON_BUSINESS_STATUS_NOT_EXIST__119',
        COMMON_BUSINESS_STATUS_TAX_TYPE_UNAVAILABLE__118 = 'COMMON_BUSINESS_STATUS_TAX_TYPE_UNAVAILABLE__118',
        COMMON_BUSINESS_STATUS_UNAVAILABLE__112 = 'COMMON_BUSINESS_STATUS_UNAVAILABLE__112',
        COMMON_BUSINESS_TAX_TYPE_UNAVAILABLE__113 = 'COMMON_BUSINESS_TAX_TYPE_UNAVAILABLE__113',
        COMMON_DUPLICATED_ELEMENT__117 = 'COMMON_DUPLICATED_ELEMENT__117',
        COMMON_DUPLICATE_DATA_EXIST__109 = 'COMMON_DUPLICATE_DATA_EXIST__109',
        COMMON_GOVERNMENT_SERVER_ERROR_NICE_DNB_NOT_EXIST__120 = 'COMMON_GOVERNMENT_SERVER_ERROR_NICE_DNB_NOT_EXIST__120',
        COMMON_GOVERNMENT_SERVER_ERROR__110 = 'COMMON_GOVERNMENT_SERVER_ERROR__110',
        COMMON_INVALID_STATUS__108 = 'COMMON_INVALID_STATUS__108',
        COMMON_JSON_PARSING_ERROR__106 = 'COMMON_JSON_PARSING_ERROR__106',
        COMMON_NO_SUCH_ELEMENT__105 = 'COMMON_NO_SUCH_ELEMENT__105',
        COMMON_OK__0 = 'COMMON_OK__0',
        COMMON_PORTAL_BAD_REQUEST__116 = 'COMMON_PORTAL_BAD_REQUEST__116',
        COMMON_REQUEST_ALREADY_RUNNING__107 = 'COMMON_REQUEST_ALREADY_RUNNING__107',
        COMMON_SMS_VERIFY_REQUEST_ERROR__111 = 'COMMON_SMS_VERIFY_REQUEST_ERROR__111',
        COMMON_TEMPORARY_UNAVAILABLE__104 = 'COMMON_TEMPORARY_UNAVAILABLE__104',
        COMMON_UNKNOWN__101 = 'COMMON_UNKNOWN__101',
        CONTRACT_FREIGHT_DUPLICATE_DATA_EXIST__1401 = 'CONTRACT_FREIGHT_DUPLICATE_DATA_EXIST__1401',
        CONTRACT_FREIGHT_PRICE_TIME_ERROR__1410 = 'CONTRACT_FREIGHT_PRICE_TIME_ERROR__1410',
        CONTRACT_FREIGHT_PRICE_VALUE_ERROR__1409 = 'CONTRACT_FREIGHT_PRICE_VALUE_ERROR__1409',
        CONTRACT_FREIGHT_TON_BASE_FEE__1408 = 'CONTRACT_FREIGHT_TON_BASE_FEE__1408',
        DELETE_BASIC_ADDRESS_BOOK_ERROR__239 = 'DELETE_BASIC_ADDRESS_BOOK_ERROR__239',
        DIFFERENT_BUSINESS_DRIVER_OPEN_OPTION__466 = 'DIFFERENT_BUSINESS_DRIVER_OPEN_OPTION__466',
        DOCUMENT_ALREADY_SAVE_ERROR__615 = 'DOCUMENT_ALREADY_SAVE_ERROR__615',
        DOCUMENT_AMOUNT_CONVERT_TO_MODEL_ERROR__620 = 'DOCUMENT_AMOUNT_CONVERT_TO_MODEL_ERROR__620',
        DOCUMENT_AMOUNT_IS_ZERO_ERROR__619 = 'DOCUMENT_AMOUNT_IS_ZERO_ERROR__619',
        DOCUMENT_AMOUNT_SAVE_INVALID_STATUS_ERROR__617 = 'DOCUMENT_AMOUNT_SAVE_INVALID_STATUS_ERROR__617',
        DOCUMENT_DUPLICATED_BILL_NO_ERROR__621 = 'DOCUMENT_DUPLICATED_BILL_NO_ERROR__621',
        DOCUMENT_OIL_PRICE_DIFFERENCE_ERROR__616 = 'DOCUMENT_OIL_PRICE_DIFFERENCE_ERROR__616',
        DO_NOT_USE_MONTHLY_SHIPPING_FEE_LIMIT__244 = 'DO_NOT_USE_MONTHLY_SHIPPING_FEE_LIMIT__244',
        DRIVER_CHANGED_ERROR__219 = 'DRIVER_CHANGED_ERROR__219',
        DRIVER_INVALID_REF_CODE__245 = 'DRIVER_INVALID_REF_CODE__245',
        DUPLICATE_FIXED_DRIVER__1407 = 'DUPLICATE_FIXED_DRIVER__1407',
        EQUAL_PAYMENT_METHOD__607 = 'EQUAL_PAYMENT_METHOD__607',
        ESTIMATE_ROUTING_BAD_REQUEST__610 = 'ESTIMATE_ROUTING_BAD_REQUEST__610',
        EVENT_ALREADY_REGISTRATION_ERROR__1201 = 'EVENT_ALREADY_REGISTRATION_ERROR__1201',
        EXIST_CONSIGNMENT_MDM_CODE__251 = 'EXIST_CONSIGNMENT_MDM_CODE__251',
        EXIST_CONSIGNMENT_MDM_VEHICLE__250 = 'EXIST_CONSIGNMENT_MDM_VEHICLE__250',
        EXPIRED_DRIVER_REF_CODE__248 = 'EXPIRED_DRIVER_REF_CODE__248',
        EXPIRED_PASSWORD__233 = 'EXPIRED_PASSWORD__233',
        EXTERNAL_INAVI_COMMON_ERROR__805 = 'EXTERNAL_INAVI_COMMON_ERROR__805',
        EXTERNAL_INAVI_GEO_OUT_OF_RANGE_ERROR__802 = 'EXTERNAL_INAVI_GEO_OUT_OF_RANGE_ERROR__802',
        EXTERNAL_INAVI_HAS_NO_ADDRESS_ERROR__803 = 'EXTERNAL_INAVI_HAS_NO_ADDRESS_ERROR__803',
        EXTERNAL_INVALID_ACCESS_TOKEN_ERROR__808 = 'EXTERNAL_INVALID_ACCESS_TOKEN_ERROR__808',
        EXTERNAL_INVALID_REFRESH_TOKEN_ERROR__809 = 'EXTERNAL_INVALID_REFRESH_TOKEN_ERROR__809',
        EXTERNAL_NO_RESPONSE__804 = 'EXTERNAL_NO_RESPONSE__804',
        EXTERNAL_OCR_NON_REQUESTED_RESULT_RETURNED__801 = 'EXTERNAL_OCR_NON_REQUESTED_RESULT_RETURNED__801',
        EXTERNAL_PAGE_ACCESS_EXPIRED__807 = 'EXTERNAL_PAGE_ACCESS_EXPIRED__807',
        EXTERNAL_SEARCH_ADDRESS_ERROR__806 = 'EXTERNAL_SEARCH_ADDRESS_ERROR__806',
        EXTERNAL_ZIGTRUCK_NO_USER_ERROR__810 = 'EXTERNAL_ZIGTRUCK_NO_USER_ERROR__810',
        EXTERNAL_ZIGTRUCK_READ_TIMEOUT_ERROR__812 = 'EXTERNAL_ZIGTRUCK_READ_TIMEOUT_ERROR__812',
        EXTERNAL_ZIGTRUCK_UNAUTHORIZED_ERROR__811 = 'EXTERNAL_ZIGTRUCK_UNAUTHORIZED_ERROR__811',
        GSCALTEX_API_AUTH__2002 = 'GSCALTEX_API_AUTH__2002',
        GSCALTEX_API_COMMON__2001 = 'GSCALTEX_API_COMMON__2001',
        GSCALTEX_CARD_ALREADY_REGISTERED_BY_CARD_NO__2004 = 'GSCALTEX_CARD_ALREADY_REGISTERED_BY_CARD_NO__2004',
        GSCALTEX_CARD_ALREADY_REGISTERED_BY_MEMBER__2003 = 'GSCALTEX_CARD_ALREADY_REGISTERED_BY_MEMBER__2003',
        GSCALTEX_CARD_NOT_DISCOUNTABLE__2006 = 'GSCALTEX_CARD_NOT_DISCOUNTABLE__2006',
        GSCALTEX_CARD_NOT_REGISTERED__2005 = 'GSCALTEX_CARD_NOT_REGISTERED__2005',
        GSCALTEX_CARD_STATUS_NOT_VALID__2008 = 'GSCALTEX_CARD_STATUS_NOT_VALID__2008',
        GSCALTEX_VEHICLE_INFO_NOT_FOUND__2007 = 'GSCALTEX_VEHICLE_INFO_NOT_FOUND__2007',
        HANSSEM_ORDER_NOT_UPDATE__1601 = 'HANSSEM_ORDER_NOT_UPDATE__1601',
        INTERNAL_ERROR__904 = 'INTERNAL_ERROR__904',
        INVALID_MEMBER_SERVICE_TYPE__218 = 'INVALID_MEMBER_SERVICE_TYPE__218',
        INVALID_PASSWORD_CHANGE_DATE__225 = 'INVALID_PASSWORD_CHANGE_DATE__225',
        INVALID_PASSWORD_CONTAIN_ID__229 = 'INVALID_PASSWORD_CONTAIN_ID__229',
        INVALID_PASSWORD_REPEAT__230 = 'INVALID_PASSWORD_REPEAT__230',
        INVALID_PASSWORD_SAME__226 = 'INVALID_PASSWORD_SAME__226',
        INVALID_PASSWORD_THREE_COMBINATION__228 = 'INVALID_PASSWORD_THREE_COMBINATION__228',
        INVALID_UPDATE_REQUEST_START_DATE__1102 = 'INVALID_UPDATE_REQUEST_START_DATE__1102',
        INVOICE_OWNER_AMOUNT_UPDATE_ERROR__623 = 'INVOICE_OWNER_AMOUNT_UPDATE_ERROR__623',
        MDM_STATUS_NOT_COMPLETE__609 = 'MDM_STATUS_NOT_COMPLETE__609',
        MEMBER_SETTLEMENT_GROUP_IS_REQUIRED__240 = 'MEMBER_SETTLEMENT_GROUP_IS_REQUIRED__240',
        MEMO_MAX_COUNT_OVER_ERROR__2201 = 'MEMO_MAX_COUNT_OVER_ERROR__2201',
        MONTHLY_SHIPPING_FEE_HAS_BEEN_EXCEEDED__243 = 'MONTHLY_SHIPPING_FEE_HAS_BEEN_EXCEEDED__243',
        MONTHLY_SHIPPING_FEE_INPUT_MORE_ZERO__242 = 'MONTHLY_SHIPPING_FEE_INPUT_MORE_ZERO__242',
        MULTI_DOCUMENT_ISSUE_ERROR__624 = 'MULTI_DOCUMENT_ISSUE_ERROR__624',
        NOT_CHANGED_PASSWORD__232 = 'NOT_CHANGED_PASSWORD__232',
        NOT_CONTRACT_FREIGHT_EXCEL_VERIFICATION__1405 = 'NOT_CONTRACT_FREIGHT_EXCEL_VERIFICATION__1405',
        NOT_CONTRACT_FREIGHT__1406 = 'NOT_CONTRACT_FREIGHT__1406',
        NOT_EQUAL_REQUEST_SUPPLY_AMOUNT_ERROR__622 = 'NOT_EQUAL_REQUEST_SUPPLY_AMOUNT_ERROR__622',
        NOT_EXIST_BASIC_ADDRESS_BOOK_ERROR__235 = 'NOT_EXIST_BASIC_ADDRESS_BOOK_ERROR__235',
        NOT_FOUND_BASIC_MEMBER_SETTLEMENT_GROUP__252 = 'NOT_FOUND_BASIC_MEMBER_SETTLEMENT_GROUP__252',
        NOT_FOUND_BUSINESS__913 = 'NOT_FOUND_BUSINESS__913',
        NOT_FOUND_CONTRACT_FREIGHT_AREA__1403 = 'NOT_FOUND_CONTRACT_FREIGHT_AREA__1403',
        NOT_FOUND_CONTRACT_FREIGHT_TON__1404 = 'NOT_FOUND_CONTRACT_FREIGHT_TON__1404',
        NOT_FOUND_CONTRACT_FREIGHT__1402 = 'NOT_FOUND_CONTRACT_FREIGHT__1402',
        NOT_FOUND_DRIVER_USER__217 = 'NOT_FOUND_DRIVER_USER__217',
        NOT_FOUND_MAIN_OWNER_USER__247 = 'NOT_FOUND_MAIN_OWNER_USER__247',
        NOT_FOUND_MEMBER_BY_PLATE_NUMBER__249 = 'NOT_FOUND_MEMBER_BY_PLATE_NUMBER__249',
        NOT_FOUND_OWNER_USER__216 = 'NOT_FOUND_OWNER_USER__216',
        NOT_FOUND_PROMOTION__1101 = 'NOT_FOUND_PROMOTION__1101',
        NOT_INPUT_LOWER_THAN_NOW_MONTHLY_SHIPPING_FEE__241 = 'NOT_INPUT_LOWER_THAN_NOW_MONTHLY_SHIPPING_FEE__241',
        NO_MAIN_OWNER_ERROR__224 = 'NO_MAIN_OWNER_ERROR__224',
        NO_MAIN_PARTNER_ERROR__253 = 'NO_MAIN_PARTNER_ERROR__253',
        NO_VENDOR_SAP_CODE_FOUND__604 = 'NO_VENDOR_SAP_CODE_FOUND__604',
        ORDER_ADDRESS_BOOK_NO_MODIFY_ERROR__435 = 'ORDER_ADDRESS_BOOK_NO_MODIFY_ERROR__435',
        ORDER_ALREADY_CLOSED_ERROR__475 = 'ORDER_ALREADY_CLOSED_ERROR__475',
        ORDER_ALREADY_OPENED_ERROR__456 = 'ORDER_ALREADY_OPENED_ERROR__456',
        ORDER_ALREADY_ORDER_CANCELED_ERROR__305 = 'ORDER_ALREADY_ORDER_CANCELED_ERROR__305',
        ORDER_CONSIGNED_KEY_IN_DRIVER_FEE__460 = 'ORDER_CONSIGNED_KEY_IN_DRIVER_FEE__460',
        ORDER_CONSIGNED_KEY_IN_OWNER_FEE__459 = 'ORDER_CONSIGNED_KEY_IN_OWNER_FEE__459',
        ORDER_CONTRACT_FREIGHT_SAVE_ERROR__416 = 'ORDER_CONTRACT_FREIGHT_SAVE_ERROR__416',
        ORDER_CONTRACT_FREIGHT_VERIFICATION_ERROR__417 = 'ORDER_CONTRACT_FREIGHT_VERIFICATION_ERROR__417',
        ORDER_CONTRACT_SECTION_FROM_ERROR__411 = 'ORDER_CONTRACT_SECTION_FROM_ERROR__411',
        ORDER_CONTRACT_SECTION_TON_ERROR__413 = 'ORDER_CONTRACT_SECTION_TON_ERROR__413',
        ORDER_CONTRACT_SECTION_TO_ERROR__412 = 'ORDER_CONTRACT_SECTION_TO_ERROR__412',
        ORDER_CREATE_ADDRESS_INVALID__453 = 'ORDER_CREATE_ADDRESS_INVALID__453',
        ORDER_CREATE_BUSINESS_TRANSACTION_LEVEL_INVALID__476 = 'ORDER_CREATE_BUSINESS_TRANSACTION_LEVEL_INVALID__476',
        ORDER_CREATE_ESTIMATE_PRICE_INVALID_INVALID__454 = 'ORDER_CREATE_ESTIMATE_PRICE_INVALID_INVALID__454',
        ORDER_CREATE_HANDWORK_INVALID__450 = 'ORDER_CREATE_HANDWORK_INVALID__450',
        ORDER_CREATE_INVALID_CAR_TYPE__436 = 'ORDER_CREATE_INVALID_CAR_TYPE__436',
        ORDER_CREATE_INVALID_ITEM_TON__437 = 'ORDER_CREATE_INVALID_ITEM_TON__437',
        ORDER_CREATE_INVALID_REQUEST_CAR__461 = 'ORDER_CREATE_INVALID_REQUEST_CAR__461',
        ORDER_CREATE_INVALID_TON__430 = 'ORDER_CREATE_INVALID_TON__430',
        ORDER_CREATE_ORDER_ITEM_INVALID__452 = 'ORDER_CREATE_ORDER_ITEM_INVALID__452',
        ORDER_CREATE_STOWAGE_INVALID__451 = 'ORDER_CREATE_STOWAGE_INVALID__451',
        ORDER_CREATE_VEHICLE_TON_INVALID_ITEM_QUANTITY__473 = 'ORDER_CREATE_VEHICLE_TON_INVALID_ITEM_QUANTITY__473',
        ORDER_DISPATCH_CANCEL_FAIL_STATUS_ERROR__407 = 'ORDER_DISPATCH_CANCEL_FAIL_STATUS_ERROR__407',
        ORDER_DISPATCH_OWNER_CANCEL_FAIL_ERROR__406 = 'ORDER_DISPATCH_OWNER_CANCEL_FAIL_ERROR__406',
        ORDER_DRIVER_OWNER_MINUS_TOTAL_AMOUNT_ERROR__455 = 'ORDER_DRIVER_OWNER_MINUS_TOTAL_AMOUNT_ERROR__455',
        ORDER_ESTIMATE_SKIP_ERROR__308 = 'ORDER_ESTIMATE_SKIP_ERROR__308',
        ORDER_EXCEL_CANCEL_STATUS_ERROR__425 = 'ORDER_EXCEL_CANCEL_STATUS_ERROR__425',
        ORDER_EXCEL_CREATE_NO_DATA_ERROR__434 = 'ORDER_EXCEL_CREATE_NO_DATA_ERROR__434',
        ORDER_EXCEL_FILE_IO_EXCEPTION_ERROR__433 = 'ORDER_EXCEL_FILE_IO_EXCEPTION_ERROR__433',
        ORDER_EXCEL_FOUND_PROGRESS_ERROR__426 = 'ORDER_EXCEL_FOUND_PROGRESS_ERROR__426',
        ORDER_EXCEL_INVALID_FILE_NAME__458 = 'ORDER_EXCEL_INVALID_FILE_NAME__458',
        ORDER_EXCEL_INVALID_FILE_TYPE__420 = 'ORDER_EXCEL_INVALID_FILE_TYPE__420',
        ORDER_EXCEL_INVALID_STATUS__423 = 'ORDER_EXCEL_INVALID_STATUS__423',
        ORDER_EXCEL_MAX_ROWS_OVER__421 = 'ORDER_EXCEL_MAX_ROWS_OVER__421',
        ORDER_EXCEL_MAX_ROWS_ZERO__428 = 'ORDER_EXCEL_MAX_ROWS_ZERO__428',
        ORDER_EXCEL_MAX_SIZE_OVER__422 = 'ORDER_EXCEL_MAX_SIZE_OVER__422',
        ORDER_EXCEL_MAX_WAYPOINT_OVER__469 = 'ORDER_EXCEL_MAX_WAYPOINT_OVER__469',
        ORDER_EXCEL_NOT_FOUND_PROGRESS__424 = 'ORDER_EXCEL_NOT_FOUND_PROGRESS__424',
        ORDER_EXCEL_OPTIMIZE_ROUTING_ERROR__470 = 'ORDER_EXCEL_OPTIMIZE_ROUTING_ERROR__470',
        ORDER_EXCEL_UPLOAD_ERROR__418 = 'ORDER_EXCEL_UPLOAD_ERROR__418',
        ORDER_EXCEL_UPLOAD_NOT_SAMPLE_FILE__468 = 'ORDER_EXCEL_UPLOAD_NOT_SAMPLE_FILE__468',
        ORDER_EXCEL_UPLOAD_NO_DATA__419 = 'ORDER_EXCEL_UPLOAD_NO_DATA__419',
        ORDER_EXCEL_VALIDITY_BROKEN_ERROR__471 = 'ORDER_EXCEL_VALIDITY_BROKEN_ERROR__471',
        ORDER_EXTRA_INVOICE_FAIL_STATUS_ERROR__408 = 'ORDER_EXTRA_INVOICE_FAIL_STATUS_ERROR__408',
        ORDER_EXTRA_INVOICE_FINAL_STATUS_ERROR__409 = 'ORDER_EXTRA_INVOICE_FINAL_STATUS_ERROR__409',
        ORDER_EXTRA_INVOICE_ITEM_CANNOT_BE_CREATE__474 = 'ORDER_EXTRA_INVOICE_ITEM_CANNOT_BE_CREATE__474',
        ORDER_EXTRA_INVOICE_ITEM_CANNOT_BE_DELETED__463 = 'ORDER_EXTRA_INVOICE_ITEM_CANNOT_BE_DELETED__463',
        ORDER_EXTRA_INVOICE_ITEM_DUPLICATED__462 = 'ORDER_EXTRA_INVOICE_ITEM_DUPLICATED__462',
        ORDER_EXTRA_INVOICE_SAVE_FINAL_CONCURRENCY__464 = 'ORDER_EXTRA_INVOICE_SAVE_FINAL_CONCURRENCY__464',
        ORDER_FREIGHT_TYPE_CHANGE_IS_POSSIBLE_DISPATCHING__429 = 'ORDER_FREIGHT_TYPE_CHANGE_IS_POSSIBLE_DISPATCHING__429',
        ORDER_ID_IS_REQUIRED__449 = 'ORDER_ID_IS_REQUIRED__449',
        ORDER_INBOUND_MANY_ORDER__427 = 'ORDER_INBOUND_MANY_ORDER__427',
        ORDER_IN_BOUND_CENTER_CODE_AND_IO_BOUND_DETAIL_CODE_IS_REQUIRED__441 = 'ORDER_IN_BOUND_CENTER_CODE_AND_IO_BOUND_DETAIL_CODE_IS_REQUIRED__441',
        ORDER_MODIFY_DRIVER_FIXED_FEE_OVER_BY_2HOUR_OUT__447 = 'ORDER_MODIFY_DRIVER_FIXED_FEE_OVER_BY_2HOUR_OUT__447',
        ORDER_MODIFY_DRIVER_FIXED_FEE_OVER__444 = 'ORDER_MODIFY_DRIVER_FIXED_FEE_OVER__444',
        ORDER_MODIFY_OWNER_FIXED_FEE_OVER_2HOUR_OUT__448 = 'ORDER_MODIFY_OWNER_FIXED_FEE_OVER_2HOUR_OUT__448',
        ORDER_MODIFY_OWNER_FIXED_FEE_OVER__445 = 'ORDER_MODIFY_OWNER_FIXED_FEE_OVER__445',
        ORDER_MONTHLY_INVOICE_NOT_MODIFY__443 = 'ORDER_MONTHLY_INVOICE_NOT_MODIFY__443',
        ORDER_MULTI_MODIFY_ERROR__431 = 'ORDER_MULTI_MODIFY_ERROR__431',
        ORDER_NOT_ALLOWED_OPEN_CONTRACT_OWNER__465 = 'ORDER_NOT_ALLOWED_OPEN_CONTRACT_OWNER__465',
        ORDER_NO_CANCELLABLE_ERROR__303 = 'ORDER_NO_CANCELLABLE_ERROR__303',
        ORDER_NO_CONTRACT_ERROR__414 = 'ORDER_NO_CONTRACT_ERROR__414',
        ORDER_NO_CONTRACT_FREIGHT_FEE_ERROR__410 = 'ORDER_NO_CONTRACT_FREIGHT_FEE_ERROR__410',
        ORDER_NO_DISPATCH_BIND_ERROR__302 = 'ORDER_NO_DISPATCH_BIND_ERROR__302',
        ORDER_NO_DISPATCH_REQUEST_ERROR__304 = 'ORDER_NO_DISPATCH_REQUEST_ERROR__304',
        ORDER_NO_ELEMENT_ERROR__301 = 'ORDER_NO_ELEMENT_ERROR__301',
        ORDER_NO_ESTIMATE_PRICE_HISTORY_ERROR__432 = 'ORDER_NO_ESTIMATE_PRICE_HISTORY_ERROR__432',
        ORDER_NO_MEMBER_PAYMENT_INFO_ERROR__404 = 'ORDER_NO_MEMBER_PAYMENT_INFO_ERROR__404',
        ORDER_NO_OWNER_ADDRESS_BOOK_ERROR__401 = 'ORDER_NO_OWNER_ADDRESS_BOOK_ERROR__401',
        ORDER_NO_OWNER_FAVORITES_ORDER_ERROR__402 = 'ORDER_NO_OWNER_FAVORITES_ORDER_ERROR__402',
        ORDER_NO_OWNER_FAVORITES_ORDER_ITEM_ERROR__403 = 'ORDER_NO_OWNER_FAVORITES_ORDER_ITEM_ERROR__403',
        ORDER_OLD_TICKET_DUE_TO_MODIFY__442 = 'ORDER_OLD_TICKET_DUE_TO_MODIFY__442',
        ORDER_OPEN_FAIL_ERROR__457 = 'ORDER_OPEN_FAIL_ERROR__457',
        ORDER_ORDER_CANCEL_ERP_ERROR__307 = 'ORDER_ORDER_CANCEL_ERP_ERROR__307',
        ORDER_ORDER_CANCEL_ERROR__306 = 'ORDER_ORDER_CANCEL_ERROR__306',
        ORDER_PAYMENT_REMAIN_ERROR__405 = 'ORDER_PAYMENT_REMAIN_ERROR__405',
        ORDER_PICKUP_REQUEST_PASSED_CURRENT__415 = 'ORDER_PICKUP_REQUEST_PASSED_CURRENT__415',
        ORDER_RECEIPT_EXPIRED_VERIFICATION_DATE_ERROR__472 = 'ORDER_RECEIPT_EXPIRED_VERIFICATION_DATE_ERROR__472',
        ORDER_REDISPATCHING_ESTIMATE_PRICE_FAIL_ERROR__310 = 'ORDER_REDISPATCHING_ESTIMATE_PRICE_FAIL_ERROR__310',
        ORDER_REDISPATCHING_INVALID_ORDER_STATUS_ERROR__309 = 'ORDER_REDISPATCHING_INVALID_ORDER_STATUS_ERROR__309',
        ORDER_RETURN_REQUEST_PASSED_DROP_REQUEST__438 = 'ORDER_RETURN_REQUEST_PASSED_DROP_REQUEST__438',
        ORDER_RE_DISPATCH_BUSINESS_TRANSACTION_LEVEL_INVALID__477 = 'ORDER_RE_DISPATCH_BUSINESS_TRANSACTION_LEVEL_INVALID__477',
        ORDER_TRUNCATE_100_WON__446 = 'ORDER_TRUNCATE_100_WON__446',
        ORDER_UPDATE_IS_POSSIBLE_DISPATCHING__440 = 'ORDER_UPDATE_IS_POSSIBLE_DISPATCHING__440',
        ORDER_WAYPOINT_VALIDATION_ERROR__439 = 'ORDER_WAYPOINT_VALIDATION_ERROR__439',
        OTHER_BUSINESS_ORDER_IS_INCLUDED__605 = 'OTHER_BUSINESS_ORDER_IS_INCLUDED__605',
        OTHER_BUSINESS_SETTLEMENT_GROUP_ORDER_IS_INCLUDED__613 = 'OTHER_BUSINESS_SETTLEMENT_GROUP_ORDER_IS_INCLUDED__613',
        OTHER_FREIGHT_TYPE_ORDER_IS_INCLUDED__614 = 'OTHER_FREIGHT_TYPE_ORDER_IS_INCLUDED__614',
        PARTNER_DRIVER_IS_NOT_MAPPING_ERROR__2104 = 'PARTNER_DRIVER_IS_NOT_MAPPING_ERROR__2104',
        PARTNER_DRIVER_PRICE_ZERO_ERROR__2105 = 'PARTNER_DRIVER_PRICE_ZERO_ERROR__2105',
        PARTNER_MAPPING_ALREADY_EXISTS_BY_OWNER__2101 = 'PARTNER_MAPPING_ALREADY_EXISTS_BY_OWNER__2101',
        PARTNER_ORDER_ACCIDENT_CANCEL_ALREADY_BY_OWNER__2106 = 'PARTNER_ORDER_ACCIDENT_CANCEL_ALREADY_BY_OWNER__2106',
        PARTNER_ORDER_ALREADY_CHANGED_DRIVER__2103 = 'PARTNER_ORDER_ALREADY_CHANGED_DRIVER__2103',
        PARTNER_ORDER_DRIVING_ALREADY_EXISTS__2102 = 'PARTNER_ORDER_DRIVING_ALREADY_EXISTS__2102',
        PARTNER_VEHICLE_ALREADY_ON_DRIVING_ERROR__2108 = 'PARTNER_VEHICLE_ALREADY_ON_DRIVING_ERROR__2108',
        PARTNER_VEHICLE_CREATE_SUCCESS_AND_DISPATCH_FAIL_ERROR__2109 = 'PARTNER_VEHICLE_CREATE_SUCCESS_AND_DISPATCH_FAIL_ERROR__2109',
        PARTNER_VEHICLE_DISPATCH_UNAVAILABLE_CAR_TYPE_ERROR__2107 = 'PARTNER_VEHICLE_DISPATCH_UNAVAILABLE_CAR_TYPE_ERROR__2107',
        PAYMENT_FAILED_FROM_ONE_PG__603 = 'PAYMENT_FAILED_FROM_ONE_PG__603',
        PAYMENT_INVALID_STATUS_CREDIT_SALES__611 = 'PAYMENT_INVALID_STATUS_CREDIT_SALES__611',
        PAYMENT_NOT_ALLOWED_STATUS_ERROR__601 = 'PAYMENT_NOT_ALLOWED_STATUS_ERROR__601',
        PAYMENT_NO_INVOICE_ERROR__602 = 'PAYMENT_NO_INVOICE_ERROR__602',
        PRICE_CONFIG_DUPLICATED_ORDER__1001 = 'PRICE_CONFIG_DUPLICATED_ORDER__1001',
        PRICE_CONFIG_HISTORY_NOT_FOUND__1006 = 'PRICE_CONFIG_HISTORY_NOT_FOUND__1006',
        PRICE_CONFIG_INVALID_CAL_VALUE__1002 = 'PRICE_CONFIG_INVALID_CAL_VALUE__1002',
        PRICE_CONFIG_INVALID_DATA__1005 = 'PRICE_CONFIG_INVALID_DATA__1005',
        PRICE_CONFIG_INVALID_DATE__1003 = 'PRICE_CONFIG_INVALID_DATE__1003',
        PRICE_CONFIG_INVALID_TIME__1004 = 'PRICE_CONFIG_INVALID_TIME__1004',
        PRICE_GROUP_ALREADY_EXISTS_BY_TITLE_ERROR__1902 = 'PRICE_GROUP_ALREADY_EXISTS_BY_TITLE_ERROR__1902',
        PRICE_GROUP_ANOTHER_TRANSACTION_METHOD_CONTAINS_ERROR__1901 = 'PRICE_GROUP_ANOTHER_TRANSACTION_METHOD_CONTAINS_ERROR__1901',
        REDIS_PHONE_NUMBER_EXPIRED__246 = 'REDIS_PHONE_NUMBER_EXPIRED__246',
        REQUEST_DATA_MALFORMED__903 = 'REQUEST_DATA_MALFORMED__903',
        SAP_DOCUMENT_ISSUE_IN_PROGRESS__606 = 'SAP_DOCUMENT_ISSUE_IN_PROGRESS__606',
        SAP_DOCUMENT_ISSUE_NOT_IN_PROGRESS__612 = 'SAP_DOCUMENT_ISSUE_NOT_IN_PROGRESS__612',
        STICKER_EVENT_ALREADY_CONTRACT_ERROR__1218 = 'STICKER_EVENT_ALREADY_CONTRACT_ERROR__1218',
        STICKER_EVENT_ALREADY_UPLOAD_IMAGE_ERROR__1210 = 'STICKER_EVENT_ALREADY_UPLOAD_IMAGE_ERROR__1210',
        STICKER_EVENT_ALREADY_VERIFICATION_ERROR__1204 = 'STICKER_EVENT_ALREADY_VERIFICATION_ERROR__1204',
        STICKER_EVENT_EXPIRED_VERIFICATION_DATE_ERROR__1209 = 'STICKER_EVENT_EXPIRED_VERIFICATION_DATE_ERROR__1209',
        STICKER_EVENT_INVALID_INVOICE_AMOUNT_ERROR__1213 = 'STICKER_EVENT_INVALID_INVOICE_AMOUNT_ERROR__1213',
        STICKER_EVENT_INVALID_INVOICE_STATUS_ERROR__1214 = 'STICKER_EVENT_INVALID_INVOICE_STATUS_ERROR__1214',
        STICKER_EVENT_INVALID_RE_CONTRACT_DATE_ERROR__1219 = 'STICKER_EVENT_INVALID_RE_CONTRACT_DATE_ERROR__1219',
        STICKER_EVENT_INVALID_UPLOAD_DATE_ERROR__1207 = 'STICKER_EVENT_INVALID_UPLOAD_DATE_ERROR__1207',
        STICKER_EVENT_INVALID_UPLOAD_FILE_TYPE_ERROR__1206 = 'STICKER_EVENT_INVALID_UPLOAD_FILE_TYPE_ERROR__1206',
        STICKER_EVENT_INVALID_UPLOAD_STATUS_ERROR__1205 = 'STICKER_EVENT_INVALID_UPLOAD_STATUS_ERROR__1205',
        STICKER_EVENT_INVOICE_ISSUE_INVALID_DATE_ERROR__1216 = 'STICKER_EVENT_INVOICE_ISSUE_INVALID_DATE_ERROR__1216',
        STICKER_EVENT_INVOICE_NO_DATA_ERROR__1212 = 'STICKER_EVENT_INVOICE_NO_DATA_ERROR__1212',
        STICKER_EVENT_IN_PROGRESS_INVOICE_ISSUE_ERROR__1215 = 'STICKER_EVENT_IN_PROGRESS_INVOICE_ISSUE_ERROR__1215',
        STICKER_EVENT_NOT_EXISTS_INVOICE_DATA_ERROR__1217 = 'STICKER_EVENT_NOT_EXISTS_INVOICE_DATA_ERROR__1217',
        STICKER_EVENT_NO_DATA_ERROR__1203 = 'STICKER_EVENT_NO_DATA_ERROR__1203',
        STICKER_EVENT_NO_TARGET_MEMBER_ERROR__1202 = 'STICKER_EVENT_NO_TARGET_MEMBER_ERROR__1202',
        STICKER_EVENT_NO_VERIFICATION_DATA_ERROR__1208 = 'STICKER_EVENT_NO_VERIFICATION_DATA_ERROR__1208',
        STICKER_EVENT_UPLOAD_CONTRACT_ERROR__1211 = 'STICKER_EVENT_UPLOAD_CONTRACT_ERROR__1211',
        TOO_LARGE_REQUEST__901 = 'TOO_LARGE_REQUEST__901',
        TPS_MATCHBACK_ENGINE_ACTIVE_ERROR__1501 = 'TPS_MATCHBACK_ENGINE_ACTIVE_ERROR__1501',
        TPS_MATCHBACK_ENGINE_MAX_IDLE_TIME_ERROR__1506 = 'TPS_MATCHBACK_ENGINE_MAX_IDLE_TIME_ERROR__1506',
        TPS_MATCHBACK_ENGINE_MAX_TRIP_COUNT_ERROR__1502 = 'TPS_MATCHBACK_ENGINE_MAX_TRIP_COUNT_ERROR__1502',
        TPS_MATCHBACK_ENGINE_TOTAL_EMPTY_DISTANCE_ERROR__1505 = 'TPS_MATCHBACK_ENGINE_TOTAL_EMPTY_DISTANCE_ERROR__1505',
        TPS_MATCHBACK_ENGINE_TOTAL_MAX_OPERATION_HOUR_ERROR__1504 = 'TPS_MATCHBACK_ENGINE_TOTAL_MAX_OPERATION_HOUR_ERROR__1504',
        TPS_MATCHBACK_ENGINE_TOTAL_MIN_DISTANCE_ERROR__1503 = 'TPS_MATCHBACK_ENGINE_TOTAL_MIN_DISTANCE_ERROR__1503',
        TPS_ORDER_DISPATCH_NOT_VALID_ADDRESS__1555 = 'TPS_ORDER_DISPATCH_NOT_VALID_ADDRESS__1555',
        TPS_ORDER_DISPATCH_NOT_VALID_DRIVER_PRICE_ERROR__1554 = 'TPS_ORDER_DISPATCH_NOT_VALID_DRIVER_PRICE_ERROR__1554',
        TPS_ORDER_DISPATCH_VEHICLE_TON_TYPE_TON_CODE_TYPE_ERROR__1553 = 'TPS_ORDER_DISPATCH_VEHICLE_TON_TYPE_TON_CODE_TYPE_ERROR__1553',
        TPS_REGION_DUPLICATED_ERROR__1551 = 'TPS_REGION_DUPLICATED_ERROR__1551',
        TPS_REGION_GEO_ADMINISTRATIVE_DUPLICATED_ERROR__1552 = 'TPS_REGION_GEO_ADMINISTRATIVE_DUPLICATED_ERROR__1552',
        UN_REGISTRATION_FAIL_ERROR__222 = 'UN_REGISTRATION_FAIL_ERROR__222',
        USE_COUPON_FREIGHT_TYPE_ERROR__1702 = 'USE_COUPON_FREIGHT_TYPE_ERROR__1702',
        USE_COUPON_ORDER_TYPE_ERROR__1703 = 'USE_COUPON_ORDER_TYPE_ERROR__1703',
        USE_COUPON_STATUS_ERROR__1701 = 'USE_COUPON_STATUS_ERROR__1701',
        VEHICLE_AFTER_ETA_ERROR__510 = 'VEHICLE_AFTER_ETA_ERROR__510',
        VEHICLE_ALREADY_ON_DRIVE_MOVING_ERROR__519 = 'VEHICLE_ALREADY_ON_DRIVE_MOVING_ERROR__519',
        VEHICLE_ALREADY_ON_DRIVING_ERROR__508 = 'VEHICLE_ALREADY_ON_DRIVING_ERROR__508',
        VEHICLE_BEFORE_ETA_ERROR__509 = 'VEHICLE_BEFORE_ETA_ERROR__509',
        VEHICLE_CANCEL_ERROR__505 = 'VEHICLE_CANCEL_ERROR__505',
        VEHICLE_CAR_TYPE_TON_TYPE_NOT_MATCH_ERROR__523 = 'VEHICLE_CAR_TYPE_TON_TYPE_NOT_MATCH_ERROR__523',
        VEHICLE_COMPLETE_ERROR__506 = 'VEHICLE_COMPLETE_ERROR__506',
        VEHICLE_DELETE_DIRECT_OPERATED_CAR_ERROR__522 = 'VEHICLE_DELETE_DIRECT_OPERATED_CAR_ERROR__522',
        VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR1__514 = 'VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR1__514',
        VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR2__515 = 'VEHICLE_DELIVERY_COMPLETE_UNAVAILABILITY_ERROR2__515',
        VEHICLE_DEPARTURE_AND_DESTINATION_NULL_ERROR__517 = 'VEHICLE_DEPARTURE_AND_DESTINATION_NULL_ERROR__517',
        VEHICLE_DIRECT_OPERATED_CAR_ALREADY_EXIST_ERROR__520 = 'VEHICLE_DIRECT_OPERATED_CAR_ALREADY_EXIST_ERROR__520',
        VEHICLE_DISPATCH_MDM_CODE_MISMATCH_ERROR__526 = 'VEHICLE_DISPATCH_MDM_CODE_MISMATCH_ERROR__526',
        VEHICLE_DISPATCH_UNAVAILABLE_CAR_TYPE_ERROR__524 = 'VEHICLE_DISPATCH_UNAVAILABLE_CAR_TYPE_ERROR__524',
        VEHICLE_DISPATCH_UNAVAILABLE_PLATE_NUMBER_ERROR__525 = 'VEHICLE_DISPATCH_UNAVAILABLE_PLATE_NUMBER_ERROR__525',
        VEHICLE_DISPATCH_UNAVAILABLE_TON_TYPE_ERROR__518 = 'VEHICLE_DISPATCH_UNAVAILABLE_TON_TYPE_ERROR__518',
        VEHICLE_DROP_MOVING_AREA_ERROR__513 = 'VEHICLE_DROP_MOVING_AREA_ERROR__513',
        VEHICLE_DUPLICATE_PREFERENCE_ERROR__507 = 'VEHICLE_DUPLICATE_PREFERENCE_ERROR__507',
        VEHICLE_INFO_NOT_ENOUGH__516 = 'VEHICLE_INFO_NOT_ENOUGH__516',
        VEHICLE_MAXIMUM_PREFERENCE_AREA_ERROR__502 = 'VEHICLE_MAXIMUM_PREFERENCE_AREA_ERROR__502',
        VEHICLE_NOT_EXIST_DIRECT_OPERATED_CAR_ERROR__521 = 'VEHICLE_NOT_EXIST_DIRECT_OPERATED_CAR_ERROR__521',
        VEHICLE_NO_DISPATCHABLE_ERROR__504 = 'VEHICLE_NO_DISPATCHABLE_ERROR__504',
        VEHICLE_NO_ELEMENT_ERROR__501 = 'VEHICLE_NO_ELEMENT_ERROR__501',
        VEHICLE_NO_ETA_ERROR__503 = 'VEHICLE_NO_ETA_ERROR__503',
        VEHICLE_PICK_UP_COMPLETE_AREA_ERROR__512 = 'VEHICLE_PICK_UP_COMPLETE_AREA_ERROR__512',
        VEHICLE_REQUEST_DISPATCH_BASE_PRICE_ERROR__599 = 'VEHICLE_REQUEST_DISPATCH_BASE_PRICE_ERROR__599',
        VEHICLE_REQUEST_TRAILER_ERROR__600 = 'VEHICLE_REQUEST_TRAILER_ERROR__600',
        VEHICLE_SAME_PICKUP_REQUEST_AT_ERROR__511 = 'VEHICLE_SAME_PICKUP_REQUEST_AT_ERROR__511',
        ZIGTRUCK_API_4XX_ERROR__1804 = 'ZIGTRUCK_API_4XX_ERROR__1804',
        ZIGTRUCK_API_5XX_ERROR__1805 = 'ZIGTRUCK_API_5XX_ERROR__1805',
        ZIGTRUCK_API_EMPTY_RESPONSE_ERROR__1801 = 'ZIGTRUCK_API_EMPTY_RESPONSE_ERROR__1801',
        ZIGTRUCK_API_READ_TIMEOUT_ERROR__1802 = 'ZIGTRUCK_API_READ_TIMEOUT_ERROR__1802',
        ZIGTRUCK_API_UNAUTHORIZED_ERROR__1803 = 'ZIGTRUCK_API_UNAUTHORIZED_ERROR__1803',
        ZIGTRUCK_EXTERNAL_USER_NOT_FOUND_ERROR__1807 = 'ZIGTRUCK_EXTERNAL_USER_NOT_FOUND_ERROR__1807',
        ZIGTRUCK_PRODUCT_CREATE_ALREADY_ERROR__1811 = 'ZIGTRUCK_PRODUCT_CREATE_ALREADY_ERROR__1811',
        ZIGTRUCK_PRODUCT_CREATE_ALREADY_OTHERS_ERROR__1813 = 'ZIGTRUCK_PRODUCT_CREATE_ALREADY_OTHERS_ERROR__1813',
        ZIGTRUCK_PRODUCT_CREATE_EXCEED_ERROR__1812 = 'ZIGTRUCK_PRODUCT_CREATE_EXCEED_ERROR__1812',
        ZIGTRUCK_PRODUCT_CREATE_ON_PAUSE_ERROR__1809 = 'ZIGTRUCK_PRODUCT_CREATE_ON_PAUSE_ERROR__1809',
        ZIGTRUCK_PRODUCT_CREATE_ON_SALES_ERROR__1810 = 'ZIGTRUCK_PRODUCT_CREATE_ON_SALES_ERROR__1810',
        ZIGTRUCK_USER_NOT_FOUND_ERROR__1806 = 'ZIGTRUCK_USER_NOT_FOUND_ERROR__1806',
        ZIGTRUCK_USER_TOKEN_NOT_FOUND_ERROR__1808 = 'ZIGTRUCK_USER_TOKEN_NOT_FOUND_ERROR__1808',
    }
}

