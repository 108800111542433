/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminQuickPaySettlementDtoTaxInvoiceRequest = {
    billNo?: string;
    businessRegistrationNumber?: string;
    freightType?: SearchAdminQuickPaySettlementDtoTaxInvoiceRequest.freightType;
    fromDate?: string;
    isTempUser?: boolean;
    orderNo?: string;
    pageNo?: number;
    pageSize?: number;
    plateNumber?: string;
    sortColumn?: string;
    sortDirection?: string;
    taxInvoiceStatus?: SearchAdminQuickPaySettlementDtoTaxInvoiceRequest.taxInvoiceStatus;
    toDate?: string;
};
export namespace SearchAdminQuickPaySettlementDtoTaxInvoiceRequest {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum taxInvoiceStatus {
        ISSUED_FAIL = 'ISSUED_FAIL',
        ISSUED_SUCCESS = 'ISSUED_SUCCESS',
        NOT_ISSUED = 'NOT_ISSUED',
        REPORTING = 'REPORTING',
        REPORT_COMPLETE = 'REPORT_COMPLETE',
        REPORT_FAIL = 'REPORT_FAIL',
    }
}

