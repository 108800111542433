/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessRegistrationFileDtoRequestFileApprovalStatusMessage = {
    fileApprovalStatus: BusinessRegistrationFileDtoRequestFileApprovalStatusMessage.fileApprovalStatus;
    memberId: string;
};
export namespace BusinessRegistrationFileDtoRequestFileApprovalStatusMessage {
    export enum fileApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

