/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SaveDriverPopupDtoRequestFileImageUpload } from './SaveDriverPopupDtoRequestFileImageUpload';
export type SaveDriverPopupDtoRequest = {
    carTonTypes?: Array<'TON00_5' | 'TON0_3' | 'TON0_5' | 'TON11_0' | 'TON18_0' | 'TON1_0' | 'TON1_4' | 'TON25_0' | 'TON2_5' | 'TON3_5' | 'TON5_0' | 'TON5_0_PLUS'>;
    displayType?: SaveDriverPopupDtoRequest.displayType;
    endDate?: string;
    id?: number;
    image?: SaveDriverPopupDtoRequestFileImageUpload;
    linkUrl?: string;
    name: string;
    startDate: string;
    type: SaveDriverPopupDtoRequest.type;
};
export namespace SaveDriverPopupDtoRequest {
    export enum displayType {
        NEW_TAB = 'NEW_TAB',
        PAGE_CHANGE = 'PAGE_CHANGE',
    }
    export enum type {
        BANNER = 'BANNER',
        BOTTOM_POPUP = 'BOTTOM_POPUP',
        FULL_POPUP = 'FULL_POPUP',
    }
}

