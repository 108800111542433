/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderReceiptDtoReceiptInfo } from './OrderReceiptDtoReceiptInfo';
export type OrderReceiptDtoGetInfoResult = {
    businessName?: string;
    dropRequestAt?: string;
    orderId?: string;
    orderNo?: string;
    ownerOrderType?: OrderReceiptDtoGetInfoResult.ownerOrderType;
    ownerOrderTypeValue?: string;
    pickupRequestAt?: string;
    receiptInfos?: Array<OrderReceiptDtoReceiptInfo>;
    waypointCount?: number;
};
export namespace OrderReceiptDtoGetInfoResult {
    export enum ownerOrderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

