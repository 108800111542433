/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessGroupNameRequest = {
    serviceType: BusinessGroupNameRequest.serviceType;
};
export namespace BusinessGroupNameRequest {
    export enum serviceType {
        DRIVER_SERVICE = 'DRIVER_SERVICE',
        OWNER_SERVICE = 'OWNER_SERVICE',
        PARTNER_DRIVER_SERVICE = 'PARTNER_DRIVER_SERVICE',
        PARTNER_SERVICE = 'PARTNER_SERVICE',
    }
}

