/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminAccessLogDtoAccessLogDto = {
    accessById?: string;
    accessByName?: string;
    accessIp?: string;
    createdAt?: string;
    logType?: AdminAccessLogDtoAccessLogDto.logType;
    memberId?: string;
};
export namespace AdminAccessLogDtoAccessLogDto {
    export enum logType {
        ACCOUNT_LOCK = 'ACCOUNT_LOCK',
        ACCOUNT_UNLOCK = 'ACCOUNT_UNLOCK',
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        DRIVER_ON_BOARDING_COMPLETE = 'DRIVER_ON_BOARDING_COMPLETE',
        DRIVER_OPTION_UPDATE = 'DRIVER_OPTION_UPDATE',
        JOIN_REJECT = 'JOIN_REJECT',
        LOGIN = 'LOGIN',
        LOGIN_FAIL = 'LOGIN_FAIL',
        LOGOUT = 'LOGOUT',
        MEMBER_ROLE_UPDATE = 'MEMBER_ROLE_UPDATE',
        PASSWORD_MODIFY = 'PASSWORD_MODIFY',
        PRIVACY_DOWNLOAD = 'PRIVACY_DOWNLOAD',
        PRIVACY_SEARCH = 'PRIVACY_SEARCH',
        PRIVACY_UPDATE = 'PRIVACY_UPDATE',
        REFRESH_LOGIN = 'REFRESH_LOGIN',
        REGULAR = 'REGULAR',
        SIGN_UP = 'SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        USER_DELETE = 'USER_DELETE',
    }
}

