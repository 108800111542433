import { IChipModel } from '@features/common/types/chipModel'
import { SearchRecentCarsDtoResult } from 'src/data'
import { i18n } from 'next-i18next'

export namespace VehicleChipsModelMapper {
  export const mapToPresentation = (result: SearchRecentCarsDtoResult): Array<IChipModel> => {
    if (!result) return []

    return result.carInfos.map(vehicle => ({
      left: i18n.t(`common:vehicleInfo.types.${vehicle.carType}`),
      right: i18n.t(`common:vehicleInfo.weights.${vehicle.carTonType}`),
    }))
  }
}
