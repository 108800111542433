/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AdminPartnerOrderStatusListDtoRequest = {
    businessGroupAffiliateCodes?: Array<'B_MART' | 'COMMON' | 'FURSYS' | 'FURSYS_VENDOR' | 'HCORE' | 'KOAS' | 'LIVART' | 'NAMYANG_WATER' | 'ONE' | 'PND' | 'SK_NETWORKS' | 'WND'>;
    businessIds?: Array<string>;
    businessSearch?: string;
    carTons?: Array<string>;
    carTypes?: Array<string>;
    createdBySearch?: string;
    customerCode?: string;
    customerName?: string;
    dispatchMethods?: Array<'CONSIGNED' | 'DIRECT' | 'FIXED_BY_ITEM' | 'FIXED_MONTHLY_TOTAL' | 'UNBAN'>;
    dispatchTypes?: Array<'ADMIN_DISPATCH' | 'DRIVER_DISPATCH' | 'NONE' | 'PARTNER_DISPATCH'>;
    driverSearch?: string;
    freightTypes?: Array<'DISTRICT_FREIGHT' | 'HALF_DAILY_FREIGHT' | 'MIXED_FREIGHT' | 'MONTHLY_FREIGHT' | 'NORMAL_FREIGHT' | 'QUICK_FREIGHT' | 'RANGE_FREIGHT'>;
    fromAddressSearch?: string;
    fromDate: string;
    isPassOrder?: boolean;
    memberCategoryType?: AdminPartnerOrderStatusListDtoRequest.memberCategoryType;
    orderChannelType?: AdminPartnerOrderStatusListDtoRequest.orderChannelType;
    orderNoSearch?: string;
    orderPaymentMethodTypes?: Array<'CASH_ON_DELIVERY' | 'CREDIT_CARD' | 'CREDIT_SALES' | 'INTERNAL_CREDIT_SALES'>;
    orderSearchDateType?: AdminPartnerOrderStatusListDtoRequest.orderSearchDateType;
    orderStatuses?: Array<string>;
    ownerOrderTypes?: Array<'MULTI' | 'ROUND_TRIP' | 'SINGLE' | 'WAYPOINTS'>;
    pageNo?: number;
    pageSize?: number;
    partnerBusinessSearch?: string;
    plateNumberSearch?: string;
    priceGroupPolicyIds?: Array<string>;
    sortColumn?: string;
    sortDirection?: string;
    toAddressSearch?: string;
    toDate: string;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
};
export namespace AdminPartnerOrderStatusListDtoRequest {
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum orderChannelType {
        ADMIN_ORDER = 'ADMIN_ORDER',
        CJ_ENM = 'CJ_ENM',
        HCORE_ERP = 'HCORE_ERP',
        OWNER_APP = 'OWNER_APP',
        OWNER_APP_LLM = 'OWNER_APP_LLM',
        OWNER_WEB = 'OWNER_WEB',
        OWNER_WEB_BLOCK = 'OWNER_WEB_BLOCK',
        SYSTEM_ERP = 'SYSTEM_ERP',
        TPS_HELLO = 'TPS_HELLO',
    }
    export enum orderSearchDateType {
        CREATED_AT = 'CREATED_AT',
        DISPATCH_DATE = 'DISPATCH_DATE',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        ORDER_CRETE = 'ORDER_CRETE',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
    }
}

