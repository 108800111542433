/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StickerApprovalStatusDto } from './StickerApprovalStatusDto';
import type { StickerEventVerificationDto } from './StickerEventVerificationDto';
export type GetEventListDtoResult = {
    backImage?: StickerEventVerificationDto;
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    carTonType?: GetEventListDtoResult.carTonType;
    carType?: GetEventListDtoResult.carType;
    contractDateTime?: string;
    contractEndDateTime?: string;
    eventStartDateTime?: string;
    invoiceMemberId?: string;
    isReContract?: boolean;
    isSend?: boolean;
    isStickerContractExpired?: boolean;
    leftImage?: StickerEventVerificationDto;
    mdmCode?: string;
    memberId?: string;
    monthAmountWithoutVat?: number;
    plateNumber?: string;
    rightImage?: StickerEventVerificationDto;
    sapSettlementStatus?: GetEventListDtoResult.sapSettlementStatus;
    sapSettlementStatusValue?: string;
    stickerApprovalStatusDtos?: Array<StickerApprovalStatusDto>;
    stickerEventConfigId?: string;
    stickerEventWinnerMemberId?: string;
    stickerMonthlyFinalStatusId?: number;
    totalAmount?: number;
    userId?: string;
    vatOfMonthAmount?: number;
    verifyDateTime?: string;
    verifyType?: GetEventListDtoResult.verifyType;
};
export namespace GetEventListDtoResult {
    export enum carTonType {
        TON00_5 = 'TON00_5',
        TON0_3 = 'TON0_3',
        TON0_5 = 'TON0_5',
        TON11_0 = 'TON11_0',
        TON18_0 = 'TON18_0',
        TON1_0 = 'TON1_0',
        TON1_4 = 'TON1_4',
        TON25_0 = 'TON25_0',
        TON2_5 = 'TON2_5',
        TON3_5 = 'TON3_5',
        TON5_0 = 'TON5_0',
        TON5_0_PLUS = 'TON5_0_PLUS',
    }
    export enum carType {
        CARGO = 'CARGO',
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
        TOP = 'TOP',
        TRAILER = 'TRAILER',
        WING_BODY = 'WING_BODY',
        WING_BODY_CARGO = 'WING_BODY_CARGO',
    }
    export enum sapSettlementStatus {
        ISSUED_COMPLETE = 'ISSUED_COMPLETE',
        ISSUE_IN_PROGRESS = 'ISSUE_IN_PROGRESS',
        NOT_ISSUED = 'NOT_ISSUED',
    }
    export enum verifyType {
        COMPLETE = 'COMPLETE',
        REJECT = 'REJECT',
        SUSPEND = 'SUSPEND',
    }
}

