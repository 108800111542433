/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NewPartnerUserDtoNewPartnerUserSummary = {
    advertisementTermsAgreeAt?: string;
    approvalStatus?: NewPartnerUserDtoNewPartnerUserSummary.approvalStatus;
    approvalStatusValue?: string;
    businessGroupId?: number;
    businessGroupName?: string;
    businessId?: string;
    businessRegistrationNumber?: string;
    departmentName?: string;
    eevidenceUrl?: string;
    email?: string;
    isAdvertisementTermsAgree?: boolean;
    managerName?: string;
    managerPhoneNumber?: string;
    mdmRegistrationStatus?: NewPartnerUserDtoNewPartnerUserSummary.mdmRegistrationStatus;
    mdmRegistrationStatusValue?: string;
    memberCreatedAt?: string;
    memberId?: string;
    officePhoneNumber?: string;
    userId?: string;
};
export namespace NewPartnerUserDtoNewPartnerUserSummary {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
}

