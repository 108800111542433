/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessGroupCreator } from './BusinessGroupCreator';
export type BusinessGroupResult = {
    createdAt: string;
    creator: BusinessGroupCreator;
    cruCode: string;
    id: number;
    name: string;
    serviceType: BusinessGroupResult.serviceType;
};
export namespace BusinessGroupResult {
    export enum serviceType {
        DRIVER_SERVICE = 'DRIVER_SERVICE',
        OWNER_SERVICE = 'OWNER_SERVICE',
        PARTNER_DRIVER_SERVICE = 'PARTNER_DRIVER_SERVICE',
        PARTNER_SERVICE = 'PARTNER_SERVICE',
    }
}

