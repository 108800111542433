/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SapSettlementDtoCalcOilAmountRequest = {
    digitHandlingType: SapSettlementDtoCalcOilAmountRequest.digitHandlingType;
    oilPriceIndexationPercent: number;
    originalSupplyAmount: number;
    shippingAdjustmentAmount: number;
};
export namespace SapSettlementDtoCalcOilAmountRequest {
    export enum digitHandlingType {
        HUNDRED_ROUND = 'HUNDRED_ROUND',
        HUNDRED_TRUNC = 'HUNDRED_TRUNC',
        ONE_ROUND = 'ONE_ROUND',
        ONE_TRUNC = 'ONE_TRUNC',
        TEN_ROUND = 'TEN_ROUND',
        TEN_TRUNC = 'TEN_TRUNC',
    }
}

