import { TonTypeEnum } from '@features/membership/common/types'
import { IListItem } from '@features/common/types'
import { OrderDetailDtoModifyDetailRequest } from 'src/data'
import { convertToSnakeCase } from '@utils/convertToSnakeCase'

export interface IOrderMetaOption {
  key: string
  value: string
}

export interface IOrderMetaAllListModel {
  addressType: Array<IOrderMetaOption>
  carTonType: Array<IOrderMetaOption>
  carType: Array<IOrderMetaOption>
  cardType: Array<IOrderMetaOption>
  dispatchIntervalType: Array<IOrderMetaOption>
  dispatchType: Array<IOrderMetaOption>
  dispatchMethod: Array<IOrderMetaOption>
  orderStatus: Array<IOrderMetaOption>
  ownerSettlementStatus: Array<IOrderMetaOption>
  packingMethodType: Array<IOrderMetaOption>
  pickupDropMethodType: Array<IOrderMetaOption>
  ownerSelectablePaymentConditionCode?: Array<IOrderMetaOption>
  freightType?: Array<IOrderMetaOption>
  appLaunchDate: string
  bankType: Array<IOrderMetaOption>
  digitHandlingType: Array<IOrderMetaOption>
  documentStatusType: Array<IOrderMetaOption>
  depositSettlementStatus: Array<IOrderMetaOption>
  vehicleOptionType: Array<IOrderMetaOption>
  businessGroupAffiliateCode: Array<IOrderMetaOption>
  invoiceItemType: Array<IOrderMetaOption>
  otherEtaxPublisherType: Array<IOrderMetaOption>
  liftedTask: Array<IOrderMetaOption> //한샘 - 양중작업
  tileBox: Array<IOrderMetaOption> //한샘 - 타일옵션
  orderItemType: Array<IOrderMetaOption> //한샘 - 물품구분
  registrationChannelType: Array<IOrderMetaOption> //CJ ENM 구분
  partnerSelectablePaymentConditionCode: Array<IOrderMetaOption>
}

/**
 * 주문 메타 리스트 view model
 */
export class OrderMetaAllListModel implements IOrderMetaAllListModel {
  addressType: Array<IOrderMetaOption>
  carTonType: Array<IOrderMetaOption>
  carType: Array<IOrderMetaOption>
  cardType: Array<IOrderMetaOption>
  dispatchIntervalType: Array<IOrderMetaOption>
  dispatchType: Array<IOrderMetaOption>
  dispatchMethod: Array<IOrderMetaOption>
  orderStatus: Array<IOrderMetaOption>
  ownerSettlementStatus: Array<IOrderMetaOption>
  packingMethodType: Array<IOrderMetaOption>
  pickupDropMethodType: Array<IOrderMetaOption>
  ownerSelectablePaymentConditionCode?: Array<IOrderMetaOption>
  freightType?: Array<IOrderMetaOption>
  appLaunchDate: string
  bankType: Array<IOrderMetaOption>
  digitHandlingType: Array<IOrderMetaOption>
  documentStatusType: Array<IOrderMetaOption>
  depositSettlementStatus: Array<IOrderMetaOption>
  vehicleOptionType: Array<IOrderMetaOption>
  businessGroupAffiliateCode: Array<IOrderMetaOption>
  invoiceItemType: Array<IOrderMetaOption>
  otherEtaxPublisherType: Array<IOrderMetaOption>
  liftedTask: Array<IOrderMetaOption> //한샘 - 양중작업
  tileBox: Array<IOrderMetaOption> //한샘 - 타일옵션
  orderItemType: Array<IOrderMetaOption> //한샘 - 물품구분
  registrationChannelType: Array<IOrderMetaOption> //CJ ENM 구분
  partnerSelectablePaymentConditionCode: Array<IOrderMetaOption>

  constructor({
    addressType,
    carTonType,
    carType,
    cardType,
    dispatchIntervalType,
    dispatchType,
    dispatchMethod,
    orderStatus,
    ownerSettlementStatus,
    packingMethodType,
    pickupDropMethodType,
    ownerSelectablePaymentConditionCode,
    freightType,
    appLaunchDate,
    bankType,
    digitHandlingType,
    documentStatusType,
    depositSettlementStatus,
    vehicleOptionType,
    businessGroupAffiliateCode,
    invoiceItemType,
    otherEtaxPublisherType,
    liftedTask,
    tileBox,
    orderItemType,
    registrationChannelType,
    partnerSelectablePaymentConditionCode,
  }: IOrderMetaAllListModel) {
    this.addressType = addressType
    this.carTonType = carTonType
    this.carType = carType
    this.cardType = cardType
    this.dispatchIntervalType = dispatchIntervalType
    this.dispatchType = dispatchType
    this.dispatchMethod = dispatchMethod
    this.orderStatus = orderStatus
    this.ownerSettlementStatus = ownerSettlementStatus
    this.packingMethodType = packingMethodType
    this.pickupDropMethodType = pickupDropMethodType
    this.ownerSelectablePaymentConditionCode = ownerSelectablePaymentConditionCode
    this.freightType = freightType
    this.appLaunchDate = appLaunchDate || process.env.NEXT_PUBLIC_APP_LAUNCH_DATE
    this.bankType = bankType
    this.digitHandlingType = digitHandlingType
    this.documentStatusType = documentStatusType
    this.depositSettlementStatus = depositSettlementStatus
    this.vehicleOptionType = vehicleOptionType
    this.businessGroupAffiliateCode = businessGroupAffiliateCode
    this.invoiceItemType = invoiceItemType
    this.otherEtaxPublisherType = otherEtaxPublisherType
    this.liftedTask = liftedTask
    this.tileBox = tileBox
    this.orderItemType = orderItemType
    this.registrationChannelType = registrationChannelType
    this.partnerSelectablePaymentConditionCode = partnerSelectablePaymentConditionCode
  }

  public get getCarTonTypeFilterOptions() {
    return this.carTonType
      .filter(carTonType => carTonType.key !== TonTypeEnum.TON0_3 && carTonType.key !== TonTypeEnum.TON0_5)
      .sort((a, b) => {
        const weightA = parseFloat(a.value.replace(/[^0-9.]/g, ''))
        const weightB = parseFloat(b.value.replace(/[^0-9.]/g, ''))

        return weightA - weightB
      })
  }

  static init(): OrderMetaAllListModel {
    return new OrderMetaAllListModel({
      addressType: [],
      carTonType: [],
      carType: [],
      cardType: [],
      dispatchIntervalType: [],
      dispatchType: [],
      dispatchMethod: [],
      orderStatus: [],
      ownerSettlementStatus: [],
      packingMethodType: [],
      pickupDropMethodType: [],
      ownerSelectablePaymentConditionCode: [],
      freightType: [],
      appLaunchDate: process.env.NEXT_PUBLIC_APP_LAUNCH_DATE,
      bankType: [],
      digitHandlingType: [],
      documentStatusType: [],
      depositSettlementStatus: [],
      vehicleOptionType: [],
      businessGroupAffiliateCode: [],
      invoiceItemType: [],
      otherEtaxPublisherType: [],
      liftedTask: [],
      tileBox: [],
      orderItemType: [],
      registrationChannelType: [],
      partnerSelectablePaymentConditionCode: [],
    })
  }

  public getCarTypeListItem(selectedCarType?: string): IListItem[] {
    return (
      this.carType
        .filter(type => type.key !== OrderDetailDtoModifyDetailRequest.carType.WING_BODY_CARGO)
        .map(type => {
          let selected: boolean
          if (selectedCarType === OrderDetailDtoModifyDetailRequest.carType.WING_BODY_CARGO) {
            // 윙바디/카고 인 경우 윙바디 또는 카고를 선택으로 만들어주기 위함
            selected =
              type.key === OrderDetailDtoModifyDetailRequest.carType.WING_BODY ||
              type.key === OrderDetailDtoModifyDetailRequest.carType.CARGO
          } else {
            selected = type.key === selectedCarType
          }

          return {
            key: type.key,
            value: type.value,
            label: type.value,
            disabled: false,
            selected: selected,
          } as IListItem
        }) || []
    )
  }

  public getCarTonListItem(selectedCarTon?: string): IListItem[] {
    return (
      this.carTonType.map(ton => {
        const item: IListItem = {
          key: ton.key,
          value: ton.value,
          label: ton.value,
          disabled: false,
          selected: ton.key === selectedCarTon,
        }

        return item
      }) || []
    )
  }

  public getCarOptionListItem(selectedCarOption?: string[]): IListItem[] {
    return (
      this.vehicleOptionType.map(option => {
        const item: IListItem = {
          key: option.key,
          value: option.value,
          label: option.value,
          disabled: false,
          selected: selectedCarOption.includes(option.value),
        }
        return item
      }) || []
    )
  }

  public getOwnerInnerGroupAffiliateCode(): string[] {
    const businessGroupAffiliateCodeEnum = this.getBusinessGroupAffiliateCodeEnum()
    const includeKeys = [businessGroupAffiliateCodeEnum.PND, businessGroupAffiliateCodeEnum.ONE]
    return this.businessGroupAffiliateCode.filter(item => includeKeys.includes(item.key)).map(item => item.key)
  }

  public getBusinessGroupAffiliateCodeEnum(): any {
    const newKeysEnum = {}
    this.businessGroupAffiliateCode.map(item => (newKeysEnum[item.key] = item.key))
    return newKeysEnum || null
  }

  public getBusinessGroupAffiliateCode(): string[] {
    const businessGroupAffiliateCodeEnum = this.getBusinessGroupAffiliateCodeEnum()
    const excludedKeys = [
      businessGroupAffiliateCodeEnum.COMMON,
      businessGroupAffiliateCodeEnum.LIVART,
      businessGroupAffiliateCodeEnum.WND,
      businessGroupAffiliateCodeEnum.SK_NETWORKS,
    ]
    return this.businessGroupAffiliateCode.filter(item => !excludedKeys.includes(item.key)).map(item => item.key)
  }

  public getContractLogisticsBusinessGroupAffiliateCode(): string[] {
    const businessGroupAffiliateCodeEnum = this.getBusinessGroupAffiliateCodeEnum()
    const excludedKeys = [
      businessGroupAffiliateCodeEnum.COMMON,
      businessGroupAffiliateCodeEnum.LIVART,
      businessGroupAffiliateCodeEnum.WND,
      businessGroupAffiliateCodeEnum.SK_NETWORKS,
      businessGroupAffiliateCodeEnum.PND,
      businessGroupAffiliateCodeEnum.ONE,
    ]
    return this.businessGroupAffiliateCode.filter(item => !excludedKeys.includes(item.key)).map(item => item.key)
  }

  public getBusinessGroupType(businessGroupId: string): string[] {
    const businessGroupAffiliateCodeEnum = this.getBusinessGroupAffiliateCodeEnum()
    const checkBusinessGroupId = convertToSnakeCase(businessGroupId)

    let businessGroupType = [checkBusinessGroupId]

    if (checkBusinessGroupId === businessGroupAffiliateCodeEnum.FURSYS)
      businessGroupType.push(businessGroupAffiliateCodeEnum.FURSYS_VENDOR)

    return businessGroupType
  }
}
