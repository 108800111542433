/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MapPoiDtoRequest = {
    isContainCoordinate?: boolean;
    keyword: string;
    option?: MapPoiDtoRequest.option;
    pageNo?: number;
    pageSize?: number;
};
export namespace MapPoiDtoRequest {
    export enum option {
        KEYWORD = 'KEYWORD',
    }
}

