/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberSearchDtoResponseExcelDownloadDriverUser = {
    approvalStatusValue?: string;
    businessGroupName?: string;
    businessLicenseApprovalStatusValue?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessTypeValue?: string;
    carTonTypeValue?: string;
    carTypeValue?: string;
    cargoTransportWorkerCertificateApprovalStatusValue?: string;
    copyOfBankbookApprovalStatusValue?: string;
    driverLicenseApprovalStatusValue?: string;
    driverUserName?: string;
    idRegistrationFileStatus?: MemberSearchDtoResponseExcelDownloadDriverUser.idRegistrationFileStatus;
    isLoadLiabilityInsuranceTarget?: boolean;
    joinStatus?: MemberSearchDtoResponseExcelDownloadDriverUser.joinStatus;
    joinStatusValue?: string;
    loadLiabilityInsuranceApprovalStatusValue?: string;
    mdmCode?: string;
    mdmRegistrationStatusValue?: string;
    memberApprovalCompletedAt?: string;
    memberCategoryName?: string;
    memberCategoryType?: MemberSearchDtoResponseExcelDownloadDriverUser.memberCategoryType;
    memberCreatedAt?: string;
    plateNumber?: string;
    registrationPathTypeValue?: string;
    settlementApprovalStatusValue?: string;
    userId?: string;
    vehicleOptionTypeValues?: Array<string>;
    vehicleOptionTypes?: Array<'FROZEN' | 'LIFT' | 'NON_VIBRATE' | 'PARTITION' | 'REFRIGERATED' | 'RISING_WING' | 'ROOT_TEMPERATURE' | 'STOWAGE_BACK_OPEN' | 'STOWAGE_LEFT_OPEN' | 'STOWAGE_RIGHT_OPEN'>;
    vehicleRegistrationApprovalStatusValue?: string;
};
export namespace MemberSearchDtoResponseExcelDownloadDriverUser {
    export enum idRegistrationFileStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum joinStatus {
        ASSOCIATE_NO_FILE = 'ASSOCIATE_NO_FILE',
        ASSOCIATE_WITH_FILE = 'ASSOCIATE_WITH_FILE',
        DEACTIVATED = 'DEACTIVATED',
        JOIN_REJECT = 'JOIN_REJECT',
        REGULAR = 'REGULAR',
        RE_SIGN_UP = 'RE_SIGN_UP',
        SLEEP = 'SLEEP',
        SLEEP_DEACTIVATED = 'SLEEP_DEACTIVATED',
        TEMP_DRIVER = 'TEMP_DRIVER',
        TEMP_TO_REGULAR = 'TEMP_TO_REGULAR',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
}

