/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CouponDtoCouponPolicyListResult = {
    countType?: CouponDtoCouponPolicyListResult.countType;
    countValue?: Array<number>;
    couponPolicyId?: number;
    couponStatusValue?: string;
    couponTitle?: string;
    createdAt?: string;
    createdBy?: string;
    endDateTime?: string;
    maximumDiscount?: number;
    priceType?: CouponDtoCouponPolicyListResult.priceType;
    priceValue?: number;
    serviceTypeValue?: string;
    startDateTime?: string;
    useEndDateTime?: string;
    useStartDateTime?: string;
};
export namespace CouponDtoCouponPolicyListResult {
    export enum countType {
        INTERVAL = 'INTERVAL',
    }
    export enum priceType {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
    }
}

