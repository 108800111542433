/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminQuickPaySettlementDtoQuickPayApplyScheduledRequest = {
    billNo?: string;
    businessRegistrationNumber?: string;
    isTempUser?: boolean;
    orderNo?: string;
    pageNo?: number;
    pageSize?: number;
    plateNumber?: string;
    quickPayApplyStatus?: SearchAdminQuickPaySettlementDtoQuickPayApplyScheduledRequest.quickPayApplyStatus;
    sortColumn?: string;
    sortDirection?: string;
};
export namespace SearchAdminQuickPaySettlementDtoQuickPayApplyScheduledRequest {
    export enum quickPayApplyStatus {
        NEW_APPLY = 'NEW_APPLY',
        REAPPLY = 'REAPPLY',
    }
}

