import { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios'

export class ApiError<T = unknown, D = any> extends Error implements AxiosError<T, D> {
  config?: InternalAxiosRequestConfig<D>
  code?: string
  request?: any
  response?: AxiosResponse<T, D>
  isAxiosError: boolean
  status?: number
  toJSON: () => object
  cause?: Error

  constructor(error: AxiosError<T, D>, message?: string, name = '') {
    super(message ?? error.message)

    this.name = name ?? error.name
    this.stack = error.stack
    this.config = error.config
    this.code = error.code
    this.request = error.request
    this.response = error.response
    this.isAxiosError = error.isAxiosError
    this.status = error.status
    this.toJSON = error.toJSON
    this.cause = error.cause
  }
}
