/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessDtoResponseMdmRequest = {
    businessId?: string;
    mdmRegistrationStatus?: BusinessDtoResponseMdmRequest.mdmRegistrationStatus;
};
export namespace BusinessDtoResponseMdmRequest {
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
}

