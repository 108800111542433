/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonbAddressDto } from './JsonbAddressDto';
import type { JsonbCar } from './JsonbCar';
import type { JsonbPickupDropMethod } from './JsonbPickupDropMethod';
import type { OrderItemDtoRequest } from './OrderItemDtoRequest';
import type { OrderWayPointDto } from './OrderWayPointDto';
import type { Point } from './Point';
export type OrderCreateDtoRequest = {
    businessPaymentId?: string;
    departmentType?: OrderCreateDtoRequest.departmentType;
    dispatchMethod?: OrderCreateDtoRequest.dispatchMethod;
    driverFixedFee?: number;
    driverMemberId?: string;
    dropMethod: JsonbPickupDropMethod;
    dropRequestAt: string;
    freightType?: OrderCreateDtoRequest.freightType;
    fromAddress: JsonbAddressDto;
    fromPoint: Point;
    isMultiOrder?: boolean;
    isRecommendedRouteUsed?: boolean;
    isRound?: boolean;
    multiOrderCarTonTypeOption?: OrderCreateDtoRequest.multiOrderCarTonTypeOption;
    orderItems: Array<OrderItemDtoRequest>;
    orderOptionCashOnDeliveryTypes?: Array<'DROP_CASH_ON_DELIVERY' | 'RETURN_CASH_ON_DELIVERY' | 'WAYPOINT10_CASH_ON_DELIVERY' | 'WAYPOINT11_CASH_ON_DELIVERY' | 'WAYPOINT12_CASH_ON_DELIVERY' | 'WAYPOINT13_CASH_ON_DELIVERY' | 'WAYPOINT14_CASH_ON_DELIVERY' | 'WAYPOINT15_CASH_ON_DELIVERY' | 'WAYPOINT16_CASH_ON_DELIVERY' | 'WAYPOINT17_CASH_ON_DELIVERY' | 'WAYPOINT18_CASH_ON_DELIVERY' | 'WAYPOINT19_CASH_ON_DELIVERY' | 'WAYPOINT1_CASH_ON_DELIVERY' | 'WAYPOINT20_CASH_ON_DELIVERY' | 'WAYPOINT2_CASH_ON_DELIVERY' | 'WAYPOINT3_CASH_ON_DELIVERY' | 'WAYPOINT4_CASH_ON_DELIVERY' | 'WAYPOINT5_CASH_ON_DELIVERY' | 'WAYPOINT6_CASH_ON_DELIVERY' | 'WAYPOINT7_CASH_ON_DELIVERY' | 'WAYPOINT8_CASH_ON_DELIVERY' | 'WAYPOINT9_CASH_ON_DELIVERY'>;
    orderOptionItemTypes?: Array<'DROP_ITEM_SET' | 'RETURN_ITEM_SET' | 'WAYPOINT10_ITEM_SET' | 'WAYPOINT11_ITEM_SET' | 'WAYPOINT12_ITEM_SET' | 'WAYPOINT13_ITEM_SET' | 'WAYPOINT14_ITEM_SET' | 'WAYPOINT15_ITEM_SET' | 'WAYPOINT16_ITEM_SET' | 'WAYPOINT17_ITEM_SET' | 'WAYPOINT18_ITEM_SET' | 'WAYPOINT19_ITEM_SET' | 'WAYPOINT1_ITEM_SET' | 'WAYPOINT20_ITEM_SET' | 'WAYPOINT2_ITEM_SET' | 'WAYPOINT3_ITEM_SET' | 'WAYPOINT4_ITEM_SET' | 'WAYPOINT5_ITEM_SET' | 'WAYPOINT6_ITEM_SET' | 'WAYPOINT7_ITEM_SET' | 'WAYPOINT8_ITEM_SET' | 'WAYPOINT9_ITEM_SET'>;
    orderPaymentMethodType?: OrderCreateDtoRequest.orderPaymentMethodType;
    ownerFixedFee?: number;
    ownerMemberId?: string;
    pickupMethod: JsonbPickupDropMethod;
    pickupRequestAt: string;
    quickTonType?: OrderCreateDtoRequest.quickTonType;
    requestCar: JsonbCar;
    returnRequestAt?: string;
    toAddress: JsonbAddressDto;
    toPoint: Point;
    trackingId?: string;
    wayPointDtos?: Array<OrderWayPointDto>;
};
export namespace OrderCreateDtoRequest {
    export enum departmentType {
        BUILDING_MATERIALS = 'BUILDING_MATERIALS',
        FURNITURE = 'FURNITURE',
        HF = 'HF',
        HOUSEHOLD = 'HOUSEHOLD',
        KITCHEN = 'KITCHEN',
        RDC_CHANGWON = 'RDC_CHANGWON',
        RDC_DAEGU = 'RDC_DAEGU',
        RDC_DAEJEON = 'RDC_DAEJEON',
        RDC_GWANGJU = 'RDC_GWANGJU',
        RDC_JEJU = 'RDC_JEJU',
        RDC_JEONJU = 'RDC_JEONJU',
        RDC_ULSAN = 'RDC_ULSAN',
        RDC_WONJU = 'RDC_WONJU',
        RDC_YANGSAN = 'RDC_YANGSAN',
        RF = 'RF',
        SOFA = 'SOFA',
        WINDOWS = 'WINDOWS',
    }
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum multiOrderCarTonTypeOption {
        TON11_0 = 'TON11_0',
        TON25_0 = 'TON25_0',
        TON5_0 = 'TON5_0',
    }
    export enum orderPaymentMethodType {
        CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
    }
    export enum quickTonType {
        DARMAS = 'DARMAS',
        LABOR = 'LABOR',
        MOTORCYCLE = 'MOTORCYCLE',
    }
}

