import { TokenDtoReissueResult } from 'src/data'

import { IAccessToken } from '@features/common/types'

export default class RefreshTokenModelMapper {
  static mapToModel(loginData: TokenDtoReissueResult): IAccessToken {
    return {
      refreshToken: loginData.refreshToken,
      refreshTokenExpiredAt: loginData.refreshTokenExpiredAt,
      accessToken: loginData.accessToken,
      accessTokenExpiredAt: loginData.accessTokenExpiredAt,
      grantType: loginData.grantType,
    }
  }
}
