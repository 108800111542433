/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderDetailDtoRequestChangeOrderFreightType = {
    freightType: OrderDetailDtoRequestChangeOrderFreightType.freightType;
    orderId: string;
};
export namespace OrderDetailDtoRequestChangeOrderFreightType {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

