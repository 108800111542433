// 대시보드
export const ROOT_PATH = '/'
export const DASHBOARD_PATH = '/dashboard'

// error
export const E404_PATH = '/404'

// 로그인
export const LOGIN_PATH = '/login'

// 회원가입
export const SIGNUP_PATH = '/signup'

export const MYPAGE_PATH = '/mypage'

/**
 * 회원관리
 */

// 회원관리
export const USER_MANAGEMENT = 'user-management'

// 회원관리 > 신규회원관리 > 화주
export const NEW_OWNER_MANAGEMENT_PATH = '/user-management/new-owners'

// 화물관리 > 신규회원관리 > 화주 > 상세페이지
export const NEW_OWNER_MANAGEMENT_DETAIL_PATH = '/user-management/new-owners/[memberId]'

// 회원관리 > 신규회원관리 > 차주
export const NEW_DRIVER_MANAGEMENT_PATH = '/user-management/new-drivers'

// 화물관리 > 신규회원관리 > 차주 > 상세페이지
export const NEW_DRIVER_MANAGEMENT_DETAIL_PATH = '/user-management/new-drivers/[memberId]'

// 회원관리 > 신규회원관리 > 임시차 차주
export const NEW_PROVISIONARY_DRIVER_MANAGEMENT_PATH = '/user-management/new-provisionary-drivers'

// 회원관리 > 신규회원관리 > 파트너
export const NEW_PARTNER_MANAGEMENT_PATH = '/user-management/new-partners'

// 화물관리 > 신규회원관리 > 임시차 차주 > 상세페이지
export const NEW_PROVISIONARY_DRIVER_MANAGEMENT_DETAIL_PATH = '/user-management/new-provisionary-drivers/[memberId]'

// 회원관리 > 회원조회 > 화주
export const OWNER_MANAGEMENT_PATH = '/user-management/owners'

// 회원관리 > 회원조회 > 차주
export const DRIVER_MANAGEMENT_PATH = '/user-management/drivers'

// 회원관리 > 회원조회 > 임시차 차주
export const PROVISIONARY_DRIVER_MANAGEMENT_PATH = '/user-management/provisionary-drivers'

// 회원관리 > 회원조회 > 파트너
export const PARTNER_MANAGEMENT_PATH = '/user-management/partners'

// 회원관리 > 회원조회 > 화주 > 상세페이지
export const OWNER_MANAGEMENT_DETAIL_PATH = '/user-management/owners/[memberId]'

// 회원관리 > 회원조회 > 차주 > 상세페이지
export const DRIVER_MANAGEMENT_DETAIL_PATH = '/user-management/drivers/[memberId]'

// 회원관리 > 회원조회 > 임시차 차주 > 상세페이지
export const PROVISIONARY_DRIVER_MANAGEMENT_DETAIL_PATH = '/user-management/provisionary-drivers/[memberId]'

// 회원관리 > 탈퇴 회원 조회
export const WITHDRAWAL_MANAGEMENT_PATH = '/user-management/withdrawal/[userType]'

// 회원관리 > 탈퇴 회원 조회 > 화주 탈퇴 회원
export const WITHDRAWAL_OWNERS_MANAGEMENT_PATH = '/user-management/withdrawal/owners'

// 회원관리 > 탈퇴 회원 조회 > 차주 탈퇴 회원
export const WITHDRAWAL_DRIVERS_MANAGEMENT_PATH = '/user-management/withdrawal/drivers'

// 회원관리 > 탈퇴 회원 조회 > 파트너 탈퇴 회원
export const WITHDRAWAL_PARTNER_MANAGEMENT_PATH = '/user-management/withdrawal/partner'

// 회원관리 > 신규회원관리 > 신용거래 신청현황
export const CREDIT_TRANSACTION_STATUS_PATH = '/user-management/credit-transaction-status'

// 회원관리 > 신용거래 현황
export const CREDIT_DETAIL_STATUS_PATH = '/user-management/credit-detail-status/[creditType]'

// 회원관리 > 신용거래 현황 > 현재 이용중
export const CREDIT_DETAIL_STATUS_TRUST_PATH = '/user-management/credit-detail-status/available'

// 회원관리 > 신용거래 현황 > 현재 이용불가
export const CREDIT_DETAIL_STATUS_DISTRUST_PATH = '/user-management/credit-detail-status/unavailable'

// 회원관리 > 신용거래 상세
export const CREDIT_DETAIL_STATUS_DETAIL_PATH = '/user-management/credit-detail-status/detail/[id]'

// 회원관리 > 휴면 회원 조회
export const DORMANT_USER_MANAGEMENT = '/user-management/dormant'
// 회원관리 > 휴면 회원 조회

export const DORMANT_USER_MANAGEMENT_PATH = '/user-management/dormant/[pageType]'

// 회원관리 > 휴면 화주회원 조회
export const DORMANT_OWNER_USER_MANAGEMENT = '/user-management/dormant/owners'

// 회원관리 > 휴면 차주회원 조회
export const DORMANT_DRIVER_USER_MANAGEMENT = '/user-management/dormant/drivers'
/**
 * 고객관리
 */

// 고객관리
export const CUSTOMER_MANAGEMENT = 'customer-management'

// 고객관리 > CTI
export const CTI_MANAGEMENT_PATH = 'https://cti.unban.ai/'

/**
 * 계약물류
 */
// 계약물류
export const CONTRACT_LOGISTICS = 'contract-logistics'

// 계약물류 > 매출 계약운임 관리
export const CONTRACT_LOGISTICS_SALES_FARE_PATH = '/contract-logistics/sales-fare'

// 계약물류 > 매출 계약운임 관리 > 상세페이지
export const CONTRACT_LOGISTICS_SALES_FARE_DETAIL_PATH = '/contract-logistics/sales-fare/[id]'

// 계약물류 > 매출 계약운임 관리 > 상세페이지 > 수정
export const CONTRACT_LOGISTICS_SALES_FARE_EDIT_PATH = '/contract-logistics/sales-fare/[id]/edit'

// 계약물류 > 매출 계약운임 관리 > 상세페이지 > 수정
export const CONTRACT_LOGISTICS_SALES_FARE_NEW_PATH = '/contract-logistics/sales-fare/[id]/new'

// 계약물류 > 매입 계약운임 관리
export const CONTRACT_LOGISTICS_PURCHASE_FARE_PATH = '/contract-logistics/purchase-fare'

// 계약물류 > 매입 계약운임 관리 > 상세페이지
export const CONTRACT_LOGISTICS_PURCHASE_FARE_DETAIL_PATH = '/contract-logistics/purchase-fare/[id]'

// 계약물류 > 매입 계약운임 관리 > 상세페이지 > 수정
export const CONTRACT_LOGISTICS_PURCHASE_FARE_EDIT_PATH = '/contract-logistics/purchase-fare/[id]/edit'

// 계약물류 > 매입 계약운임 관리 > 상세페이지 > 추가
export const CONTRACT_LOGISTICS_PURCHASE_FARE_NEW_PATH = '/contract-logistics/purchase-fare/[id]/new'

// 계약물류 > 퍼시스 그룹
export const CONTRACT_LOGISTICS_FURSYS_PATH = 'fursys'

// 계약물류 > 퍼시스 그룹 > 거래매칭현황
export const CONTRACT_LOGISTICS_FURSYS_MATCHING_PATH = '/contract-logistics/fursys/matching-status'

// 화물관리 > 거래매칭현황 > 상세페이지
export const CONTRACT_LOGISTICS_FURSYS_MATCHING_DETAIL_PATH = '/contract-logistics/fursys/matching-status/[orderId]'

// 계약물류 > 퍼시스 그룹 > 운송현황관리
export const CONTRACT_LOGISTICS_FURSYS_DRIVING_PATH = '/contract-logistics/fursys/driving-status'

// 화물관리 > 운송현황관리 > 상세페이지
export const CONTRACT_LOGISTICS_FURSYS_DRIVING_DETAIL_PATH = '/contract-logistics/fursys/driving-status/[orderId]'

// 계약물류 > B마트
export const B_MART = 'bMart'
export const CONTRACT_LOGISTICS_B_MART_MATCHING_PATH = '/contract-logistics/bMart/matching-status'
export const CONTRACT_LOGISTICS_B_MART_MATCHING_DETAIL_PATH = '/contract-logistics/bMart/matching-status/[orderId]'
export const CONTRACT_LOGISTICS_B_MART_DRIVING_PATH = '/contract-logistics/bMart/driving-status'
export const CONTRACT_LOGISTICS_B_MART_DRIVING_DETAIL_PATH = '/contract-logistics/bMart/driving-status/[orderId]'

// 내부물자
export const INTERNAL = 'internal-logistics'
export const INTERNAL_PND = 'pnd'
export const INTERNAL_LOGISTICS = 'logistics'
export const ORDER_PND_PATH = '/internal-logistics/pnd/order'
export const ORDERS_PND_UPLOAD_PATH = '/internal-logistics/pnd/orders-upload'
export const MATCHING_STATUS_PND_PATH = '/internal-logistics/pnd/matching-status'
export const MATCHING_STATUS_LOGISTICS_PATH = '/internal-logistics/logistics/matching-status'
export const MATCHING_STATUS_PND_DETAIL_PATH = '/internal-logistics/pnd/matching-status/[orderId]'
export const MATCHING_STATUS_LOGISTICS_DETAIL_PATH = '/internal-logistics/logistics/matching-status/[orderId]'
export const DRIVING_STATUS_PND_PATH = '/internal-logistics/pnd/driving-status'
export const DRIVING_STATUS_LOGISTICS_PATH = '/internal-logistics/logistics/driving-status'
export const DRIVING_STATUS_PND_DETAIL_PATH = '/internal-logistics/pnd/driving-status/[orderId]'
export const DRIVING_STATUS_LOGISTICS_DETAIL_PATH = '/internal-logistics/logistics/driving-status/[orderId]'
export const ORDER_LOGISTICS_PATH = '/internal-logistics/logistics/order'
export const ORDERS_UPLOAD_LOGISTICS_PATH = '/internal-logistics/logistics/orders-upload'

// SK 네트웍스
export const SK_NETWORKS = 'setting-management'
export const SK_NETWORKS_ORDER = 'setting-management/order'

/**
 * 화물관리
 */

// 화물관리
export const CARGO_MANAGEMENT = 'cargo-management'

// 화물관리 > 전체주문관리
export const ORDERS_PATH = '/cargo-management/orders'

// 화물관리 > 거래매칭현황
export const MATCHING_STATUS_PATH = '/cargo-management/matching-status'

// 화물관리 > 거래매칭현황 > 상세페이지
export const MATCHING_STATUS_DETAIL_PATH = '/cargo-management/matching-status/[orderId]'

// 화물관리 > 운송현황관리
export const DRIVING_STATUS_PATH = '/cargo-management/driving-status'

// 화물관리 > 운송현황관리 > 상세페이지
export const DRIVING_STATUS_DETAIL_PATH = '/cargo-management/driving-status/[orderNo]'

// 화물관리 > 운송현황관리(전체)
export const DRIVING_STATUS_ALL_PATH = '/cargo-management/driving-status-all'

// 화물관리 > 운송현황관리(전체) > 상세페이지
export const DRIVING_STATUS_ALL_DETAIL_PATH = '/cargo-management/driving-status-all/[orderNo]'

// 화물관리 > 거래내역관리
export const TRANSACTION_HISTORY_PATH = '/cargo-management/transaction-history'

// 화물관리 > 선호운송권역등록현황
export const PREFER_DRIVING_AREA_STATUS_PATH = '/cargo-management/prefer-driving-area'

// 화물관리 > 선호운송권역등록현황 > 상세페이지
export const PREFER_DRIVING_AREA_STATUS_DETAIL_PATH = '/cargo-management/prefer-driving-area/[orderId]'

// 화물관리 > 화주 직영차 운송현황
export const OWNER_DIRECT_CAR_STATUS_PATH = '/cargo-management/owner-direct-car'

// 화물관리 > 화주 직영차 운송현황 > 상세페이지
export const OWNER_DIRECT_CAR_STATUS_DETAIL_PATH = '/cargo-management/owner-direct-car/[orderId]'

/**
 * 차량관제
 */

// 차량관제
export const DRIVING_CONTROL_MANAGEMENT = '/driving-management/unban'

/**
 * 정산관리
 */

// 정산관리
export const SETTLEMENT_MANAGEMENT = 'settlement-management'

// 정산관리 > PG결제정보확인
export const PG_PAYMENT = '/settlement-management/pg-payment'

// 정산관리 > PG결제정보확인
export const PG_PAYMENT_PATH = '/settlement-management/pg-payment/[pageType]'

// 정산관리 > PG결제정보확인 > PG결제정보
export const PG_PAYMENT_HISTORY_PATH = '/settlement-management/pg-payment/payment'

// 정산관리 > PG결제정보확인 > 결제실패주문조회
export const PG_PAYMENT_FAIL_HISTORY_PATH = '/settlement-management/pg-payment/payment-fail'

// 정산관리 > 퀵페이지급요청
export const QUICKPAY_PAYMENT_REQUESTS = '/settlement-management/quickpay-payment-requests'

// 정산관리 > 퀵페이지급요청
export const QUICKPAY_PAYMENT_REQUESTS_PATH = '/settlement-management/quickpay-payment-requests/[pageType]'

// 정산관리 > 퀵페이지급요청 > 세금계산서발행상태조회
export const TAX_BILL_PATH = '/settlement-management/quickpay-payment-requests/tax-bill'

// 정산관리 > 퀵페이지급요청 > 퀵페이 요청 가능 주문
export const QUICKPAY_REQUEST_PATH = '/settlement-management/quickpay-payment-requests/quickpay-request'

// 정산관리 > 퀵페이지급요청 > 퀵페이지급실패
export const QUICKPAY_FAIL_PATH = '/settlement-management/quickpay-payment-requests/quickpay-fail'

// 정산관리 > 퀵페이지급요청 > 차주정산대기
export const DRIVER_WAITING_PATH = '/settlement-management/quickpay-payment-requests/driver-waiting'

// 정산관리 > 퀵페이지급요청 > 외부정보망 발행 세금계산서 입력 및 정산
export const NOT_ISSUE_TAX_INVOICE_PATH = '/settlement-management/quickpay-payment-requests/not-issue-tax-invoice'

// 정산관리 > 퀵페이지급내역확인
export const QUICKPAY_PAYMENT_HISTORY_PATH = '/settlement-management/quickpay-payment-history'

// 정산관리 > 매출매입현황
export const SALES_PURCHASE_STATUS_PATH = '/settlement-management/sales-purchase-status'

// 정산관리 > 매출매입정산
export const SALES_PURCHASE_SETTLEMENT = '/settlement-management/sales-purchase-settlement'

// 정산관리 > 매출매입정산
export const SALES_PURCHASE_SETTLEMENT_PATH = '/settlement-management/sales-purchase-settlement/[pageType]'
export const SALES_PURCHASE_SETTLEMENT_CREDIT_TRANSACTION_PATH =
  '/settlement-management/sales-purchase-settlement/credit-transaction'
export const SALES_PURCHASE_SETTLEMENT_CREDIT_TRANSACTION_DETAIL_PATH =
  '/settlement-management/sales-purchase-settlement/credit-transaction/[code]'

// 정산관리 > 매출매입정산 > 매출 전표 조회 및 회계전기
export const SALES_PATH = '/settlement-management/sales-purchase-settlement/sales'

// 정산관리 > 매출매입정산 > 매입 전표 조회 및 회계전기
export const PURCHASE_PATH = '/settlement-management/sales-purchase-settlement/purchase'

// 정산관리 > 수수료 정산
export const FEE_SETTLEMENT_PATH = '/settlement-management/fee-settlement'

// 정산관리 > 수수료 정산
export const FEE_SETTLEMENT_REQUESTS_PATH = '/settlement-management/fee-settlement/[pageType]'

// 정산관리 > 수수료 정산 > 매출(PG) 수수료 정산
export const FEE_SETTLEMENT_PG_PATH = '/settlement-management/fee-settlement/fee-settlement-pg'

// 정산관리 > 수수료 정산 > 매입(퀵페이) 수수료 정산
export const FEE_SETTLEMENT_QUICKPAY_PATH = '/settlement-management/fee-settlement/fee-settlement-quickpay'

// 정산관리 > 매출매입정산 > 계약물류 추가운임비 추가
export const CONTRACT_LOGISTICS_ADD_CODE_PATH =
  '/settlement-management/sales-purchase-settlement/contract-logistics-add-cost'

// 정산관리 > 정산그룹 조회
export const SETTLEMENT_GROUP_LIST_PATH = '/settlement-management/settlement-group'

/**
 * 최적운임
 */

// 최적운임
export const ESTIMATED_FARE = 'estimated-fare'

// 운임설정 > 최적운임 가격설정
export const ESTIMATED_FARE_OPTIONS_PATH = '/estimated-fare/optimal-fare-setting'

//운임설정 > 최적운임 추가운임 설정
export const ESTIMATED_ADDITINAL_OPTIONS_PATH = '/estimated-fare/additional-fare-setting'
export const ESTIMATED_ADDITINAL_OPTIONS_FULL_FARE_PATH = '/estimated-fare/additional-fare-setting/full-fare'
export const ESTIMATED_ADDITINAL_REQUESTS_PATH = '/estimated-fare/additional-fare-setting/[pageType]'

/**
 * 프로모션
 */

// 프로모션
export const PROMOTION = 'promotion'

// 프로모션 > 프로모션 설정
export const PROMOTION_SETTINGS_PATH = '/promotion/settings'

// 프로모션 > 프로모션 설정
export const PROMOTION_SETTINGS_REQUESTS_PATH = '/promotion/settings/[pageType]'

// 프로모션 > 프로모션 설정  > 진행 프로모션
export const PROMOTION_SETTINGS_PROGRESS_PATH = '/promotion/settings/promotion-progress'

// 프로모션 > 프로모션 설정  > 종료 프로모션
export const PROMOTION_SETTINGS_COMPLETE_PATH = '/promotion/settings/promotion-complete'

// 프로모션 > 프로모션 설정  > 예정 프로모션
export const PROMOTION_SETTINGS_UPCOMING_PATH = '/promotion/settings/promotion-upcoming'

export const PROMOTION_SETTINGS_PROGRESS_DETAIL_PATH = '/promotion/settings/promotion-progress-detail/[promotionId]'
export const PROMOTION_SETTINGS_PROGRESS_CREATE_PATH = '/promotion/settings/promotion-progress-detail'

// 프로모션 > 차주 팝업/배너
export const DRIVER_PROMOTION_CONFIG = '/promotion/driver-promotion'

// 프로모션 > 차주 팝업/배너
export const DRIVER_PROMOTION_CONFIG_PATH = '/promotion/driver-promotion/[pageType]'

// 프로모션 > 차주 하단 팝업
export const DRIVER_PROMOTION_CONFIG_POPUP_PATH = '/promotion/driver-promotion/popup'

// 프로모션 > 차주하단팝업 > 등록 페이지
export const DRIVER_PROMOTION_CONFIG_POPUP_ADD_PATH = '/promotion/driver-promotion/popup/add'

// 프로모션 > 차주하단팝업 > 상세페이지
export const DRIVER_PROMOTION_POPUP_DETAIL_PATH = '/promotion/driver-promotion/popup/[id]'

// 프로모션 > 차주전면팝업
export const DRIVER_PROMOTION_CONFIG_POPUP_FRONT_PATH = '/promotion/driver-promotion/popup-front'

// 프로모션 > 차주전면팝업 > 등록 페이지
export const DRIVER_PROMOTION_CONFIG_POPUP_FRONT_ADD_PATH = '/promotion/driver-promotion/popup-front/add'

// 프로모션 > 차주 팝업/배너 > 팝업 상세페이지
export const DRIVER_PROMOTION_POPUP_FRONT_DETAIL_PATH = '/promotion/driver-promotion/popup-front/[id]'

// 프로모션 > 차주 배너
export const DRIVER_PROMOTION_CONFIG_BANNER_PATH = '/promotion/driver-promotion/banner'

// 프로모션 > 차주 배너 추가 페이지
export const DRIVER_PROMOTION_CONFIG_BANNER_ADD_PATH = '/promotion/driver-promotion/banner/add'

// 프로모션 > 차주 배너 추가 페이지
export const DRIVER_PROMOTION_CONFIG_BANNER_DETAIL_PATH = '/promotion/driver-promotion/banner/[id]'

// 프로모션 > 화주 팝업/배너
export const OWNER_PROMOTION_CONFIG = '/promotion/owner-promotion'

// 프로모션 > 화주 팝업/배너
export const OWNER_PROMOTION_CONFIG_PATH = '/promotion/owner-promotion/[pageType]'

// 프로모션 > 화주 팝업
export const OWNER_PROMOTION_CONFIG_POPUP_PATH = '/promotion/owner-promotion/popup'

// 프로모션 > 화주 배너
export const OWNER_PROMOTION_CONFIG_BANNER_PATH = '/promotion/owner-promotion/banner'

// 프로모션 > 화주 팝업/배너 > 팝업 상세페이지
export const OWNER_PROMOTION_POPUP_DETAIL_PATH = '/promotion/owner-promotion/popup/[id]'

// 프로모션 > 화주 팝업/배너 > 배너 상세페이지
export const OWNER_PROMOTION_BANNER_DETAIL_PATH = '/promotion/owner-promotion/banner/[id]'
// 프로모션 > 프로모션 설정  > 프로모션 상세
export const PROMOTION_SETTINGS_DETAIL_PATH = '/promotion/settings/promotion-progress'
export const PROMOTION_SETTINGS_DETAIL_LIST_PATH = '/promotion/settings/promotion-progress/[promotionId]'

// 프로모션 > 프로모션 설정  > 프로모션 추가
export const PROMOTION_SETTINGS_CREATE_PATH = '/promotion/settings/promotion-create'

// 프로모션 > 대형스티커 인증
export const PROMOTION_DRIVER_BIG_STICKER_PATH = '/promotion/driver-big-sticker'
export const PROMOTION_DRIVER_BIG_STICKER_CONFIG_PATH = '/promotion/driver-big-sticker/[pageType]'

// 프로모션 > 대형스티커 인증 > 리스트
export const PROMOTION_DRIVER_BIG_STICKER_LIST_PATH = '/promotion/driver-big-sticker/list'

// 프로모션 > 대형스티커 인증 > 상세페이지
export const PROMOTION_DRIVER_BIG_STICKER_DETAIL_PATH = '/promotion/driver-big-sticker/list/[id]'

// 프로모션 > 대형스티커 정산 신청 목록 > 리스트
export const PROMOTION_DRIVER_BIG_STICKER_SETTLEMENT_LIST_PATH = '/promotion/driver-big-sticker/settlement-list'

// 프로모션 > 대형스티커 정산 신청 목록 > 상세 페이지
export const PROMOTION_DRIVER_BIG_STICKER_SETTLEMENT_DETAIL_PATH = '/promotion/driver-big-sticker/settlement-list/[id]'

export const COUPON_PATH = '/promotion/coupon'
export const COUPON_DETAIL_PATH = '/promotion/coupon/detail'
export const COUPON_LIST_PATH = '/promotion/coupon/list'
export const COUPON_HISTORY_PATH = '/promotion/coupon/history'

/**
 * 기준정보
 */

// 기준정보
export const STANDARD_MANAGEMENT = 'standard-management'

// 기준정보 > 코드 관리
export const CODE_PATH = '/standard-settings/code'

// 기준정보 > 차종별 최대적재기준관리
export const VEHICLE_MAXIMUM_LOADING_PATH = '/standard-settings/vehicle-maximum-loading'

/**
 * 공통관리
 */

// 공통관리
export const COMMON_MANAGEMENT = 'common-management'

// 공통관리 > 운영자관리
export const ADMIN_MANAGEMENT_PATH = '/common-management/admin-users'

// 공통관리 > 운영자관리 > 상세페이지
export const ADMIN_DETAIL_MANAGEMENT_PATH = '/common-management/admin-users/[adminId]'

// 공통관리 > 위치정보 접근 취급 대장
export const LOCATION_INFO_ACCESS_MANAGEMENT_PATH = '/common-management/location-info-access-logs'

// 공통관리 > 시스템 접근 권한 로그
export const SYSTEM_ACCESS_PERMISSION_MANAGEMENT_PATH = '/common-management/system-access-permission-logs'

// 공통관리 > 시스템 사용 로그
export const SYSTEM_USING_MANAGEMENT_PATH = '/common-management/system-using-logs'

// 공통관리 > 기밀/개인정보 접근 로그
export const PRIVACY_ACCESS_MANAGEMENT_PATH = '/common-management/privacy-access-logs'

/**
 * 권한관리
 */

// 권한관리
export const AUTH_MANAGEMENT = 'auth-management'

// 권한관리 > 내부사용자관리
export const AUTH_USERS_PATH = '/permission-management/users'

/**
 * 게시물관리
 */

// 게시물 관리
export const POST_MANAGEMENT = 'post-management'

// 게시물 관리 > 공지사항관리
export const NOTICE_PATH = '/board-management/notice'

// 게시물 관리 > FAQ관리
export const FAQ_PATH = '/board-management/faq'

/**
 * 통계관리
 */

// 통계관리
export const STATISTICS_MANAGEMENT = 'statistics-management'

// 통계관리 > 회원가입현황
export const SIGN_UP_STATUS_PATH = '/statistics-management/registration'

// 통계관리 > 화주별거래내역현황
export const TRANSACTION_HISTORY_BY_OWNER_PATH = '/statistics-management/transaction-history-by-owner'

// 통계관리 > 차주별거래내역현황
export const TRANSACTION_HISTORY_BY_DRIVER_PATH = '/statistics-management/transaction-history-by-driver'

/**
 * 설정
 */

// 설정
export const SETTING_MANAGEMENT = 'setting-management'

// 설정 > 시스템 설정관리
export const SERVICE_CONFIG_PATH = '/setting-management/service-config'

// 설정 > 사업자그룹 설정
export const GROUP_CONFIG_PATH = '/setting-management/group-config'

// 회계팀 > 회원조회
export const ACCOUNTING_TEAM = '/accounting-team'

export const UNAUTH_ROUTES = [LOGIN_PATH, E404_PATH]

/**
 * 운송포탈
 */
export const PORTAL_ORDER_PATH = '/portal/order-management'

// 운임그룹 설정
export const PRICE_GROUP_PATH = '/price-group'
// 운임그룹 > 매출 계약운임 관리
export const PRICE_GROUP_SALES_FARE_PATH = '/price-group/[pageType]/sales-fare'

// 운임그룹 > 매출 계약운임 관리 > 상세페이지
export const PRICE_GROUP_SALES_FARE_DETAIL_PATH = '/price-group/[pageType]/sales-fare/[id]'

// 운임그룹 > 매출 계약운임 관리 > 상세페이지 > 수정
export const PRICE_GROUP_SALES_FARE_EDIT_PATH = '/price-group/[pageType]/sales-fare/[id]/edit'

// 운임그룹 > 매출 계약운임 관리 > 상세페이지 > 수정
export const PRICE_GROUP_SALES_FARE_NEW_PATH = '/price-group/[pageType]/sales-fare/[id]/new'

// 운임그룹 > 매입 계약운임 관리
export const PRICE_GROUP_PURCHASE_FARE_PATH = '/price-group/[pageType]/purchase-fare'

// 운임그룹 > 매입 계약운임 관리 > 상세페이지
export const PRICE_GROUP_PURCHASE_FARE_DETAIL_PATH = '/price-group/[pageType]/purchase-fare/[id]'

// 운임그룹 > 매입 계약운임 관리 > 상세페이지 > 수정
export const PRICE_GROUP_PURCHASE_FARE_EDIT_PATH = '/price-group/[pageType]/purchase-fare/[id]/edit'

// 운임그룹 > 매입 계약운임 관리 > 상세페이지 > 추가
export const PRICE_GROUP_PURCHASE_FARE_NEW_PATH = '/price-group/[pageType]/purchase-fare/[id]/new'

// 차주 플랫폼 서비스 > 매물 등록 현황 main path
export const ZIGTRUCK_INVENTORY_MAIN_PATH = '/zigtruck/inventory'

export const ZIGTRUCK_INVENTORY_REGISTERED = '/zigtruck/inventory/registered'

export const ZIGTRUCK_INVENTORY_TEMPORARY = '/zigtruck/inventory/temporary'

// 차주 플랫폼 서비스 > 매ㅔ문 등록 현황 > 상세 페이지
export const ZIGTRUCK_INVENTORY_DETAIL_PATH = '/zigtruck/inventory/[id]'
