/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfoDto } from './AddressInfoDto';
import type { Point } from './Point';
export type WayPointDto = {
    address?: AddressInfoDto;
    id?: string;
    isSetItemType?: boolean;
    memo?: string;
    orderBy?: number;
    point?: Point;
    type?: WayPointDto.type;
    waypointAt?: string;
    waypointRequestAt?: string;
};
export namespace WayPointDto {
    export enum type {
        DEPARTURE = 'DEPARTURE',
        DESTINATION = 'DESTINATION',
    }
}

