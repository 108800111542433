import { i18n } from 'next-i18next'

import { IListItem } from '@features/common/types'
import { VehicleType as VehicleTypeEnum } from '../enums'

export class CarTypeFilterModel implements IListItem {
  public key: string
  public value: any
  public selected?: boolean
  public disabled?: boolean

  constructor({ key, selected = false }) {
    this.key = key
    this.value = key
    this.selected = selected
  }

  public get label(): string {
    return i18n.t(`common:vehicleInfo.filterTypes.${this.key}`)
  }
}

export class CarTypeFilterCollection {
  items: Array<CarTypeFilterModel>

  constructor({ items = null }) {
    this.items = items
  }

  static init(): CarTypeFilterCollection {
    const carTypes = [
      VehicleTypeEnum.ALL,
      VehicleTypeEnum.CARGO,
      VehicleTypeEnum.TOP,
      VehicleTypeEnum.WING_BODY,
      VehicleTypeEnum.DARMAS,
      VehicleTypeEnum.LABOR,
      VehicleTypeEnum.TRAILER,
    ]
    const items = carTypes.map((item): CarTypeFilterModel => new CarTypeFilterModel({ key: item, selected: true }))
    return new CarTypeFilterCollection({ items })
  }

  public get selectedItems(): Array<CarTypeFilterModel> {
    return this.items.filter(item => item.selected)
  }

  public get selectedItemValues(): Array<string> {
    return this.selectedItems.find(item => item.key === VehicleTypeEnum.ALL)
      ? null
      : this.selectedItems.map(item => item.value)
  }
}
