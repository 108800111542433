/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessGroupNameResult } from './BusinessGroupNameResult';
export type BusinessOwnerUserDto = {
    businessGroup?: BusinessGroupNameResult;
    businessTransactionLevel?: BusinessOwnerUserDto.businessTransactionLevel;
    department?: string;
    email?: string;
    memberCategoryName?: string;
    memberCategoryType?: BusinessOwnerUserDto.memberCategoryType;
    name?: string;
    officePhoneNumber?: string;
    phoneNumber?: string;
    priceGroupPolicyIds?: Array<string>;
    transactionMethodType?: BusinessOwnerUserDto.transactionMethodType;
};
export namespace BusinessOwnerUserDto {
    export enum businessTransactionLevel {
        CAUTION = 'CAUTION',
        INVALID = 'INVALID',
        NORMAL = 'NORMAL',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

