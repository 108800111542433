/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type QuickPaySettlementDtoExternalTaxInvoiceIssuedResult = {
    billNo?: string;
    billNoUpdatedByAdminName?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    dispatchChannel?: QuickPaySettlementDtoExternalTaxInvoiceIssuedResult.dispatchChannel;
    documentId?: string;
    documentIdIssuedByAdminName?: string;
    documentStatusType?: QuickPaySettlementDtoExternalTaxInvoiceIssuedResult.documentStatusType;
    documentStatusTypeValue?: string;
    driverMemberCategoryType?: QuickPaySettlementDtoExternalTaxInvoiceIssuedResult.driverMemberCategoryType;
    driverMemberId?: string;
    orderId?: string;
    orderNo?: string;
    otherEtaxPublisherType?: QuickPaySettlementDtoExternalTaxInvoiceIssuedResult.otherEtaxPublisherType;
    otherEtaxPublisherTypeValue?: string;
    ownerMemberCategoryType?: QuickPaySettlementDtoExternalTaxInvoiceIssuedResult.ownerMemberCategoryType;
    paySuccessAt?: string;
    pickupRequestAt?: string;
    plateNumber?: string;
    quickpayRequestDesignateAt?: string;
    sapErrorMessage?: string;
    supplyAmount?: number;
    totalAmount?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace QuickPaySettlementDtoExternalTaxInvoiceIssuedResult {
    export enum dispatchChannel {
        EXTERNAL = 'EXTERNAL',
        REFERRAL = 'REFERRAL',
        UNBAN = 'UNBAN',
    }
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum driverMemberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
    export enum otherEtaxPublisherType {
        CARGO_MAN = 'CARGO_MAN',
        HOME_TAX = 'HOME_TAX',
        ONE_CALL = 'ONE_CALL',
        TWENTY_FOUR_CALL = 'TWENTY_FOUR_CALL',
    }
    export enum ownerMemberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
}

