/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UploadStickerImageDtoResult = {
    fileType?: UploadStickerImageDtoResult.fileType;
    fileUrl?: string;
    memberId?: string;
    stickerEventVerificationId?: string;
};
export namespace UploadStickerImageDtoResult {
    export enum fileType {
        APP_MAINTENANCE = 'APP_MAINTENANCE',
        BANNER = 'BANNER',
        BUSINESS_LICENSE = 'BUSINESS_LICENSE',
        CARGO_TRANSPORT_BUSINESS_PERMIT = 'CARGO_TRANSPORT_BUSINESS_PERMIT',
        CARGO_TRANSPORT_WORKER_CERTIFICATE = 'CARGO_TRANSPORT_WORKER_CERTIFICATE',
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        COPY_OF_BANKBOOK = 'COPY_OF_BANKBOOK',
        DRIVER_IMAGE = 'DRIVER_IMAGE',
        DRIVER_LICENSE = 'DRIVER_LICENSE',
        ETC = 'ETC',
        EVENT = 'EVENT',
        FAQ_CONTENT_IMAGE = 'FAQ_CONTENT_IMAGE',
        LOAD_LIABILITY_INSURANCE = 'LOAD_LIABILITY_INSURANCE',
        NOTICE_CONTENT_IMAGE = 'NOTICE_CONTENT_IMAGE',
        ORDER_EXCEL = 'ORDER_EXCEL',
        OWNER_APP_MAINTENANCE = 'OWNER_APP_MAINTENANCE',
        PAYMENT_GUARANTEE_INSURANCE_POLICY = 'PAYMENT_GUARANTEE_INSURANCE_POLICY',
        POPUP = 'POPUP',
        RECEIPT = 'RECEIPT',
        STICKER_EVENT_CONTRACT = 'STICKER_EVENT_CONTRACT',
        VEHICLE_BACK_STICKER = 'VEHICLE_BACK_STICKER',
        VEHICLE_LEFT_STICKER = 'VEHICLE_LEFT_STICKER',
        VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
        VEHICLE_RIGHT_STICKER = 'VEHICLE_RIGHT_STICKER',
        ZIGTRUCK_PRODUCT_AGREEMENT = 'ZIGTRUCK_PRODUCT_AGREEMENT',
        ZIGTRUCK_PRODUCT_INVOICE = 'ZIGTRUCK_PRODUCT_INVOICE',
    }
}

