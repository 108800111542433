/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderElectronicReceiptDtoElectronicReceiptInfo } from './OrderElectronicReceiptDtoElectronicReceiptInfo';
export type OrderElectronicReceiptDtoResult = {
    consumerBusinessAddress?: string;
    consumerBusinessName?: string;
    consumerBusinessNumber?: string;
    consumerBusinessRepresentativeName?: string;
    createdAt?: string;
    electronicReceiptInfoList?: Array<OrderElectronicReceiptDtoElectronicReceiptInfo>;
    orderElectronicReceiptNo?: string;
    orderNo?: string;
    orderType?: OrderElectronicReceiptDtoResult.orderType;
    providerBusinessAddress?: string;
    providerBusinessName?: string;
    providerBusinessNumber?: string;
    providerBusinessRepresentativeName?: string;
};
export namespace OrderElectronicReceiptDtoResult {
    export enum orderType {
        MULTI = 'MULTI',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

