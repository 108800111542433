/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderVehicleListDtoOrderVehicleInfo = {
    businessRegistrationNumber?: string;
    carTonType?: string;
    carType?: string;
    consignmentMdm?: string;
    consignmentVehicleId?: number;
    createdAt?: string;
    createdBy?: string;
    driverMdm?: string;
    isUsed?: boolean;
    plateNumber?: string;
    updatedAt?: string;
    updatedBy?: string;
    vehicleOperationType?: OrderVehicleListDtoOrderVehicleInfo.vehicleOperationType;
    vehicleOperationTypeValue?: string;
};
export namespace OrderVehicleListDtoOrderVehicleInfo {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

