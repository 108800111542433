/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OffsetTimeReq } from './OffsetTimeReq';
import type { PriceConfigInfoReq } from './PriceConfigInfoReq';
import type { PriceConfigTonInfo } from './PriceConfigTonInfo';
export type PriceConfigDtoReq = {
    createdDate?: string;
    displayOrder?: number;
    endDate?: string;
    endTime?: OffsetTimeReq;
    priceConfigType: PriceConfigDtoReq.priceConfigType;
    priceInfo: PriceConfigInfoReq;
    startDate?: string;
    startTime?: OffsetTimeReq;
    status: PriceConfigDtoReq.status;
    title: string;
    tonInfo: PriceConfigTonInfo;
};
export namespace PriceConfigDtoReq {
    export enum priceConfigType {
        EVERYDAY = 'EVERYDAY',
        NONE = 'NONE',
        WEEKDAY = 'WEEKDAY',
        WEEKEND_AND_HOLIDAY = 'WEEKEND_AND_HOLIDAY',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        DISABLED = 'DISABLED',
    }
}

