/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAccessLogDtoAccessLogDto } from './AdminAccessLogDtoAccessLogDto';
export type AdminInfoWithLogDto = {
    accessLogs?: Array<AdminAccessLogDtoAccessLogDto>;
    adminId?: string;
    adminName?: string;
    isLocked?: boolean;
    loginFailCount?: number;
    memberId?: string;
    memberRoleType?: AdminInfoWithLogDto.memberRoleType;
    phoneNumber?: string;
    slackId?: string;
};
export namespace AdminInfoWithLogDto {
    export enum memberRoleType {
        ADMIN_MEMBER_USER = 'ADMIN_MEMBER_USER',
        ADMIN_O_NE_TPS_USER = 'ADMIN_O_NE_TPS_USER',
        ADMIN_O_NE_USER = 'ADMIN_O_NE_USER',
        ADMIN_SETTLEMENT_USER = 'ADMIN_SETTLEMENT_USER',
        ADMIN_SUPER_USER = 'ADMIN_SUPER_USER',
        ADMIN_TPS_USER = 'ADMIN_TPS_USER',
        ADMIN_TRANSPORT_USER = 'ADMIN_TRANSPORT_USER',
        ADMIN_USER = 'ADMIN_USER',
        MAIN_DRIVER = 'MAIN_DRIVER',
        MAIN_OWNER = 'MAIN_OWNER',
        MAIN_PARTNER = 'MAIN_PARTNER',
        MAIN_PARTNER_DRIVER = 'MAIN_PARTNER_DRIVER',
        SUB_DRIVER = 'SUB_DRIVER',
        SUB_OWNER = 'SUB_OWNER',
        SUB_PARTNER = 'SUB_PARTNER',
        SUPER_OWNER = 'SUPER_OWNER',
    }
}

