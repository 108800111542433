/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchDriverPopupDtoResponseInfo = {
    carTonTypeValue?: string;
    carTonTypes?: Array<string>;
    createdAt?: string;
    createdBy?: string;
    endDate?: string;
    id?: number;
    name?: string;
    signUrl?: string;
    startDate?: string;
    status?: SearchDriverPopupDtoResponseInfo.status;
    statusValue?: string;
};
export namespace SearchDriverPopupDtoResponseInfo {
    export enum status {
        FINNISH = 'FINNISH',
        PLAN = 'PLAN',
        PROCEEDING = 'PROCEEDING',
    }
}

