/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OwnerInfo = {
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessType?: OwnerInfo.businessType;
    businessTypeValue?: string;
    linkedBusinessName?: string;
    mdmCode?: string;
    transactionMethodType?: OwnerInfo.transactionMethodType;
    userId?: string;
};
export namespace OwnerInfo {
    export enum businessType {
        CORPORATE = 'CORPORATE',
        INDIVIDUAL = 'INDIVIDUAL',
        NO_TAX = 'NO_TAX',
        SIMPLIFIED = 'SIMPLIFIED',
        SIMPLIFIED_NO_TAX = 'SIMPLIFIED_NO_TAX',
    }
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

