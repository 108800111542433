/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RegistrationFileApprovalStatus = {
    businessLicenseStatus?: RegistrationFileApprovalStatus.businessLicenseStatus;
    cargoTransportWorkerCertificateStatus?: RegistrationFileApprovalStatus.cargoTransportWorkerCertificateStatus;
    copyOfBankbookStatus?: RegistrationFileApprovalStatus.copyOfBankbookStatus;
    driverLicenseStatus?: RegistrationFileApprovalStatus.driverLicenseStatus;
    loadLiabilityInsuranceApprovalStatus?: RegistrationFileApprovalStatus.loadLiabilityInsuranceApprovalStatus;
    vehicleRegistrationStatus?: RegistrationFileApprovalStatus.vehicleRegistrationStatus;
};
export namespace RegistrationFileApprovalStatus {
    export enum businessLicenseStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum cargoTransportWorkerCertificateStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum copyOfBankbookStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum driverLicenseStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum loadLiabilityInsuranceApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
    export enum vehicleRegistrationStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REGISTERING = 'REGISTERING',
        REGISTRATION = 'REGISTRATION',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        WAIT = 'WAIT',
    }
}

