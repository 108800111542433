import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { QueryState } from '@tanstack/react-query'
import React from 'react'

type Props = React.PropsWithChildren<{
  state?: QueryState
}>

function ReactQueryProvider(props: Props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: false,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        refetchOnReconnect: false,
        onError: (_err: any) => {},
      },
      mutations: {
        onError: (_err: any) => {},
        onMutate: () => {},
        onSettled: () => {},
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={props.state}>{props.children}</Hydrate>
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
