/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateBusinessOwnerUserRequest = {
    businessGroupNo?: number;
    businessTransactionLevel?: UpdateBusinessOwnerUserRequest.businessTransactionLevel;
    department?: string;
    email?: string;
    memberCategoryType?: UpdateBusinessOwnerUserRequest.memberCategoryType;
    name?: string;
    officePhoneNumber?: string;
    phoneNumber?: string;
    priceGroupPolicyIds?: Array<string>;
};
export namespace UpdateBusinessOwnerUserRequest {
    export enum businessTransactionLevel {
        CAUTION = 'CAUTION',
        INVALID = 'INVALID',
        NORMAL = 'NORMAL',
    }
    export enum memberCategoryType {
        DRIVER_MEMBER = 'DRIVER_MEMBER',
        DRIVER_TEMP = 'DRIVER_TEMP',
        DRIVER_TEST = 'DRIVER_TEST',
        OWNER_CONTRACT = 'OWNER_CONTRACT',
        OWNER_INNER = 'OWNER_INNER',
        OWNER_NORMAL = 'OWNER_NORMAL',
        OWNER_TEST = 'OWNER_TEST',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        PARTNER_MEMBER = 'PARTNER_MEMBER',
        PARTNER_OWNER = 'PARTNER_OWNER',
    }
}

