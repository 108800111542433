/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SapSettlementDtoInvoiceDriverFile } from './SapSettlementDtoInvoiceDriverFile';
export type QuickPaySettlementDtoTaxInvoiceResult = {
    billNo?: string;
    businessRegistrationNumber?: string;
    deliveryCompleteDate?: string;
    driverMemberId?: string;
    freightType?: QuickPaySettlementDtoTaxInvoiceResult.freightType;
    freightTypeValue?: string;
    isTempUserValue?: string;
    orderId?: string;
    orderNo?: string;
    plateNumber?: string;
    supplyAmount?: number;
    taxInvoiceFile?: SapSettlementDtoInvoiceDriverFile;
    taxInvoiceReportAt?: string;
    taxInvoiceRequestAt?: string;
    taxInvoiceStatus?: string;
    taxInvoiceStatusValue?: string;
    totalAmount?: number;
    vatAmount?: number;
    wciAmount?: number;
};
export namespace QuickPaySettlementDtoTaxInvoiceResult {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

