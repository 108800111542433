import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { LOGIN_PATH, UNAUTH_ROUTES, ROOT_PATH } from '@constants/routes'
import * as Sentry from '@sentry/nextjs'
import { useAuth } from '../hooks'

function RouteGuard({ children }) {
  const router = useRouter()
  const { asPath } = router
  const { getCurrentUser, logout } = useAuth()
  const [authorized, setAuthorized] = useState(false)
  const user = getCurrentUser()

  // 센트리에 사용자 정보를 정의한다.
  if (user?.userId) {
    Sentry.getCurrentScope().setUser({ key: user.userId, id: user.userId, username: user.userName })
  }

  // Auth를 체크하여 하위컴포넌트를 노출할지에대한 여부를 판단 한다.
  useEffect(() => {
    if (!user?.authorized && !UNAUTH_ROUTES.includes(asPath)) {
      setAuthorized(false)
      logout()

      return
    }

    setAuthorized(true)
  }, [user, router])

  // 로그인 되어있는 경우 이전 페이지가 아래 url일 때, 이동을 막고 history를 현재 url로 변경한다.
  useEffect(() => {
    if (user?.authorized) {
      if (router.pathname === LOGIN_PATH) {
        router.push(ROOT_PATH)
        return
      }
      router.beforePopState(({ as }) => {
        if (as === LOGIN_PATH) {
          window.history.pushState(null, '', router.asPath)
          return false
        }

        return true
      })
    }
  }, [router])

  return authorized && children
}

export { RouteGuard }
