/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfoDto } from './AddressInfoDto';
import type { JsonbCar } from './JsonbCar';
import type { JsonbPickupDropMethod } from './JsonbPickupDropMethod';
import type { OrderItemDtoResult } from './OrderItemDtoResult';
import type { OrderJsonbCarDtoResult } from './OrderJsonbCarDtoResult';
import type { Point } from './Point';
import type { WayPointDto } from './WayPointDto';
export type OrderDuplicateDtoResult = {
    businessId?: string;
    businessName?: string;
    createdAt?: string;
    dispatchMethod?: OrderDuplicateDtoResult.dispatchMethod;
    dispatchMethodValue?: string;
    dropAt?: string;
    dropMethod?: JsonbPickupDropMethod;
    dropRequestAt?: string;
    estimateMaxFee?: number;
    estimateMinFee?: number;
    fromAddress?: AddressInfoDto;
    fromPoint?: Point;
    memberId?: string;
    memberName?: string;
    orderId?: string;
    orderItems?: Array<OrderItemDtoResult>;
    orderNo?: string;
    ownerOrderType?: OrderDuplicateDtoResult.ownerOrderType;
    pickupAt?: string;
    pickupMethod?: JsonbPickupDropMethod;
    pickupRequestAt?: string;
    requestCar?: JsonbCar;
    requestCarInfo?: OrderJsonbCarDtoResult;
    toAddress?: AddressInfoDto;
    toPoint?: Point;
    updatedAt?: string;
    wayPointDtos?: Array<WayPointDto>;
};
export namespace OrderDuplicateDtoResult {
    export enum dispatchMethod {
        CONSIGNED = 'CONSIGNED',
        DIRECT = 'DIRECT',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
        UNBAN = 'UNBAN',
    }
    export enum ownerOrderType {
        MULTI = 'MULTI',
        ROUND_TRIP = 'ROUND_TRIP',
        SINGLE = 'SINGLE',
        WAYPOINTS = 'WAYPOINTS',
    }
}

