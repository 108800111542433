/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractFreightDtoBusinessGroupInfo } from './ContractFreightDtoBusinessGroupInfo';
export type ContractFreightDtoInfoResult = {
    businessGroups?: Array<ContractFreightDtoBusinessGroupInfo>;
    businessId?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    contractFreightDriverDesignationId?: number;
    contractFreightEndAt?: string;
    contractFreightStartAt?: string;
    createdAt?: string;
    driverType?: ContractFreightDtoInfoResult.driverType;
    isActiveContractOwnerPriceExisted?: boolean;
    memberId?: string;
    paymentMethodValue?: string;
    updatedAt?: string;
    userId?: string;
};
export namespace ContractFreightDtoInfoResult {
    export enum driverType {
        DIRECT = 'DIRECT',
        FIXED = 'FIXED',
        PARTNER_DRIVER = 'PARTNER_DRIVER',
        TEMP_DRIVER = 'TEMP_DRIVER',
        UNBAN = 'UNBAN',
    }
}

