import { useState } from 'react'
import { IConfirmModalProps } from '@features/promotion/common/types/confirmModalTypes'

export const useConfirmModal = () => {
  const [isOpenedConfirmModal, setIsOpenedConfirmModal] = useState<boolean>(false)
  const [confirmModalDescription, setConfirmModalDescription] = useState<{ title: string; description: string }>({
    title: '',
    description: '',
  })

  const setConfirmModal = ({ isOpened, title, description }: IConfirmModalProps) => {
    if (typeof isOpened === 'boolean') {
      setIsOpenedConfirmModal(isOpened)
    }

    if (title || description) {
      setConfirmModalDescription({ title, description })
    }
  }
  return {
    isOpenedConfirmModal,
    confirmModalDescription,
    setConfirmModal,
  }
}
