/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MemberDtoResponseMemberSettlementApprovalStatus = {
    memberId: string;
    settlementApprovalStatus: MemberDtoResponseMemberSettlementApprovalStatus.settlementApprovalStatus;
};
export namespace MemberDtoResponseMemberSettlementApprovalStatus {
    export enum settlementApprovalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
}

