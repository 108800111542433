/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OffsetTime } from './OffsetTime';
import type { PriceConfigInfo } from './PriceConfigInfo';
import type { PriceConfigTonInfo } from './PriceConfigTonInfo';
export type PriceConfigDto = {
    createdDate?: string;
    displayOrder?: number;
    endDate?: string;
    endTime?: OffsetTime;
    priceConfigType: PriceConfigDto.priceConfigType;
    priceInfo: PriceConfigInfo;
    startDate?: string;
    startTime?: OffsetTime;
    status: PriceConfigDto.status;
    title: string;
    tonInfo: PriceConfigTonInfo;
};
export namespace PriceConfigDto {
    export enum priceConfigType {
        EVERYDAY = 'EVERYDAY',
        NONE = 'NONE',
        WEEKDAY = 'WEEKDAY',
        WEEKEND_AND_HOLIDAY = 'WEEKEND_AND_HOLIDAY',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        DISABLED = 'DISABLED',
    }
}

