import { AdminInfoWithLogDto } from 'src/data'
export const MemberRoleType = [
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_USER,
    value: '더운반 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_SUPER_USER,
    value: '관리자 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_O_NE_USER,
    value: 'O-NE 본부 직접 배차 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_TPS_USER,
    value: '운송포털 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_MEMBER_USER,
    value: '회원 관리 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_SETTLEMENT_USER,
    value: '정산 관리 그룹',
  },
  {
    key: AdminInfoWithLogDto.memberRoleType.ADMIN_TRANSPORT_USER,
    value: '운송 관리 그룹',
  },
] as const

export type MemberRoleEnumType = (typeof MemberRoleType)[keyof typeof MemberRoleType]
