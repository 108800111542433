import dayjs from 'dayjs'

export class OrderDateTimeUtil {
  /**
   * 상차 ~ 하차 거리로 부터 시간을 가져오는 함수
   * @param distance
   */
  static getMinuteFromDistance = (distance: number): number => {
    // 아직 예상 거리계산이 이루어지지 않은 경우, 기본 값 1h30m
    if (!distance) {
      return 90
    }

    const km = distance / 1000

    // 장거리(251km 이상) 2시간 30분
    if (km > 250) {
      return 150
    }

    // 중거리(250km 이하) 2시간
    if (km > 60) {
      return 120
    }

    // 단거리 (60km 이하) 1시간 30분
    if (km > 0) {
      return 90
    }

    return 90
  }

  static getMinLoadingDate = (distance: number, isExcludePickupDateTimeCheck: boolean = false): Date => {
    const minute = isExcludePickupDateTimeCheck ? 0 : OrderDateTimeUtil.getMinuteFromDistance(distance)
    return dayjs(OrderDateTimeUtil.ceilToNearest30(new Date())).add(minute, 'minutes').toDate()
  }

  /**
   * 가장 가까운 30분을 찾는 함수
   * 예: 1시 11분 -> 1시 30분, 1시 31분 -> 2시 00분
   * @param date
   */
  static ceilToNearest30 = (date: Date): Date => {
    const currentTime = dayjs(date).startOf('hours').toDate()
    const currentMinutes = date.getMinutes()
    const share = Math.ceil(currentMinutes / 15)
    const minutes = 15 * share

    if (currentMinutes / 30 === 0) {
      return date
    }

    const minutesToAdd = minutes > 30 ? 60 : 30

    return dayjs(currentTime).add(minutesToAdd, 'minutes').toDate()
  }

  static currentDate = (): Date => {
    const initDate = new Date()
    initDate.setHours(0)
    initDate.setMinutes(0)
    initDate.setSeconds(0)
    return initDate
  }

  static getDefaultBetweenTime = (spendTime: number) => {
    // 예상 소요 시간이 장거리에 해당 하는 2h 30m을 초과한 경우, 예상 소요시간으로 반환

    if (!spendTime) {
      return 30
    }

    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    const diffDate = new Date(today)
    diffDate.setMinutes(Math.ceil(spendTime / 60))

    const nearestDate = this.ceilToNearest30(diffDate)

    const t = new Date(today)
    const d = new Date(nearestDate)
    const diff = (d.getTime() - t.getTime()) / 1000 / 60

    // 상차지 하차지 사이의 소요시간 + 30
    return Math.ceil(Math.abs(diff)) + 30
  }

  /**
   *
   * @param isExcludePickupDateTimeCheck 시간에 따른 추가 소요 시간 부여 여부
   * @returns 초기화시 세팅할 상하차지 일정값
   */
  static getInitMinLoadingAndUnloadingDate = (isExcludePickupDateTimeCheck: boolean = false) => {
    const defaultLoadingTime = isExcludePickupDateTimeCheck ? 0 : this.getMinuteFromDistance(null)
    const minLoading = dayjs(this.ceilToNearest30(new Date())).add(defaultLoadingTime, 'minutes').toDate()
    const defaultBetweenTime = this.getDefaultBetweenTime(null)
    return {
      defaultBetweenTime,
      minLoadingDate: minLoading,
      minUnloadingDate: dayjs(minLoading).add(defaultBetweenTime, 'minutes').toDate(),
    }
  }
}
