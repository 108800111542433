import { useRef, useState } from 'react'

interface IUseDatePicker {
  dateFormat: string
  setSelected: (date: Date) => void
}

/**
 * DatePicker와 관련된 custom hook
 */
export const useDatePicker = ({ setSelected, dateFormat }: IUseDatePicker) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const [isOpened, setIsOpened] = useState<boolean>(false)

  /**
   * DatePicker를 활성화 한다.
   */
  const openDatePicker = () => {
    setIsOpened(true)
  }

  /**
   * DatePicker를 비활성화 한다.
   */
  const closeDatePicker = () => {
    setIsOpened(false)
  }

  /**
   * DatePicker에서 date 변경시 실행된다.
   */
  const changeDatePicker = (date: Date) => {
    setSelected(date)
    closeDatePicker()
  }

  return {
    ref,
    isOpened,
    dateFormat,
    openDatePicker,
    closeDatePicker,
    changeDatePicker,
  }
}
