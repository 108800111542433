/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NoticeCreateDtoRequest = {
    contents: string;
    endAt?: string;
    isPinned: boolean;
    noticeType: NoticeCreateDtoRequest.noticeType;
    startAt?: string;
    title: string;
};
export namespace NoticeCreateDtoRequest {
    export enum noticeType {
        EVENT = 'EVENT',
        NOTICE = 'NOTICE',
        UPDATE = 'UPDATE',
    }
}

