/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderVehicleRegisterDtoRequest = {
    consignmentMdm?: string;
    isUsed?: boolean;
    memberId?: string;
    vehicleId?: number;
    vehicleOperationType?: OrderVehicleRegisterDtoRequest.vehicleOperationType;
};
export namespace OrderVehicleRegisterDtoRequest {
    export enum vehicleOperationType {
        CONSIGNED = 'CONSIGNED',
        FIXED_BY_ITEM = 'FIXED_BY_ITEM',
        FIXED_MONTHLY_TOTAL = 'FIXED_MONTHLY_TOTAL',
    }
}

