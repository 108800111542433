/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchBusinessDtoRequest = {
    businessName?: string;
    transactionMethodType: SearchBusinessDtoRequest.transactionMethodType;
};
export namespace SearchBusinessDtoRequest {
    export enum transactionMethodType {
        CONTRACT_FREIGHT = 'CONTRACT_FREIGHT',
        CONTRACT_RANGE_FREIGHT = 'CONTRACT_RANGE_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
}

