/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NewPartnerUserDtoNewPartnerUserResult = {
    approvalStatus?: NewPartnerUserDtoNewPartnerUserResult.approvalStatus;
    approvalStatusValue?: string;
    businessGroupName?: string;
    businessName?: string;
    businessRegistrationNumber?: string;
    businessType?: NewPartnerUserDtoNewPartnerUserResult.businessType;
    businessTypeValue?: string;
    departmentName?: string;
    managerName?: string;
    mdmCode?: string;
    mdmRegistrationStatus?: NewPartnerUserDtoNewPartnerUserResult.mdmRegistrationStatus;
    mdmRegistrationStatusValue?: string;
    memberApprovalCompletedAt?: string;
    memberCreatedAt?: string;
    memberId?: string;
    officePhoneNumber?: string;
    userId?: string;
};
export namespace NewPartnerUserDtoNewPartnerUserResult {
    export enum approvalStatus {
        COMPLETE = 'COMPLETE',
        NONE = 'NONE',
        REJECT = 'REJECT',
        RETURN = 'RETURN',
        REVIEW = 'REVIEW',
        WAITING = 'WAITING',
    }
    export enum businessType {
        CORPORATE = 'CORPORATE',
        INDIVIDUAL = 'INDIVIDUAL',
        NO_TAX = 'NO_TAX',
        SIMPLIFIED = 'SIMPLIFIED',
        SIMPLIFIED_NO_TAX = 'SIMPLIFIED_NO_TAX',
    }
    export enum mdmRegistrationStatus {
        COMPLETE = 'COMPLETE',
        LEAVE = 'LEAVE',
        REAPPLY = 'REAPPLY',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
        WAITING = 'WAITING',
    }
}

