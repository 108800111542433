import { getRouteDetail } from '@cjconnect/react-common'
import {
  CHANGE_ORDER_COMPLETE_STATUS,
  CHANGE_ORDER_STATUS,
  CHANGE_WARE_HOUSE_STATUS,
  ORDER_DETAIL_DISPATCH_COMPLETED_MUTATION,
  ORDER_DETAIL_DISPATCH_OWNER_CANCEL_MUTATION,
  ORDER_DETAIL_EXTRA_INVOICE_CREATED_AT_QUERY,
  ORDER_DETAIL_EXTRA_INVOICE_HISTORY_MUTATION,
  ORDER_DETAIL_FOR_SELECTED_DRIVER_QUERY,
  ORDER_DETAIL_MEMO_QUERY,
  ORDER_DETAIL_MEMO_SAVE_MUTATION,
  ORDER_DETAIL_ORDER_CANCEL_MUTATION,
  ORDER_DETAIL_QUERY,
  ORDER_DETAIL_RANGE_INVOICE_INFO_QUERY,
  ORDER_DETAIL_RECEIPT_INFO_QUERY,
  ORDER_DETAIL_ROUTE_DETAIL_QUERY,
  ORDER_DETAIL_SEND_ORDER_RECEIPT_SMS_MUTATION,
  ORDER_DETAIL_SETTLEMENT_GROUP_QUERY,
  ORDER_DETAIL_UPDATE_MODIFY_INVOICE_INFO_MUTATION,
  ORDER_DETAIL_UPDATE_RECEIPT_INFO_MUTATION,
  PARTNER_CHANGE_ORDER_COMPLETE_STATUS,
  PARTNER_CHANGE_ORDER_STATUS,
  PARTNER_ORDER_DETAIL_ORDER_CANCEL_MUTATION,
  PARTNER_ORDER_DETAIL_QUERY,
  ROLLBACK_ORDER_STATUS,
} from '@/constants/queryKeys'
import { BaseResponse } from '@features/common/types/responses/baseResponse'
import { GetExtraInvoiceCreatedAtRequest } from '@features/orderDetail/types/getExtraInvoiceCreatedAtRequest'
import { GetExtraInvoiceCreatedAtResponse } from '@features/orderDetail/types/getExtraInvoiceCreatedAtResponse'
import { GetExtraInvoiceHistoryRequest } from '@features/orderDetail/types/getExtraInvoiceHistoryRequest'
import { GetExtraInvoiceHistoryResponse } from '@features/orderDetail/types/getExtraInvoiceHistoryResponse'
import { GetOrderDetailRequest } from '@features/orderDetail/types/getOrderDetailRequest'
import { GetOrderDetailResponse } from '@features/orderDetail/types/getOrderDetailResponse'
import { SetRollbackOrderStatusRequest } from '@features/orderDetail/types/setRollbackOrderStatusRequest'
import { SetRollbackOrderStatusResponse } from '@features/orderDetail/types/setRollbackOrderStatusResponse'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  DriverUserDtoOrderReceiptSmsRequest,
  OrderAdministrationMemoDtoGetRequest,
  OrderAdministrationMemoDtoGetResult,
  OrderAdministrationMemoDtoSaveRequest,
  OrderExtraInvoiceDtoCreatedRequest,
  OrderModifyInvoiceDtoRequest,
  OrderRangePriceDtoRequest,
  OrderRangePriceDtoResult,
  OrderReceiptDtoGetInfoRequest,
  OrderReceiptDtoGetInfoResult,
  OrderReceiptDtoRequestV2,
} from 'src/data'
import {
  DispatchCompleteRequest,
  DispatchCompleteResponse,
  DispatchOwnerCancelRequest,
  DispatchOwnerCancelResponse,
  GetRangeInvoiceResponse,
  IBusinessSettlementListGroupRequest,
  IBusinessSettlementListGroupResponse,
  IBusinessSettlementListGroupResponseResult,
  IRequestCarArrivalResponse,
  OrderCancelRequest,
  OrderCancelResponse,
  SetChangeOrderCompleteStatusRequest,
  SetChangeOrderCompleteStatusResponse,
  SetChangeOrderStatusRequest,
  SetChangeOrderStatusResponse,
  UpdateInvoiceResponse,
} from '../types'
import { useFetcher } from '@/providers/FetcherContext'

export const useOrderDetailQueryForSelectedDriver = (request: GetOrderDetailRequest, options?: UseQueryOptions) => {
  const apiInstance = useFetcher()

  /**
   * 거래매칭현황 > 주문상세 정보를 반환한다.
   * @returns 주문 상세 정보
   */
  const getOrderDetailForMatchingStatus = async <T>(request: GetOrderDetailRequest) =>
    apiInstance.post<T>(`/order/v1/detail`, request)

  const queryFn = async () => {
    const { data: response } = await getOrderDetailForMatchingStatus<GetOrderDetailResponse>(request)
    return response.result
  }

  return useQuery([ORDER_DETAIL_FOR_SELECTED_DRIVER_QUERY], queryFn, options)
}

export const useOrderDetailQuery = (request: GetOrderDetailRequest) => {
  const apiInstance = useFetcher()

  /**
   * 거래매칭현황 > 주문상세 정보를 반환한다.
   * @returns 주문 상세 정보
   */
  const getOrderDetailForMatchingStatus = async <T>(request: GetOrderDetailRequest) =>
    apiInstance.post<T>(`/order/v1/detail`, request)

  const queryFn = async () => {
    const { data: response } = await getOrderDetailForMatchingStatus<GetOrderDetailResponse>(request)
    return response.result
  }

  return useQuery([ORDER_DETAIL_QUERY], queryFn)
}

export const usePartnerOrderDetailQuery = (request: GetOrderDetailRequest) => {
  const apiInstance = useFetcher()

  /**
   * 운송사 운송현황 > 주문상세
   * @returns 주문 상세 정보
   */
  const getOrderDetailForPartner = async <T>(request: GetOrderDetailRequest) =>
    apiInstance.post<T>(`/partners/v1/order/detail`, request)

  const queryFn = async () => {
    const { data: response } = await getOrderDetailForPartner<GetOrderDetailResponse>(request)
    return response.result
  }

  return useQuery([PARTNER_ORDER_DETAIL_QUERY], queryFn)
}

/**
 * 배차 취소
 * @returns
 */
export const useDispatchOwnerCancelMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 배차 취소 Event
   * @returns
   */
  const dispatchOwnerCancel = async <T>(request: DispatchOwnerCancelRequest) =>
    apiInstance.post<T>(`/order/v1/order/dispatchOwnerCancel`, request)

  const mutationFn = async (orderId: string) => {
    const request: DispatchOwnerCancelRequest = {
      orderId,
      isIncludePaths: true,
    }
    const { data: response } = await dispatchOwnerCancel<DispatchOwnerCancelResponse>(request)
    return response.result
  }

  return useMutation([ORDER_DETAIL_DISPATCH_OWNER_CANCEL_MUTATION], mutationFn)
}

/**
 * 배차 완료
 * @returns
 */
export const useDispatchCompletedMutation = () => {
  const apiInstance = useFetcher()
  const dispatchComplete = async <T>(request: DispatchCompleteRequest) =>
    apiInstance.post<T>(`/order/v1/order/dispatchComplete`, request)

  const mutationFn = async (orderId: string) => {
    const request: DispatchCompleteRequest = { orderId, isIncludePaths: true }
    const { data: response } = await dispatchComplete<DispatchCompleteResponse>(request)
    return response.result
  }

  return useMutation([ORDER_DETAIL_DISPATCH_COMPLETED_MUTATION], mutationFn)
}

/**
 * 주문 취소
 * @returns
 */
export const useOrderCancelMutation = () => {
  const apiInstance = useFetcher()
  const cancelOrder = async <T>(request: OrderCancelRequest) => apiInstance.post<T>(`/order/v1/cancel`, request)

  const mutationFn = async (orderId: string) => {
    const request: OrderCancelRequest = { orderId, isIncludePaths: true }
    const { data: response } = await cancelOrder<OrderCancelResponse>(request)
    return response.result
  }

  return useMutation([ORDER_DETAIL_ORDER_CANCEL_MUTATION], mutationFn)
}

/**
 * 운송사 운송현황 > 주문상세 > 주문 취소
 * @returns
 */
export const usePartnerOrderCancelMutation = () => {
  const apiInstance = useFetcher()
  const cancelOrder = async <T>(request: OrderCancelRequest) =>
    apiInstance.post<T>(`/partners/v1/order/cancel`, request)

  const mutationFn = async (orderId: string) => {
    const request: OrderCancelRequest = { orderId, isIncludePaths: true }
    const { data: response } = await cancelOrder<OrderCancelResponse>(request)
    return response.result
  }

  return useMutation([PARTNER_ORDER_DETAIL_ORDER_CANCEL_MUTATION], mutationFn)
}

/**
 * 추가 운임 변경 날짜 리스트 조회
 * @param request
 * @param options
 * @returns
 */
export const useGetExtraInvoiceCreatedAt = (request: GetExtraInvoiceCreatedAtRequest, options: UseQueryOptions) => {
  const apiInstance = useFetcher()

  /**
   * 추가 운임 변경 날짜 리스트를 반환 한다.
   * @returns 날짜 리스트
   */
  const getExtraInvoiceCreatedAt = async <T>(request: GetExtraInvoiceCreatedAtRequest) =>
    apiInstance.post<T>(`/order/v1/extra-invoice/created-at`, request)

  const queryFn = async () => {
    const { data: response } = await getExtraInvoiceCreatedAt<GetExtraInvoiceCreatedAtResponse>(request)
    return response.result
  }

  return useQuery([ORDER_DETAIL_EXTRA_INVOICE_CREATED_AT_QUERY], queryFn, options)
}

/**
 * 추가 운임 히스토리 정보
 * @returns
 */
export const useExtraInvoiceHistoryMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 추가 운임 히스토리 정보를 반환 한다.
   * @returns 추가 운임 히스토리 정보
   */
  const getExtraInvoiceHistory = async <T>(request) => apiInstance.post<T>(`/order/v1/extra-invoice/history`, request)

  const mutationFn = async (request: GetExtraInvoiceHistoryRequest) => {
    const { data: response } = await getExtraInvoiceHistory<GetExtraInvoiceHistoryResponse>(request)
    return response.result
  }

  return useMutation([ORDER_DETAIL_EXTRA_INVOICE_HISTORY_MUTATION], mutationFn)
}

/**
 * 추가 운임 히스토리 정보
 * @returns
 */
export const useOriginalExtraInvoiceHistoryMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 추가 운임 히스토리 정보를 반환 한다.
   * @returns 추가 운임 히스토리 정보
   */
  const getExtraInvoiceHistory = async <T>(request) => apiInstance.post<T>(`/order/v1/extra-invoice/history`, request)

  const mutationFn = async (request: OrderExtraInvoiceDtoCreatedRequest) => {
    const { data: response } = await getExtraInvoiceHistory<GetExtraInvoiceHistoryResponse>(request)
    return response.result
  }

  return useMutation([ORDER_DETAIL_EXTRA_INVOICE_HISTORY_MUTATION, 'orginal'], mutationFn)
}

/**
 * 주문상세 페이지 > 주문상태 변경
 * @returns
 */
export const useChangeOrderStatusMutation = (request: SetChangeOrderStatusRequest) => {
  const apiInstance = useFetcher()
  const setChangeOrderStatus = async <T>(request: SetChangeOrderStatusRequest) =>
    apiInstance.post<T>(`/order/v1/change-order-status`, request)

  const mutationFn = async () => {
    const { data: response } = await setChangeOrderStatus<SetChangeOrderStatusResponse>(request)
    return response.result
  }

  return useMutation([CHANGE_ORDER_STATUS], mutationFn)
}

/**
 * 운송사현황 > 주문상세 페이지 > 주문상태 변경
 * @returns
 */
export const usePartnerChangeOrderStatusMutation = (request: SetChangeOrderStatusRequest) => {
  const apiInstance = useFetcher()
  const setPartnerChangeOrderStatus = async <T>(request: SetChangeOrderStatusRequest) =>
    apiInstance.post<T>(`/partners/v1/order/change-status`, request)

  const mutationFn = async () => {
    const { data: response } = await setPartnerChangeOrderStatus<SetChangeOrderStatusResponse>(request)
    return response.result
  }

  return useMutation([PARTNER_CHANGE_ORDER_STATUS], mutationFn)
}

export const useExpectRouteDetailQuery = (request: GetRouteDetail.Request, options?: UseQueryOptions) => {
  const queryFn = async () => {
    const { result } = await getRouteDetail(request, process.env.NEXT_PUBLIC_INAVI_APP_KEY)
    return result
  }

  return useQuery([ORDER_DETAIL_ROUTE_DETAIL_QUERY], queryFn, options)
}

/**
 * 주문상세 페이지 > 주문 완료상태로 변경
 * @returns
 */
export const useChangeOrderCompleteStatusMutation = () => {
  const apiInstance = useFetcher()
  const setChangeOrderCompleteStatus = async <T>(request: SetChangeOrderCompleteStatusRequest) =>
    apiInstance.post<T>('/order/v1/delivery-complete', request)

  const mutationFn = async (request: SetChangeOrderCompleteStatusRequest) => {
    const { data: response } = await setChangeOrderCompleteStatus<SetChangeOrderCompleteStatusResponse>(request)
    return response
  }

  return useMutation([CHANGE_ORDER_COMPLETE_STATUS], mutationFn)
}

/**
 * 운송사 현황 > 주문상세 페이지 > 주문 완료상태로 변경
 * @returns
 */
export const usePartnerChangeOrderCompleteStatusMutation = () => {
  const apiInstance = useFetcher()
  const setPartnerChangeOrderCompleteStatus = async <T>(request: SetChangeOrderCompleteStatusRequest) =>
    apiInstance.post<T>('/partners/v1/order/delivery-complete', request)

  const mutationFn = async (request: SetChangeOrderCompleteStatusRequest) => {
    const { data: response } = await setPartnerChangeOrderCompleteStatus<SetChangeOrderCompleteStatusResponse>(request)
    return response
  }

  return useMutation([PARTNER_CHANGE_ORDER_COMPLETE_STATUS], mutationFn)
}

export const useRollbackOrderStatusMutation = (
  request: SetRollbackOrderStatusRequest,
  options?: UseMutationOptions,
) => {
  const apiInstance = useFetcher()

  /**
   * 배차실패 또는 주문취소 주문 배차중으로 변경
   * @param request
   */
  const updateOrderStatusRollback = async <T>(request) =>
    apiInstance.post<T>('/order/v1/change-to-dispatching', request)

  const mutationFn = async () => {
    const { data: response } = await updateOrderStatusRollback<SetRollbackOrderStatusResponse>(request)
    return response
  }

  return useMutation([ROLLBACK_ORDER_STATUS], mutationFn, options)
}

export const useChangeWareHouseStatusMutation = (request, options?: UseMutationOptions) => {
  const apiInstance = useFetcher()

  /**
   * 도크 도착 예정 정보 전송
   * @param request
   * @returns
   */
  const requestCarArrival = async <T>(request) => apiInstance.post<T>('/order/v1/baros/car-requests-arrival', request)

  const mutationFn = async () => {
    const { data: response } = await requestCarArrival<IRequestCarArrivalResponse>(request)
    return response?.result
  }

  return useMutation([CHANGE_WARE_HOUSE_STATUS], mutationFn, options)
}

export const useSettlementGroupQuery = (request: IBusinessSettlementListGroupRequest) => {
  const apiInstance = useFetcher()

  /**
   * 정산 그룹 목록 조회
   * @param request
   * @returns
   */
  const getBusinessSettlementGroups = async <T>(request: IBusinessSettlementListGroupRequest) =>
    apiInstance.post<T>('/member-business-settlement-group/v1/list/order', request)

  const queryFn = async () => {
    const { data: response } = await getBusinessSettlementGroups<IBusinessSettlementListGroupResponse>(request)
    return response?.result
  }

  return useQuery<Array<IBusinessSettlementListGroupResponseResult>>([ORDER_DETAIL_SETTLEMENT_GROUP_QUERY], queryFn)
}

export const useGetRangeInvoiceQuery = (request: OrderRangePriceDtoRequest, options?: UseQueryOptions) => {
  const apiInstance = useFetcher()

  /**
   * 차주/화주 고정 운임 조회
   * @param request
   * @returns
   */
  const getRangeInvoice = async <T>(request: OrderRangePriceDtoRequest) =>
    apiInstance.post<T>('order/v1/range-invoice', request)

  const queryFn = async () => {
    const { data: response } = await getRangeInvoice<GetRangeInvoiceResponse>(request)
    return response.result
  }

  return useQuery<unknown, BaseResponse, OrderRangePriceDtoResult>(
    [ORDER_DETAIL_RANGE_INVOICE_INFO_QUERY],
    queryFn,
    options,
  )
}

export const useUpdateModifyInvoiceMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 차주/화주 고정 운임 변경
   * @param request
   * @returns
   */
  const modifyInvoice = async <T>(request: OrderModifyInvoiceDtoRequest) =>
    apiInstance.post<T>('order/v1/modify-invoice', request)

  const mutationFn = async (request: OrderModifyInvoiceDtoRequest) => {
    const { data: response } = await modifyInvoice<UpdateInvoiceResponse>(request)
    return response
  }

  return useMutation<UpdateInvoiceResponse, BaseResponse, OrderModifyInvoiceDtoRequest, unknown>(
    [ORDER_DETAIL_UPDATE_MODIFY_INVOICE_INFO_MUTATION],
    mutationFn,
  )
}

export const useGetReceiptInfoQuery = (request: OrderReceiptDtoGetInfoRequest) => {
  const apiInstance = useFetcher()

  /**
   * 인수증 관리하기 팝업
   * @param request
   * @returns
   */
  const getReceiptInfo = async <T>(request: OrderReceiptDtoGetInfoRequest) =>
    apiInstance.post<T>('/order/v1/receipt/info', request)

  const queryFn = async () => {
    const { data: response } = await getReceiptInfo<{ result: OrderReceiptDtoGetInfoResult }>(request)
    return response?.result
  }

  return useQuery([ORDER_DETAIL_RECEIPT_INFO_QUERY], queryFn)
}

export const useUpdateReceiptInfoMutation = () => {
  const apiInstance = useFetcher()

  /**
   *
   * 인수증 관리하기 팝업 > 저장하기
   * @param request
   * @returns
   */
  const updateReceiptInfo = async <T>(request: OrderReceiptDtoRequestV2) =>
    apiInstance.post<T>('/order/v2/receipt-update', request)

  const mutationFn = async (request: OrderReceiptDtoRequestV2) => {
    const { data: response } = await updateReceiptInfo<BaseResponse>(request)
    return response
  }

  return useMutation([ORDER_DETAIL_UPDATE_RECEIPT_INFO_MUTATION], mutationFn)
}

export const useSendOrderReceiptMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 차주 회원 - 주문 인수증 등록 sms 발송
   * @param request
   */
  const sendOrderReceiptSms = async <T>(request: DriverUserDtoOrderReceiptSmsRequest) =>
    apiInstance.post<T>('/driver/v2/order-receipt/sms', request)

  const mutationFn = async (request: DriverUserDtoOrderReceiptSmsRequest) => {
    const { data: response } = await sendOrderReceiptSms<BaseResponse>(request)
    return response
  }

  return useMutation([ORDER_DETAIL_SEND_ORDER_RECEIPT_SMS_MUTATION], mutationFn)
}

export const useMemoListQuery = (request: OrderAdministrationMemoDtoGetRequest) => {
  const apiInstance = useFetcher()

  /**
   * 특이사항 조회 팝업
   * @param request
   * @returns
   */
  const getMemoInfo = async <T>(request: OrderAdministrationMemoDtoGetRequest) =>
    apiInstance.post<T>('/order/v1/administration-memo/v1/list', request)

  const queryFn = async () => {
    const { data: response } = await getMemoInfo<{ result: OrderAdministrationMemoDtoGetResult }>(request)
    return response?.result
  }

  return useQuery([ORDER_DETAIL_MEMO_QUERY], queryFn)
}

export const useSaveOrderMemoMutation = () => {
  const apiInstance = useFetcher()

  /**
   * 특이사항 조회 > 주문특이사항 저장
   * @param request
   */
  const sendOrderReceiptSms = async <T>(request: OrderAdministrationMemoDtoSaveRequest) =>
    apiInstance.post<T>('/order/v1/administration-memo/v1/save', request)

  const mutationFn = async (request: OrderAdministrationMemoDtoSaveRequest) => {
    const { data: response } = await sendOrderReceiptSms<{ result: BaseResponse }>(request)
    return response
  }

  return useMutation([ORDER_DETAIL_MEMO_SAVE_MUTATION], mutationFn)
}
