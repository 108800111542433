/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OwnerUserDtoRequestOwnerOrderCreateUserInfo = {
    createOwnerUserType?: OwnerUserDtoRequestOwnerOrderCreateUserInfo.createOwnerUserType;
};
export namespace OwnerUserDtoRequestOwnerOrderCreateUserInfo {
    export enum createOwnerUserType {
        LOGISTICS = 'LOGISTICS',
        PND = 'PND',
        SK_NETWORKS = 'SK_NETWORKS',
    }
}

