import { LOGIN_PATH } from '@/constants/routes'
import { IAccessToken, User } from '../types'
import { useExpiryLocalStorage as expiryLocalStorageHook } from './useExpiryLocalStorage'
import RefreshTokenModelMapper from '@features/auth/mappers/refreshTokenModelMapper'
import { RefreshTokenResponse } from '@features/auth/types/responses/refreshTokenResponse'
import { ILogoutResponse } from '@features/auth/types/responses/logoutResponse'
import { useFetcher } from '@/providers/FetcherContext'

/**
 * 사용자 관련 hook
 *
 * @returns
 */
export const useAuth = () => {
  const apiInstance = useFetcher()

  /**
   * 현재 로그인된 사용자 정보를 반환한다.
   *
   * @returns User 사용자 정보
   */
  const getCurrentUser = (): User | null => {
    if (typeof window === 'undefined') return null

    const { getWithExpiry } = expiryLocalStorageHook()
    const storedUserInfo = getWithExpiry('_cu')
    if (!storedUserInfo) return null

    return storedUserInfo
  }

  /**
   * 로컬 스토리지에 사용자 정보를 기록한다.
   * @returns
   */
  const saveCurrentUser = (user: User): void => {
    const { setWithExpiry } = expiryLocalStorageHook()
    setWithExpiry('_cu', user)
  }

  /**
   * 로컬 스토리지로부터 인증 토큰을 얻는다.
   * @returns
   */
  const getToken = (): IAccessToken => {
    const { getWithExpiry } = expiryLocalStorageHook()
    const token = getWithExpiry('_ct') || ''
    if (token === '') return null

    return token as IAccessToken
  }

  /**
   * 로컬 스토리지로에 인증 토큰을 기록한다.
   * @returns
   */
  const saveToken = (token: IAccessToken) => {
    const { setWithExpiry } = expiryLocalStorageHook()
    setWithExpiry('_ct', token)
  }

  const getRefreshToken = async () => {
    const { refreshToken } = getToken()
    const { data: response } = await apiInstance.post<RefreshTokenResponse>(`/admin_user/v1/reissue-token`, {
      refreshToken,
    })
    const result = response.result
    return RefreshTokenModelMapper.mapToModel(result)
  }

  const logout = async (callLogoutApi: boolean = false) => {
    if (callLogoutApi) {
      try {
        await apiInstance.post<ILogoutResponse>(`/admin_user/v1/logout`)
      } catch (e) {
        // 로그아웃 api 에서 에러가 발생하더라도 무시하고 넘기도록 try catch 추가
      }
    }

    // tab은 제외하고, localStorage.clear()
    Object.keys(localStorage).forEach(key => {
      if (key !== 'ownerTab') {
        localStorage.removeItem(key)
      }
    })

    const win: Window = window
    win.location = LOGIN_PATH
  }

  /**
   * refresh 토큰이 존재하는지 여부 확인
   */
  const isExistRefreshToken = (): boolean => {
    const token = getToken()
    return !!token?.refreshToken
  }

  return { getCurrentUser, saveCurrentUser, getToken, saveToken, getRefreshToken, logout, isExistRefreshToken }
}
