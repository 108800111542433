import { i18n } from 'next-i18next'

import { IListItem } from '@features/common/types'
import { TonType as TonTypeEnum } from '../enums'

export class CarTonFilterModel implements IListItem {
  public key: string
  public value: any
  public selected?: boolean
  public disabled?: boolean

  constructor({ key, selected = false }) {
    this.key = key
    this.value = key
    this.selected = selected
  }

  public get label(): string {
    return i18n.t(`common:vehicleInfo.weights.${this.key}`)
  }
}

export class CarTonFilterCollection {
  items: Array<CarTonFilterModel>

  constructor({ items = null }) {
    this.items = items
  }

  static init(): CarTonFilterCollection {
    const carTons = [
      TonTypeEnum.ALL,
      TonTypeEnum.TON1_0,
      TonTypeEnum.TON1_4,
      TonTypeEnum.TON2_5,
      TonTypeEnum.TON3_5,
      TonTypeEnum.TON5_0,
      TonTypeEnum.TON5_0_PLUS,
      TonTypeEnum.TON11_0,
      TonTypeEnum.TON18_0,
      TonTypeEnum.TON25_0,
    ]
    const items = carTons.map((item): CarTonFilterModel => new CarTonFilterModel({ key: item, selected: true }))
    return new CarTonFilterCollection({ items })
  }

  public get selectedItems(): Array<CarTonFilterModel> {
    return this.items.filter(item => item.selected)
  }

  public get selectedItemValues(): Array<string> {
    return this.selectedItems.find(item => item.key === TonTypeEnum.ALL)
      ? null
      : this.selectedItems.map(item => item.value)
  }
}
