/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CouponDtoUsedCouponResult = {
    businessId?: string;
    businessName?: string;
    couponId?: number;
    couponNo?: string;
    couponPriceType?: CouponDtoUsedCouponResult.couponPriceType;
    couponPriceValue?: number;
    couponTitle?: string;
    expectedDiscount?: string;
    maximumDiscount?: number;
    priceValueForConfigModifyInfo?: number;
    userId?: string;
};
export namespace CouponDtoUsedCouponResult {
    export enum couponPriceType {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
    }
}

