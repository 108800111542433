/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentModifyDtoRequest = {
    businessId?: string;
    memo?: string;
    paymentConditionCode?: PaymentModifyDtoRequest.paymentConditionCode;
    status?: PaymentModifyDtoRequest.status;
};
export namespace PaymentModifyDtoRequest {
    export enum paymentConditionCode {
        D_3003 = 'D_3003',
        D_3015 = 'D_3015',
        D_3020 = 'D_3020',
        D_3023 = 'D_3023',
        D_3025 = 'D_3025',
        D_3028 = 'D_3028',
        D_3030 = 'D_3030',
        D_3035 = 'D_3035',
        D_3040 = 'D_3040',
        D_3042 = 'D_3042',
        D_3045 = 'D_3045',
        D_3050 = 'D_3050',
        D_3052 = 'D_3052',
        D_3055 = 'D_3055',
        D_3060 = 'D_3060',
        D_3065 = 'D_3065',
        D_3067 = 'D_3067',
        D_3070 = 'D_3070',
        D_3075 = 'D_3075',
        D_3080 = 'D_3080',
        D_3090 = 'D_3090',
        D_3120 = 'D_3120',
        D_3910 = 'D_3910',
    }
    export enum status {
        CONFIRM = 'CONFIRM',
        REQUEST = 'REQUEST',
        RETURN = 'RETURN',
    }
}

