/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PriceConfigInfoReq = {
    type: PriceConfigInfoReq.type;
    value?: number;
};
export namespace PriceConfigInfoReq {
    export enum type {
        FIXED = 'FIXED',
        PERCENT = 'PERCENT',
        REFERENCE = 'REFERENCE',
    }
}

