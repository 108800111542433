/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminSapSettlementDtoSapContractRequest = {
    businessId?: string;
    businessSettlementGroupIds?: Array<string>;
    businessText?: string;
    freightType?: SearchAdminSapSettlementDtoSapContractRequest.freightType;
    fromDate?: string;
    invoiceExtraModifyType?: SearchAdminSapSettlementDtoSapContractRequest.invoiceExtraModifyType;
    isRegistered?: boolean;
    orderMemberIds?: Array<string>;
    orderText?: string;
    pageNo?: number;
    pageSize?: number;
    settlementSearchDateType?: SearchAdminSapSettlementDtoSapContractRequest.settlementSearchDateType;
    sortColumn?: string;
    sortDirection?: string;
    toDate?: string;
};
export namespace SearchAdminSapSettlementDtoSapContractRequest {
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum invoiceExtraModifyType {
        MODIFIED = 'MODIFIED',
        NOT_SETTLED = 'NOT_SETTLED',
        NO_EXTRA = 'NO_EXTRA',
    }
    export enum settlementSearchDateType {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DOCUMENT_ISSUED = 'DOCUMENT_ISSUED',
        DROP = 'DROP',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        PAY_SUCCESS = 'PAY_SUCCESS',
        PICKUP = 'PICKUP',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
        QUICKPAY_REQUEST_DESIGNATED = 'QUICKPAY_REQUEST_DESIGNATED',
    }
}

