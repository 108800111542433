/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchAdminSapSettlementDtoSapRequest = {
    businessId?: string;
    businessMdmCode?: string;
    businessName?: string;
    businessSettlementGroupIds?: Array<string>;
    documentId?: string;
    documentStatusType?: SearchAdminSapSettlementDtoSapRequest.documentStatusType;
    freightType?: SearchAdminSapSettlementDtoSapRequest.freightType;
    fromDate?: string;
    isRegistered?: boolean;
    orderMemberIds?: Array<string>;
    orderNo?: string;
    pageNo?: number;
    pageSize?: number;
    paymentMethodType?: SearchAdminSapSettlementDtoSapRequest.paymentMethodType;
    settlementSearchDateType?: SearchAdminSapSettlementDtoSapRequest.settlementSearchDateType;
    sortColumn?: string;
    sortDirection?: string;
    toDate?: string;
};
export namespace SearchAdminSapSettlementDtoSapRequest {
    export enum documentStatusType {
        CANCELLED = 'CANCELLED',
        CANCEL_ISSUED = 'CANCEL_ISSUED',
        COMPLETE = 'COMPLETE',
        FAIL = 'FAIL',
        ISSUED = 'ISSUED',
        NONE = 'NONE',
    }
    export enum freightType {
        DISTRICT_FREIGHT = 'DISTRICT_FREIGHT',
        HALF_DAILY_FREIGHT = 'HALF_DAILY_FREIGHT',
        MIXED_FREIGHT = 'MIXED_FREIGHT',
        MONTHLY_FREIGHT = 'MONTHLY_FREIGHT',
        NORMAL_FREIGHT = 'NORMAL_FREIGHT',
        QUICK_FREIGHT = 'QUICK_FREIGHT',
        RANGE_FREIGHT = 'RANGE_FREIGHT',
    }
    export enum paymentMethodType {
        CREDIT_CARD = 'CREDIT_CARD',
        CREDIT_SALES = 'CREDIT_SALES',
        INTERNAL_CREDIT_SALES = 'INTERNAL_CREDIT_SALES',
        QUICK_PAY = 'QUICK_PAY',
    }
    export enum settlementSearchDateType {
        DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
        DOCUMENT_ISSUED = 'DOCUMENT_ISSUED',
        DROP = 'DROP',
        DROP_REQUEST = 'DROP_REQUEST',
        ORDER_CREATE = 'ORDER_CREATE',
        PAY_SUCCESS = 'PAY_SUCCESS',
        PICKUP = 'PICKUP',
        PICKUP_REQUEST = 'PICKUP_REQUEST',
        QUICKPAY_REQUEST_DESIGNATED = 'QUICKPAY_REQUEST_DESIGNATED',
    }
}

